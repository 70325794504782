
import Vue from 'vue';
import { ICellRendererParams } from 'ag-grid-community';
import { ReportTableData } from '@/components/report-table/contracts/table-data';
import { ReportStatusColors } from '@/enums/report-status-colors';

const ApprovalStatusCell = Vue.extend({
    name: 'ApprovalStatusCell',
    data (): {
        agGridParams: ICellRendererParams | undefined;
        } {
        return {
            agGridParams: undefined,
        };
    },
    beforeMount() {
        // this.params contains the cell & row information and
        // is made available to this component at creation time
        // see ICellRendererParams for more details
        this.agGridParams = (this as any).params;
    },
    computed: {
        tableData(): ReportTableData {
            return (this as any).params.data as ReportTableData;
        },
        status(): string {
            return this.tableData['col-approval-state'] ?? '';
        },
        approvalStatusColor(): ReportStatusColors {
            if (this.status) {
                return ReportStatusColors[this.status as keyof typeof ReportStatusColors];
            }
            return ReportStatusColors.New;
        }
    }
});
export default ApprovalStatusCell;
