export function getCurrentViewportWidth(): number {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
}

export function calculateWideSidebarWidth(): string {
    const vw = getCurrentViewportWidth();

    if (vw <= 425) {
        return '100vw';
    }

    if (vw > 425 && vw < 565) {
        return '100vw';
    }

    if (vw >= 565 && vw <= 768) {
        return '60vw';
    }

    if (vw > 768 && vw <= 1024) {
        return '45vw';
    }

    return '58rem';
}

export function calculateDefaultSidebarWidth(): string {
    const vw = getCurrentViewportWidth();

    if (vw < 565) {
        return '100vw';
    }

    if (vw >= 565 && vw <= 768) {
        return '30vw';
    }

    if (vw > 768 && vw <= 1024) {
        return '25vw';
    }

    return '38rem';
}

export function removeParamsFromNavItemPaths(path: string, params: { [key: string]: string }): string {
    let parameterlessPath = path;
    for (const paramKey in params) {
        if(params[paramKey]) {
            parameterlessPath = parameterlessPath.replaceAll(params[paramKey], '');
        }
    }
    if (parameterlessPath === '/') {
        return parameterlessPath.trimEnd();
    }
    // remove all trailing slashes (/),
    // this is a drawback of this algorithm
    return parameterlessPath.replace(/\/+$/, '').trimEnd();
}