import AverageReport from '@/components/average-report/average-report.vue';
import { ErrorLoaderIcons } from '@/enums/pebble-ui-enums';
import { EventBus } from '@/utils';
import Vue from 'vue';

const CentralAnalysisView = Vue.extend({
    name: 'CentralAnalysisView',
    components: {
        AverageReport,
    },
    data (): {
        sidebarOptionsList: Array<{ displayValue: string; componentName: string; disabled?: boolean; settingsType?: string }>;
        selectedComponentName: string;
        } {
        return {
            sidebarOptionsList: [],
            selectedComponentName: '',
        }
    },
    async mounted (): Promise<void> {
        this.fillSidebarOptionsList();
        this.registerCallbacks();
        await this.showAdminSidebar();
    },
    computed: {
        selectedComponentProperties (): { componentName: string; componentDisplayValue: string } {
            const selectedListItem = this.sidebarOptionsList.find(x => x.componentName === this.selectedComponentName);
            return {
                componentName: this.selectedComponentName,
                componentDisplayValue: selectedListItem?.displayValue ?? '',
                ...(selectedListItem?.settingsType) && { settingsType: selectedListItem.settingsType },
            }
        },
        errorLoaderIcon (): string {
            return ErrorLoaderIcons.EmptyData;
        },
        errorLoaderLabels (): { title: string; message: string } {
            return {
                title: this.$t('monitoring.errorTitle').toString(),
                message: this.$t('monitoring.errorMessage').toString(),
            }
        },
    },
    methods: {
        registerCallbacks (): void {
            EventBus.$on(EventBus.VIEWS.CENTRAL.SIDEBAR_BUTTON_CLICKED,
                (event: { componentName: string }) => this.selectedComponentName = event.componentName
            );
        },
        fillSidebarOptionsList (): void {
            this.sidebarOptionsList = [
                {
                    displayValue: this.$t('averageReport.name').toString(),
                    componentName: 'averageReport',
                    disabled: false,
                },
            ];
        },
        async showAdminSidebar (): Promise<void> {
            await this.$store.dispatch('sidebar/setCurrentSidebar',
                {
                    shouldShowSidebar: true,
                    currentSidebarComponent: 'SidebarMultiOption',
                    sidebarComponentProperties: {
                        optionsList: this.sidebarOptionsList,
                        eventName: EventBus.VIEWS.CENTRAL.SIDEBAR_BUTTON_CLICKED,
                    }
                });

            // Hack to disable the padding of the sidepanel here
            await this.$nextTick();
            const sidepanel = document.querySelector('.pui-side-panel__main--padding');
            if (sidepanel) {
                (sidepanel as any).style.padding = 0;
                await this.$store.dispatch('sidebar/setCurrentSidebarWidth', '25rem');
            }
        }
    }
});

export default CentralAnalysisView
