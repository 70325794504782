import { RiskRating } from '@/service-proxies/service-proxies.g';

function hueToRgb(p: number, q: number, t: number): number {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
}

function hslToRgb(h: number, s: number, l: number): string {
    let r, g, b;

    if (s === 0) {
        r = g = b = l;
    } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hueToRgb(p, q, h + 1 / 3);
        g = hueToRgb(p, q, h);
        b = hueToRgb(p, q, h - 1 / 3);
    }

    return `rgb(${Math.round(r * 255)},${Math.round(g * 255)},${Math.round(
        b * 255
    )})`;
}

type ProperRiskRating = Exclude<RiskRating, RiskRating.Undefined>;

const riskToHue: { [k in ProperRiskRating]: number } = {
    [RiskRating.NoRisk]: 120 / 360,
    [RiskRating.LowRisk]: 53 / 360,
    [RiskRating.MedRisk]: 31 / 360,
    [RiskRating.HighRisk]: 0 / 360,
};

export function svgColoredBar(
    width: number,
    percent: number,
    risk: ProperRiskRating
): string {
    // prettier-ignore
    return `<svg width="${width}" height="12" xmlns="http://www.w3.org/2000/svg">
        <rect width="${percent * width / 100}" height="12" style="fill:${hslToRgb(riskToHue[risk], 1, 0.5)};stroke-width:0;stroke:rgb(0,0,0)" />
        <rect x="${percent * width / 100}" width="${width - percent * width / 100}" height="12" style="fill:${hslToRgb(riskToHue[risk], 0, 1)};stroke-width:0;stroke:rgb(0,0,0)" />
        <rect width="${width}" height="12" style="fill:rgba(255,255,255,0);stroke-width:1;stroke:rgb(0,0,0)" />
    </svg>`;
}

export function svgSquare(risk: ProperRiskRating): string {
    // prettier-ignore
    return `<svg width="9" height="9" xmlns="http://www.w3.org/2000/svg">
        <rect width="9" height="9" style="fill:${hslToRgb(riskToHue[risk], 1, 0.5)};stroke-width:0;stroke:rgb(0,0,0)" />
    </svg>`;
}

export function svgColorRamp(
    width: number,
    markerAtPercentage: number
): string {
    const markerWidth = 2;
    // prettier-ignore
    return `<svg width="${width}" height="12" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient id="Gradient1">
                <stop style="stop-color:${hslToRgb(riskToHue[RiskRating.NoRisk], 1, 0.5)}" offset="0%"/>
                <stop style="stop-color:${hslToRgb(riskToHue[RiskRating.LowRisk], 1, 0.5)}" offset="33%"/>
                <stop style="stop-color:${hslToRgb(riskToHue[RiskRating.MedRisk], 1, 0.5)}" offset="66%"/>
                <stop style="stop-color:${hslToRgb(riskToHue[RiskRating.HighRisk], 1, 0.5)}" offset="100%"/>
            </linearGradient>
        </defs>
        <rect width="${width}" height="12" style="fill:url(#Gradient1);stroke-width:1;stroke:rgb(0,0,0)" />
        <rect x="${(markerAtPercentage * (width - markerWidth)) / 100}" width="${markerWidth}" height="12" style="fill:rgb(0,0,0)" />
    </svg>`;
}

export function svgDivider(width: number): string {
    // prettier-ignore
    return `<svg width="${width}" height="2" xmlns="http://www.w3.org/2000/svg">
        <line x1="0" y1="0" x2="${width}" y2="0" style="stroke:black;stroke-width:2" />
    </svg>`;
}
