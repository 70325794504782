
import { EventBus } from '@/utils';
import Vue from 'vue';
import { ICellRendererParams } from 'ag-grid-community';
import { ReportDto } from '@/service-proxies/service-proxies.g';
import { ReportTableData } from '@/components/report-table/contracts/table-data';

const EditReportButtonCell = Vue.extend({
    name: 'EditReportButtonCell',
    data (): {
        buttonText: string | null;
        loaderText: string;
        isLoading: boolean;
        agGridParams: ICellRendererParams | undefined;
        } {
        return {
            buttonText: null,
            isLoading: false,
            loaderText: this.$t('loading').toString(),
            agGridParams: undefined,
        };
    },
    beforeMount() {
        // this.params contains the cell & row information and
        // is made available to this component at creation time
        // see ICellRendererParams for more details
        this.agGridParams = (this as any).params;
        if (this.agGridParams) {
            this.buttonText = this.getValueToDisplay(this.agGridParams);
        }

        EventBus.$on(EventBus.VIEWS.USER.REPORT_DETAILS_LOADING, (id: number, loadingState: boolean) => {
            if (this.reportId === id) {
                this.isLoading = loadingState;
            }
        });
    },
    computed: {
        tableData(): ReportTableData {
            return (this as any).params.data as ReportTableData;
        },
        reportId(): number {
            return Number(this.tableData.meta.reportDto?.id);
        },
        reportDto(): ReportDto {
            return this.tableData.meta.reportDto as ReportDto;
        },
        canViewReport (): boolean {
            return this.$store.getters['userAccessManagement/canViewReport'](
                this.tableData.meta.reportDto?.locationId ?? 0,
                this.tableData.meta.reportDto?.catalogTypeId ?? 0
            );
        },
        canEditReport (): boolean {
            return this.$store.getters['userAccessManagement/canEditReport'](
                this.tableData.meta.reportDto?.locationId ?? 0,
                this.tableData.meta.reportDto?.catalogTypeId ?? 0
            );
        },
        canDeleteReport (): boolean {
            return this.$store.getters['userAccessManagement/canDeleteReport'](
                this.tableData.meta.reportDto?.locationId ?? 0,
                this.tableData.meta.reportDto?.catalogTypeId ?? 0,
                this.tableData.meta.reportDto?.approvalStatus ?? 0
            );
        },
        canCreateReport (): boolean {
            return this.$store.getters['userAccessManagement/canCreateReport'](
                this.tableData.meta.reportDto?.locationId ?? 0,
                this.tableData.meta.reportDto?.catalogTypeId ?? 0
            );
        },
        editIcon (): { clickable: boolean; iconName: string; title: string } {
            if (this.canEditReport) {
                return {
                    clickable: true,
                    iconName: 'edit',
                    title: this.$t('reportTableDetailsTooltip.edit').toString(),
                };
            }
            else if (!this.canEditReport && this.canViewReport) {
                return {
                    clickable: true,
                    iconName: 'info',
                    title: this.$t('reportTableDetailsTooltip.view').toString(),
                };
            }
            return {
                clickable: false,
                iconName: 'info',
                title: this.$t('reportTableDetailsTooltip.view').toString(),
            };
        },
    },
    methods: {
        buttonClicked (): void {
            EventBus.$emit(EventBus.VIEWS.USER.REPORT_EDIT_BUTTON_CLICKED, this.reportDto);
        },
        deleteButtonClicked (): void {
            EventBus.$emit(EventBus.VIEWS.USER.REPORT_DELETE_BUTTON_CLICKED, this.reportDto);
        },
        duplicateButtonClicked (): void {
            EventBus.$emit(EventBus.VIEWS.USER.REPORT_DUPLICATE_BUTTON_CLICKED, this.reportDto);
        },
        getValueToDisplay(params: ICellRendererParams): any {
            return params.valueFormatted ? params.valueFormatted : params.value;
        }
    }
});
export default EditReportButtonCell;
