
import Vue, { PropType } from 'vue';
import { MainQuestionDto, QuestionGroupDto } from '@/service-proxies/service-proxies.g';
import _ from 'lodash';
import AutoCorrectFormInput from '@/components/auto-correct-form-input/auto-correct-form-input.vue';
import { Validation } from '@/components/questionnaire-administration/questionnaire-details/contracts/validation';
import { FormSelectOption } from '@/models/pebble-ui';
const MainQuestionForm = Vue.extend({
    name: 'MainQuestionForm',
    components: {AutoCorrectFormInput},
    props: {
        value: {
            type: Object as PropType<MainQuestionDto>,
            required: true,
        },
        totalLength: {
            type: Number,
            required: true,
        },
        isEditable: {
            type: Boolean,
            required: true,
        }
    },
    data (): {
        internalMainQuestion: MainQuestionDto;
        cycleOptions: FormSelectOption[];
        validations: Record<string, Validation>;
        weightOptions: FormSelectOption[];
        noRiskWhenAnsweredWithOptions: FormSelectOption[];
        } {
        return {
            internalMainQuestion: _.cloneDeep(this.value),
            cycleOptions: [
                {
                    label: this.$t('questionCatalogDetails.formLabels.mainQuestion.cycle1').toString(),
                    value: 1,
                },
                {
                    label: this.$t('questionCatalogDetails.formLabels.mainQuestion.cycle2').toString(),
                    value: 2,
                }
            ],
            validations: {
                'text': {
                    maxLength: 500,
                    minLength: 2,
                    isValid: true,
                    helperText: '',
                }
            },
            weightOptions: _.range(0, 10).map(x => {
                return {
                    label: String(x),
                    value: x
                }
            }),
            noRiskWhenAnsweredWithOptions: [
                {
                    label: String(this.$t('questionCatalogDetails.formLabels.mainQuestion.noRiskWhenAnsweredWithYesYes')),
                    value: String(true),
                },
                {
                    label: String(this.$t('questionCatalogDetails.formLabels.mainQuestion.noRiskWhenAnsweredWithYesNo')),
                    value: String(false),
                }
            ],
        }
    },
    watch: {
        value: {
            handler (newVal: QuestionGroupDto): void {
                this.internalMainQuestion = _.cloneDeep(newVal);
            },
            deep: true,
        }
    },
    computed: {
        minLengthString(): string {
            if(this.validations.text.minLength){
                return (this.validations.text.minLength + 1).toString()
            } else {
                return ''
            }
        },
        isModified (): boolean {
            return !_.isEqual(this.value, this.internalMainQuestion);
        }
    },
    methods: {
        modifyAndEmitChange (obj: any, prop: string, value: any): void {
            Vue.set(obj, prop, value);
            this.$emit('input', this.internalMainQuestion);
        }
    }
});
export default MainQuestionForm;
