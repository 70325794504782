

import { Component, Vue } from 'vue-property-decorator';
import HeaderWrapper from '@/components/header/header.vue';
import Snackbar from '@/components/snackbar/snackbar.vue';
import Loader from '@/components/loader/loader.vue';
import './main.less';
import { LANGUAGES } from './utils/languages';
import { LocalStorageHelper } from './utils/local-storage-helper';
import { Sidebar } from './store/modules/contracts/Sidebar';
import SidebarMultiOption from './components/sidebar-multi-option/sidebar-multi-option.vue';
import { UserService } from '@/services/user-service';
import ConfirmationModal from '@/components/confirmation-modal/confirmation-modal.vue';
import WaitingModal from '@/components/waiting-modal/waiting-modal.vue';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import { DataSeedingService } from '@/services/data-seeding-service';
import { UserActions } from '@/enums/user-actions';
import { AppDataLoading } from '@/enums/app-data-loading';
import Datetime from './components/datetime/datetime.vue';

@Component({
    name: 'app',
    components: {
        HeaderWrapper,
        Snackbar,
        Loader,
        SidebarMultiOption,
        ConfirmationModal,
        WaitingModal,
        Datetime
    },
})
export default class App extends Vue {
    private signedIn = true;
    private setupReady = false;
    private leftPanelIsOpen = true;
    private dataSeedingService = new DataSeedingService();
    private firstLoaderError = '';
    private arePermissionsSet = false;

    get currentSidebar (): Sidebar {
        return this.$store.getters['sidebar/getCurrentSidebar'];
    }

    get currentSidebarWidth (): string {
        return this.$store.getters['sidebar/getCurrentSidebarWidth'];
    }

    get loaderError (): any {
        return {
            title: this.$t('appLoading.error.title').toString(),
            text: this.$t('appLoading.error.text').toString(),
            refresh: this.$t('appLoading.error.refresh').toString(),
            details: this.$t(`appLoading.error.details.${this.firstLoaderError}`).toString(),
        }
    }

    private async created(): Promise<void> {
        await (this as any).$sdk?.$authenticator?.loadAuthModule();
        await (this as any).$sdk?.$authenticator?.attemptSsoSilent('loginRedirect');
        this.signedIn = await (this as any).$sdk?.$authenticator?.isAuthenticated() as boolean;
        if (!this.signedIn) {
            await this.$sdk.$authenticator?.login('loginRedirect');
        }

        await (this as any).$sdk.$http?.$generic.request.api.setBaseUrl(process.env.VUE_APP_API_BASE_URL);
        await (this as any).$sdk.$http?.$generic.request.api.setTokenCallback(async () => await (this as any).$sdk.$authenticator?.getToken(process.env.VUE_APP_SCOPE));


        if (this.signedIn) {
            const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);
            this.arePermissionsSet = await (this as any).$sdk?.$permissions?.setPermissions(useCaseId);

            await this.loadInitialData();
        }
    }

    private async loadInitialData(): Promise<void> {
        this.loadLanguages();
        if (this.signedIn) {
            // list of every data loading step we have to do (requests to backend)
            const functionList = [
                [this.setFunctionalPermissions, AppDataLoading.FunctionalPermissions],
                [this.loadAppData, AppDataLoading.AppData],
                [this.setRolesAndPermissions, AppDataLoading.RolesAndPermissions],
                [this.loadCatalogTypes, AppDataLoading.CatalogTypes],
                [this.loadLocations, AppDataLoading.Locations],
                [this.loadCatalogLanguages, AppDataLoading.CatalogLanguages]
            ] as [() => Promise<boolean>, AppDataLoading][];

            // Send all requests in parallel
            const promiseList: Promise<boolean>[] = [];
            for (const func of functionList) {
                promiseList.push(func[0]());
            }

            // wait for results and check if they were successful
            const promiseResults = await Promise.all(promiseList);
            for(const idx in promiseResults) {
                const success = promiseResults[Number(idx)];
                const func = functionList[Number(idx)];
                if (!success) {
                    (this as any).$pui.toast({
                        type: 'error',
                        title: this.$t('appLoading.error.title').toString(),
                        copy: this.$t('appLoading.error.text').toString(),
                    });
                    this.setupReady = false;
                    this.firstLoaderError = AppDataLoading[func[1]];
                    return
                }
            }
        }
        this.setupReady = true;
    }

    private reloadPage(): void {
        document.location.reload()
    }

    private async setFunctionalPermissions(): Promise<boolean> {
        // Convert permissions from to token to list of string enum (UserActions) values
        const permissions = (await LocalStorageHelper.getProfile()).permissions
            .filter(x => x.useCaseId.toString() === process.env.VUE_APP_USE_CASE_ID)
            .map(x => x.name.charAt(0).toLowerCase() + x.name.slice(1)) as UserActions[];

        // This is somewhat dangerous because our only relation between the values from the token and
        // the typescript enum is the string value. Therefore, we check here if we just added any permissions
        // which are unknown to the enum (no matching string).
        const enumValues = Object.values(UserActions);
        for (const el of permissions) {
            if (!enumValues.includes(el)) {
                console.error(`Unknown functional permission in user token: ${el}`)
            }
        }

        this.$store.commit('userAccessManagement/SET_FUNCTIONAL_PERMISSIONS', permissions);
        return true;
    }

    private async loadAppData (): Promise<boolean> {
        return await this.$store.dispatch('appData/loadAppData') === HttpStatusCodes.Ok;
    }

    private async loadCatalogTypes (): Promise<boolean> {
        return await this.$store.dispatch('catalogTypes/loadCatalogTypes') === HttpStatusCodes.Ok;
    }

    private async loadLocations (): Promise<boolean> {
        return await this.$store.dispatch('locations/loadLocations') === HttpStatusCodes.Ok;
    }

    private async loadCatalogLanguages (): Promise<boolean> {
        return await this.$store.dispatch('languages/loadLanguages') === HttpStatusCodes.Ok;
    }

    private async setRolesAndPermissions (): Promise<boolean> {
        const userService = new UserService();
        const profile = await LocalStorageHelper.getProfile();

        const success = await this.$store.dispatch('userAccessManagement/setUserPermissions', {
            service: userService,
            userId: profile.id
        })

        this.$store.commit('userAccessManagement/SET_USER_ID', profile.id);
        this.$store.commit('userAccessManagement/SET_USER_NAME', `${profile.givenName} ${profile.familyName}`);
        this.$store.commit('userAccessManagement/SET_USER_KID', profile.kid);

        return success;
    }

    private loadLanguages(): void {
        LocalStorageHelper.setLanguageOptions(Object.keys(LANGUAGES));
        const selectedLanguage = LocalStorageHelper.getSelectedLanguage() ?? LocalStorageHelper.determineDefaultLanguage();
        if (selectedLanguage) {
            this.selectLanguage(selectedLanguage);
        }
    }

    private selectLanguage(selectedLanguage: string): void {
        LocalStorageHelper.setSelectedLanguage(selectedLanguage);
        this.$i18n.locale = LANGUAGES[selectedLanguage];
    }

    get isTestEnvironment(): boolean {
        return !this.$store.getters['isProductionEnv'];
    }
}
