import { PermissionMatrix, staticPermissionMatrix } from './permissions-matrix';
import { UserActions } from '@/enums/user-actions';
import { UserRoles } from '@/enums/user-roles';

export class Supervisor {

    private _role: UserRoles = UserRoles.StandardUser;
    private _matrix: PermissionMatrix = {} as PermissionMatrix;

    constructor(role: UserRoles) {
        this.init(role);
    }

    private init(role: UserRoles): void {
        this._matrix = staticPermissionMatrix;
        this._role = role;
    }

    public hasAccess (action: UserActions, location: string): boolean {
        // TODO: check location
        console.log(location);
        return this._matrix[this._role]?.includes(action) ?? false;
    }
}
