import { HomeGetRequest, HomeGetResponseOkResponse, HomeServiceProxy } from '@/service-proxies/service-proxies.g';

/**
 * @name AppDataService
 * @description This class is used to query the backend server for catalogType data
 */
export class AppDataService {
    private appDataServiceProxy: HomeServiceProxy;

    constructor() {
        this.appDataServiceProxy = new HomeServiceProxy();
    }

    /**
     * Get App data.
     */
    getAppData(): Promise<HomeGetResponseOkResponse> {
        return this.appDataServiceProxy.get(new HomeGetRequest());
    }
}
