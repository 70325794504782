
import Vue, { PropType } from 'vue';
import { FormSelectOption } from '@/models/pebble-ui';
import {
    LanguageDto, LocationDto,
    ReportApprovalStatus,
    ReportType,
    UserDto
} from '@/service-proxies/service-proxies.g';
import AutoCorrectFormInput from '@/components/auto-correct-form-input/auto-correct-form-input.vue';
import { enumKeys } from '@/utils/javascript-utils';
import { selectedLanguageToMomentLocale } from '@/utils/date-time-utils';
import { LANGUAGES } from '@/utils/languages';
import { LocalStorageHelper } from '@/utils/local-storage-helper';
import { Validation } from '@/components/questionnaire-administration/questionnaire-details/contracts/validation';
import { ReportStatusColors } from '@/enums/report-status-colors';
import moment from 'moment';

export enum FormValidations {
    Version = 'version',
    ReportYear = 'reportYear',
    Description = 'description',
}

const ReportForm = Vue.extend({
    name: 'ReportForm',
    components: {
        AutoCorrectFormInput,
    },
    props: {
        reportYear: {
            type: Number,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        version: {
            type: Number,
            required: true,
        },
        locationId: {
            type: Number,
            required: true,
        },
        reportType: {
            type: Number as PropType<ReportType>,
            required: true,
        },
        languageId: {
            type: Number,
            required: true,
        },
        currentReportStatus: {
            type: Number as PropType<ReportApprovalStatus>,
            required: true,
        },
        originalReportStatus: {
            type: Number as PropType<ReportApprovalStatus>,
            required: true,
        },
        creatorApprovalUser: {
            type: Object as PropType<UserDto>,
            default: undefined,
        },
        creatorApprovalDate: {
            type: String,
            default: undefined,
        },
        plantManagerApprovalUser: {
            type: Object as PropType<UserDto>,
            default: undefined,
        },
        plantManagerApprovalDate: {
            type: String,
            default: undefined,
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        testReportMode: {
            type: Boolean,
            default: false,
        },
        catalog: {
            type: String,
            default: ''
        },
        catalogVersion: {
            type: Number,
            default: -1
        },
        catalogType: {
            type: String,
            default: ''
        },
        comment: {
            type: String,
            default: ''
        },
    },
    data (): {
        validations: Record<FormValidations, Validation>;
        selectedLanguage: string;
        } {
        return {
            validations: {
                'version': {
                    min: 1,
                    max: 100,
                    isValid: true,
                    helperText: '',
                },
                'reportYear': {
                    min: 1900,
                    max: 3000,
                    isValid: true,
                    helperText: '',
                },
                'description': {
                    maxLength: 500,
                    isValid: true,
                    helperText: '',
                }
            },
            selectedLanguage: 'en-GB',
        }
    },
    mounted (): void {
        this.selectedLanguage = LANGUAGES[LocalStorageHelper.getSelectedLanguage() ?? 'EN'];
    },
    methods: {
        reportYearUpdate (event: number): void {
            // Update the year
            this.$emit('update:reportYear', event);
        }
    },
    computed: {
        originalReportStatusString (): string {
            return ReportApprovalStatus[this.originalReportStatus];
        },
        originalReportStatusColor (): ReportStatusColors {
            return ReportStatusColors[ReportApprovalStatus[this.originalReportStatus] as keyof typeof ReportStatusColors];
        },
        reportTypeOptions (): FormSelectOption[] {
            return enumKeys(ReportType).map(x => {
                return {
                    label: this.$t(`reportType.${x.toString()}`).toString(),
                    value: Number(ReportType[x]),
                }
            })
        },
        languageOptions (): FormSelectOption[] {
            const languageDtos = this.$store.getters['languages/languages'] as LanguageDto[];
            return languageDtos.map(x => {
                return {
                    label: x.languageName ?? '',
                    value: x.id ?? 0,
                }
            })
        },
        reportStatusOptions (): FormSelectOption[] {
            return enumKeys(ReportApprovalStatus).map(x => {
                return {
                    label: this.$t(`reportStatus.${ReportApprovalStatus[ReportApprovalStatus[x]]}`).toString(),
                    value: Number(ReportApprovalStatus[x]),
                }
            })
        },
        creatorApprovalDateString (): string {
            if (!this.creatorApprovalDate) {
                return ''
            }

            return moment.tz(this.creatorApprovalDate, 'Etc/UTC')
                ?.local()
                .locale(selectedLanguageToMomentLocale(this.selectedLanguage))
                .format('lll') ?? ''
        },
        plantManagerApprovalDateString (): string {
            if (!this.plantManagerApprovalDate) {
                return ''
            }

            return moment.tz(this.plantManagerApprovalDate, 'Etc/UTC')
                ?.local()
                .locale(selectedLanguageToMomentLocale(this.selectedLanguage))
                .format('lll') ?? ''
        },
        approvedByEditor (): boolean {
            return this.originalReportStatus === ReportApprovalStatus.ApprovedByEditor;
        },
        approvedByApprover (): boolean {
            return this.originalReportStatus === ReportApprovalStatus.ApprovedByApprover;
        },
        locations (): LocationDto[] {
            return this.$store.getters['locations/locations'];
        },
        currentLocation (): LocationDto {
            return this.locations.filter(x => x.id === this.locationId)[0];
        },
        currentLocationName (): string {
            return this.currentLocation?.locationName ?? '';
        }
    }
});
export default ReportForm;
