
import { EventBus } from '@/utils';
import Vue from 'vue';
import { ICellRendererParams } from 'ag-grid-community';
import { QuestionCatalogDto } from '@/service-proxies/service-proxies.g';
import { QuestionCatalogTableData } from '@/components/questionnaire-config-table/contracts/table-data';

const EditQuestionCatalogButtonCell = Vue.extend({
    name: 'EditQuestionCatalogButtonCell',
    data (): {
        buttonText: string | null;
        loaderText: string;
        isLoading: boolean;
        agGridParams: ICellRendererParams | undefined;
        } {
        return {
            buttonText: null,
            isLoading: false,
            loaderText: this.$t('loading').toString(),
            agGridParams: undefined,
        };
    },
    beforeMount() {
        // this.params contains the cell & row information and
        // is made available to this component at creation time
        // see ICellRendererParams for more details
        this.agGridParams = (this as any).params;
        if (this.agGridParams) {
            this.buttonText = this.getValueToDisplay(this.agGridParams);
        }

        EventBus.$on(EventBus.VIEWS.ADMIN.QUESTION_CATALOG_DETAILS_LOADING, (id: number, loadingState: boolean) => {
            if (this.questionCatalogId === id) {
                this.isLoading = loadingState;
            }
        });
    },
    computed: {
        tableData(): QuestionCatalogTableData {
            return (this as any).params.data as QuestionCatalogTableData;
        },
        questionCatalogId(): number {
            return Number(this.tableData.meta.questionCatalogDto?.id);
        },
        questionCatalogDto(): QuestionCatalogDto {
            return this.tableData.meta.questionCatalogDto as QuestionCatalogDto;
        }
    },
    methods: {
        buttonClicked (): void {
            EventBus.$emit(EventBus.VIEWS.ADMIN.QUESTION_CATALOG_EDIT_BUTTON_CLICKED, this.questionCatalogDto);
        },
        deleteButtonClicked (): void {
            EventBus.$emit(EventBus.VIEWS.ADMIN.QUESTION_CATALOG_DELETE_BUTTON_CLICKED, this.questionCatalogDto);
        },
        duplicateButtonClicked (): void {
            EventBus.$emit(EventBus.VIEWS.ADMIN.QUESTION_CATALOG_DUPLICATE_BUTTON_CLICKED, this.questionCatalogDto);
        },
        getValueToDisplay(params: ICellRendererParams): any {
            return params.valueFormatted ? params.valueFormatted : params.value;
        }
    }
});
export default EditQuestionCatalogButtonCell;
