import { calculateDefaultSidebarWidth, calculateWideSidebarWidth } from '@/utils/dom-utils';
import { Sidebar } from './contracts/Sidebar';

export const sidebar = {
    namespaced: true,
    state: (): any => ({
        currentSidebar: { shouldShowSidebar: false, currentSidebarComponent: '', sidebarComponentProperties: null },
        // This is a sane default for most views
        width: '25vw',
    }),
    getters: {
        getCurrentSidebar: (state: any): Sidebar => state.currentSidebar,
        getCurrentSidebarWidth: (state: any): Sidebar => state.width,
    },
    actions: {
        setCurrentSidebar: ({ commit }: any, sidebar: Sidebar): void => {
            commit('SET_IF_SHOW_SIDEBAR', sidebar.shouldShowSidebar);
            commit('SET_CURRENT_SIDEBAR', sidebar.currentSidebarComponent);
            commit('SET_CURRENT_SIDEBAR_PROPS', sidebar.sidebarComponentProperties);
        },
        setCurrentSidebarWidth: ({ commit }: any, width: string): void => {
            commit('SET_CURRENT_SIDEBAR_WIDTH', width);
        },
        widenSidebar: ({ commit }: any): void => {
            commit('SET_CURRENT_SIDEBAR_WIDTH', calculateWideSidebarWidth());
        },
        defaultenSidebar: ({ commit }: any): void => {
            commit('SET_CURRENT_SIDEBAR_WIDTH', calculateDefaultSidebarWidth());
        },
    },
    mutations: {
        SET_IF_SHOW_SIDEBAR(state: any, shouldShowSidebar: boolean): void {
            state.currentSidebar.shouldShowSidebar = shouldShowSidebar;
        },
        SET_CURRENT_SIDEBAR(state: any, componentName: string): void {
            state.currentSidebar.currentSidebarComponent = componentName;
        },
        SET_CURRENT_SIDEBAR_PROPS(state: any, sidebarComponentProperties: any): void {
            state.currentSidebar.sidebarComponentProperties = sidebarComponentProperties;
        },
        SET_CURRENT_SIDEBAR_WIDTH(state: any, width: string): void {
            state.width = width;
        },
    }
};
