

import { Component, Vue } from 'vue-property-decorator';
import UserProfile from './user-profile/user-profile.vue';
import { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import { Application } from '@/models';
import Snackbar from '@/components/snackbar/snackbar.vue';
import { EventBus } from '@/utils';
import _ from 'lodash';
import { UserRoles } from '@/enums/user-roles';
import { LocalStorageHelper } from '@/utils/local-storage-helper';
import { UseCaseService } from '@/services/use-case-service';

@Component({
    name: 'header-wrapper',
    components: {
        userProfile: UserProfile,
        snackbar: Snackbar,
    },
})
export default class HeaderWrapper extends Vue {
    private useCaseService: UseCaseService = new UseCaseService();
    private applications: MenuEntry[] = [];
    private user = {
        name: '',
        email: '',
    };
    private navItems: any[] = [];

    private async mounted(): Promise<void> {
        await this.init();
    }

    private async init(): Promise<void> {
        await this.setProfileData();
        await this.loadApps();
        this.setNavItems();
    }

    private async setProfileData(): Promise<void> {
        this.$store.commit('loading');
        try {
            const profile = LocalStorageHelper.getProfile();
            if (profile) {
                if (!profile.givenName && !profile.familyName) {
                    this.user.name = this.$t('guestUserName').toString();
                } else {
                    this.user.name = `${profile.givenName} ${profile.familyName}`;
                }
                this.user.email = profile.email;
            }
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingProfileData');
        } finally {
            this.$store.commit('loading');
        }
    }

    private async loadApps(): Promise<void> {
        this.$store.commit('loading');
        try {
            const apps = (await this.useCaseService.getUseCases()).result.items;
            this.applications = this.processApps(apps);
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingApplications');
        } finally {
            this.$store.commit('loading');
        }
    }

    private processApps(apps: Application[]): MenuEntry[] {
        return apps.map((app: Application): MenuEntry => ({
            id: app.useCaseId,
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconId: this.getLogo(app.logo),
            url: app.url ? app.url : undefined,
        })).sort((a: MenuEntry, b: MenuEntry) => {
            const nameA = a?.label?.toLowerCase();
            const nameB = b?.label?.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    private clickDrawer(): void {
        (this.$refs.appDrawer as any).open = true;
    }

    private handleAppClick(app: Application): void {
        if (app.url) {
            window.open(app.url);
        }
    }

    private getLogo(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`);
            return img;
        } catch (e) {
            return 'app-generic';
        }
    }

    get getAppDrawerLabels(): any {
        return { applications: this.$i18n.t('appsWithAccess'), miscellaneous: this.$i18n.t('appsWithoutAccess') };
    }

    private setNavItems(): void {
        this.navItems = this.$router.options.routes ? _.uniqWith(this.$router.options.routes.map(route => (
            {
                displayName: this.$t(route.meta?.translationKey),
                href: this.removeParamsFromNavItemPaths(route.path),
                isActive: route.name === this.$router.currentRoute.name
            })
        ), _.isEqual).filter((x: any) => this.allowedRoute(x.href.split('/').slice(-1)[0])) : [];
    }

    private allowedRoute (path: string): boolean {
        const role = this.$store.getters['userAccessManagement/getUserRole'] as UserRoles;
        if (role !== UserRoles.Admin && (path === 'admin')) {
            return false;
        }

        if (role !== UserRoles.Admin && role !== UserRoles.Controller && (path === 'central-analysis')) {
            return false;
        }

        if (!this.$store.getters['userAccessManagement/canExportReport'] && (path === 'export')) {
            return false;
        }
        return true;
    }

    private removeParamsFromNavItemPaths(path: string): string {
        return path.split(':')[0].replace(/\/+$/, '').trimEnd();
    }

    get isTestEnvironment(): boolean {
        return !this.$store.getters['isProductionEnv'];
    }
}
