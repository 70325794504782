
import Vue, { PropType } from 'vue';
import { FormSelectOption } from '@/models/pebble-ui';
import { CatalogTypeDto, LanguageDto, UserDto } from '@/service-proxies/service-proxies.g';
import Datetime from '@/components/datetime/datetime.vue';
import AutoCorrectFormInput from '@/components/auto-correct-form-input/auto-correct-form-input.vue';
import { CatalogStatus } from '@/enums/catalog-status';
import { enumKeys } from '@/utils/javascript-utils';
import { selectedLanguageToMomentLocale } from '@/utils/date-time-utils';
import { LANGUAGES } from '@/utils/languages';
import { LocalStorageHelper } from '@/utils/local-storage-helper';
import { CatalogStatusColors } from '@/enums/catalog-status-colors';
import { Validation } from '@/components/questionnaire-administration/questionnaire-details/contracts/validation';

export enum FormValidations {
    Version = 'version',
    ReportYear = 'reportYear',
    Description = 'description',
}

const QuestionCatalogForm = Vue.extend({
    name: 'QuestionCatalogForm',
    components: {
        AutoCorrectFormInput,
        Datetime
    },
    props: {
        reportYear: {
            type: Number,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        version: {
            type: Number,
            required: true,
        },
        catalogTypeId: {
            type: Number,
            required: true,
        },
        languageId: {
            type: Number,
            required: true,
        },
        deadlineHReport: {
            type: String,
            required: true,
        },
        deadlineZReport: {
            type: String,
            required: true,
        },
        currentCatalogStatus: {
            type: String as PropType<CatalogStatus>,
            required: true,
        },
        originalCatalogStatus: {
            type: String as PropType<CatalogStatus>,
            required: true,
        },
        approver: {
            type: Object as PropType<UserDto>,
            default: undefined,
        },
        approvalDate: {
            type: Object as PropType<moment.Moment>,
            default: undefined,
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        questionCatalogId: {
            type: Number,
            default: undefined
        }
    },
    data (): {
        validations: Record<FormValidations, Validation>;
        selectedLanguage: string;
        } {
        return {
            validations: {
                'version': {
                    min: 1,
                    max: 100,
                    isValid: true,
                    helperText: '',
                },
                'reportYear': {
                    min: 1900,
                    max: 3000,
                    isValid: true,
                    helperText: '',
                },
                'description': {
                    maxLength: 500,
                    minLength: 2,
                    isValid: true,
                    helperText: '',
                },
            },
            selectedLanguage: 'en-GB',

        }
    },
    mounted (): void {
        this.selectedLanguage = LANGUAGES[LocalStorageHelper.getSelectedLanguage() ?? 'EN'];
    },
    methods: {
        reportYearUpdate (event: number): void {
            // Update the year
            this.$emit('update:reportYear', event);

            // But also update the deadlines with the new year
            this.$emit('update:deadlineHReport',
                event + this.deadlineHReport.substring(4, this.deadlineHReport.length));
            this.$emit('update:deadlineZReport',
                event + this.deadlineZReport.substring(4, this.deadlineZReport.length));
        }
    },
    computed: {
        minLengthString(): string {
            if(this.validations.description.minLength){
                return (this.validations.description.minLength + 1).toString()
            } else {
                return ''
            }
        },
        originalCatalogStatusColor (): CatalogStatusColors {
            return CatalogStatusColors[this.originalCatalogStatus];
        },
        catalogTypeOptions (): FormSelectOption[] {
            const catalogTypeDtos = this.$store.getters['catalogTypes/catalogTypes'] as CatalogTypeDto[];
            return catalogTypeDtos.map(x => {
                return {
                    label: x.getDescription(this.$i18n.locale) ?? '',
                    value: x.id ?? 0,
                }
            })
        },
        languageOptions (): FormSelectOption[] {
            const languageDtos = this.$store.getters['languages/languages'] as LanguageDto[];
            return languageDtos.map(x => {
                return {
                    label: x.languageName ?? '',
                    value: x.id ?? 0,
                }
            })
        },
        catalogStatusOptions (): FormSelectOption[] {
            return enumKeys(CatalogStatus).map(x => {
                return {
                    label: this.$t(`questionCatalogStatus.${CatalogStatus[x].toString()}`).toString(),
                    value: CatalogStatus[x].toString()
                }
            })
        },
        approvalDateString (): string {
            return this.approvalDate
                .local()
                .locale(selectedLanguageToMomentLocale(this.selectedLanguage))
                .format('lll')
        }
    }
});
export default QuestionCatalogForm;
