
import Vue from 'vue';
import { ICellRendererParams } from 'ag-grid-community';
import { TableData } from '@/components/user-config-table/contracts/table-data';
import { UserRoleColors } from '@/enums/user-role-colors';
import { UserRoles } from '@/enums/user-roles';

const UserRoleCell = Vue.extend({
    name: 'UserRoleCell',
    data (): {
        agGridParams: ICellRendererParams | undefined;
        } {
        return {
            agGridParams: undefined,
        };
    },
    beforeMount() {
        // this.params contains the cell & row information and
        // is made available to this component at creation time
        // see ICellRendererParams for more details
        this.agGridParams = (this as any).params;
    },
    computed: {
        roleUnset(): boolean {
            return this.role && this.role == UserRoles.DummyUnset;
        },
        tableData(): TableData {
            return (this as any).params.data as TableData;
        },
        role(): UserRoles {
            return this.tableData['user-role'];
        },
        userRoleColor(): UserRoleColors {
            if (this.role) {
                return UserRoleColors[this.role];
            }
            return UserRoleColors.Unknown;
        }
    }
});
export default UserRoleCell;
