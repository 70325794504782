
import Vue from 'vue'

const BgTooltip = Vue.extend({
    name: 'BgTooltip',
    data (): {
            color: string | null;
            key: string | null;
            header: string | null;
            data: any;
            } {
        return {
            color: null,
            key: null,
            header: null,
            data: null,
        };
    },
    beforeMount() {
        this.data = (this as any).params.api.getDisplayedRowAtIndex(
            (this as any).params.rowIndex
        ).data;
        this.key = (this as any).params.key;
        this.header = (this as any).params.column.colDef.headerName;
        this.color = (this as any).params.color || 'white';
    },
    computed: {
        displayValue (): string {
            if (typeof (this.data[this.key as any]) !== 'string' && typeof (this.data[this.key as any]) !== 'number') {
                return Object.entries(this.data[this.key as any])
                    .map(x => {
                        return `${x[0]}: ${x[1]}`
                    })
                    .join('\n');
            }
            return (this as any).params.valueFormatted ?
                (this as any).params.valueFormatted :
                this.data[this.key as any];
        }
    }
});
export default BgTooltip;
