import Vue from 'vue';
import { EventBus } from '@/utils';
import ReportOverview from '@/components/report-overview/report-overview.vue';
import { ErrorLoaderIcons } from '@/enums/pebble-ui-enums';
import { RouteNames } from '@/router/route-names';

const UserView = Vue.extend({
    name: 'UserView',
    components: {
        ReportOverview,
    },
    data (): {
        sidebarOptionsList: Array<{ displayValue: string; componentName: string; disabled?: boolean; settingsType?: string }>;
        selectedComponentName: string;
        } {
        return {
            sidebarOptionsList: [],
            selectedComponentName: '',
        }
    },
    async mounted (): Promise<void> {
        this.fillSidebarOptionsList();
        this.registerCallbacks();
        await this.showUserSidebar();
    },
    computed: {
        selectedComponentProperties (): { componentName: string; componentDisplayValue: string } {
            const selectedListItem = this.sidebarOptionsList.find(x => x.componentName === this.selectedComponentName);
            return {
                componentName: this.selectedComponentName,
                componentDisplayValue: selectedListItem?.displayValue ?? '',
                ...(selectedListItem?.settingsType) && { settingsType: selectedListItem.settingsType },
            }
        },
        userRole (): string {
            return this.$store.getters['userAccessManagement/getUserRole'];
        },
        unauthorized (): boolean {
            // FIXME: Can a user not be authorized?
            return false;
        },
        errorOccured (): boolean {
            return this.unauthorized;
        },
        errorLoaderIcon (): string {
            if (this.unauthorized) {
                return ErrorLoaderIcons.Alert;
            }

            return ErrorLoaderIcons.EmptyData;
        },
        errorLoaderLabels (): { title: string; message: string } {
            if (this.unauthorized) {
                return {
                    title: this.$t('monitoring.unauthorizedErrorTitle').toString(),
                    message: this.$t('monitoring.unauthorizedErrorMessage').toString(),
                }
            }

            return {
                title: this.$t('monitoring.errorTitle').toString(),
                message: this.$t('monitoring.errorMessage').toString(),
            }
        },
    },
    methods: {
        registerCallbacks (): void {
            EventBus.$on(EventBus.VIEWS.USER.SIDEBAR_BUTTON_CLICKED,
                (event: { componentName: string }) => {
                    this.$router
                        .push({name: event.componentName})
                        .catch(() => {
                            // pass
                        });
                }
            );
        },
        fillSidebarOptionsList (): void {
            this.sidebarOptionsList = [
                {
                    displayValue: this.$t('userView.sidepanel.options.reports').toString(),
                    componentName: RouteNames.UserViewReports,
                    disabled: false,
                },
            ];
        },
        async showUserSidebar (): Promise<void> {
            await this.$store.dispatch('sidebar/setCurrentSidebar',
                {
                    shouldShowSidebar: true,
                    currentSidebarComponent: 'SidebarMultiOption',
                    sidebarComponentProperties: {
                        optionsList: this.sidebarOptionsList,
                        eventName: EventBus.VIEWS.USER.SIDEBAR_BUTTON_CLICKED,
                    }
                });

            // Hack to disable the padding of the sidepanel here
            await this.$nextTick();
            const sidepanel = document.querySelector('.pui-side-panel__main--padding');
            if (sidepanel) {
                (sidepanel as any).style.padding = 0;
                await this.$store.dispatch('sidebar/setCurrentSidebarWidth', '25rem');
            }
        }
    }
});

export default UserView
