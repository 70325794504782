import Vue from 'vue';
import Vuex from 'vuex';

import { sidebar } from './modules/sidebar';
import { userAccessManagement } from '@/store/modules/user-access-management';
import { confirmationModal } from '@/store/modules/confirmation-modal';
import { waitingModal } from '@/store/modules/waiting-modal';
import { appData } from '@/store/modules/app-data';
import { catalogTypes } from '@/store/modules/catalog-types';
import { locations } from '@/store/modules/locations';
import { languages } from '@/store/modules/languages';

Vue.use(Vuex);

export const applicationState: {
    isLoading: boolean;
    isProductionEnv: boolean;
    usesLocalBackend: boolean;
} = {
    isLoading: false,
    isProductionEnv: process.env.VUE_APP_ENVIRONMENT === 'prd',
    usesLocalBackend: String(process.env.VUE_APP_API_BASE_URL).startsWith('https://localhost'),
};

export default new Vuex.Store({
    state: applicationState,
    mutations: {
        loading(state): void {
            state.isLoading = !state.isLoading;
        },
    },
    actions: {},
    modules: {
        sidebar,
        userAccessManagement,
        confirmationModal,
        waitingModal,
        appData,
        catalogTypes,
        locations,
        languages,
    },
    getters: {
        isLoading(state): boolean {
            return state.isLoading;
        },
        isProductionEnv(state: typeof applicationState): boolean {
            return state.isProductionEnv;
        },
        usesLocalBackend(state: typeof applicationState): boolean {
            return state.usesLocalBackend;
        }
    },
});
