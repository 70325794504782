import { QuestionCatalogListEndpointRequest } from '@/models';
import {
    IQuestionCatalogDto, QuestionCatalogAddEndpointResponseOkResponse,
    QuestionCatalogDto,
    QuestionCatalogEditEndpointResponseOkResponse,
    QuestionCatalogGetEndpointResponseOkResponse,
    QuestionCatalogListEndpointResponseOkResponse,
    QuestionCatalogsServiceProxy,
    SubQuestionType
} from '@/service-proxies/service-proxies.g';

/**
 * @name QuestionCatalogService
 * @description This class is used to query the backend server for questionCatalog data
 */
export class QuestionCatalogService {
    private questionCatalogServiceProxy: QuestionCatalogsServiceProxy;

    constructor() {
        this.questionCatalogServiceProxy = new QuestionCatalogsServiceProxy();
    }

    /**
     * Edit the permissions of the questionCatalog.
     * @param editDto Edit Request.
     */
    public editQuestionCatalog = async (editDto: IQuestionCatalogDto): Promise<QuestionCatalogEditEndpointResponseOkResponse> => {
        if (!editDto.id) {
            throw 'Dto has no valid id!';
        }

        const result = await this.questionCatalogServiceProxy.editQuestionCatalog(editDto.id, new QuestionCatalogDto(editDto));

        if (result.result) {
            this.replaceEnums(result.result);
        }
        return result;
    }

    /**
     * Get filtered list of questionCatalogs.
     * @param filter QuestionCatalog Filter.
     */
    public getQuestionCatalogs = async (filter: QuestionCatalogListEndpointRequest | undefined = undefined): Promise<QuestionCatalogListEndpointResponseOkResponse> => {
        const result = await this.questionCatalogServiceProxy.listQuestionCatalog(
            filter?.sortDirection,
            filter?.sortProperty,
            filter?.questionCatalogIds,
            filter?.catalogTypeIds,
            filter?.reportYear,
            filter?.catalogVersion,
            filter?.description,
            filter?.questionCatalogStatus,
            filter?.page,
            filter?.size,
            filter?.Term,
            filter?.TermToLower,
        );

        if (result.result?.items) {
            for (const el of result.result.items) {
                this.replaceEnums(el);
            }
        }
        return result;
    }

    /**
     * Get filtered list of questionCatalogs (proxy function).
     * @param filter QuestionCatalog Filter.
     */
    public getItems = async (filter: QuestionCatalogListEndpointRequest | undefined = undefined): Promise<QuestionCatalogListEndpointResponseOkResponse> => {
        return this.getQuestionCatalogs(filter);
    }

    /**
     * Get questionCatalog by id.
     * @param id
     */
    public getQuestionCatalog = async (id: number): Promise<QuestionCatalogGetEndpointResponseOkResponse> => {
        const result = await this.questionCatalogServiceProxy.getQuestionCatalog(id);
        if (result.result) {
            this.replaceEnums(result.result);
        }
        return result;
    }

    /**
     * Delete a questionCatalog by id.
     * @param id
     */
    public deleteQuestionCatalogById = async (id: number): Promise<void> => {
        return this.questionCatalogServiceProxy.deleteQuestionCatalog(id);
    }

    /**
     * Delete testReports corresponding to questionCatalog by id.
     * @param id
     */
    public deleteTestReportsByQuestionCatalogId = async (id: number): Promise<void> => {
        return this.questionCatalogServiceProxy.deleteTestReportsByQuestionCatalogId(id);
    }

    /**
     * Add a questionCatalog.
     * @param questionCatalogDto
     */
    public addQuestionCatalog = async (questionCatalogDto: QuestionCatalogDto): Promise<QuestionCatalogAddEndpointResponseOkResponse> => {
        const result = await this.questionCatalogServiceProxy.addQuestionCatalog(questionCatalogDto);
        if (result.result) {
            this.replaceEnums(result.result);
        }
        return result;
    }

    /**
     * Helper function which mutates the given Report and replaces all enums with their integer values.
     */
    // eslint-disable-next-line sonarjs/cognitive-complexity
    private replaceEnums = (questionCatalogDto: QuestionCatalogDto): void => {
        // Sub-Question type enum
        for(const group of questionCatalogDto.questionGroups ?? []) {
            for (const mq of group.mainQuestions ?? []) {
                for (const sq of mq.subQuestions ?? []) {
                    if (typeof sq.subQuestionType === 'string') {
                        sq.subQuestionType = sq.subQuestionType ? SubQuestionType[sq.subQuestionType] as unknown as SubQuestionType : sq.subQuestionType;
                    }
                }
            }
        }
    }
}
