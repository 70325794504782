
import Vue, { PropType } from 'vue';
import BgTooltip from '@/components/table/custom-tooltips/bgTooltip.vue';
import TableFiltered from '@/components/table-filtered/table-filtered.vue';
import { QuestionCatalogListEndpointRequest } from '@/models';
import { ColumnDef, VendorOptions } from '@/components/table/contracts/table-data';
import { EventBus } from '@/utils';
import moment from 'moment-timezone';
import { QuestionCatalogDto, QuestionCatalogSortColumn, SortType, } from '@/service-proxies/service-proxies.g';
import { FilterChangedEvent, GridApi } from 'ag-grid-community';
import { removeEmptyFieldsFrom } from '@/utils/filter-utils';
import { QuestionCatalogService } from '@/services/question-catalog-service';
import { selectedLanguageToMomentLocale } from '@/utils/date-time-utils';
import { LocalStorageHelper } from '@/utils/local-storage-helper';
import { LANGUAGES } from '@/utils/languages';
import { ColumnSort } from '@/components/table/contracts/table-enums';
import { QuestionCatalogTableData } from '@/components/questionnaire-config-table/contracts/table-data';
import _ from 'lodash';

const ReadonlyQuestionnaireTable = Vue.extend({
    name: 'ReadonlyQuestionnaireTable',
    props: {
        tableTitle: {
            type: String,
            required: true,
        },
        filterValues: {
            type: Object as PropType<QuestionCatalogListEndpointRequest>,
            required: true
        },
        overrideVendorOptions: {
            type: Object as PropType<Partial<VendorOptions<Partial<QuestionCatalogTableData>>>>,
            required: false
        }
    },
    components: {
        BgTooltip, // eslint-disable-line vue/no-unused-components
        TableFiltered
    },
    data (): {
        eventTableName: string;
        lastUpdateRequest: moment.Moment;
        tz: string;
        pagination: {
            totalPages: number;
            rangeOfPages: number;
        };
        vendorOptions: VendorOptions<Partial<QuestionCatalogTableData>>;
        questionCatalogService: QuestionCatalogService;
        sortColumnToRequestFieldMap: Record<string, QuestionCatalogSortColumn>;
        filterColumnToRequestFieldMap: Record<string, keyof QuestionCatalogListEndpointRequest>;
        sortTypeToRequestFieldMap: { [key: string]: SortType | undefined };
        gridApi: undefined | GridApi;
        availableColumnsFilterValues: Array<keyof QuestionCatalogListEndpointRequest>;
        selectedLanguage: string;
        } {
        return {
            eventTableName: 'ReadonlyQuestionnaireTable',
            lastUpdateRequest: moment.tz(moment.tz.guess()),
            tz: moment.tz.guess(),
            pagination: {
                totalPages: 1,
                rangeOfPages: 3,
            },
            vendorOptions: {
                rowSelection: 'single',
                paginationAutoPageSize: true,
                withPagination: true,
                columnDefs: [],
                data:
                    _.range(500).map(() => ({
                        'meta': {
                            questionCatalogDto: new QuestionCatalogDto(),
                        },
                        'col-description': '',
                        'col-year': undefined,
                        'col-language': '' ,
                        'col-type': '',
                        'col-version': undefined,
                        'col-testApproval': '',
                        'col-approval':  '',
                        'col-approvalDate': '',
                        'col-deadlineHReport': '',
                        'col-deadlineZReport': '',
                        'col-details': '',
                    })),
                ...this.overrideVendorOptions,
            },
            questionCatalogService: new QuestionCatalogService(),
            sortColumnToRequestFieldMap: {
                'col-version': QuestionCatalogSortColumn.Version,
                'col-description': QuestionCatalogSortColumn.Description,
                'col-year': QuestionCatalogSortColumn.Year,
            },
            filterColumnToRequestFieldMap: {
                'col-version': 'catalogVersion',
                'col-year': 'reportYear',
            },
            sortTypeToRequestFieldMap: {
                'asc': SortType.Ascending,
                'desc': SortType.Descending,
            },
            gridApi: undefined,
            availableColumnsFilterValues: ['catalogVersion', 'reportYear', 'description'],
            selectedLanguage: 'en-GB',
        }
    },
    created (): void {
        this.registerEventCallbacks();
        this.vendorOptions.columnDefs = this.defaultColumnDefs();
    },
    async mounted (): Promise<void> {
        this.selectedLanguage = LANGUAGES[LocalStorageHelper.getSelectedLanguage() ?? 'EN'];
        //await this.constructTable();
    },
    computed: {
        filterColumnToRequestFieldMapInverse (): Record<string, string> {
            const inverseMap: Record<string, string> = {};
            for (const [k, v] of Object.entries(this.filterColumnToRequestFieldMap)) {
                inverseMap[v] = k;
            }
            return inverseMap;
        },
        agGridFilterModel (): Record<string, { value: any }> {
            // Map to correct filter props
            const filterModel: Record<string, { value: any }> = {};
            for (const [k, v] of Object.entries(this.filterValues)) {
                const column = this.filterColumnToRequestFieldMapInverse[k];
                if (this.availableColumnsFilterValues.includes(k as keyof QuestionCatalogListEndpointRequest)) {
                    filterModel[column] = {
                        value: v,
                    };
                }
            }

            return filterModel;
        }
    },
    methods: {
        onPageSizeFound(pageSize: number): void {
            this.filterValues.size = pageSize;
        },
        addButtonClicked (): void {
            EventBus.$emit(EventBus.VIEWS.CENTRAL.QUESTION_CATALOG_ADD_BUTTON_CLICKED);
        },
        /* eslint-disable sonarjs/no-duplicate-string */
        defaultColumnDefs (): ColumnDef[] {
            return [
                {
                    headerName: this.$t('questionCatalogConfigTable.description').toString(),
                    field: 'col-description', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-description', tooltipComponentParams: { key: 'col-description' },
                    comparator: (): number => 0,
                    filter: false,
                    checkboxSelection: true,
                    suppressSizeToFit: true,
                },
                {
                    headerName: this.$t('questionCatalogConfigTable.year').toString(),
                    field: 'col-year', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-year', tooltipComponentParams: { key: 'col-year' },
                    comparator: (): number => 0,
                    filter: false,
                    sort: ColumnSort.desc,
                },
                {
                    headerName: this.$t('language').toString(),
                    field: 'col-language', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-language', tooltipComponentParams: { key: 'col-language' },
                    comparator: (): number => 0,
                    filter: false,
                    sortable: false,
                },
                {
                    headerName: this.$t('questionCatalogConfigTable.type').toString(),
                    field: 'col-type', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-type', tooltipComponentParams: { key: 'col-type' },
                    comparator: (): number => 0,
                    filter: false,
                    sortable: false,
                    suppressSizeToFit: true,
                },
                {
                    headerName: this.$t('questionCatalogConfigTable.version').toString(),
                    field: 'col-version', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-version', tooltipComponentParams: { key: 'col-version' },
                    comparator: (): number => 0,
                    filter: false,
                },
                {
                    headerName: this.$t('questionCatalogConfigTable.approvalDate').toString(),
                    field: 'col-approvalDate', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-approvalDate', tooltipComponentParams: { key: 'col-approvalDate' },
                    comparator: (): number => 0,
                    filter: false,
                    sortable: false,
                },
            ];
        },
        registerEventCallbacks (): void {
            EventBus.$on(EventBus.VIEWS.CENTRAL.REFRESH_TABLE, () => {
                this.constructTable();
            });
        },
        async constructTable (): Promise<void> {
            const { filteredQuest, time } = await this.getFilteredValues(this.filterValues);

            // only update the table if this is really the latest request
            if (time.valueOf() === this.lastUpdateRequest.valueOf()) {
                this._constructTable(filteredQuest);
                this.$emit('tableUpdated');
            }

        },
        async getFilteredValues (filters: QuestionCatalogListEndpointRequest): Promise<{ filteredQuest: QuestionCatalogDto[]; time: moment.Moment }> {
            const utcFilters: QuestionCatalogListEndpointRequest = {
                ...filters,
            }

            const time = moment.tz(this.tz);
            this.lastUpdateRequest = time; // save the current time to handle overlapping requests
            const res = (await this.questionCatalogService.getQuestionCatalogs(utcFilters)).result;

            if (res) {
                this.pagination.totalPages = res.totalPages === 0 ? 1 : res.totalPages ?? 0;
                return {
                    filteredQuest: res.items ?? [],
                    time: time
                };
            }

            return {
                filteredQuest: [],
                time: time,
            }
        },
        _constructTable (filteredQuest: QuestionCatalogDto[]): void {
            this.vendorOptions.data = [];
            filteredQuest.forEach((questionCatalog: QuestionCatalogDto) => {
                this.vendorOptions.data.push(
                    {
                        'meta': {
                            questionCatalogDto: questionCatalog,
                        },
                        'col-description': questionCatalog.description,
                        'col-year': questionCatalog.reportYear,
                        'col-language': questionCatalog.language?.languageName
                            ? this.$t((questionCatalog.language?.languageName).toLowerCase()).toString()
                            : '',
                        'col-type': (questionCatalog.catalogType as any).getDescription(this.$i18n.locale),
                        'col-version': questionCatalog.version,
                        'col-approvalDate': questionCatalog.approvalDate
                            ? moment.tz(questionCatalog.approvalDate, 'Etc/UTC')
                                .local()
                                .locale(selectedLanguageToMomentLocale(this.selectedLanguage))
                                .format('lll')
                            : ''
                    }
                );
            });
        },
        onSortChanged (sortChangedEvent: any): void {

            // caution: this doesnt work as expected once, multi-column sort is activated
            // only the first found sorted column will be used
            const currentSortedColumn = sortChangedEvent.columnApi
                .getColumnState()
                .find((col: any) => col.sort === 'asc' || col.sort === 'desc');

            if (!currentSortedColumn) {
                this.filterValues.sortProperty = undefined;
                this.filterValues.sortDirection = undefined;
                return;
            }

            if (this.sortColumnToRequestFieldMap[currentSortedColumn.colId] == null
                || this.sortColumnToRequestFieldMap[currentSortedColumn.colId] == undefined) {
                return;
            }

            this.filterValues.sortDirection = this.sortTypeToRequestFieldMap[currentSortedColumn.sort];
            this.filterValues.sortProperty = this.sortColumnToRequestFieldMap[currentSortedColumn.colId];
        },
        onAGGridFilterChanged (filterChangedEvent: FilterChangedEvent): void {
            const filters = filterChangedEvent.api.getFilterModel();
            this.gridApi = filterChangedEvent.api;

            // Map to correct filter props
            // As a first step set everything to undefined
            const mappedFilters: Record<string, string | undefined> = {};
            for (const el of this.availableColumnsFilterValues) {
                mappedFilters[el] = undefined;
            }

            // then fill with actual filter values
            for (const [k, v] of Object.entries(filters)) {
                const mappedValue = this.filterColumnToRequestFieldMap[k];
                if (mappedValue) {
                    // standard ag-grid uses 'filter' but our custom filter uses 'value'
                    if (v.filter) {
                        mappedFilters[mappedValue] = v.filter;
                    } else {
                        mappedFilters[mappedValue] = v.value;
                    }
                }
            }

            const newFilterValues = { ...this.filterValues };
            // Add filters to "real/global" filter object which is passed down again to the ag grid table
            for (const [k, v] of Object.entries(mappedFilters)) {
                Vue.set(newFilterValues, k as keyof QuestionCatalogListEndpointRequest, v as never);
            }

            // Update global filter
            this.$emit('questionCatalogFilterChanged', removeEmptyFieldsFrom<QuestionCatalogListEndpointRequest>(newFilterValues));
        },
        autoSizeAllColumns(): void {
            (this.$refs['ReadonlyQuestionnaireTableComponentRef'] as any).autoSizeAllColumns(false);
        },
        sizeColumnsToFit(): void {
            (this.$refs['ReadonlyQuestionnaireTableComponentRef'] as any).sizeColumnsToFit();
        }
    }
});

export default ReadonlyQuestionnaireTable;
