import { LanguageListEndpointResponseOkResponse, LanguagesServiceProxy } from '@/service-proxies/service-proxies.g';
import { LanguageListEndpointRequest } from '@/models';

/**
 * @name LanguageService
 * @description This class is used to query the backend server for language data
 */
export class LanguageService {
    private languageServiceProxy: LanguagesServiceProxy;

    constructor() {
        this.languageServiceProxy = new LanguagesServiceProxy();
    }

    /**
     * Get filtered list of languages
     * @param filter Language Filter
     */
    getLanguages(filter: LanguageListEndpointRequest): Promise<LanguageListEndpointResponseOkResponse> {
        return this.languageServiceProxy.listLanguage(
            filter?.sortDirection,
            filter?.sortProperty,
            filter?.page,
            filter?.size,
            filter?.term,
            filter?.termToLower
        )
    }
}
