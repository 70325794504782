export enum UserActions {
    // Administration
    ManageCatalogueTypeAccess = 'manageCatalogueTypeAccess',
    ManageLocationAccess = 'manageLocationAccess',
    AddNewCatalogue = 'addNewCatalogue',
    TestReleaseCatalogue = 'testReleaseCatalogue',
    FinalReleaseCatalogue = 'finalReleaseCatalogue',
    CreateTestReport = 'createTestReport',

    // Overview & Analysis
    ReportStatusOverview = 'reportStatusOverview',
    AccessToReports = 'accessToReports',
    AccessToAverageReports = 'accessToAverageReports',
    Export = 'export',

    // Reporting
    CreateReport = 'createReport',
    EditReport = 'editReport',
    ReleaseReport = 'releaseReport',
    ApproveReport = 'approveReport',
    DeleteReport = 'deleteReport',
    SetReleasedReportBackToEditMode = 'setReleasedReportBackToEditMode',
    SetApprovedReportBackToEditMode = 'setApprovedReportBackToEditMode',
}
