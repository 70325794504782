
import { ReportDto } from '@/service-proxies/service-proxies.g';
import Vue, { PropType } from 'vue';
import CubicBezier from '@/components/ui/drawing/cubic-bezier.vue'; 

const ReportAverageMerge = Vue.extend({
    name: 'ReportAverageMerge',
    components: {
        CubicBezier
    },
    props: {
        items: {
            required: true,
            type: [] as PropType<ReportDto[]>,
        }
    },
    data (): {
        title: string;
        size: number;
        } {
        return {
            title: this.$t('averageReport.name').toString(),
            size: 350,
        }
    },
    computed: {
        topHeight(): number {
            return this.size * 0.2;
        },
        bottomHeight(): number {
            return this.size * 0.8;
        },
        width(): number {
            return this.size;
        },
        halfSize(): number {
            return this.size * 0.5;
        },
        distance(): number {
            // distance between two array items on the horizon
            return Math.round(this.width / this.items.length);
        },
        radius(): number {
            return (this.topHeight * 0.5)
        },
        viewbox(): string {
            return '0 0 ' + this.size + ' ' + this.size;
        }
    },
    methods: {
        calculateXPos(index: number): number {
            return Math.round(this.distance * index + this.distance * 0.5);
        },
        printItemDescription(report: ReportDto, elementCount: number): string {
            let elipsis = 14;
            let truncatedText = '';

            if (elementCount > 3) {
                elipsis = 8; 
            }

            truncatedText = report.description?.slice(0, elipsis) || `Id: ${report.id?.toString() || 'N/A'}`;

            if (report.description && report.description?.length > truncatedText.length) {
                truncatedText = truncatedText.concat('...');
            }

            return truncatedText;
        }
    }
});
export default ReportAverageMerge;
