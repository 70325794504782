import Vue from 'vue';
import { EventBus, reportValidator } from '@/utils';
import { ReportListEndpointRequest } from '@/models';
import {
    CatalogTypeDto, CatalogTypePermissionDto,
    LocationDto,
    PDFReportType,
    QuestionCatalogDto,
    ReportApprovalStatus,
    ReportDto,
    ReportStatusEndpointRequest,
    ReportType,
    UserDto,
} from '@/service-proxies/service-proxies.g';
import { ReportService } from '@/services/report-service';
import { LocationService } from '@/services/location-service';
import { CatalogTypeService } from '@/services/catalog-type-service';
import { ConfirmationModalProps } from '@/store/modules/confirmation-modal';
import axios from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';
import {
    WAITING_MODAL_CLOSE_STRING,
    WAITING_MODAL_DEFAULT_TEXT,
    WAITING_MODAL_DEFAULT_TITLE,
    WAITING_MODAL_OPEN_STRING
} from '@/store/modules/waiting-modal';
import ReportDetails from '@/components/report-overview/report-details/report-details.vue';
import ReportTable from '@/components/report-table/report-table.vue';
import NewReportModal from '@/components/report-overview/new-report-modal/new-report-modal.vue';
import { filterChangedHandler, REPORT_DEFAULT_FILTER } from '@/utils/table-utils';
import WarningModal from '@/components/warning-modal/warning-modal.vue'
import PdfCreator from '@/components/pdf-creator/pdf-creator.vue'
import { QuestionCatalogService } from '@/services/question-catalog-service';
import ReportConfirmationModal from '@/components/report-confirmation-modal/report-confirmation-modal.vue';
import { WorkflowService } from '@/services/workflow-service';
import { UserService } from '@/services/user-service';
import { apiCallWithErrorHandling } from '@/services/utils';
import { WorkflowInteraction } from '@/enums/workflow-interaction';
import { BasicResponse } from '@/models/api-response';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import { sleep } from '@/utils/javascript-utils';
import { FlatQuestion } from '@/models/reports';

const ReportAdministration = Vue.extend({
    name: 'ReportAdministration',
    beforeRouteEnter(to, from, next) {
        next(async (vm: any) => {
            if (vm.preloadedReportId) {
                const report = (await vm.reportApi.getReport(vm.preloadedReportId)).result as ReportDto;
                const locationAccess = vm.$store.getters['userAccessManagement/isReaderFor'](report.locationId);
                const catalogTypeAccess = vm.$store.getters['userAccessManagement/hasAccessTo'](report.catalogTypeId);
                const location = (vm.$store.getters['locations/locations'] as LocationDto[]).filter(x => x.id === report.locationId)[0];
                const catalogType = (vm.$store.getters['catalogTypes/catalogTypes'] as CatalogTypeDto[]).filter(x => x.id === report.catalogTypeId)[0];

                if (report && locationAccess && catalogTypeAccess) {
                    await vm.loadReport(report);
                } else if (!locationAccess) {
                    vm.$pui.toast({
                        type: 'error',
                        title: vm.$t('reportDetails.unauthorizedLocation.errorTitle').toString(),
                        copy: vm.$t('reportDetails.unauthorizedLocation.errorText', { id: report.id, location: location.locationName }).toString(),
                    });
                } else if (!catalogTypeAccess) {
                    vm.$pui.toast({
                        type: 'error',
                        title: vm.$t('reportDetails.unauthorizedCatalogType.errorTitle').toString(),
                        copy: vm.$t('reportDetails.unauthorizedCatalogType.errorText', { id: report.id, catalogType: catalogType.getDescription(this.$i18n.locale) }).toString(),
                    });
                }
            }
        });
    },
    components: {
        ReportTable,
        ReportDetails,
        NewReportModal,
        WarningModal,
        PdfCreator,
        ReportConfirmationModal
    },
    props: {
        preloadedReportId: {
            type: Number,
            default: 0,
        },
        showOnlyTestReports: {
            type: Boolean,
            default: false,
        }
    },
    data(): {
        dataLoaded: boolean;
        filterValues: ReportListEndpointRequest;
        tableTitle: string;
        selectedReportDetailsCandidate: ReportDto | undefined;
        internalQuestionCatalog: QuestionCatalogDto | undefined;
        reports: ReportDto[];
        locations: LocationDto[];
        catalogTypes: CatalogTypeDto[];
        reportApi: ReportService;
        locationApi: LocationService;
        catalogTypeApi: CatalogTypeService;
        questionCatalogApi: QuestionCatalogService;
        userApi: UserService;
        workflowApi: WorkflowService;
        tz: string;
        dataChanged: boolean;
        saveData: boolean;
        creatorUser: UserDto | undefined;
        creatorApprovalUser: UserDto | undefined;
        plantManagerApprovalUser: UserDto | undefined;
        catalogApprovalUser: UserDto | undefined;
        testCatalogApprovalUser: UserDto | undefined;
        auditKey: string;
        riskKey: string;
        pdfBlobs: {
            auditBlob: Blob | undefined;
            riskBlob: Blob | undefined;
        };
        } {
        return {
            dataLoaded: false,
            filterValues: _.cloneDeep(REPORT_DEFAULT_FILTER),
            tableTitle: this.showOnlyTestReports
                ? this.$t('reportTable.testReportTableTitle').toString()
                : this.$t('reportTable.tableTitle').toString(),
            selectedReportDetailsCandidate: undefined,
            internalQuestionCatalog: undefined,
            reports: [] as ReportDto[],
            locations: [] as LocationDto[],
            catalogTypes: [] as CatalogTypeDto[],
            reportApi: new ReportService(),
            locationApi: new LocationService(),
            catalogTypeApi: new CatalogTypeService(),
            questionCatalogApi: new QuestionCatalogService(),
            userApi: new UserService(),
            workflowApi: new WorkflowService(),
            tz: moment.tz.guess(),
            dataChanged: false,
            saveData: false,
            creatorUser: undefined,
            creatorApprovalUser: undefined,
            plantManagerApprovalUser: undefined,
            catalogApprovalUser: undefined,
            testCatalogApprovalUser: undefined,
            auditKey: `audit_${0}`,
            riskKey: `risk_${0}`,
            pdfBlobs: {
                auditBlob: undefined,
                riskBlob: undefined,
            }
        }
    },
    created(): void {
        // noop
    },
    async mounted(): Promise<void> {
        this.registerCallbacks();
        Vue.set(this.filterValues, 'reportTypeIds', [ReportType.MainReport, ReportType.InterimReport]);
        Vue.set(this.filterValues, 'locationIds', this.userReaderLocations.map(x => x.id));
        Vue.set(this.filterValues, 'catalogTypeIds', this.userCatalogTypes.map(x => x.catalogTypeId));
        if (this.showOnlyTestReports) {
            Vue.set(this.filterValues, 'reportTypeIds', [ReportType.TestReport]);
            Vue.set(this.filterValues, 'locationIds', [...this.userReaderLocations.map(x => x.id), -1]);
        }
    },
    watch: {
        pdfBlobs: {
            async handler(newVal: { auditBlob: Blob | undefined; riskBlob: Blob | undefined }): Promise<void> {
                if (newVal.riskBlob && newVal.auditBlob && !this.showOnlyTestReports) {
                    await this.submitRiskAndAuditReport(newVal.auditBlob, newVal.riskBlob, false); // TODO check all possible calls
                    this.pdfBlobs.auditBlob = undefined;
                    this.pdfBlobs.riskBlob = undefined;
                    this.$store.commit('waitingModal/CLOSE_AND_RESET');
                }
            },
            deep: true,
        }
    },
    computed: {
        workflowErrorStrings(): { errorTitle: string; errorText: string; waitingTitle: string; waitingText: string } {
            return {
                errorTitle: this.$t('reportDetails.workflowInteractionToast.errorTitle').toString(),
                errorText: this.$t('reportDetails.workflowInteractionToast.errorText').toString(),
                waitingTitle: this.$t(WAITING_MODAL_DEFAULT_TITLE).toString(),
                waitingText: this.$t(WAITING_MODAL_DEFAULT_TEXT).toString(),
            };
        },
        reportTitle(): string {
            return this.selectedReportDetailsCandidate?.description ?? '';
        },
        userReaderLocations(): LocationDto[] {
            // get's all locations to which the user has read access
            return this.$store.getters['userAccessManagement/getReaderUserLocations'];
        },
        userCatalogTypes(): CatalogTypePermissionDto[] {
            // get's all catalogTypes to which the user has read access
            return this.$store.getters['userAccessManagement/getUserCatalogTypes'];
        },
        auditReportId(): number {
            return PDFReportType.AuditReport;
        },
        riskReportId(): number {
            return PDFReportType.RiskReport;
        },
        candidateAlreadyApprovedByEditor(): boolean {
            if (this.selectedReportDetailsCandidate) {
                return this.selectedReportDetailsCandidate.approvalStatus === ReportApprovalStatus.ApprovedByEditor;
            }
            return false;
        },
        candidateInEditState(): boolean {
            if (this.selectedReportDetailsCandidate) {
                return this.selectedReportDetailsCandidate.approvalStatus === ReportApprovalStatus.Editable;
            }
            return false;
        },
        candidateInNewState(): boolean {
            if (this.selectedReportDetailsCandidate) {
                return this.selectedReportDetailsCandidate.approvalStatus === ReportApprovalStatus.New;
            }
            return false;
        },
        candidateInApprovedState(): boolean {
            if (this.selectedReportDetailsCandidate) {
                return this.selectedReportDetailsCandidate.approvalStatus === ReportApprovalStatus.ApprovedByApprover;
            }
            return false;
        },
        userAllowedToApprove(): boolean {
            if (this.selectedReportDetailsCandidate) {
                return this.$store.getters['userAccessManagement/canApproveReport'](
                    this.selectedReportDetailsCandidate.locationId,
                    this.selectedReportDetailsCandidate.catalogTypeId
                );
            }
            return false;
        },
        userAllowedToRelease(): boolean {
            if (this.selectedReportDetailsCandidate) {
                return this.$store.getters['userAccessManagement/canReleaseReport'](
                    this.selectedReportDetailsCandidate.locationId,
                    this.selectedReportDetailsCandidate.catalogTypeId
                );
            }
            return false;
        },
        userAllowedToRevoke(): boolean {
            if (this.selectedReportDetailsCandidate) {
                return this.$store.getters['userAccessManagement/canSetReleasedReportBackToEditMode'](
                    this.selectedReportDetailsCandidate.locationId,
                    this.selectedReportDetailsCandidate.catalogTypeId
                );
            }
            return false;
        },
        userAllowedToRevokeApproval(): boolean {
            if (this.selectedReportDetailsCandidate) {
                return this.$store.getters['userAccessManagement/canSetApprovedReportBackToEditMode'](
                    this.selectedReportDetailsCandidate.locationId,
                    this.selectedReportDetailsCandidate.catalogTypeId
                );
            }
            return false;
        },
        currentUserId(): number {
            return this.$store.getters['userAccessManagement/getUserId'] as number;
        }
    },
    methods: {
        // eslint-disable-next-line sonarjs/cognitive-complexity
        async updateUsers(): Promise<void> {
            if (this.selectedReportDetailsCandidate) {
                if (this.creatorUser?.id !== this.selectedReportDetailsCandidate.creatorUser) {
                    this.creatorUser = this.selectedReportDetailsCandidate.creatorUser
                        ? (await this.userApi.getUser(this.selectedReportDetailsCandidate.creatorUser)).result
                        : undefined;
                }

                if (this.creatorApprovalUser?.id !== this.selectedReportDetailsCandidate.creatorApprovalUser) {
                    this.creatorApprovalUser = this.selectedReportDetailsCandidate.creatorApprovalUser
                        ? (await this.userApi.getUser(this.selectedReportDetailsCandidate.creatorApprovalUser)).result
                        : undefined;
                }

                if (this.plantManagerApprovalUser?.id !== this.selectedReportDetailsCandidate.plantManagerApprovalUser) {
                    this.plantManagerApprovalUser = this.selectedReportDetailsCandidate.plantManagerApprovalUser
                        ? (await this.userApi.getUser(this.selectedReportDetailsCandidate.plantManagerApprovalUser)).result
                        : undefined;
                }
            } else {
                this.creatorUser = undefined;
                this.creatorApprovalUser = undefined;
                this.plantManagerApprovalUser = undefined;
            }
        },
        updateChangeStatus(passedValue: boolean): void {
            this.dataChanged = passedValue;
        },
        closeDialog(): void {
            if (this.dataChanged) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_WARNING_MODAL);
            } else {
                this.toggleDetailsModal()
            }
        },
        saveChanges(): void {
            this.saveData = !this.saveData;
        },
        async loadReport(reportDto: ReportDto): Promise<void> {
            try {
                EventBus.$emit(EventBus.VIEWS.USER.REPORT_DETAILS_LOADING, reportDto.id, true);

                this.selectedReportDetailsCandidate = reportDto;
                await this.loadQuestionCatalogAndUpdateUsers(this.selectedReportDetailsCandidate?.questionCatalogId ?? 0);

                if (this.selectedReportDetailsCandidate) {
                    this.toggleDetailsModal();
                }
            } catch (error) {
                console.error(error);
            } finally {
                EventBus.$emit(EventBus.VIEWS.USER.REPORT_DETAILS_LOADING, reportDto.id, false);
            }
        },
        registerCallbacks(): void {
            EventBus.$on(EventBus.VIEWS.USER.REPORT_EDIT_BUTTON_CLICKED,
                async (reportDto: ReportDto) => {
                    await this.loadReport(reportDto);
                }
            );

            EventBus.$on(EventBus.VIEWS.USER.REPORT_ADD_BUTTON_CLICKED,
                async () => {
                    this.toggleNewReportModal();
                }
            );

            EventBus.$on(EventBus.VIEWS.USER.REPORT_DELETE_BUTTON_CLICKED,
                async (reportDto: ReportDto) => {
                    const deleteFunction = async (id: number): Promise<void> => {
                        try {
                            this.$store.commit(WAITING_MODAL_OPEN_STRING, {
                                title: this.$t(WAITING_MODAL_DEFAULT_TITLE),
                                content: this.$t(WAITING_MODAL_DEFAULT_TEXT)
                            });
                            this.$store.commit('confirmationModal/CLOSE_AND_RESET')
                            await this.reportApi.deleteReportById(id);
                            this.showSuccessToast();
                        } catch (e) {
                            if (axios.isAxiosError(e)) {
                                console.error(e);
                                this.showErrorToast();
                            } else {
                                console.error(`An unknown error occurred while delete a report.`);
                                console.error(e);
                                this.showErrorToast();
                            }
                        } finally {
                            EventBus.$emit(EventBus.VIEWS.USER.REFRESH_TABLE);
                            this.$store.commit(WAITING_MODAL_CLOSE_STRING);
                        }
                    }

                    this.$store.commit('confirmationModal/WAIT_FOR_CONFIRMATION', {
                        header: this.$t('reportDeleteModal.header', { description: reportDto.description }).toString(),
                        footerConfirmLabel: this.$t('yes').toString(),
                        footerCancelLabel: this.$t('no').toString(),
                        content: this.$t('reportDeleteModal.content', { description: reportDto.description }).toString(),
                        onConfirmCallback: () => deleteFunction(reportDto.id as number),
                        onCancelCallback: () => this.$store.commit('confirmationModal/CLOSE_AND_RESET'),
                        showLightboxCloseIcon: false,
                    } as ConfirmationModalProps)
                }
            );


            EventBus.$on(EventBus.VIEWS.USER.REPORT_EDITED, async (reportDto: ReportDto) => {
                this.selectedReportDetailsCandidate = reportDto;
                await this.updateUsers();
            });
        },
        toggleModal(name: 'detailsReportModalRef' | 'newReportModalRef'): void {
            try {
                (this.$refs[name] as any).isOpen = !((this.$refs[name] as any).isOpen);
                if (!((this.$refs[name] as any).isOpen) && name === 'detailsReportModalRef') {
                    this.selectedReportDetailsCandidate = undefined;
                }
            } catch (err) {
                // Catching buggy pebble ui type error
                // console.log(err);
            }
        },
        toggleDetailsModal(): void {
            this.toggleModal('detailsReportModalRef');
        },
        toggleNewReportModal(): void {
            this.toggleModal('newReportModalRef');
        },
        toggleReportConfirmationModal(internalReport: ReportDto | undefined = undefined): void {
            (this.$refs['reportConfirmationModalRef'] as any).toggleModal(internalReport);
        },
        showErrorToast(): void {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('reportDeleteToast.error.title').toString(),
                copy: this.$t('reportDeleteToast.error.text').toString(),
            });
        },
        showSuccessToast(): void {
            (this as any).$pui.toast({
                type: 'success',
                title: this.$t('reportDeleteToast.success.title').toString(),
                copy: this.$t('reportDeleteToast.success.text').toString(),
            });
        },
        filterChangedHandler(filters: ReportListEndpointRequest): void {
            filterChangedHandler(filters, REPORT_DEFAULT_FILTER, this.filterValues);
        },
        async newReportCreationRequestHandler(event: { report: ReportDto }): Promise<void> {
            // payload of the event is always a fresh ReportDto, assign it to the candidate and then toggle the modal
            this.selectedReportDetailsCandidate = event.report;
            await this.loadQuestionCatalogAndUpdateUsers(this.selectedReportDetailsCandidate?.questionCatalogId ?? 0);
            this.toggleDetailsModal();
        },
        triggerValidationFlow(): void {
            this.toggleReportConfirmationModal((this.$refs['reportDetailsComponentRef'] as any).internalReport);
        },
        async sendApprovalToWorkflow(showWaitingModal = true): Promise<boolean> {
            const report = ((this.$refs['reportDetailsComponentRef'] as any).internalReport as ReportDto);
            let result: BasicResponse;
            if (report.workflowId) {
                result = await this.interactWithWorkflow(WorkflowInteraction.EditorApprove, 7, showWaitingModal);
            } else {
                result = await this.interactWithWorkflow(WorkflowInteraction.Initialize, 7, showWaitingModal);
            }

            // Success? This operation might fail if there is more than or less than one approver
            if (result?.statusCode === HttpStatusCodes.InternalServerError) {
                if (result.message?.includes('More than one approver')) {
                    const approvers = result.message
                        .split('[')[1]
                        .split(']')[0]
                        .split(';')
                        .join(' & ');
                    (this as any).$pui.toast({
                        type: 'error',
                        title: this.$t('reportDetails.moreThanOneApprover.errorTitle').toString(),
                        copy: this.$t('reportDetails.moreThanOneApprover.errorText', { approvers: approvers }).toString(),
                    });
                }
                if (result.message?.includes('No valid approver')) {
                    (this as any).$pui.toast({
                        type: 'error',
                        title: this.$t('reportDetails.noValidApprover.errorTitle').toString(),
                        copy: this.$t('reportDetails.noValidApprover.errorText').toString(),
                    });
                }
                return false;
            }
            return true;
        },
        async triggerReportSubmission(): Promise<void> {
            this.$store.commit(WAITING_MODAL_OPEN_STRING, {
                title: this.$t('reportSubmissionWaitingModal.title'),
                content: this.$t('reportSubmissionWaitingModal.content')
            });

            const showWaitingModal = false;
            // Submit report (with comment) to backend
            await (this.$refs['reportDetailsComponentRef'] as any).verifyAndSubmit(undefined, showWaitingModal);

            // For normal reports we trigger the workflow here and send the pdfs to the blob storage
            if (!this.showOnlyTestReports) {
                // 3. Start workflow (this will implicitly calculate the risks for this report)
                const success = await this.sendApprovalToWorkflow(showWaitingModal);
                if (!success) {
                    return;
                }

                // 4. Trigger PDF creation
                await this.refreshPdfs();
            }
            // For test reports we simply trigger the calculation of the evaluation for this report
            // and afterwards we trigger the update of the reportStatus in the backend (normally done by the workflow)
            else {
                if (this.selectedReportDetailsCandidate?.id) {
                    // Trigger evaluation
                    const response = await apiCallWithErrorHandling(
                        this.reportApi.triggerReportEvaluation,
                        this.selectedReportDetailsCandidate.id,
                        (this as any).$pui.toast,
                        this.workflowErrorStrings,
                        this.$store);

                    if (response?.statusCode !== HttpStatusCodes.Ok) {
                        return;
                    }

                    // Update status
                    const result = await apiCallWithErrorHandling(
                        this.reportApi.updateReportStatus,
                        new ReportStatusEndpointRequest({
                            reportId: this.selectedReportDetailsCandidate.id,
                            workflowId: undefined,
                            reportStatus: ReportApprovalStatus.ApprovedByEditor,
                            userId: this.currentUserId,
                            date: moment.tz(moment.tz.guess()).toISOString(false),
                        }),
                        (this as any).$pui.toast,
                        this.workflowErrorStrings,
                        this.$store);

                    // trigger refresh of table and update candidate
                    if (result?.statusCode === HttpStatusCodes.Ok) {
                        EventBus.$emit(EventBus.VIEWS.USER.REFRESH_TABLE);
                        this.selectedReportDetailsCandidate = result.result;
                        await this.updateUsers();
                    }
                }
            }
        },
        async approveByEditor(): Promise<void> {
            // 1. Check (all questions answered) & submit the event
            const reportValidation = reportValidator(this.selectedReportDetailsCandidate!, this.internalQuestionCatalog!);

            if (reportValidation.openQuestions.length === 0) {
                this.triggerReportSubmission();
                return;
            }

            await this.triggerValidationFlow();
        },
        async approveByApprover(): Promise<void> {
            this.$store.commit(WAITING_MODAL_OPEN_STRING, {
                title: this.$t('reportApproverApproveWaitingModal.title'),
                content: this.$t('reportApproverApproveWaitingModal.content')
            });

            await this.interactWithWorkflow(WorkflowInteraction.ApproverApprove, 3, false);

            // Trigger PDF re-creation
            // Note: this will indirectly close the waiting modal through the triggered watcher on 'pdfBlobs'
            await this.refreshPdfs();
        },
        async revokeByEditor(): Promise<void> {
            // For normal reports we trigger the workflow but not for test reports!
            if (!this.showOnlyTestReports) {
                this.$store.commit(WAITING_MODAL_OPEN_STRING, {
                    title: this.$t(WAITING_MODAL_DEFAULT_TITLE),
                    content: this.$t(WAITING_MODAL_DEFAULT_TEXT)
                });

                await this.interactWithWorkflow(WorkflowInteraction.EditorRevoke, 3, false);

                // Close the waiting modal now
                this.$store.commit(WAITING_MODAL_CLOSE_STRING);
            } else {
                if (this.selectedReportDetailsCandidate?.id) {
                    // update report status
                    const result = await apiCallWithErrorHandling(
                        this.reportApi.updateReportStatus,
                        new ReportStatusEndpointRequest({
                            reportId: this.selectedReportDetailsCandidate.id,
                            workflowId: undefined,
                            reportStatus: ReportApprovalStatus.Editable,
                            userId: this.currentUserId,
                            date: moment.tz(moment.tz.guess()).toISOString(false),
                        }),
                        (this as any).$pui.toast,
                        this.workflowErrorStrings,
                        this.$store);

                    // trigger refresh of table and update candidate
                    if (result?.statusCode === HttpStatusCodes.Ok) {
                        EventBus.$emit(EventBus.VIEWS.USER.REFRESH_TABLE);
                        this.selectedReportDetailsCandidate = result.result;
                        await this.updateUsers();
                    }
                }
            }
        },
        async rejectByApprover(): Promise<void> {
            // Open the waiting modal now
            this.$store.commit(WAITING_MODAL_OPEN_STRING, {
                title: this.$t(WAITING_MODAL_DEFAULT_TITLE),
                content: this.$t(WAITING_MODAL_DEFAULT_TEXT)
            });

            // And don't open any additional waiting modals
            // This function call will trigger the workflow and update the Dto here in the frontend
            await this.interactWithWorkflow(WorkflowInteraction.ApproveReject, 3, false);

            // Close the waiting modal now
            this.$store.commit(WAITING_MODAL_CLOSE_STRING);
        },
        async revokeByApprover(): Promise<void> {
            // Open the waiting modal now
            this.$store.commit(WAITING_MODAL_OPEN_STRING, {
                title: this.$t(WAITING_MODAL_DEFAULT_TITLE),
                content: this.$t(WAITING_MODAL_DEFAULT_TEXT)
            });

            // And don't open any additional waiting modals
            // This function call will trigger the workflow and update the Dto here in the frontend
            await this.interactWithWorkflow(WorkflowInteraction.AdminRevoke, 3, false);

            // Close the waiting modal now
            this.$store.commit(WAITING_MODAL_CLOSE_STRING);
        },
        async interactWithWorkflow(interaction: WorkflowInteraction, sleepTime = 3, showWaitingModal = true): Promise<BasicResponse> {
            let apiFunction: any;
            let newState = ReportApprovalStatus.New;
            const report = ((this.$refs['reportDetailsComponentRef'] as any).internalReport as ReportDto);
            const locations = this.$store.getters['locations/locations'] as LocationDto[];
            let argument: any = report.id ?? 0;
            const newReport = _.cloneDeep(report);
            const dateNow = moment.tz(this.tz).toISOString(false);

            switch (interaction) {
            case WorkflowInteraction.ApproverApprove: {
                apiFunction = this.workflowApi.approveReport;
                newState = ReportApprovalStatus.ApprovedByApprover;
                argument = {
                    reportId: report.id ?? 0,
                    date: dateNow,
                }
                newReport.plantManagerApprovalUser = this.currentUserId;
                newReport.plantManagerApprovalDate = dateNow;
                break;
            }

            case WorkflowInteraction.ApproveReject: {
                apiFunction = this.workflowApi.rejectReport;
                newState = ReportApprovalStatus.Editable;
                newReport.creatorApprovalUser = undefined;
                newReport.creatorApprovalDate = undefined;
                break;
            }

            case WorkflowInteraction.EditorRevoke: {
                apiFunction = this.workflowApi.revokeRelease;
                newState = ReportApprovalStatus.Editable;
                newReport.creatorApprovalUser = undefined;
                newReport.creatorApprovalDate = undefined;
                break;
            }

            case WorkflowInteraction.AdminRevoke: {
                apiFunction = this.workflowApi.revokeApproval;
                newState = ReportApprovalStatus.Editable;
                newReport.creatorApprovalUser = undefined;
                newReport.creatorApprovalDate = undefined;
                newReport.plantManagerApprovalUser = undefined;
                newReport.plantManagerApprovalDate = undefined;
                break;
            }

            case WorkflowInteraction.Initialize: {
                apiFunction = this.workflowApi.startWorkflow;
                newState = ReportApprovalStatus.ApprovedByEditor;
                argument = {
                    reportId: report.id,
                    editorUserId: this.currentUserId,
                    locationName: locations.filter(x => x.id === report.locationId)[0].locationName,
                    reportTypeGerman: this.$t(`reportType.${ReportType[report.reportType as unknown as number]}`, this.$i18n.availableLocales.filter(x => x.includes('de-DE'))[0]),
                    reportTypeEnglish: this.$t(`reportType.${ReportType[report.reportType as unknown as number]}`, this.$i18n.availableLocales.filter(x => x.includes('en-GB'))[0]),
                    date: dateNow,
                }
                newReport.creatorApprovalUser = this.currentUserId;
                newReport.creatorApprovalDate = dateNow;
                break;
            }

            case WorkflowInteraction.EditorApprove: {
                apiFunction = this.workflowApi.releaseReport;
                newState = ReportApprovalStatus.ApprovedByEditor;
                argument = {
                    reportId: report.id ?? 0,
                    date: dateNow,
                }
                newReport.creatorApprovalUser = this.currentUserId;
                newReport.creatorApprovalDate = dateNow;
                break;
            }
            }

            // trigger workflow interaction
            const result = await apiCallWithErrorHandling(
                apiFunction,
                argument,
                (this as any).$pui.toast,
                this.workflowErrorStrings,
                this.$store,
                sleepTime,
                showWaitingModal
            );

            // if successful, update report dto in the frontend (required for pdf creation!)
            if (result?.statusCode === HttpStatusCodes.Ok) {
                // Wait two seconds and then try to load the report from the backend
                // By now meta-data should have called the backend and the update should be complete
                await this.getReportFromBackendAndRefreshCandidate(showWaitingModal);

                if (!this.wasUpdateSuccessful(interaction)) {
                    (this as any).$pui.toast({
                        type: 'error',
                        title: this.$t('reportUpdateErrorToast.title').toString(),
                        copy: this.$t('reportUpdateErrorToast.content').toString(),
                    });
                }
            } else {
                this.showErrorToast();
            }

            return result;
        },
        wasUpdateSuccessful(interaction: WorkflowInteraction): boolean {
            const report = this.selectedReportDetailsCandidate;
            if (!report) {
                return false;
            }
            let updateSuccessful = true;
            if (interaction === WorkflowInteraction.ApproverApprove) {
                if (report.approvalStatus != ReportApprovalStatus.ApprovedByApprover) {
                    updateSuccessful = false;
                }
            } else if (interaction === WorkflowInteraction.Initialize ||
                interaction === WorkflowInteraction.EditorApprove) {
                if (report.approvalStatus != ReportApprovalStatus.ApprovedByEditor) {
                    updateSuccessful = false;
                }
            } else if (interaction === WorkflowInteraction.ApproveReject ||
                interaction === WorkflowInteraction.EditorRevoke ||
                interaction === WorkflowInteraction.AdminRevoke) {
                if (report.approvalStatus != ReportApprovalStatus.Editable) {
                    updateSuccessful = false;
                }
            } else {
                throw 'Unknown workflow interaction!';
            }
            return updateSuccessful;
        },
        async onSaveSubmit(): Promise<void> {
            await (this.$refs['reportDetailsComponentRef'] as any).verifyAndSubmit();
        },
        openReportDetailsTreeView(q: any, questionString: any, question: FlatQuestion): void {
            if ((this.$refs['detailsReportModalRef'] as any).isOpen) {
                (this.$refs['reportDetailsComponentRef'] as any).onTabChanged(1, questionString, question);
            }
        },
        async refreshPdfs(): Promise<void> {
            // we need to make sure that Vue updates everything so that the PDF renders correctly
            // therefore we use the "key change triggers rerender trick" here
            this.auditKey = `audit_${Number(this.auditKey.split('_')[1]) + 1}`;
            this.riskKey = `risk_${Number(this.auditKey.split('_')[1]) + 1}`;
            await Vue.nextTick();

            // then we trigger the rendering of the pdfs
            // Note: this does not perform an upload to the backend directly!
            // However, there is a watcher that will be triggered once both pdfs have been created
            // and then they are sent to the backend.
            await (this.$refs['auditReportRef'] as any).createReport({
                openInTab: false,
                showWaitingModal: false,
            });
            await (this.$refs['riskReportRef'] as any).createReport({
                openInTab: false,
                showWaitingModal: false,
            });
        },
        async getReportFromBackendAndRefreshCandidate(showWaitingModal = true): Promise<void> {
            // get updated DTO from backend (new status, evaluation, ...)
            const result = await apiCallWithErrorHandling(
                this.reportApi.getReport,
                this.selectedReportDetailsCandidate?.id ?? 0,
                (this as any).$pui.toast,
                this.workflowErrorStrings,
                this.$store,
                0,
                showWaitingModal
            );

            // trigger refresh of table and update candidate
            if (result?.statusCode === HttpStatusCodes.Ok) {
                this.selectedReportDetailsCandidate = result.result;
                await this.updateUsers();
            }
            EventBus.$emit(EventBus.VIEWS.USER.REFRESH_TABLE);
        },
        async handleRiskPDFCreation(blob: Blob): Promise<void> {
            this.pdfBlobs.riskBlob = blob;
        },
        async handleAuditPDFCreation(blob: Blob): Promise<void> {
            this.pdfBlobs.auditBlob = blob;
        },
        async handlePDFCreation(blob: Blob, pdfType: PDFReportType, showWaitingModal = false): Promise<void> {
            // send pdf
            await apiCallWithErrorHandling(
                this.reportApi.addPDF,
                {
                    id: this.selectedReportDetailsCandidate?.id ?? 0,
                    pdf: { data: blob, fileName: 'pdf-file' },
                    reportType: pdfType,
                    useLocalTestMode: this.$store.getters['usesLocalBackend'],
                    suffix: this.$store.getters['usesLocalBackend']
                        ? this.$store.getters['userAccessManagement/getUserKid']
                        : undefined,
                },
                (this as any).$pui.toast,
                {
                    successTitle: this.$t('reportDetails.pdfUploadToast.successTitle').toString(),
                    successText: this.$t('reportDetails.pdfUploadToast.successText').toString(),
                    errorTitle: this.$t('reportDetails.pdfUploadToast.errorTitle').toString(),
                    errorText: this.$t('reportDetails.pdfUploadToast.errorText').toString(),
                },
                this.$store,
                0,
                showWaitingModal
            );

            // Afterwards perform a refresh of the ReportDto (so that we have the correct PDF URIs)
            await this.getReportFromBackendAndRefreshCandidate(showWaitingModal);
        },
        async submitRiskAndAuditReport(auditBlob: Blob, riskBlob: Blob, showWaitingModal = true): Promise<void> {
            // Open waiting modal
            if (showWaitingModal) {
                this.$store.commit(WAITING_MODAL_OPEN_STRING, {
                    title: this.$t('reportDetails.pdfSubmissionWaitingModal.title'),
                    content: this.$t('reportDetails.pdfSubmissionWaitingModal.content')
                });
            }

            // submit reports
            // waitingModals should never be shown here, since either we opened one before, or we don't want any
            const auditPromise = this.handlePDFCreation(auditBlob, PDFReportType.AuditReport, false);
            const riskPromise = this.handlePDFCreation(riskBlob, PDFReportType.RiskReport, false);
            await Promise.all([auditPromise, riskPromise]);

            // Close waiting modal
            if (showWaitingModal) {
                this.$store.commit(WAITING_MODAL_CLOSE_STRING);
            }
        },
        async loadQuestionCatalog(questionCatalogId: number): Promise<QuestionCatalogDto> {
            const response = await apiCallWithErrorHandling(
                this.questionCatalogApi.getQuestionCatalog,
                questionCatalogId,
                (this as any).$pui.toast,
                {
                    errorTitle: this.$t('reportTable.catalogLoadingToast.errorTitle').toString(),
                    errorText: this.$t('reportTable.catalogLoadingToast.errorText').toString(),
                },
                this.$store
            );

            if (response?.result) {
                return response.result;
            }
            throw 'Cannot load question catalog!';
        },
        async loadQuestionCatalogAndUpdateUsers(questionCatalogId: number): Promise<void> {
            this.internalQuestionCatalog = await this.loadQuestionCatalog(questionCatalogId);
            this.catalogApprovalUser = this.internalQuestionCatalog.approvalUserId
                ? (await this.userApi.getUser(this.internalQuestionCatalog.approvalUserId)).result
                : undefined;
            this.testCatalogApprovalUser = this.internalQuestionCatalog.testApprovalUserId
                ? (await this.userApi.getUser(this.internalQuestionCatalog.testApprovalUserId)).result
                : undefined;
            await this.updateUsers();
        }
    }
});

export default ReportAdministration;
