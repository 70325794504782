
import Vue, { PropType } from 'vue';
import {
    CatalogTypeDto,
    LocationDto, LocationPermissionDto,
    UserCatalogTypePermissionDto,
    UserDto,
    UserLocationPermissionDto
} from '@/service-proxies/service-proxies.g';
import _ from 'lodash';
import { EventBus } from '@/utils';
import { UserService } from '@/services/user-service';
import { UserRoles } from '@/enums/user-roles';
import { UserRoleColors } from '@/enums/user-role-colors';
import { apiCallWithErrorHandling } from '@/services/utils';
import { WAITING_MODAL_DEFAULT_TEXT, WAITING_MODAL_DEFAULT_TITLE } from '@/store/modules/waiting-modal';
import '@/utils/extensions'

const UserDetails = Vue.extend({
    name: 'UserDetails',
    props: {
        user: {
            type: Object as PropType<UserDto>,
            required: true,
        },
        locations: {
            type: Array as PropType<Array<LocationDto>>,
            required: true,
        },
        catalogTypes: {
            type: Array as PropType<Array<CatalogTypeDto>>,
            required: true,
        },
        saveData: {
            type: Boolean,
            required: false
        }
    },
    data (): {
        internalUser: UserDto;
        dataLoaded: boolean;
        selected: number;
        isLoading: boolean;
        cardColor: string;
        userLocationPermissions: Record<number, [boolean, boolean, boolean]>;
        userCatalogTypePermissions: Record<number, boolean>;
        originalUserLocationPermissions: Record<number, [boolean, boolean, boolean]>;
        originalUserCatalogTypePermissions: Record<number, boolean>;
        userApi: UserService;
        userRole: UserRoles;
        userRoleColor: UserRoleColors;
        } {
        return {
            internalUser: this.user,
            dataLoaded: false,
            selected: 0,
            isLoading: true,
            cardColor: '#0078dc',
            userLocationPermissions: {} as Record<number, [boolean, boolean, boolean]>,
            userCatalogTypePermissions: {} as Record<number, boolean>,
            originalUserLocationPermissions: {} as Record<number, [boolean, boolean, boolean]>,
            originalUserCatalogTypePermissions: {} as Record<number, boolean>,
            userApi: new UserService(),
            userRole: UserRoles.Unknown,
            userRoleColor: UserRoleColors.Unknown,
        }
    },
    mounted(): void {
        this.loadUserRole();
        this.fillPermissionLists();
        this.registerCallbacks();

        this.dataLoaded = true;
    },
    watch: {
        dataModified: {
            handler(newValue: boolean): void {
                if (!this.isAdmin) {
                    this.$emit('data-changed', newValue)
                }
            },
            immediate: true
        },
        saveData: {
            handler(): void {
                this.submitChanges();
            }
        }
    },
    methods: {
        registerCallbacks (): void {
            EventBus.$on(EventBus.VIEWS.ADMIN.SUBMIT_USER_CHANGES,
                (userId: number) => {
                    if (userId === this.internalUser.id) {
                        // noop
                    }
                }
            );
        },
        onTabChanged(newTab: number): void {
            this.selected = newTab;
        },
        onLocationChange (id: number, roleIdx: number, state: boolean): void {
            Vue.set(this.userLocationPermissions[id], roleIdx, state);
        },
        onCatalogTypeChange (id: number, state: boolean): void {
            Vue.set(this.userCatalogTypePermissions, id, state);
        },
        fillPermissionLists (): void {
            // locations
            this.fillLocationPermissions();

            // catalogTypes
            this.fillCatalogTypePermissions();

            // create copy of originalStatus
            this.originalUserCatalogTypePermissions = _.cloneDeep(this.userCatalogTypePermissions);
            this.originalUserLocationPermissions = _.cloneDeep(this.userLocationPermissions);
        },
        fillLocationPermissions (): void {
            for(const el of this.locations) {
                if (el.id) {
                    Vue.set(this.userLocationPermissions, el.id, [false, false, false]);
                    const dto = this.internalUser.locationPermissionDtos?.find(x => x.locationId === el.id);
                    this.setReaderEditorApprover(el, dto);
                }
            }
        },
        setReaderEditorApprover (loc: LocationDto, dto: LocationPermissionDto | undefined): void {
            if (loc.id) {
                if (this.userRole === UserRoles.Controller) {
                    Vue.set(this.userLocationPermissions[loc.id], 0, dto?.reader ?? false);
                    Vue.set(this.userLocationPermissions[loc.id], 1, false);
                    Vue.set(this.userLocationPermissions[loc.id], 2, false);
                } else if (this.userRole === UserRoles.Admin) {
                    Vue.set(this.userLocationPermissions[loc.id], 0, true);
                    Vue.set(this.userLocationPermissions[loc.id], 1, true);
                    Vue.set(this.userLocationPermissions[loc.id], 2, true);
                } else {
                    Vue.set(this.userLocationPermissions[loc.id], 0, dto?.reader ?? false);
                    Vue.set(this.userLocationPermissions[loc.id], 1, dto?.editor ?? false);
                    Vue.set(this.userLocationPermissions[loc.id], 2, dto?.approver ?? false);
                }
            }
        },
        fillCatalogTypePermissions (): void {
            for(const el of this.catalogTypes) {
                if (el.id) {
                    const hasPermission = this.internalUser.catalogTypePermissionDtos?.map(x => x.catalogTypeId)
                        .includes(el.id) ?? false;

                    if (this.userRole === UserRoles.Admin) {
                        Vue.set(this.userCatalogTypePermissions, el.id, true);
                    } else {
                        Vue.set(this.userCatalogTypePermissions, el.id, hasPermission);
                    }
                }
            }
        },
        loadUserRole (): void {
            function toUserRole(role: string): UserRoles {
                return _.startCase(_.camelCase(role)).replace(/ /g, '') as UserRoles;
            }
            const useCaseId = this.$store.getters['appData/useCaseId'] as number;
            this.userRole = toUserRole(this.internalUser.userGroupTypes?.filter(x => x.useCaseId === useCaseId).map(x => x.name)[0] as string);
            this.userRoleColor = UserRoleColors[this.userRole];
        },
        async callBackendAndUpdateData (newLocationPermissionDtos: UserLocationPermissionDto[] | undefined, newCatalogTypePermissionDtos: UserCatalogTypePermissionDto[] | undefined): Promise<void> {
            const response = await apiCallWithErrorHandling(
                this.userApi.editUser,
                {
                    userId: this.internalUser.id,
                    locationPermissionDtos: newLocationPermissionDtos,
                    catalogTypePermissionDtos: newCatalogTypePermissionDtos,
                },
                (this as any).$pui.toast,
                {
                    successTitle: this.$t('userDetails.userChangeToast.successTitle').toString(),
                    successText: this.$t('userDetails.userChangeToast.successText').toString(),
                    errorTitle: this.$t('userDetails.userChangeToast.errorTitle').toString(),
                    errorText: this.$t('userDetails.userChangeToast.errorText').toString(),
                    waitingTitle: this.$t(WAITING_MODAL_DEFAULT_TITLE).toString(),
                    waitingText: this.$t(WAITING_MODAL_DEFAULT_TEXT).toString(),
                    forbiddenErrorText: this.$t('userDetails.userChangeToast.forbiddenErrorText').toString(),
                },
                this.$store
            );

            // Update User
            if (response && response?.result) {
                this.internalUser = response.result;
                this.fillPermissionLists();
                EventBus.$emit(EventBus.VIEWS.ADMIN.REFRESH_TABLE);

                // In addition, we need to update the vuex store (only for "own" user though!)
                if (this.internalUser.id === this.$store.getters['userAccessManagement/getUserId']) {
                    await this.$store.commit('userAccessManagement/SET_USER_LOCATIONS_AND_CATALOG_TYPES', {
                        locations: _.cloneDeep(this.internalUser.locationPermissionDtos) ?? [],
                        catalogTypes: _.cloneDeep(this.internalUser.catalogTypePermissionDtos) ?? [],
                    })
                }
            }
        },
        async submitChanges (): Promise<void> {
            const newLocationPermissionDtos = Object
                .entries(this.userLocationPermissions)
                .map(keyValuePair => new UserLocationPermissionDto({
                    locationId: Number(keyValuePair[0]),
                    reader: keyValuePair[1][0],
                    editor: keyValuePair[1][1],
                    approver: keyValuePair[1][2],
                }))
                .filter(x => x.reader || x.editor || x.approver);

            const newCatalogTypePermissionDtos = Object
                .entries(this.userCatalogTypePermissions)
                .filter(keyValuePair => keyValuePair[1])
                .map(keyValuePair => new UserCatalogTypePermissionDto({
                    catalogTypeId: Number(keyValuePair[0]),
                }));

            await this.callBackendAndUpdateData(
                this.locationsModified ? newLocationPermissionDtos : undefined,
                this.catalogTypesModified ? newCatalogTypePermissionDtos : undefined
            );
        },
        async resetAllPermissionsForUser (): Promise<void> {
            await this.callBackendAndUpdateData([], []);
        },
        isLocationCheckBoxDisabled(item: 'reader' | 'editor' | 'approver'): boolean {
            return this.isAdmin
                || (this.userIsDataProvider && item === 'approver' )
                || (this.userIsController && item !== 'reader');
        }
    },
    computed: {
        cardTitle (): string {
            return this.internalUser.name ?? '';
        },
        locationsModified (): boolean {
            return !_.isEqual(this.userLocationPermissions, this.originalUserLocationPermissions);
        },
        catalogTypesModified (): boolean {
            return !_.isEqual(this.userCatalogTypePermissions, this.originalUserCatalogTypePermissions);
        },
        dataModified (): boolean {
            return this.locationsModified || this.catalogTypesModified;
        },
        isAdmin (): boolean {
            return this.userRole === UserRoles.Admin;
        },
        userIsDataProvider (): boolean {
            return this.userRole === UserRoles.DataProvider;
        },
        userIsController (): boolean {
            return this.userRole === UserRoles.Controller;
        }
    }
})

export default UserDetails;

