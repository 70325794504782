
import Vue, { PropType } from 'vue';
import BgTooltip from '@/components/table/custom-tooltips/bgTooltip.vue';
import TableFiltered from '@/components/table-filtered/table-filtered.vue';
import { ReportListEndpointRequest } from '@/models';
import { ColumnDef, VendorOptions } from '@/components/table/contracts/table-data';
import moment from 'moment-timezone';
import {
    LocationDto,
    LocationPermissionDto,
    ReportApprovalStatus,
    ReportDto,
    ReportSortColumn,
    ReportType,
    SortType,
} from '@/service-proxies/service-proxies.g';
import { FilterChangedEvent, GridApi, SelectionChangedEvent } from 'ag-grid-community';
import CustomMultiSelectFilter from '@/components/table-filtered/custom-filters/multi-select-filter.vue';
import { removeEmptyFieldsFrom } from '@/utils/filter-utils';
import { ReportService } from '@/services/report-service';
import { LocalStorageHelper } from '@/utils/local-storage-helper';
import { LANGUAGES } from '@/utils/languages';
import EditReportButtonCell from '@/components/report-table/custom-cells/edit-report-button-cell.vue';
import { UserActions } from '@/enums/user-actions';
import {
    ApprovalStatus,
    FakeApprovalStatusService
} from '@/components/report-table/fake-services/fake-approval-status-service';
import ApprovalStatusCell from '@/components/report-table/custom-cells/approval-status-cell.vue';
import { CustomFilterProps } from '@/components/table-filtered/custom-filters/contracts/CustomFilterProps';
import { ColumnSort } from '@/components/table/contracts/table-enums';
import _ from 'lodash';
import { ReportTableData } from '@/components/report-table/contracts/table-data';

const ReadonlyReportTable: any = Vue.extend({
    name: 'ReadonlyReportTable',
    props: {
        tableTitle: {
            type: String,
            required: true,
        },
        filterValues: {
            type: Object as PropType<ReportListEndpointRequest>,
            required: true
        },
        filtersAvailable: {
            type: Boolean,
            default: true,
        },
        averageReportMode: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        BgTooltip, // eslint-disable-line vue/no-unused-components
        TableFiltered,
        CustomMultiSelectFilter, // eslint-disable-line vue/no-unused-components
        EditReportButtonCell, // eslint-disable-line vue/no-unused-components
        ApprovalStatusCell // eslint-disable-line vue/no-unused-components
    },
    data (): {
        eventTableName: string;
        lastUpdateRequest: moment.Moment;
        tz: string;
        pagination: {
            totalPages: number;
            rangeOfPages: number;
        };
        vendorOptions: VendorOptions<ReportTableData>;
        reportService: ReportService;
        sortColumnToRequestFieldMap: Record<string, ReportSortColumn>;
        filterColumnToRequestFieldMap: Record<string, keyof ReportListEndpointRequest>;
        sortTypeToRequestFieldMap: { [key: string]: SortType | undefined };
        gridApi: undefined | GridApi;
        changedEvent: any;
        filterChangedEvent: any;
        availableColumnsFilterValues: Array<keyof ReportListEndpointRequest>;
        selectedLanguage: string;
        fakeApprovalStatusService: FakeApprovalStatusService;
        selectedReportRows: ReportDto[];
        allSelectedRows: any;
        defaultFilterValues: Partial<ReportListEndpointRequest>;
        } {
        return {
            eventTableName: 'UserViewReportTable',
            lastUpdateRequest: moment.tz(moment.tz.guess()),
            tz: moment.tz.guess(),
            pagination: {
                totalPages: 1,
                rangeOfPages: 3,
            },
            vendorOptions: {
                data:
                    _.range(500).map(() => ({
                        'meta': {
                            reportDto: new ReportDto(),
                        },
                        'col-description': '',
                        'col-catalogTitle': '',
                        'col-year': undefined,
                        'col-type': '',
                        'col-version': undefined,
                        'col-details': '',
                        'col-reportType': '',
                        'col-location': '',
                        'col-approval-state': undefined,
                    })),
                rowSelection: 'multiple',
                columnDefs: [],
                withPagination: true,
                paginationAutoPageSize: true,
            },
            reportService: new ReportService(),
            sortColumnToRequestFieldMap: {
                'col-description': ReportSortColumn.Description,
                'col-year': ReportSortColumn.Year,
                'col-version': ReportSortColumn.Version,
            },
            filterColumnToRequestFieldMap: {
                'col-questionCatalogs': 'questionCatalogIds',
                'col-approval-state': 'approvalStatus',
            },
            sortTypeToRequestFieldMap: {
                'asc': SortType.Ascending,
                'desc': SortType.Descending,
            },
            gridApi: undefined,
            changedEvent: undefined,
            filterChangedEvent: undefined,
            availableColumnsFilterValues: ['approvalStatus'],
            selectedLanguage: 'en-GB',
            fakeApprovalStatusService: new FakeApprovalStatusService(),
            selectedReportRows: [],
            allSelectedRows: [],
            defaultFilterValues: {
                approvalStatus: [
                    ReportApprovalStatus.ApprovedByEditor,
                    ReportApprovalStatus.ApprovedByApprover,
                    ReportApprovalStatus.Finalized
                ]
            },
        }
    },
    created (): void {
        this.vendorOptions.columnDefs = this.defaultColumnDefs();
    },
    async mounted (): Promise<void> {
        this.selectedLanguage = LANGUAGES[LocalStorageHelper.getSelectedLanguage() ?? 'EN'];
        await this.constructTable();
    },
    computed: {
        filterColumnToRequestFieldMapInverse (): Record<string, string> {
            const inverseMap: Record<string, string> = {};
            for (const [k, v] of Object.entries(this.filterColumnToRequestFieldMap)) {
                inverseMap[v] = k;
            }
            return inverseMap;
        },
        agGridFilterModel (): Record<string, { value: any }> {
            // Map to correct filter props
            const filterModel: Record<string, { value: any }> = {};
            for (const [k, v] of Object.entries(this.filterValues)) {
                const column = this.filterColumnToRequestFieldMapInverse[k];
                if (this.availableColumnsFilterValues.includes(k as keyof ReportListEndpointRequest)) {
                    filterModel[column] = {
                        value: v,
                    };
                }
            }

            return filterModel;
        },
        canCreateReport (): boolean {
            const locations = this.$store.getters['userAccessManagement/getUserLocations'] as LocationPermissionDto[];
            return locations.length > 0 &&
            this.$store.getters['userAccessManagement/getUserPermissions'].includes(UserActions.CreateReport);
        }
    },
    methods: {
        onPageSizeFound(pageSize: number): void {
            this.filterValues.size = pageSize;
        },
        /* eslint-disable sonarjs/no-duplicate-string */
        defaultColumnDefs (): ColumnDef[] {
            const staticList = this.averageReportMode
                ? this.fakeApprovalStatusService.getItems().result.items
                    .filter(x => x.id === ReportApprovalStatus.ApprovedByEditor
                        || x.id === ReportApprovalStatus.ApprovedByApprover
                        || x.id === ReportApprovalStatus.Finalized)
                : this.fakeApprovalStatusService.getItems().result.items;

            const approvalStatusCustomUserFilterParams: CustomFilterProps<ApprovalStatus> = {
                filterLabel: this.$t('filterLabels.approvalStatus').toString(),
                dataName: 'col-approval-state',
                apiService: this.fakeApprovalStatusService,
                valueName: 'id',
                labelName: 'description',
                secondaryLabelName: 'description',
                staticList: staticList,
            }
            return [
                {
                    headerName: this.$t('reportTable.description').toString(),
                    field: 'col-description', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-description', tooltipComponentParams: { key: 'col-description' },
                    comparator: (): number => 0,
                    filter: false,
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                },
                {
                    headerName: this.$t('reportTable.year').toString(),
                    field: 'col-year', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-year', tooltipComponentParams: { key: 'col-year' },
                    comparator: (): number => 0,
                    filter: true,
                    sort: ColumnSort.desc,
                },
                {
                    headerName: this.$t('reportTable.location').toString(),
                    field: 'col-location', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-location', tooltipComponentParams: { key: 'col-location' },
                    comparator: (): number => 0,
                    filter: false,
                    sortable: false,
                },
                {
                    headerName: this.$t('reportTable.reportType').toString(),
                    field: 'col-type', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-type', tooltipComponentParams: { key: 'col-type' },
                    comparator: (): number => 0,
                    filter: true,
                    sortable: false,
                },
                {
                    headerName: this.$t('reportTable.version').toString(),
                    field: 'col-version', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-version', tooltipComponentParams: { key: 'col-version' },
                    comparator: (): number => 0,
                    filter: false,
                },
                {
                    headerName: this.$t('reportTable.approvalState').toString(),
                    field: 'col-approval-state', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-approval-state', tooltipComponentParams: { key: 'col-approval-state' },
                    comparator: (): number => 0,
                    filter: 'CustomMultiSelectFilter',
                    filterParams: approvalStatusCustomUserFilterParams,
                    sortable: false,
                    cellRenderer: 'ApprovalStatusCell',
                }
            ];
        },
        async constructTable (): Promise<void> {
            const { filteredReports, time } = await this.getFilteredValues(this.filterValues);

            // only update the table if this is really the latest request
            if (time.valueOf() === this.lastUpdateRequest.valueOf()) {
                this._constructTable(filteredReports);
            }
        },
        async getFilteredValues (filters: ReportListEndpointRequest): Promise<{ filteredReports: ReportDto[]; time: moment.Moment }> {
            // make sure the datetimes are in utc!
            // TODO: Not needed at the moment
            const utcFilters: ReportListEndpointRequest = {
                ...filters,
            };

            const time = moment.tz(this.tz);
            this.lastUpdateRequest = time; // save the current time to handle overlapping requests
            const res = (await this.reportService.getReports(utcFilters)).result;
            // success
            if (res) {
                if (res.items && res.items.length) {
                    res.items.map((item: any) => {
                        item.currentPage = res.currentPage;
                        item.hasNextPage = res.hasNextPage;
                        item.hasPreviousPage = res.hasPreviousPage;
                        return item;
                    })
                }
                this.pagination.totalPages = res.totalPages === 0 ? 1 : res.totalPages ?? 0;
                return {
                    filteredReports: res.items ?? [],
                    time: time
                };
            }

            // failure
            return {
                filteredReports: [],
                time: time,
            }
        },
        _constructTable (filteredReports: ReportDto[]): void {
            this.vendorOptions.data = [];
            filteredReports.forEach((report: ReportDto) => {
                this.vendorOptions.data.push(
                    {
                        'meta': {
                            reportDto: report,
                        },
                        'col-description': report.description,
                        'col-year': report.reportYear,
                        'col-type': this.$t(`reportType.${ReportType[report.reportType!]}`).toString(),
                        'col-version': report.version,
                        'col-approval-state': ReportApprovalStatus[report.approvalStatus ?? 0],
                        'col-details': undefined,
                        'col-location': (this.$store.getters['locations/locations'] as LocationDto[]).filter(x => x.id === report.locationId)[0].locationName
                    }
                );
            });
        },
        onSortChanged (sortChangedEvent: any): void {

            // caution: this doesnt work as expected once, multi-column sort is activated
            // only the first found sorted column will be used
            const currentSortedColumn = sortChangedEvent.columnApi
                .getColumnState()
                .find((col: any) => col.sort === 'asc' || col.sort === 'desc');

            if (!currentSortedColumn) {
                this.filterValues.sortProperty = undefined;
                this.filterValues.sortDirection = undefined;
                return;
            }

            if (!this.sortColumnToRequestFieldMap[currentSortedColumn.colId]) {
                return;
            }

            this.filterValues.sortDirection = this.sortTypeToRequestFieldMap[currentSortedColumn.sort];
            this.filterValues.sortProperty = this.sortColumnToRequestFieldMap[currentSortedColumn.colId];
        },
        onAGGridFilterChanged (filterChangedEvent: FilterChangedEvent): void {
            this.filterChangedEvent = filterChangedEvent;
            const filters = filterChangedEvent.api.getFilterModel();
            this.gridApi = filterChangedEvent.api;

            // Map to correct filter props
            // As a first step set everything to undefined
            const mappedFilters: Record<string, any> = {};
            for (const el of this.availableColumnsFilterValues) {
                mappedFilters[el] = this.defaultFilterValues[el];
            }

            // then fill with actual filter values
            for (const [k, v] of Object.entries(filters)) {
                const mappedValue = this.filterColumnToRequestFieldMap[k];
                if (mappedValue) {
                    // standard ag-grid uses 'filter' but our custom filter uses 'value'
                    if (v.filter) {
                        mappedFilters[mappedValue] = v.filter;
                    } else {
                        mappedFilters[mappedValue] = v.value;
                    }
                }
            }

            const newFilterValues = { ...this.filterValues };
            // Add filters to "real/global" filter object which is passed down again to the ag grid table
            for (const [k, v] of Object.entries(mappedFilters)) {
                Vue.set(newFilterValues, k as keyof ReportListEndpointRequest, v as never);
            }
            
            this.$emit('readonlyReportFilterChanged', removeEmptyFieldsFrom<ReportListEndpointRequest>(newFilterValues));
        },
        onSelectionChanged (event: SelectionChangedEvent): void {
            this.changedEvent = event;
            const nodes = event.api.getSelectedNodes();
            const reports = nodes.map(n => n.data.meta.reportDto);
            const pageNo = this.filterValues.page;
            const oldReports = this.selectedReportRows.filter((ele: any) => ele.currentPage != pageNo);
            this.selectedReportRows = [...reports, ...oldReports];
        },
        changeFilters(): any {
            const filters = this.filterChangedEvent.api.getFilterModel();
            this.gridApi = this.filterChangedEvent.api;

            // Map to correct filter props
            // As a first step set everything to undefined
            const mappedFilters: Record<string, any> = {};
            for (const el of this.availableColumnsFilterValues) {
                mappedFilters[el] = this.defaultFilterValues[el];
            }

            // then fill with actual filter values
            for (const [k, v] of Object.entries(filters) as any) {
                const mappedValue = this.filterColumnToRequestFieldMap[k];
                if (mappedValue) {
                    // standard ag-grid uses 'filter' but our custom filter uses 'value'
                    if (v.filter) {
                        mappedFilters[mappedValue] = v.filter;
                    } else {
                        mappedFilters[mappedValue] = v.value;
                    }
                }
            }

            const newFilterValues = { ...this.filterValues };
            // Add filters to "real/global" filter object which is passed down again to the ag grid table
            for (const [k, v] of Object.entries(mappedFilters)) {
                Vue.set(newFilterValues, k as keyof ReportListEndpointRequest, v as never);
            }
            
            this.$emit('readonlyReportFilterChanged', removeEmptyFieldsFrom<ReportListEndpointRequest>(newFilterValues));
        },
        onPaginationChange (newPage: any): void {
            if(this.changedEvent){
                const selectedIds = this.selectedReportRows.map((report: any) => report.id);
                this.changedEvent.api.forEachNode((node: any) => {
                    const isSelected = selectedIds.includes(node.data.meta.reportDto.id);
                    node.setSelected(isSelected);
                })
            }
            
            if(this.filterChangedEvent){
                this.changeFilters();
            }
        },
  
    }
});

export default ReadonlyReportTable;


