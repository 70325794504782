import Vue, { PropType } from 'vue';
import BgTooltip from '@/components/table/custom-tooltips/bgTooltip.vue';
import TableFiltered from '@/components/table-filtered/table-filtered.vue';
import { QuestionCatalogListEndpointRequest } from '@/models';
import { ColumnDef, VendorOptions } from '@/components/table/contracts/table-data';
import { EventBus } from '@/utils';
import moment from 'moment-timezone';
import { QuestionCatalogDto, QuestionCatalogSortColumn, SortType, } from '@/service-proxies/service-proxies.g';
import { FilterChangedEvent, GridApi } from 'ag-grid-community';
import CustomMultiSelectFilter from '@/components/table-filtered/custom-filters/multi-select-filter.vue';
import { removeEmptyFieldsFrom } from '@/utils/filter-utils';
import { QuestionCatalogTableData } from '@/components/questionnaire-config-table/contracts/table-data';
import { QuestionCatalogService } from '@/services/question-catalog-service';
import { selectedLanguageToMomentLocale } from '@/utils/date-time-utils';
import { LocalStorageHelper } from '@/utils/local-storage-helper';
import { LANGUAGES } from '@/utils/languages';
import EditQuestionCatalogButtonCell
    from '@/components/questionnaire-config-table/custom-cells/edit-questionnaire-button-cell.vue';
import { ColumnSort } from '@/components/table/contracts/table-enums';
import _ from 'lodash';

const QuestionnaireConfigTable = Vue.extend({
    name: 'QuestionnaireConfigTable',
    props: {
        tableTitle: {
            type: String,
            required: true,
        },
        filterValues: {
            type: Object as PropType<QuestionCatalogListEndpointRequest>,
            required: true
        },
        hideAddButton: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        filtersAvailable: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        BgTooltip,
        TableFiltered,
        CustomMultiSelectFilter,
        EditQuestionCatalogButtonCell
    },
    data (): {
        eventTableName: string;
        lastUpdateRequest: moment.Moment;
        tz: string;
        pagination: {
            totalPages: number;
            rangeOfPages: number;
        };
        vendorOptions: VendorOptions<QuestionCatalogTableData>;
        questionCatalogService: QuestionCatalogService;
        sortColumnToRequestFieldMap: Record<string, QuestionCatalogSortColumn>;
        filterColumnToRequestFieldMap: Record<string, keyof QuestionCatalogListEndpointRequest>;
        sortTypeToRequestFieldMap: { [key: string]: SortType | undefined };
        gridApi: undefined | GridApi;
        availableColumnsFilterValues: Array<keyof QuestionCatalogListEndpointRequest>;
        selectedLanguage: string;
        } {
        return {
            eventTableName: 'AdminViewQuestionCatalogConfigTable',
            lastUpdateRequest: moment.tz(moment.tz.guess()),
            tz: moment.tz.guess(),
            pagination: {
                totalPages: 1,
                rangeOfPages: 3,
            },
            vendorOptions: {
                data:
                    _.range(500).map(() => ({
                        'meta': {
                            questionCatalogDto: new QuestionCatalogDto(),
                        },
                        'col-description': '',
                        'col-year': undefined,
                        'col-language': '' ,
                        'col-type': '',
                        'col-version': undefined,
                        'col-testApproval': '',
                        'col-approval':  '',
                        'col-approvalDate': '',
                        'col-deadlineHReport': '',
                        'col-deadlineZReport': '',
                        'col-details': '',
                    })),
                rowSelection: 'single',
                columnDefs: [],
                withPagination: true,
                paginationAutoPageSize: true,
            },
            questionCatalogService: new QuestionCatalogService(),
            sortColumnToRequestFieldMap: {
                'col-version': QuestionCatalogSortColumn.Version,
                'col-description': QuestionCatalogSortColumn.Description,
                'col-year': QuestionCatalogSortColumn.Year,
            },
            filterColumnToRequestFieldMap: {
                'col-version': 'catalogVersion',
                'col-year': 'reportYear',
            },
            sortTypeToRequestFieldMap: {
                'asc': SortType.Ascending,
                'desc': SortType.Descending,
            },
            gridApi: undefined,
            availableColumnsFilterValues: ['catalogVersion', 'reportYear', 'description'],
            selectedLanguage: 'en-GB',
        }
    },
    created (): void {
        this.registerEventCallbacks();
        this.vendorOptions.columnDefs = this.defaultColumnDefs();
    },
    async mounted (): Promise<void> {
        this.selectedLanguage = LANGUAGES[LocalStorageHelper.getSelectedLanguage() ?? 'EN'];
        //await this.constructTable();
    },
    computed: {
        filterColumnToRequestFieldMapInverse (): Record<string, string> {
            const inverseMap: Record<string, string> = {};
            for (const [k, v] of Object.entries(this.filterColumnToRequestFieldMap)) {
                inverseMap[v] = k;
            }
            return inverseMap;
        },
        agGridFilterModel (): Record<string, { value: any }> {
            // Map to correct filter props
            const filterModel: Record<string, { value: any }> = {};
            for (const [k, v] of Object.entries(this.filterValues)) {
                const column = this.filterColumnToRequestFieldMapInverse[k];
                if (this.availableColumnsFilterValues.includes(k as keyof QuestionCatalogListEndpointRequest)) {
                    filterModel[column] = {
                        value: v,
                    };
                }
            }

            return filterModel;
        }
    },
    methods: {
        addButtonClicked (): void {
            EventBus.$emit(EventBus.VIEWS.ADMIN.QUESTION_CATALOG_ADD_BUTTON_CLICKED);
        },
        /* eslint-disable sonarjs/no-duplicate-string */
        defaultColumnDefs (): ColumnDef[] {
            const detailsColumn: ColumnDef = {
                headerName: this.$t(`questionCatalogConfigTable.details`).toString(),
                field: `col-details`,
                minWidth: 50,
                cellRenderer: 'EditQuestionCatalogButtonCell',
                filter: false,
                sortable: false,
            };

            const columns: ColumnDef[] = [
                {
                    headerName: this.$t('questionCatalogConfigTable.description').toString(),
                    field: 'col-description', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-description', tooltipComponentParams: { key: 'col-description' },
                    comparator: (): number => 0,
                    filter: false,
                },
                {
                    headerName: this.$t('questionCatalogConfigTable.year').toString(),
                    field: 'col-year', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-year', tooltipComponentParams: { key: 'col-year' },
                    comparator: (): number => 0,
                    filter: false,
                    sort: ColumnSort.desc,
                },
                {
                    headerName: this.$t('language').toString(),
                    field: 'col-language', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-language', tooltipComponentParams: { key: 'col-language' },
                    comparator: (): number => 0,
                    filter: false,
                    sortable: false,
                },
                {
                    headerName: this.$t('questionCatalogConfigTable.type').toString(),
                    field: 'col-type', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-type', tooltipComponentParams: { key: 'col-type' },
                    comparator: (): number => 0,
                    filter: false,
                    sortable: false,
                },
                {
                    headerName: this.$t('questionCatalogConfigTable.version').toString(),
                    field: 'col-version', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-version', tooltipComponentParams: { key: 'col-version' },
                    comparator: (): number => 0,
                    filter: false,
                },
                {
                    headerName: this.$t('questionCatalogConfigTable.testApproval').toString(),
                    field: 'col-testApproval', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-testApproval', tooltipComponentParams: { key: 'col-testApproval' },
                    comparator: (): number => 0,
                    filter: false,
                    sortable: false,
                },
                {
                    headerName: this.$t('questionCatalogConfigTable.approval').toString(),
                    field: 'col-approval', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-approval', tooltipComponentParams: { key: 'col-approval' },
                    comparator: (): number => 0,
                    filter: false,
                    sortable: false,
                },
                {
                    headerName: this.$t('questionCatalogConfigTable.approvalDate').toString(),
                    field: 'col-approvalDate', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-approvalDate', tooltipComponentParams: { key: 'col-approvalDate' },
                    comparator: (): number => 0,
                    filter: false,
                    sortable: false,
                },
                {
                    headerName: this.$t('questionCatalogConfigTable.deadlineHReport').toString(),
                    field: 'col-deadlineHReport', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-deadlineHReport', tooltipComponentParams: { key: 'col-deadlineHReport' },
                    comparator: (): number => 0,
                    filter: false,
                    sortable: false,
                },
                {
                    headerName: this.$t('questionCatalogConfigTable.deadlineZReport').toString(),
                    field: 'col-deadlineZReport', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-deadlineZReport', tooltipComponentParams: { key: 'col-deadlineZReport' },
                    comparator: (): number => 0,
                    filter: false,
                    sortable: false,
                }
            ];

            if (!this.hideDetails) {
                columns.push(detailsColumn);
            }

            return columns;
        },
        registerEventCallbacks (): void {
            EventBus.$on(EventBus.VIEWS.ADMIN.REFRESH_TABLE, () => {
                this.constructTable();
            });
        },
        async constructTable (): Promise<void> {
            const { filteredEvents, time } = await this.getFilteredValues(this.filterValues);

            // only update the table if this is really the latest request
            if (time.valueOf() === this.lastUpdateRequest.valueOf()) {
                this._constructTable(filteredEvents);
            }
        },
        async getFilteredValues (filters: QuestionCatalogListEndpointRequest): Promise<{ filteredEvents: QuestionCatalogDto[]; time: moment.Moment }> {
            // make sure the datetimes are in utc!
            // TODO: Not needed at the moment
            const utcFilters: QuestionCatalogListEndpointRequest = {
                ...filters,
            }

            const time = moment.tz(this.tz);
            this.lastUpdateRequest = time; // save the current time to handle overlapping requests
            const res = (await this.questionCatalogService.getQuestionCatalogs(utcFilters)).result;

            // success
            if (res) {
                this.pagination.totalPages = res.totalPages === 0 ? 1 : res.totalPages ?? 0;
                return {
                    filteredEvents: res.items ?? [],
                    time: time
                };
            }

            // failure
            return {
                filteredEvents: [],
                time: time,
            }
        },
        onPageSizeFound(pageSize: number): void {
            this.filterValues.size = pageSize;
        },
        _constructTable (filteredEvents: QuestionCatalogDto[]): void {
            this.vendorOptions.data = [];
            filteredEvents.forEach((questionCatalog: QuestionCatalogDto) => {
                this.vendorOptions.data.push(
                    {
                        'meta': {
                            questionCatalogDto: questionCatalog,
                        },
                        'col-description': questionCatalog.description,
                        'col-year': questionCatalog.reportYear,
                        'col-language': questionCatalog.language?.languageName
                            ? this.$t((questionCatalog.language?.languageName).toLowerCase()).toString()
                            : '',
                        'col-type': questionCatalog.catalogType?.getDescription(this.$i18n.locale),
                        'col-version': questionCatalog.version,
                        'col-testApproval': questionCatalog.testApproval
                            ? this.$t('yes').toString()
                            : this.$t('no').toString(),
                        'col-approval': questionCatalog.approval
                            ? this.$t('yes').toString()
                            : this.$t('no').toString(),
                        'col-approvalDate': questionCatalog.approvalDate
                            ? moment.tz(questionCatalog.approvalDate, 'Etc/UTC')
                                .local()
                                .locale(selectedLanguageToMomentLocale(this.selectedLanguage))
                                .format('lll')
                            : '',
                        'col-deadlineHReport': moment
                            .tz(questionCatalog.deadlineHReport, 'Etc/UTC')
                            .local()
                            .locale(selectedLanguageToMomentLocale(this.selectedLanguage))
                            .format('lll'),
                        'col-deadlineZReport': moment
                            .tz(questionCatalog.deadlineZReport, 'Etc/UTC')
                            .local()
                            .locale(selectedLanguageToMomentLocale(this.selectedLanguage))
                            .format('lll'),
                        'col-details': this.$t('questionCatalogConfigTable.details').toString(),
                    }
                );
            });
        },
        onSortChanged (sortChangedEvent: any): void {

            // caution: this doesnt work as expected once, multi-column sort is activated
            // only the first found sorted column will be used
            const currentSortedColumn = sortChangedEvent.columnApi
                .getColumnState()
                .find((col: any) => col.sort === 'asc' || col.sort === 'desc');

            if (!currentSortedColumn) {
                this.filterValues.sortProperty = undefined;
                this.filterValues.sortDirection = undefined;
                return;
            }

            if (this.sortColumnToRequestFieldMap[currentSortedColumn.colId] == null
                || this.sortColumnToRequestFieldMap[currentSortedColumn.colId] == undefined) {
                return;
            }

            this.filterValues.sortDirection = this.sortTypeToRequestFieldMap[currentSortedColumn.sort];
            this.filterValues.sortProperty = this.sortColumnToRequestFieldMap[currentSortedColumn.colId];
        },
        onAGGridFilterChanged (filterChangedEvent: FilterChangedEvent): void {
            const filters = filterChangedEvent.api.getFilterModel();
            this.gridApi = filterChangedEvent.api;

            // Map to correct filter props
            // As a first step set everything to undefined
            const mappedFilters: Record<string, string | undefined> = {};
            for (const el of this.availableColumnsFilterValues) {
                mappedFilters[el] = undefined;
            }

            // then fill with actual filter values
            for (const [k, v] of Object.entries(filters)) {
                const mappedValue = this.filterColumnToRequestFieldMap[k];
                if (mappedValue) {
                    // standard ag-grid uses 'filter' but our custom filter uses 'value'
                    if (v.filter) {
                        mappedFilters[mappedValue] = v.filter;
                    } else {
                        mappedFilters[mappedValue] = v.value;
                    }
                }
            }

            const newFilterValues = { ...this.filterValues };
            // Add filters to "real/global" filter object which is passed down again to the ag grid table
            for (const [k, v] of Object.entries(mappedFilters)) {
                Vue.set(newFilterValues, k as keyof QuestionCatalogListEndpointRequest, v as never);
            }

            // Update global filter
            this.$emit('questionCatalogFilterChanged', removeEmptyFieldsFrom<QuestionCatalogListEndpointRequest>(newFilterValues));
        },
    }
});

export default QuestionnaireConfigTable;
