import { LocalStorageHelper as SDKLocalStorageHelper } from '@coode/fe-sdk';
import { Permission } from '@coode/fe-sdk/dist/types/uam';

export interface UserProfile {
    department: string;
    email: string;
    id: number;
    kid: string;
    name: string;
    familyName: string;
    givenName: string;
    permissions: Permission[];
}

/**
 * Collection of helper methods for handling local storage items.
 * Inspired by Coode Master Data.
 */
export class LocalStorageHelper {

    public static setSelectedLanguage(val: any): void {
        localStorage.setItem(LocalStorageHelper.keys.selectedLanguage, JSON.stringify(val));
    }

    public static setFilterValues(key: any, val: any): void {
        localStorage.setItem(key, JSON.stringify(val));
    }

    public static setLanguageOptions(options: string[]): void {
        localStorage.setItem(LocalStorageHelper.keys.languageOptions, JSON.stringify(options));
    }

    public static removeFilters(key: any): void {
        localStorage.removeItem(key);
    }

    public static getSelectedLanguage(): string | null {
        const languageString = localStorage.getItem(LocalStorageHelper.keys.selectedLanguage);
        if (languageString !== null) {
            return JSON.parse(languageString);
        }
        return null;
    }

    public static getLanguageOptions(): string[] {
        const languageOptions = localStorage.getItem(LocalStorageHelper.keys.languageOptions);
        if (languageOptions !== null) {
            return JSON.parse(languageOptions);
        }
        return [];
    }

    public static getFilterValues(filterVal: any): any {
        const filteredValues = localStorage.getItem(filterVal);
        if (filteredValues !== null) {
            return JSON.parse(filteredValues);
        }
        return null;
    }

    public static determineDefaultLanguage(): string {
        LocalStorageHelper.getLanguageOptions().find((el: string) => {
            if (el === navigator.language) {
                return el;
            }
        });
        return LocalStorageHelper.getLanguageOptions()[0];
    }

    public static setSoundIsActive(isActive: boolean): void {
        localStorage.setItem(LocalStorageHelper.keys.soundIsActive, JSON.stringify(isActive));
    }

    public static getSoundIsActive(): boolean {
        const isActive = localStorage.getItem(LocalStorageHelper.keys.soundIsActive);
        if (isActive) {
            return JSON.parse(isActive);
        }
        return false;
    }

    private static keys = {
        selectedLanguage: 'coode.emissionRiskManagement.selectedLanguage',
        languageOptions: 'coode.emissionRiskManagement.languageOptions',
        soundIsActive: 'coode.emissionRiskManagement.soundIsActive'
    };

    public static getProfile(): UserProfile {
        const permissions = SDKLocalStorageHelper.getPermissionsLocalStorage() as any;
        const name = permissions?.name?.split(',') ?? [];
        return {
            department: permissions?.department as string,
            id: permissions?.id as number,
            kid: permissions?.kid,
            email: permissions?.email,
            name: permissions?.name,
            familyName: name[0] as string,
            givenName: name[1] as string,
            permissions: permissions?.permissions ?? [],
        }
    }
}
