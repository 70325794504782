import { HomeGetResponse } from '@/service-proxies/service-proxies.g';
import { AppDataService } from '@/services/app-data-service';
import axios, { AxiosError } from 'axios';
import { HttpStatusCodes } from '@/enums/http-status-codes';

export type AppDataState = {
    appDataApi: AppDataService;
    useCaseId: number;
}

export type AppDataGetters = {
    useCaseId: (state: AppDataState) => number;
}

export type AppDataActions = {
    loadAppData: ({ commit, state }: { commit: any; state: AppDataState }) => Promise<number>;
}
export type AppDataMutations = {
    SET_APP_DATA: (state: AppDataState, appData: HomeGetResponse | undefined) => void;
}

export const appData = {
    namespaced: true,
    state: (): AppDataState => ({
        appDataApi: new AppDataService(),
        useCaseId: 0,
    }),
    getters: {
        useCaseId: (state: AppDataState): number => state.useCaseId,
    } as AppDataGetters,
    actions: {
        async loadAppData ({ commit, state }: { commit: any; state: AppDataState }): Promise<number> {
            try {
                const appData = await state.appDataApi.getAppData();
                commit('SET_APP_DATA', appData.result);
            }
            catch (e) {
                if (axios.isAxiosError(e)) {
                    return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
                } else {
                    console.error(`An unknown error occurred while fetching the app data.`);
                    console.error(e);
                    return HttpStatusCodes.InternalServerError;
                }
            }
            return HttpStatusCodes.Ok;
        }
    } as AppDataActions,
    mutations: {
        SET_APP_DATA (state: AppDataState, appData: HomeGetResponse | undefined): void {
            if (appData) {
                state.useCaseId = appData.useCaseId ?? 0;
            }
        },
    } as AppDataMutations
};
