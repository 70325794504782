
import Vue from 'vue';
const AutoCorrectFormInput = Vue.extend({
    name: 'AutoCorrectFormInput',
    props: {
        value: {
            type: [String, Number],
            required: true,
        },
        min: {
            type: Number,
            default: undefined,
        },
        max: {
            type: Number,
            default: undefined,
        },
        maxLength: {
            type: Number,
            default: undefined,
        },
        minLength: {
            type: Number,
            default: 0
        },
        minText: {
            type: String,
            default: undefined,
        },
        maxText: {
            type: String,
            default: undefined,
        },
        maxLengthText: {
            type: String,
            default: undefined,
        },
        minLengthText: {
            type: String,
            default: undefined
        },
        type: {
            type: String,
            default: 'text',
        },
        label: {
            type: String,
            default: ''
        },
        textRows: {
            type: Number,
            default: 4,
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
    },
    data (): {
        helperText: string;
        internalValue: string | number;
        refreshCounter: number;
        oldValue: string | number;
        } {
        return {
            helperText: '',
            internalValue: this.value,
            refreshCounter: 0,
            oldValue: '',
        }
    },
    watch: {
        value: {
            handler (newVal: string | number): void {
                this.internalValue = newVal;
            }
        }
    },
    methods: {
        setInternalValue (newVal: string | number): void {
            if (this.type === 'number') {
                this.internalValue = Number(newVal);
            } else if (this.type === 'text') {
                this.internalValue = String(newVal);
            } else {
                this.internalValue = newVal;
            }
        },
        async validateAndEmitChange (newVal: string | number): Promise<void> {
            let autoCorrectedValue = newVal;
            let correctionPerformed = false;

            if (typeof newVal === 'number') {
                if (newVal <= (this.min as number)) {
                    autoCorrectedValue = this.min as number;
                    correctionPerformed = true;
                    this.helperText = this.minText
                }

                if (newVal >= (this.max as number)) {
                    autoCorrectedValue = this.max as number;
                    correctionPerformed = true;
                    this.helperText = this.maxText
                }
            }

            // eslint-disable-next-line sonarjs/no-collapsible-if
            if (typeof newVal === 'string') {
                if (newVal.length >= (this.maxLength as number)) {
                    autoCorrectedValue = newVal.substr(0, this.maxLength);
                    correctionPerformed = true;
                    this.helperText = this.maxLengthText
                }
                if (newVal.length < (this.minLength as number)) {
                    autoCorrectedValue = this.value;
                    correctionPerformed = true;
                    this.helperText = this.minLengthText;
                }
            }

            if (!correctionPerformed) {
                this.helperText = '';
            }

            this.$emit(`input`, autoCorrectedValue);
            this.refreshCounter++;
        }
    },
});
export default AutoCorrectFormInput;
