import { render, staticRenderFns } from "./average-report.vue?vue&type=template&id=3eb15f19&scoped=true&class=average-report"
import script from "./average-report.ts?vue&type=script&lang=ts&external"
export * from "./average-report.ts?vue&type=script&lang=ts&external"
import style0 from "./average-report.less?vue&type=style&index=0&id=3eb15f19&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eb15f19",
  null
  
)

export default component.exports