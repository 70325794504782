
import Vue, { PropType } from 'vue';
import AutoCorrectFormInput from '@/components/auto-correct-form-input/auto-correct-form-input.vue';
import {
    MainQuestionDto,
    QuestionGroupDto,
    ReportRowDto,
    ReportRowListEntryDto,
    ReportRowReply,
    SubQuestionDto,
    SubQuestionType
} from '@/service-proxies/service-proxies.g';
import _ from 'lodash';
import { Validation } from '@/components/questionnaire-administration/questionnaire-details/contracts/validation';
import { FormSelectOption } from '@/models/pebble-ui';
import { ReportRowStatus } from '@/enums/report-row-status';
import Datetime from '@/components/datetime/datetime.vue';
import { riskPresent } from '@/utils/composables';

const SubQuestionAnswerForm = Vue.extend({
    name: 'SubQuestionAnswerForm',
    components: {
        AutoCorrectFormInput,
        Datetime,
    },
    props: {
        questionGroup: {
            type: Object as PropType<QuestionGroupDto>,
            required: true,
        },
        mainQuestion: {
            type: Object as PropType<MainQuestionDto>,
            required: true,
        },
        subQuestion: {
            type: Object as PropType<SubQuestionDto>,
            required: true,
        },
        mainQuestionReportRow: {
            type: Object as PropType<ReportRowDto>,
            required: true,
        },
        value: {
            type: Object as PropType<ReportRowDto>,
            required: true,
        },
        isEditable: {
            type: Boolean,
            default: true,
        }
    },
    data (): {
        internalReportRow: ReportRowDto;
        answerOptions: FormSelectOption[];
        reportStatusOptions: FormSelectOption[];
        selectorOptions: FormSelectOption[];
        validations: Record<string, Validation>;
        } {
        return {
            internalReportRow: _.cloneDeep(this.value),
            answerOptions: Object.entries(ReportRowReply)
                .filter((k, v) => isNaN(k[1] as any))
                .map((k, v) => ({
                    label: this.$t(`reportRowReply.${k[1]}`).toString(),
                    value: v,
                })),
            reportStatusOptions: Object.entries(ReportRowStatus)
                .filter((k, v) => isNaN(k[1] as any))
                .map((k, v) => ({
                    label: this.$t(`reportRowStatus.${k[1]}`).toString(),
                    value: v,
                })),
            selectorOptions: [
                {
                    label: this.$t('yes').toString(),
                    value: ReportRowReply.Yes,
                },
                {
                    label: this.$t('no').toString(),
                    value: ReportRowReply.NotYetAnswered,
                },
            ],
            validations: {
                'annotation': {
                    maxLength: 500,
                    isValid: true,
                    helperText: '',
                },
                'listing': {
                    maxLength: 500,
                    isValid: true,
                    helperText: '',
                }
            }
        }
    },
    watch: {
        value: {
            handler (newVal: ReportRowDto): void {
                this.internalReportRow = _.cloneDeep(newVal);
            },
            deep: true,
        }
    },
    computed: {
        subQuestionId (): number {
            return this.subQuestion?.id ?? -1;
        },
        seqNo (): string {
            return `${this.questionGroup.position}.${this.mainQuestion.position}.${this.subQuestion.position}`;
        },
        annotationWrapper (): string {
            return this.internalReportRow.annotation ?? '';
        },
        sortedListEntries (): ReportRowListEntryDto[] {
            if (this.internalReportRow.reportRowListEntries) {
                return [...this.internalReportRow.reportRowListEntries]
                    .sort((a, b) => (a.position ?? 0) - (b.position ?? 0));
            }
            return [];
        },
        riskPresent (): boolean {
            return riskPresent(this.mainQuestion, this.mainQuestionReportRow);
        }
    },
    methods: {
        isList (): boolean {
            return this.subQuestion.subQuestionType === SubQuestionType.List;
        },
        modifyAndEmitChange (obj: ReportRowDto, prop: string, value: any, targetType: 'number' | 'boolean' | 'string'): void {
            let convertedValue: number | boolean | string;

            switch (targetType) {
            case 'boolean':
                convertedValue = value === 'true';
                break;
            case 'number':
                convertedValue = Number(value);
                break;
            case 'string':
                convertedValue = String(value);
            }

            // special care for list sub questions
            for (const el of ['listing', 'status', 'dueDate']) {
                if (prop.includes(el) && obj.reportRowListEntries) {
                    const pos = Number(prop.substring(el.length, prop.length));

                    if (el === 'dueDate' && convertedValue === '') {
                        Vue.set(obj.reportRowListEntries.filter(x => x.position === pos)[0], el, undefined);
                    } else {
                        Vue.set(obj.reportRowListEntries.filter(x => x.position === pos)[0], el, convertedValue);
                    }

                    this.$emit('input', this.internalReportRow);
                    return;
                }
            }

            if (value !== undefined &&  value !== null) {
                Vue.set(obj, prop, convertedValue);
            }
            this.$emit('input', this.internalReportRow);
        }
    },
});

export default SubQuestionAnswerForm;
