import { UserService } from '@/services/user-service';
import _ from 'lodash';
import { UserRoles } from '@/enums/user-roles';
import { Supervisor } from '@/models';
import {
    CatalogTypeDto,
    CatalogTypePermissionDto,
    LocationDto,
    LocationPermissionDto,
    ReportApprovalStatus,
    UserDto,
} from '@/service-proxies/service-proxies.g';
import { applicationState } from '@/store';
import { UserActions } from '@/enums/user-actions';

export type UserAccessManagementState = {
    userRole: UserRoles | undefined;
    userId: number | undefined;
    supervisor: Supervisor | undefined;
    userName: string | undefined;
    userKid: string | undefined;
    userDto: UserDto | undefined;
    userLocations: LocationPermissionDto[] | undefined;
    userCatalogTypes: CatalogTypePermissionDto[] | undefined;
    functionalPermissions: UserActions[];
};

export type UserAccessManagementGetters = {
    getUserId: (state: UserAccessManagementState) => number | undefined;
    getUserRole: (state: UserAccessManagementState) => string | undefined;
    getUserName: (state: UserAccessManagementState) => string | undefined;
    getUserKid: (state: UserAccessManagementState) => string | undefined;
    getUserPermissions: (state: UserAccessManagementState) => UserActions[];
    getUserLocations: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any) => LocationPermissionDto[] | undefined;
    getUserCatalogTypes: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any) => CatalogTypePermissionDto[] | undefined;
    isReaderFor: (state: UserAccessManagementState) => (locationId: number) => boolean;
    isEditorFor: (state: UserAccessManagementState) => (locationId: number) => boolean;
    isApproverFor: (state: UserAccessManagementState) => (locationId: number) => boolean;
    hasAccessTo: (state: UserAccessManagementState) => (catalogTypeId: number) => boolean;
    getReaderUserCatalogTypes: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any) => (locationId: number | undefined) => CatalogTypeDto[] | undefined;
    getEditorUserCatalogTypes: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any) => (locationId: number | undefined) => CatalogTypeDto[] | undefined;
    getApproverUserCatalogTypes: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any) => (locationId: number | undefined) => CatalogTypeDto[] | undefined;
    getReaderUserLocations: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any) => LocationDto[];
    getEditorUserLocations: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any) => LocationDto[];
    getApproverUserLocations: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any) => LocationDto[];
    canViewReport: (state: UserAccessManagementState) => (locationId: number, catalogTypeId: number) => boolean;
    canViewAverageReport: (state: UserAccessManagementState) => (locationId: number, catalogTypeId: number) => boolean;
    canCreateReport: (state: UserAccessManagementState) => (locationId: number, catalogTypeId: number) => boolean;
    canEditReport: (state: UserAccessManagementState) => (locationId: number, catalogTypeId: number) => boolean;
    canReleaseReport: (state: UserAccessManagementState) => (locationId: number, catalogTypeId: number) => boolean;
    canApproveReport: (state: UserAccessManagementState) => (locationId: number, catalogTypeId: number) => boolean;
    canDeleteReport: (state: UserAccessManagementState) => (locationId: number, catalogTypeId: number, reportApprovalStatus: ReportApprovalStatus) => boolean;
    canSetReleasedReportBackToEditMode: (state: UserAccessManagementState) => (locationId: number) => boolean;
    canSetApprovedReportBackToEditMode: (state: UserAccessManagementState) => (locationId: number) => boolean;
    canExportReport: (state: UserAccessManagementState) => boolean;
};

export type UserAccessManagementActions = {
    setUserPermissions: (context: any, { service, userId }: { service: UserService; userId: number }) => Promise<boolean>;
};

export type UserAccessManagementMutations = {
    SET_USER_ROLE: (state: UserAccessManagementState, role: UserRoles) => void;
    SET_SUPERVISOR: (state: UserAccessManagementState, supervisor: Supervisor) => void ;
    SET_NEW_SUPERVISOR: (state: UserAccessManagementState, role: UserRoles) => void;
    SET_USER_ID: (state: UserAccessManagementState, userId: number) => void;
    SET_USER_NAME: (state: UserAccessManagementState, userName: string) => void;
    SET_USER_KID: (state: UserAccessManagementState, userKid: string) => void;
    SET_USER_LOCATIONS: (state: UserAccessManagementState, locations: LocationPermissionDto[]) => void;
    SET_USER_CATALOG_TYPES: (state: UserAccessManagementState, catalogTypes: CatalogTypePermissionDto[]) => void;
    SET_FUNCTIONAL_PERMISSIONS: (state: UserAccessManagementState, functionalPermissions: UserActions[]) => void;
    SET_USER_LOCATIONS_AND_CATALOG_TYPES: (state: UserAccessManagementState, payload: { locations: LocationPermissionDto[]; catalogTypes: CatalogTypePermissionDto[] }) => void;
}

/**
 * Calculate the list of catalog types to which the user has access. This depends on the user <-> catalog type
 * configuration but also on the location <-> catalog type configuration! The accessType parameter defines whether
 * reader/editor/approver locations are considered. Optionally a locationId can be provided to restrict the list to a
 * specific location. Note that admin users are processed correctly here (they have access to everything).
 * @param getters
 * @param state
 * @param rootGetters
 * @param locationId
 * @param accessType
 */
function calculateCatalogTypeList(getters: UserAccessManagementGetters, state: UserAccessManagementState, rootGetters: any, locationId: number | undefined, accessType: 'approver' | 'editor' | 'reader'): CatalogTypeDto[] {
    const userLocationsGetter = getters.getUserLocations as unknown as LocationPermissionDto[];
    const userCatalogTypesGetter = getters.getUserCatalogTypes as unknown as CatalogTypePermissionDto[];

    let userLocationIds: (number | undefined)[] = [];
    if (locationId) {
        userLocationIds = userLocationsGetter?.filter(x => x[accessType] && x.locationId === locationId).map(x => x.locationId) ?? [];
    }
    else {
        userLocationIds = userLocationsGetter?.filter(x => x[accessType]).map(x => x.locationId) ?? [];
    }
    const userCatalogTypeIds = userCatalogTypesGetter?.map(x => x.catalogTypeId) ?? [];

    const allCatalogTypes = (rootGetters['catalogTypes/catalogTypes'] as CatalogTypeDto[]);
    // eslint-disable-next-line sonarjs/no-duplicate-string
    const allLocations = (rootGetters['locations/locations'] as LocationDto[]);

    // Here we simply get lists of the locations and user catalog types to which a user has access
    const userLocations = allLocations.filter(x => userLocationIds.includes(x.id));
    const userCatalogTypes = allCatalogTypes.filter(x => userCatalogTypeIds.includes(x.id));

    /*
    // <------------------ DEPRECATED! ---------------------------->
    // Additionally, we use the userLocations to get a second list of catalog type ids (locations and catalog types are mapped!)
    // However, if the user is an admin this restriction must not be applied so instead we re-use the userCatalogTypes
    // which effectively disables the check
    const locationCatalogTypeIds = state.userRole === UserRoles.Admin
        ? userCatalogTypes.map(x => x.id)
        : _.uniq(userLocations.flatMap(x => x.locationCatalogTypeDtos)).map(x => x?.catalogTypeId);
     */


    // Additionally, we use the userLocations to get a second list of catalog type ids (locations and catalog types are mapped!)
    // Update 2022-07-22: I have removed the admin short-cut above because this breaks the evaluation logic. Do we really need
    // special handling of an admin here?
    const locationCatalogTypeIds = _.uniq(userLocations.flatMap(x => x.locationCatalogTypeDtos)).map(x => x?.catalogTypeId);

    const locationCatalogTypes = allCatalogTypes.filter(x => locationCatalogTypeIds.includes(x.id));

    // The end result is the intersection of the userCatalogTypes and the locationCatalogTypes
    return _.intersection(userCatalogTypes, locationCatalogTypes);
}

/**
 * Get all locationDtos to which a user has access. Because the getUserLocations getter is used (which returns
 * LocationPermissionDtos) this function behaves correctly for admin users (they have access to everything).
 * @param rootGetters
 * @param getters
 * @param role
 */
function getUserLocations(rootGetters: any, getters: UserAccessManagementGetters, role: 'approver' | 'editor' | 'reader'): LocationDto[] {
    const allLocations = (rootGetters['locations/locations'] as LocationDto[]);
    const userLocationPermissionDtos = getters.getUserLocations as unknown as LocationPermissionDto[];
    const userLocationIds = userLocationPermissionDtos.filter(x => x[role]).map(x => x.locationId);
    return allLocations.filter(x => userLocationIds.includes(x.id));
}

export const userAccessManagement = {
    namespaced: true,
    state: (): UserAccessManagementState => ({
        userRole: undefined,
        supervisor: undefined,
        userId: undefined,
        userName: undefined,
        userKid: undefined,
        userDto: undefined,
        userLocations: undefined,
        userCatalogTypes: undefined,
        functionalPermissions: []
    }),
    getters: {
        getUserId: (state: UserAccessManagementState): number | undefined => Number(state.userId),
        getUserRole: (state: UserAccessManagementState): string | undefined => state.userRole,
        getUserName: (state: UserAccessManagementState): string | undefined => state.userName,
        getUserKid: (state: UserAccessManagementState): string | undefined => state.userKid,
        getUserPermissions: (state: UserAccessManagementState): UserActions[] => state.functionalPermissions,
        getUserLocations: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): LocationPermissionDto[] | undefined => {
            // Admins have access to all Locations
            if (state.userRole === UserRoles.Admin) {
                return (rootGetters['locations/locations'] as LocationDto[]).map(x => new LocationPermissionDto({
                    userId: state.userId,
                    locationId: x.id,
                    reader: true,
                    editor: true,
                    approver: true,
                }));
            }

            // Controllers can only have read access
            if (state.userRole === UserRoles.Controller) {
                return state.userLocations?.map(x => new LocationPermissionDto({
                    ...x,
                    editor: false,
                    approver: false,
                }));
            }

            // For all other users, the access is restricted to their locations
            return state.userLocations;
        },
        getUserCatalogTypes: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): CatalogTypePermissionDto[] | undefined => {
            // Admins have access to all Catalog Types
            if (state.userRole === UserRoles.Admin) {
                return (rootGetters['catalogTypes/catalogTypes'] as CatalogTypeDto[]).map(x => new CatalogTypePermissionDto({
                    userId: state.userId,
                    catalogTypeId: x.id,
                }));
            }

            // For all other users, the access is restricted to their catalog types
            return state.userCatalogTypes
        },
        isReaderFor: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): (locationId: number) => boolean => (locationId: number): boolean => {
            return getUserLocations(rootGetters, getters, 'reader')
                .filter(x => x.id === locationId).length > 0;
        },
        isEditorFor: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): (locationId: number) => boolean => (locationId: number): boolean => {
            return getUserLocations(rootGetters, getters, 'editor')
                .filter(x => x.id === locationId).length > 0;
        },
        isApproverFor: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): (locationId: number) => boolean => (locationId: number): boolean => {
            return getUserLocations(rootGetters, getters, 'approver')
                .filter(x => x.id === locationId).length > 0;
        },
        hasAccessTo: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): (catalogTypeId: number) => boolean => (catalogTypeId: number): boolean => {
            const userCatalogTypesGetter = getters.getUserCatalogTypes as unknown as CatalogTypePermissionDto[];
            return userCatalogTypesGetter.filter(x => x.catalogTypeId === catalogTypeId).length > 0;
        },
        getReaderUserCatalogTypes: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): (locationId: number | undefined) => CatalogTypeDto[] | undefined  => (locationId: number | undefined): CatalogTypeDto[] | undefined => {
            return calculateCatalogTypeList(getters, state, rootGetters, locationId, 'reader');
        },
        getEditorUserCatalogTypes: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): (locationId: number | undefined) => CatalogTypeDto[] | undefined  => (locationId: number | undefined): CatalogTypeDto[] | undefined => {
            return calculateCatalogTypeList(getters, state, rootGetters, locationId, 'editor');
        },
        getApproverUserCatalogTypes: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): (locationId: number | undefined) => CatalogTypeDto[] | undefined  => (locationId: number | undefined): CatalogTypeDto[] | undefined => {
            return calculateCatalogTypeList(getters, state, rootGetters, locationId, 'approver');
        },
        getReaderUserLocations: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): LocationDto[] => {
            return getUserLocations(rootGetters, getters, 'reader');
        },
        getEditorUserLocations: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): LocationDto[] => {
            return getUserLocations(rootGetters, getters, 'editor');
        },
        getApproverUserLocations: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): LocationDto[] => {
            return getUserLocations(rootGetters, getters, 'approver');
        },
        canViewReport: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): (locationId: number, catalogTypeId: number) => boolean => (locationId: number, catalogTypeId: number): boolean => {
            const editCatalogTypes = calculateCatalogTypeList(getters, state, rootGetters, locationId, 'reader').filter(x => x.id === catalogTypeId);
            return state.functionalPermissions.includes(UserActions.AccessToReports)
                && ((editCatalogTypes?.length ?? 0) > 0);
        },
        canViewAverageReport: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): (locationId: number, catalogTypeId: number) => boolean => (locationId: number, catalogTypeId: number): boolean => {
            const editCatalogTypes = calculateCatalogTypeList(getters, state, rootGetters, locationId, 'reader').filter(x => x.id === catalogTypeId);
            return state.functionalPermissions.includes(UserActions.AccessToAverageReports)
                && ((editCatalogTypes?.length ?? 0) > 0);
        },
        canCreateReport: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): (locationId: number, catalogTypeId: number) => boolean => (locationId: number, catalogTypeId: number): boolean => {
            const editCatalogTypes = calculateCatalogTypeList(getters, state, rootGetters, locationId, 'editor').filter(x => x.id === catalogTypeId);
            return state.functionalPermissions.includes(UserActions.CreateReport)
                && ((editCatalogTypes?.length ?? 0) > 0);
        },
        canEditReport: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): (locationId: number, catalogTypeId: number) => boolean => (locationId: number, catalogTypeId: number): boolean => {
            const editCatalogTypes = calculateCatalogTypeList(getters, state, rootGetters, locationId, 'editor').filter(x => x.id === catalogTypeId);
            return state.functionalPermissions.includes(UserActions.EditReport)
                && ((editCatalogTypes?.length ?? 0) > 0);
        },
        canReleaseReport: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): (locationId: number, catalogTypeId: number) => boolean => (locationId: number, catalogTypeId: number): boolean => {
            const editCatalogTypes = calculateCatalogTypeList(getters, state, rootGetters, locationId, 'editor').filter(x => x.id === catalogTypeId);
            return state.functionalPermissions.includes(UserActions.ReleaseReport)
                && ((editCatalogTypes?.length ?? 0) > 0);
        },
        canApproveReport: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): (locationId: number, catalogTypeId: number) => boolean => (locationId: number, catalogTypeId: number): boolean => {
            const approveCatalogTypes = calculateCatalogTypeList(getters, state, rootGetters, locationId, 'approver').filter(x => x.id === catalogTypeId);
            return state.functionalPermissions.includes(UserActions.ApproveReport)
                && ((approveCatalogTypes?.length ?? 0) > 0);
        },
        canDeleteReport: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): (locationId: number, catalogTypeId: number, reportApprovalStatus: ReportApprovalStatus) => boolean => (locationId: number, catalogTypeId: number, reportApprovalStatus: ReportApprovalStatus): boolean => {
            const editCatalogTypes = calculateCatalogTypeList(getters, state, rootGetters, locationId, 'editor').filter(x => x.id === catalogTypeId);
            return state.functionalPermissions.includes(UserActions.DeleteReport)
                && ((editCatalogTypes?.length ?? 0) > 0)
            && (reportApprovalStatus === ReportApprovalStatus.Editable || reportApprovalStatus === ReportApprovalStatus.New);
        },
        canSetReleasedReportBackToEditMode: (state: UserAccessManagementState, getters: UserAccessManagementGetters, rootState: typeof applicationState, rootGetters: any): (locationId: number) => boolean => (locationId: number): boolean => {
            const locations = getUserLocations(rootGetters, getters, 'editor');
            return state.functionalPermissions.includes(UserActions.SetReleasedReportBackToEditMode)
                && ((locations?.filter(x => x.id === locationId)?.length ?? 0) > 0);
        },
        canSetApprovedReportBackToEditMode: (state: UserAccessManagementState): (locationId: number) => boolean => (locationId: number): boolean => {
            return state.userRole === UserRoles.Admin;
        },
        canExportReport: (state: UserAccessManagementState): boolean => {
            return state.functionalPermissions.includes(UserActions.Export);
        },
    } as UserAccessManagementGetters,
    actions: {
        async setUserPermissions (context: any, { service, userId }: { service: UserService; userId: number }): Promise<boolean> {
            const user = (await service.getUser(userId))?.result;
            const useCaseId = context.rootGetters['appData/useCaseId'] as number;
            if (user && user.userGroupTypes && user.userGroupTypes.length > 0) {
                const filteredList = user.userGroupTypes.filter(x => x.useCaseId === useCaseId);
                if (filteredList.length > 0) {
                    const userRole = filteredList[0].name;
                    const role = _.startCase(_.camelCase(userRole)).replace(/ /g, '') as UserRoles;
                    const supervisor = new Supervisor(role);
                    context.commit('SET_USER_ROLE', role);
                    context.commit('SET_SUPERVISOR', supervisor);
                }
            }

            if (user) {
                const locationPermissionDtos = user.locationPermissionDtos;
                const catalogTypePermissionDtos = user.catalogTypePermissionDtos;
                context.commit('SET_USER_LOCATIONS_AND_CATALOG_TYPES', {
                    locations: locationPermissionDtos ?? [],
                    catalogTypes: catalogTypePermissionDtos ?? [],
                })
            }

            return !!user;
        }
    } as UserAccessManagementActions,
    mutations: {
        SET_USER_ROLE (state: UserAccessManagementState, role: UserRoles): void {
            state.userRole = role;
        },
        SET_SUPERVISOR (state: UserAccessManagementState, supervisor: Supervisor): void {
            state.supervisor = supervisor;
        },
        SET_NEW_SUPERVISOR (state: UserAccessManagementState, role: UserRoles): void {
            state.supervisor = new Supervisor(role);
        },
        SET_USER_ID (state: UserAccessManagementState, userId: number): void {
            state.userId = userId;
        },
        SET_USER_NAME (state: UserAccessManagementState, userName: string): void {
            state.userName = userName;
        },
        SET_USER_KID (state: UserAccessManagementState, userKid: string): void {
            state.userKid = userKid;
        },
        SET_USER_LOCATIONS (state: UserAccessManagementState, locations: LocationPermissionDto[]): void {
            state.userLocations = locations;
        },
        SET_USER_CATALOG_TYPES (state: UserAccessManagementState, catalogTypes: CatalogTypePermissionDto[]): void {
            state.userCatalogTypes = catalogTypes;
        },
        SET_FUNCTIONAL_PERMISSIONS (state: UserAccessManagementState, functionalPermissions: UserActions[]): void {
            state.functionalPermissions = functionalPermissions;
        },
        SET_USER_LOCATIONS_AND_CATALOG_TYPES (state: UserAccessManagementState, payload: { locations: LocationPermissionDto[]; catalogTypes: CatalogTypePermissionDto[] }): void {
            state.userLocations = payload.locations;
            state.userCatalogTypes = payload.catalogTypes;
        },
    } as UserAccessManagementMutations
};
