import moment from 'moment';
import { VueConstructor } from 'vue';

export const GlobalCustomFilters: CustomVueFilter[] = [
    {
        name: 'localdatetime',
        cb: (val: string): string => moment(val).local().format('DD.MM.YYYY HH:mm')
    },
    {
        name: 'toString',
        cb: (val: any): string => String(val)
    }
]; 

export type CustomVueFilter = {
    name: string;
    cb: (value: any) => any;
}

export const applyFilters = (vue: VueConstructor<Vue>, filters: CustomVueFilter[]): void => {
    filters.forEach((f: CustomVueFilter) => {
        vue.filter(f.name, f.cb);
    });
}