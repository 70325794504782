interface SimplePagination {
    page: number | undefined;
    size: number | undefined;
}

export function removeEmptyFieldsFrom<T extends SimplePagination>(filterFields: T): T {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const cleanedFilter: T = {
        page: filterFields.page,
        size: filterFields.size,
    }

    for (const _field in filterFields) {
        const field = _field as keyof typeof filterFields
        if (typeof filterFields[field] !== 'undefined') {
            if (typeof filterFields[field] === 'number') {
                cleanedFilter[field] = filterFields[field] as never;
            }
            if (typeof filterFields[field] === 'string' && filterFields[field]) {
                cleanedFilter[field] = filterFields[field] as never;
            }
            if (Array.isArray(filterFields[field]) && (filterFields[field] as unknown as Array<any>).length > 0) {
                cleanedFilter[field] = filterFields[field] as never;
            }
        }
    }

    return cleanedFilter;
}
