import {
    ILocationDto,
    LocationDto, LocationEditEndpointResponseOkResponse,
    LocationListEndpointResponseOkResponse,
    LocationsServiceProxy,
} from '@/service-proxies/service-proxies.g';
import { LocationListEndpointRequest } from '@/models';

/**
 * @name LocationService
 * @description This class is used to query the backend server for location data
 */
export class LocationService {
    private locationServiceProxy: LocationsServiceProxy;

    constructor() {
        this.locationServiceProxy = new LocationsServiceProxy();
    }

    /**
     * Get filtered list of locations
     * @param filter Location Filter
     */
    getLocations(filter: LocationListEndpointRequest): Promise<LocationListEndpointResponseOkResponse> {
        return this.locationServiceProxy.listLocations(
            filter?.locationIds,
            filter?.catalogTypeIds,
            filter?.sortDirection,
            filter?.sortProperty,
            filter?.page,
            filter?.size,
            filter?.Term,
            filter?.TermToLower
        )
    }

    /**
     * Edit the location.
     * @param editDto Edit Request.
     */
    public editLocation = async (editDto: ILocationDto): Promise<LocationEditEndpointResponseOkResponse> => {
        if (!editDto.id) {
            throw 'Dto has no valid id!';
        }

        return this.locationServiceProxy.editLocation(editDto.id, new LocationDto(editDto));
    }

    /**
     * Get filtered list of locations (proxy function).
     * @param filter Locations Filter.
     */
    public getItems = async (filter: LocationListEndpointRequest): Promise<LocationListEndpointResponseOkResponse> => {
        return this.getLocations(filter);
    }
}
