import { render, staticRenderFns } from "./report-table.vue?vue&type=template&id=572e742a&scoped=true"
import script from "./report-table.ts?vue&type=script&lang=ts&external"
export * from "./report-table.ts?vue&type=script&lang=ts&external"
import style0 from "./report-table.less?vue&type=style&index=0&id=572e742a&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "572e742a",
  null
  
)

export default component.exports