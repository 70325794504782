export interface ConfirmationModalProps {
    header: string;
    footerConfirmLabel?: string;
    footerCancelLabel?: string;
    onConfirmCallback?: object;
    onCancelCallback?: object;
    content: string;
    showLightboxCloseIcon?: boolean;
}

interface ConfirmationModalState extends ConfirmationModalProps {
    fitContent: boolean;
    fullScreen: boolean;
    isWaiting: boolean;
}

const defaultModalState: ConfirmationModalProps = {
    header: '',
    footerConfirmLabel: undefined,
    footerCancelLabel: undefined,
    onConfirmCallback: undefined,
    onCancelCallback: undefined,
    content: '',
    showLightboxCloseIcon: true
}

function setAllModalProps (state: ConfirmationModalState, input: ConfirmationModalProps): void {
    state.header = input.header;
    state.footerConfirmLabel = input.footerConfirmLabel;
    state.footerCancelLabel = input.footerCancelLabel;
    state.onConfirmCallback = input.onConfirmCallback;
    state.onCancelCallback = input.onCancelCallback;
    state.content = input.content;
    state.showLightboxCloseIcon = input.showLightboxCloseIcon === undefined ? true : input.showLightboxCloseIcon;
}

function resetAllModalProps (state: ConfirmationModalState): void {
    setAllModalProps(state, defaultModalState);
}

export const confirmationModal = {
    namespaced: true,
    state: (): ConfirmationModalState => ({
        ...defaultModalState,
        isWaiting: false,
        fitContent: true,
        fullScreen: false,
    }),
    getters: {
        header: (state: ConfirmationModalState): string => state.header,
        content: (state: ConfirmationModalState): string => state.content,
        footerConfirmLabel: (state: ConfirmationModalState): string | undefined => state.footerConfirmLabel,
        footerCancelLabel: (state: ConfirmationModalState): string | undefined => state.footerCancelLabel,
        fitContent: (state: ConfirmationModalState): boolean => state.fitContent,
        fullScreen: (state: ConfirmationModalState): boolean => state.fullScreen,
        onConfirmCallback: (state: ConfirmationModalState): object | undefined => state.onConfirmCallback,
        onCancelCallback: (state: ConfirmationModalState): object | undefined => state.onCancelCallback,
        isWaitingForConfirmation: (state: ConfirmationModalState): any => state.isWaiting,
        showLightboxCloseIcon: (state: ConfirmationModalState): any => state.showLightboxCloseIcon,
    },
    actions: {
    },
    mutations: {
        WAIT_FOR_CONFIRMATION (state: ConfirmationModalState, modalArgs: ConfirmationModalProps): void {
            if (modalArgs) {
                setAllModalProps(state, modalArgs);
            } else {
                resetAllModalProps(state);
            }
            state.isWaiting = true;
        },
        CLOSE_AND_RESET (state: ConfirmationModalState): void {
            resetAllModalProps(state);
            state.isWaiting = false;
        },
        SET_CONFIRMATION_MODAL_PROPS (state: ConfirmationModalState, input: ConfirmationModalProps): void {
            setAllModalProps(state, input);
        },
        SET_FIT_CONTENT (state: ConfirmationModalState, fitContent: boolean): void {
            state.fitContent = fitContent;
        },
    }
};
