/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable indent */
import {
    AverageReportGetEndpointResponse,
    LocationDto,
    MainQuestionDto,
    PDFReportType,
    QuestionCatalogDto,
    ReportApprovalStatus,
    ReportDto,
    ReportRowDto,
    ReportRowReply,
    ReportType,
    RiskRating,
    SubQuestionDto,
    SubQuestionType,
    UserDto,
} from '@/service-proxies/service-proxies.g';
import { ReportService } from '@/services/report-service';
import { apiCallWithErrorHandling } from '@/services/utils';
import store from '@/store';
import { WAITING_MODAL_DEFAULT_TEXT, WAITING_MODAL_DEFAULT_TITLE } from '@/store/modules/waiting-modal';
import { selectedLanguageToMomentLocale } from '@/utils/date-time-utils';
import { base64PDFtoBlob } from '@/utils/javascript-utils';
import { LANGUAGES } from '@/utils/languages';
import { LocalStorageHelper } from '@/utils/local-storage-helper';
import _ from 'lodash';
import moment from 'moment';
import Vue, { PropType } from 'vue';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { svgColorRamp, svgColoredBar, svgDivider, svgSquare } from './svg-helper';
import { ReportRowStatus } from '@/enums/report-row-status';
import { TranslateResult } from 'vue-i18n';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

function notBool(x: any): x is Exclude<typeof x, boolean> {
    return typeof x !== 'boolean';
}
function percentageToString(value?: number): string {
    return value !== undefined ? value.toFixed(2) + '%' : 'N/A';
}

const PdfCreator = Vue.extend({
    components: {},
    props: {
        hideButtons: {
            type: Boolean,
            default: false
        },
        pdfType: {
            type: Number as PropType<PDFReportType>,
            default: undefined,
            validator: (p): boolean => typeof p === 'number',
        },
        data: {
            type: Object as PropType<ReportDto | undefined>,
            default: undefined,
            validator: (p): boolean => typeof p === 'object' || p === undefined,
        },
        catalog: {
            type: Object as PropType<QuestionCatalogDto | undefined>,
            default: undefined,
            validator: (p): boolean => typeof p === 'object' || p === undefined,
        },
        skipUpload: {
            type: Boolean,
            required: false,
            default: false,
        },
        creatorUser: {
            type: Object as PropType<UserDto | undefined>,
            default: undefined,
        },
        approverUser: {
            type: Object as PropType<UserDto | undefined>,
            default: undefined,
        },
        catalogApproverUser: {
            type: Object as PropType<UserDto | undefined>,
            default: undefined,
        },
        catalogTestApproverUser: {
            type: Object as PropType<UserDto | undefined>,
            default: undefined,
        },
    },
    data: () => ({
        placeholder: '0000',
        placeholderText: '-',
        selectedLanguage: 'en-GB',
        reportApi: new ReportService(),
    }),
    computed: {
        translatedReportTypeName(): string {
            return this.$t(`pdfReportType.open${PDFReportType[this.pdfType]}`).toString();
        },
        testReportMode(): boolean {
            return this.data?.reportType === ReportType.TestReport;
        },
        currentUri(): string | undefined {
            switch (this.pdfType) {
                case PDFReportType.AuditReport:
                    return this.data?.auditReportUri;
                case PDFReportType.RiskReport:
                    return this.data?.riskReportUri;
                default:
                    return undefined;
            }
        },
        pdfsCanBeCreated(): boolean {
            return (
                this.data?.approvalStatus !== ReportApprovalStatus.New &&
                this.data?.approvalStatus !== ReportApprovalStatus.Editable
            );
        },
    },
    methods: {
        formatDate(dateString: string | undefined): string {
            if (dateString === undefined) return this.placeholderText;
            return moment
                .tz(dateString, 'Etc/UTC')
                .local()
                .locale(selectedLanguageToMomentLocale(this.selectedLanguage))
                .format('llll')
                .concat(' (UTC+2)');
        },
        getLocation(locationId: number): string {
            return (
                (store.getters['locations/locations'] as LocationDto[]).find((k) => k.id === locationId)
                    ?.locationName || '-'
            );
        },
        async openReport(): Promise<void> {
            if (this.pdfType === undefined) {
                throw new Error('pdfType is undefined');
            }

            const pdfResponse = await apiCallWithErrorHandling(
                this.reportApi.getPDF,
                {
                    id: this.data?.id ?? 0,
                    reportType: this.pdfType,
                },
                (this as any).$pui.toast,
                {
                    successTitle: this.$t('reportDetails.pdfDownloadToast.successTitle').toString(),
                    successText: this.$t('reportDetails.pdfDownloadToast.successText').toString(),
                    errorTitle: this.$t('reportDetails.pdfDownloadToast.errorTitle').toString(),
                    errorText: this.$t('reportDetails.pdfDownloadToast.errorText').toString(),
                    waitingTitle: this.$t(WAITING_MODAL_DEFAULT_TITLE).toString(),
                    waitingText: this.$t(WAITING_MODAL_DEFAULT_TEXT).toString(),
                },
                this.$store
            );
            const blob = await base64PDFtoBlob(pdfResponse.result?.fileStreamResult ?? '');
            const fileObjectUrl = URL.createObjectURL(blob);
            window.open(fileObjectUrl);
        },
        createReport({ openInTab, showWaitingModal }: { openInTab: boolean; showWaitingModal: boolean }): void {
            if (showWaitingModal) {
                this.$store.commit('waitingModal/WAITING', {
                    title: this.$t(WAITING_MODAL_DEFAULT_TITLE),
                    text: this.$t(WAITING_MODAL_DEFAULT_TEXT),
                });
            }

            this.selectedLanguage = LANGUAGES[LocalStorageHelper.getSelectedLanguage() ?? 'EN'];

            let content: Content[];
            switch (this.pdfType) {
                case PDFReportType.RiskReport:
                    content = [
                        this.renderHeadline(
                            `${this.catalog?.catalogType?.getDescription(this.$i18n.locale)} [${
                                this.data?.reportYear
                            }]`,
                            this.$t('reportHeadlines.riskReportHeadline2').toString()
                        ),
                        this.renderReportInformation(),
                        this.renderQuestionnaireInformation(),
                        this.renderRiskRatingReport(),
                        this.renderReportComment(),
                        this.renderRiskRatingPerGroup(),
                        this.renderQuestionGroups(true),
                    ];
                    break;
                case PDFReportType.AuditReport:
                    content = [
                        this.renderHeadline(
                            `${this.catalog?.catalogType?.getDescription(this.$i18n.locale)} [${
                                this.data?.reportYear
                            }]`,
                            this.$t('reportHeadlines.auditReportHeadline2')
                        ),
                        this.renderReportInformation(),
                        this.renderQuestionnaireInformation(),
                        this.renderQuestionGroups(),
                        this.renderRiskRatingPerGroup(),
                        this.renderRiskRatingReport(),
                        this.renderReportComment(),
                    ];
                    break;
                case PDFReportType.AverageReport:
                    content = [
                        this.renderHeadline(
                            `${this.$t('reportHeadlines.averageReportHeadline1')}${this.data?.reportYear}`,
                            this.$t('reportHeadlines.averageReportHeadline2')
                        ),
                        this.renderQuestionnaireInformation(),
                        this.renderLocationOverview(),
                        this.renderRiskRatingReport(),
                        this.renderRiskRatingPerGroup(),
                        this.renderQuestionGroups(true, true),
                    ];
                    break;
                default:
                    throw new Error('pdfType is undefined');
            }

            const documentDefinitions: TDocumentDefinitions = {
                content,
                styles: {
                    h1: { fontSize: 22, bold: true },
                    h2: { fontSize: 19, bold: true },
                    h3: { fontSize: 16, bold: true },
                    h4: { fontSize: 13, bold: true },
                    h5: { fontSize: 11, bold: true },
                    emph: { fontSize: 9, bold: true },
                },
                defaultStyle: {
                    fontSize: 9,
                },
                pageBreakBefore: (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) => {
                    return (
                        // HeadlineLevel 1: Node should be a unbreakable stack
                        (currentNode.headlineLevel === 1 &&
                            currentNode.pageNumbers.length > 1 &&
                            previousNodesOnPage.length > 0) ||
                        // HeadlineLevel 2 and 3: Node should be headline without page break immediately following it
                        ((currentNode.headlineLevel === 2 || currentNode.headlineLevel === 3) &&
                            (followingNodesOnPage.length === 0 ||
                                followingNodesOnPage[0].startPosition.top + 140 >= // Heuristic approach: If next element has less than 140pt space left, page break
                                    followingNodesOnPage[0].startPosition.pageInnerHeight))
                    );
                },
                footer: (currentPage, pageCount) => {
                    return {
                        text: `${this.$t('pdfCreation.page')} ${currentPage} ${this.$t('pdfCreation.of')} ${pageCount}`,
                        alignment: 'center',
                        margin: [0, 10, 0, 0],
                    };
                },
            };
            const pdfDocGenerator = pdfMake.createPdf(documentDefinitions);
            if (openInTab) {
                pdfDocGenerator.open();
            }
            pdfDocGenerator.getBlob((blob) => this.$emit('pdfCreated', blob));

            if (showWaitingModal) {
                this.$store.commit('waitingModal/CLOSE_AND_RESET');
            }
        },
        renderHeadline(headline1: TranslateResult, headline2: TranslateResult): Content {
            return {
                columns: [
                    { width: '*', style: 'h1', stack: [headline1.toString(), headline2.toString()] },
                ],
                margin: [0, 0, 0, 15],
                columnGap: 10,
            };
        },
        renderReportInformation(): Content {
            return this.renderBlock({
                unbreakable: true,
                header: this.$t('pdfCreation.reportInformation'),
                content: this.renderReportTable(),
            });
        },
        renderQuestionnaireInformation(): Content {
            return this.renderBlock({
                unbreakable: true,
                header: this.$t('pdfCreation.basedOnQuestionnaire'),
                content: this.renderCatalogTable(),
            });
        },
        renderRiskRatingReport(): Content {
            return this.renderBlock({
                unbreakable: true,
                header: this.$t('pdfCreation.riskRatingReport'),
                content: [
                    this.renderBarChart({
                        header: this.$t('OverallRiskReportHeadline'),
                        risk: this.data?.reportEvaluation,
                    }),
                    this.renderRow(this.$t('pdfCreation.positive'), this.$t('pdfCreation.positiveDescription')),
                    this.renderRow(
                        this.$t('pdfCreation.potentialForImprovement'),
                        this.$t('pdfCreation.potentialForImprovementDescription')
                    ),
                    this.renderRow(
                        this.$t('pdfCreation.potentialDeviation'),
                        this.$t('pdfCreation.potentialDeviationDescription')
                    ),
                    this.renderRow(this.$t('pdfCreation.deviation'), this.$t('pdfCreation.deviationDescription')),
                    this.renderColorScale(this.data?.reportEvaluation?.riskTotal),
                ],
            });
        },
        renderReportComment(): Content {
            return this.renderBlock({
                unbreakable: true,
                header: this.$t('pdfCreation.reportComment.commentHeader'),
                content: this.renderRow(
                    this.$t('pdfCreation.reportComment.comment'),
                    this.data?.comment ?? this.placeholderText
                ),
            });
        },
        renderRiskRatingPerGroup(): Content {
            return this.renderBlock({
                unbreakable: false,
                header: this.$t('pdfCreation.riskRatingPerQuestionGroup'),
                content: _.sortBy(this.catalog?.questionGroups ?? [], (x) => x.position ?? 0).map((questionGroup) => {
                    const riskEvaluationQuestionGroup = this.data?.reportEvaluationQuestionGroupPercentages?.find(
                        (element) => questionGroup.id === element.questionGroupId
                    );
                    return this.renderBarChart({
                        header: questionGroup.position + '. ' + questionGroup.description,
                        risk: riskEvaluationQuestionGroup,
                    });
                }),
            });
        },
        renderQuestionGroups(hideNoRisk = false, showLocationForMainQuestion = false): Content {
            const questionGroups: Content[] = _.sortBy(
                this.catalog?.questionGroups ?? [],
                (x) => x.position ?? 0
            ).map((questionGroup) => {
                const mainQuestions = _.sortBy(questionGroup.mainQuestions ?? [], (x) => x.position ?? 0).flatMap(
                    (mainQuestion) => {
                        return (this.data?.reportRows ?? [])
                            .filter(
                                (k) =>
                                    k.referenceId === mainQuestion.id &&
                                    (!hideNoRisk ||
                                        (k?.riskRating !== RiskRating.NoRisk && k?.riskRating !== RiskRating.Undefined))
                            )
                            .map((mainQuestionReportRow) => {
                                return this.renderQuestion(
                                    mainQuestionReportRow,
                                    mainQuestion,
                                    showLocationForMainQuestion
                                );
                            });
                    }
                );
                if (mainQuestions.length === 0) return false;
                return [
                    {
                        margin: [0, 15, 0, 0],
                        headlineLevel: 3,
                        style: 'h3',
                        text: `${questionGroup.position}: ${questionGroup.description}`,
                    },
                    ...mainQuestions,
                ];
            }).filter(notBool);
            return this.renderBlock({
                unbreakable: false,
                header: this.$t('pdfCreation.questionGroups'),
                content: [
                    hideNoRisk &&
                        (questionGroups.length === 0
                            ? { text: this.$t('pdfCreation.noQuestionsWithRisk') }
                            : { text: this.$t('pdfCreation.onlyQuestionsWithRiskShown') }),
                    ...questionGroups,
                ].filter(notBool),
            });
        },
        renderLocationOverview(): Content {
            const { obligatedLocations, participatedLocations, locationReportParticipationMap } = this
                .data as AverageReportGetEndpointResponse;

            return this.renderBlock({
                unbreakable: true,
                header: this.$t('pdfCreation.averageReport.locationOverview.header'),
                content: [
                    this.renderRow(
                        this.$t('pdfCreation.averageReport.locationOverview.obligated'),
                        obligatedLocations
                            ?.map((l) => l.locationName)
                            .join(', ')
                            .trimEnd() ?? this.placeholderText
                    ),
                    this.renderRow(
                        this.$t('pdfCreation.averageReport.locationOverview.participated'),
                        participatedLocations
                            ?.map((l) => {
                                const report = locationReportParticipationMap?.find(
                                    (m) => m.location?.id === l.id
                                )?.report;
                                const reportApprovalDate = moment(report?.plantManagerApprovalDate)
                                    .local()
                                    .format('DD.MM.YYYY HH:mm')
                                    .concat(' (UTC+2)');
                                const atString = this.$t('pdfCreation.averageReport.locationOverview.at').toString();
                                const reportTypeAbbrString =
                                    report?.reportType === ReportType.MainReport
                                        ? this.$t('reportTypeAbbr.MainReport').toString()
                                        : this.$t('reportTypeAbbr.InterimReport').toString();
                                return `${l.locationName} (${reportTypeAbbrString}${report?.id} ${atString} ${reportApprovalDate})`;
                            })
                            .join(', ')
                            .trimEnd() ?? this.placeholderText
                    ),
                ],
            });
        },

        renderBlock({
            header,
            content,
            unbreakable,
        }: {
            header: TranslateResult;
            content: Content;
            unbreakable: boolean;
        }): Content {
            return {
                headlineLevel: unbreakable ? 1 : undefined,
                stack: [
                    {
                        headlineLevel: unbreakable ? undefined : 2,
                        style: 'h2',
                        margin: [0, 0, 0, 10],
                        text: header.toString(),
                    },
                    content,
                    { svg: svgDivider(515.28), margin: [0, 10, 0, 20] },
                ],
            };
        },
        _locationOfReport(row: ReportRowDto): string {
            const locationToReportMapDto = (
                this.data as AverageReportGetEndpointResponse
            ).locationReportParticipationMap?.find((x) => x.report?.id === row.reportId);
            return this.getLocation(locationToReportMapDto?.report?.locationId ?? this.data?.locationId ?? 0);
        },
        _descriptionOfRiskRating(riskRating: RiskRating): string {
            return {
                [RiskRating.Undefined]: this.$t('pdfCreation.notYetAnswered'),
                [RiskRating.NoRisk]: this.$t('pdfCreation.noRisk'),
                [RiskRating.LowRisk]: this.$t('pdfCreation.lowRisk'),
                [RiskRating.MedRisk]: this.$t('pdfCreation.mediumRisk'),
                [RiskRating.HighRisk]: this.$t('pdfCreation.highRisk'),
            }[riskRating].toString();
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        renderQuestion(row: ReportRowDto, mainQuestion: MainQuestionDto, showLocation: boolean): Content {
            const mainReply = ReportRowReply[Number(row?.replyYN)];

            const riskRating = row.riskRating ?? RiskRating.Undefined;
            const hasRisk = riskRating in [RiskRating.LowRisk, RiskRating.MedRisk, RiskRating.HighRisk];

            const status = row.reportRowListEntries?.[0]?.status;

            const subQuestions = _.sortBy(mainQuestion.subQuestions ?? [], (x) => x.position ?? 0);

            return [
                {
                    unbreakable: true,
                    margin: [0, 10, 0, 10],
                    stack: [
                        {
                            style: 'h4',
                            text: `${row.seqNo ?? this.placeholderText}: ${mainQuestion.text ?? this.placeholderText}`,
                        },
                        {
                            layout: 'noBorders',
                            table: {
                                headerRows: 0,
                                widths: ['25%', '75%'],
                                body: [
                                    showLocation && [this.$t('pdfCreation.location'), this._locationOfReport(row)],
                                    [
                                        `${this.$t('pdfCreation.reply')}:`,
                                        this.$t('pdfCreation.' + _.camelCase(mainReply)),
                                    ],
                                    [
                                        `${this.$t('pdfCreation.riskClassification')}:`,
                                        {
                                            columns: [
                                                riskRating !== RiskRating.Undefined && {
                                                    width: 12,
                                                    svg: svgSquare(riskRating),
                                                },
                                                { width: '*', text: this._descriptionOfRiskRating(riskRating) },
                                            ].filter(notBool),
                                        },
                                    ],
                                    hasRisk && riskRating!= RiskRating.NoRisk &&[
                                        `${this.$t('pdfCreation.remedy')}:`,
                                        row.remedy ?? this.placeholderText,
                                    ],
                                    hasRisk && riskRating!= RiskRating.NoRisk && [
                                        `${this.$t('pdfCreation.responsible')}:`,
                                        row.remedyResponsible ?? this.placeholderText,
                                    ],
                                    [`${this.$t('pdfCreation.annotation')}:`, row.annotation ?? this.placeholderText],
                                    hasRisk && riskRating!= RiskRating.NoRisk &&[
                                        `${this.$t('pdfCreation.status')}:`,
                                        status === undefined
                                            ? this.placeholderText
                                            : this.$t('reportRowStatus.' + ReportRowStatus[status]).toString(),
                                    ],
                                ].filter(notBool),
                            },
                        },
                    ],
                },
                riskRating !== RiskRating.NoRisk &&
                    subQuestions.map((subQuestion) => {
                        const sub = (this.data?.reportRows ?? []).find((k) => k.referenceId === subQuestion.id);
                        return {
                            unbreakable: true,
                            margin: [15, 10, 0, 0],
                            stack: [
                                subQuestion.subQuestionType === SubQuestionType.List
                                    ? this.renderSubQuestionList(subQuestion, sub)
                                    : this.renderSubQuestion(subQuestion, sub),
                            ],
                        };
                    }),
            ].filter(notBool);
        },
        renderSubQuestion(subQuestion: SubQuestionDto, sub?: ReportRowDto): Content {
            const riskRating = sub?.riskRating ?? RiskRating.Undefined;
            const hasRisk = riskRating in [RiskRating.LowRisk, RiskRating.MedRisk, RiskRating.HighRisk];
            const status = sub?.reportRowListEntries?.[0]?.status;
            return [
                {
                    style: 'h5',
                    text: `${sub?.seqNo ?? this.placeholderText}: ${subQuestion.text}`,
                },
                {
                    layout: 'noBorders',
                    table: {
                        headerRows: 0,
                        widths: ['25%', '75%'],
                        body: [
                            [
                                `${this.$t('pdfCreation.reply')}:`,
                                this.$t(
                                    `pdfCreation.${_.camelCase(
                                        ReportRowReply[sub?.replyYN ?? ReportRowReply.NotYetAnswered]
                                    )}`
                                ),
                            ],
                            hasRisk && [`${this.$t('pdfCreation.remedy')}:`, sub?.remedy ?? this.placeholderText],
                            hasRisk && [
                                `${this.$t('pdfCreation.responsible')}:`,
                                sub?.remedyResponsible ?? this.placeholderText,
                            ],
                            [`${this.$t('pdfCreation.annotation')}:`, sub?.annotation ?? this.placeholderText],
                            hasRisk && [
                                `${this.$t('pdfCreation.status')}:`,
                                status === undefined
                                    ? this.placeholderText
                                    : this.$t('reportRowStatus.' + ReportRowStatus[status]).toString(),
                            ],
                        ].filter(notBool),
                    },
                },
            ];
        },
        renderSubQuestionList(subQuestion: SubQuestionDto, sub?: ReportRowDto): Content {
            const items = _.sortBy(
                sub?.reportRowListEntries?.filter((x) => x.listing !== '') ?? [],
                (x) => x.position ?? 0
            );
            return [
                {
                    style: 'h5',
                    text: `${sub?.seqNo ?? this.placeholderText}: ${subQuestion.text}`,
                },
                items.length > 0 && {
                    layout: 'noBorders',
                    table: {
                        headerRows: 0,
                        widths: ['25%', '75%'],
                        body: items.map((item) => [
                            `- ${item.listing || ''}`,
                            `${this.$t('pdfCreation.status')}: ${
                                item.status === undefined
                                    ? this.placeholderText
                                    : this.$t('reportRowStatus.' + ReportRowStatus[item.status])
                            }, ${this.$t('pdfCreation.date')}: ${moment
                                .utc(item.dueDate)
                                .local()
                                .format('DD.MM.YYYY')
                                .toString()}`,
                        ]),
                    },
                },
                {
                    text: `${this.$t('pdfCreation.numberListItems')}: ${items.length}`,
                },
            ].filter(notBool);
        },
        renderReportTable(): Content {
            return {
                layout: 'noBorders',
                table: {
                    headerRows: 0,
                    widths: ['auto', '*', 'auto', '*'],
                    body: [
                        [`${this.$t('pdfCreation.version')}:`, this.data?.version ?? this.placeholderText, '', ''],
                        [
                            `${this.$t('pdfCreation.internalReportId')}:`,
                            this.data?.id ?? this.placeholder,
                            `${this.$t('pdfCreation.locationOrArea')}:`,
                            this.getLocation(this.data?.locationId ?? 0),
                        ],
                        [
                            `${this.$t('pdfCreation.description')}:`,
                            this.data?.description ?? this.placeholderText,
                            `${this.$t('pdfCreation.language')}:`,
                            this.data?.language?.languageName ?? this.placeholderText,
                        ],
                        [
                            `${this.$t('pdfCreation.reportType')}:`,
                            this.$t('reportType.' + ReportType[this.data?.reportType as number]),
                            `${this.$t('pdfCreation.createdBy')}:`,
                            this.creatorUser?.name ?? this.placeholderText,
                        ],
                        [
                            `${this.$t('pdfCreation.reportYear')}:`,
                            this.data?.reportYear ?? this.placeholderText,
                            `${this.$t('pdfCreation.createdAt')}:`,
                            this.formatDate(this.data?.creatorApprovalDate),
                        ],
                        [
                            '',
                            '',
                            `${this.$t('pdfCreation.approvedBy')}:`,
                            this.approverUser?.name ?? this.placeholderText,
                        ],
                        [
                            '',
                            '',
                            `${this.$t('pdfCreation.approvedAt')}:`,
                            this.formatDate(this.data?.plantManagerApprovalDate),
                        ],
                    ],
                },
            };
        },
        renderCatalogTable(): Content {
            return {
                layout: 'noBorders',
                table: {
                    headerRows: 0,
                    widths: ['auto', '*', 'auto', '*'],
                    body: [
                        [
                            `${this.$t('pdfCreation.internalCatalogId')}:`,
                            this.catalog?.id ?? '',
                            `${this.$t('pdfCreation.version')}:`,
                            this.catalog?.version ?? this.placeholderText,
                        ],
                        [
                            `${this.$t('pdfCreation.description')}:`,
                            this.catalog?.description ?? this.placeholderText,
                            `${this.$t('pdfCreation.language')}:`,
                            this.$t(this.catalog?.language?.languageName?.toLowerCase() || ''),
                        ],
                        [
                            `${this.$t('pdfCreation.catalogType')}:`,
                            this.catalog?.catalogType?.getDescription(this.$i18n.locale) ?? this.placeholderText,
                            `${this.$t('pdfCreation.approvedBy')}:`,
                            (this.testReportMode ? this.catalogTestApproverUser : this.catalogApproverUser)?.name ??
                                this.placeholderText,
                        ],
                        [
                            `${this.$t('pdfCreation.reportYear')}:`,
                            this.catalog?.reportYear ?? this.placeholderText,
                            `${this.$t('pdfCreation.approvedAt')}:`,
                            this.formatDate(
                                this.testReportMode ? this.catalog?.testApprovalDate : this.catalog?.approvalDate
                            ),
                        ],
                    ],
                },
            };
        },
        renderBarChart({
            header,
            risk,
        }: {
            header: TranslateResult;
            risk?: {
                percentageNoRisk?: number;
                percentageLowRisk?: number;
                percentageMediumRisk?: number;
                percentageHighRisk?: number;
            };
        }): Content {
            return {
                unbreakable: true,
                margin: [0, 10, 0, 0],
                stack: [
                    { style: 'h4', text: header.toString() },
                    {
                        layout: 'noBorders',
                        table: {
                            headerRows: 0,
                            widths: ['auto', '*', 'auto', '50'],
                            body: [
                                [
                                    { style: 'emph', text: `${this.$t('pdfCreation.riskClassification')}:` },
                                    this.$t('pdfCreation.noRisk'),
                                    { svg: svgColoredBar(200, risk?.percentageNoRisk ?? 0, RiskRating.NoRisk) },
                                    percentageToString(risk?.percentageNoRisk),
                                ],
                                [
                                    '',
                                    this.$t('pdfCreation.lowRisk'),
                                    { svg: svgColoredBar(200, risk?.percentageLowRisk ?? 0, RiskRating.LowRisk) },
                                    percentageToString(risk?.percentageLowRisk),
                                ],
                                [
                                    '',
                                    this.$t('pdfCreation.mediumRisk'),
                                    { svg: svgColoredBar(200, risk?.percentageMediumRisk ?? 0, RiskRating.MedRisk) },
                                    percentageToString(risk?.percentageMediumRisk),
                                ],
                                [
                                    '',
                                    this.$t('pdfCreation.highRisk'),
                                    { svg: svgColoredBar(200, risk?.percentageHighRisk ?? 0, RiskRating.HighRisk) },
                                    percentageToString(risk?.percentageHighRisk),
                                ],
                            ],
                        },
                    },
                ],
            };
        },
        renderColorScale(rating?: number): Content {
            return {
                layout: 'noBorders',
                margin: [0, 10, 0, 0],
                table: {
                    headerRows: 0,
                    widths: ['*', 350],
                    body: [
                        [
                            { style: 'emph', text: this.$t('pdfCreation.weightedNeedForAction') },
                            { svg: svgColorRamp(350, rating ?? 100) },
                        ],
                        [
                            '',
                            {
                                columns: [
                                    { width: '*', text: this.$t('pdfCreation.none'), alignment: 'left' },
                                    {
                                        width: 'auto',
                                        text: this.$t('pdfCreation.weightingFactors'),
                                        alignment: 'center',
                                    },
                                    { width: '*', text: this.$t('pdfCreation.high'), alignment: 'right' },
                                ],
                            },
                        ],
                    ],
                },
            };
        },
        renderRow(left: TranslateResult, right: TranslateResult): Content {
            return {
                columns: [
                    { width: '25%', text: `${left}:` },
                    { width: '75%', text: right.toString() },
                ],
                margin: [0, 0, 0, 5],
            };
        },
    },
});

export default PdfCreator;
