import Vue, { PropType } from 'vue';
import SidebarButton from './partials/single-button/sidebar-single-button.vue'
import { EventBus } from '@/utils';
import { SelectedOptionEvent } from './contracts/selected-option-event';

const SidebarMultiOption = Vue.extend({
    name: 'SidebarMultiOption',
    components: {
        SidebarButton,
    },
    props: {
        eventName: {
            type: String,
            default: '.sidebarButtonClicked'
        },
        optionsList: {
            type: Array as PropType<Array<{ displayValue: string; componentName: string; disabled?: boolean }>>,
            required: true
        }
    },
    data (): {
        selectedOption: string;
        } {
        return {
            selectedOption: '',
        }
    },
    watch: {
    },
    created (): void {
        // noop
    },
    async mounted (): Promise<void> {
        this.registerCallbacks();
    },
    computed: {
    },
    methods: {
        isSelected (componentName: string): boolean {
            return componentName === this.selectedOption;
        },
        registerCallbacks (): void {
            EventBus.$on(`${this.eventName}`,
                (event: SelectedOptionEvent) => this.selectedOption = event.componentName
            );
        },
    }
})

export default SidebarMultiOption;
