import { applyFilters, GlobalCustomFilters } from './utils/vue-filters';
import Vue from 'vue';
import App from './App.vue';
import VueI18n from 'vue-i18n';
import router from './router';
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCopy, faCaretRight, faCaretUp, faCaretDown, faSort, faGripLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'adam.ui-core/dist/umd';
import 'adam.ui-core/utils/scaling.css';
import './registerServiceWorker';
import { VNode } from 'vue/types/umd';
import PebbleUI from '@enerlytics/pebble-ui/dist/pebble-ui';
import { LANGUAGES } from './utils/languages';
import { LocalStorageHelper } from './utils/local-storage-helper';
import { CoodeSdkPlugin } from '@coode/fe-sdk';
import coodeSdkPluginConfiguration from '@/utils/coode-sdk-plugin-configuration';

Vue.config.productionTip = false;

Vue.component('font-awesome-icon', FontAwesomeIcon);

library.add(faCopy as any, faCaretRight as any, faCaretUp as any, faCaretDown as any, faSort as any, faGripLines as any);

Vue.use(VueI18n);

applyFilters(Vue, GlobalCustomFilters);

const currentLanguage = LANGUAGES[LocalStorageHelper.getSelectedLanguage() ?? LocalStorageHelper.determineDefaultLanguage()];
const defaultOrSupportedLang = !currentLanguage ? 'en-GB' : currentLanguage;

const i18n = new VueI18n({
    locale: defaultOrSupportedLang,
    messages: {
        'en-GB': require(`./locales/en-GB.json`),
        'de-DE': require(`./locales/de-DE.json`),
    }
});

/* @ts-ignore */
Vue.use(PebbleUI, {
    lang: defaultOrSupportedLang,
    dateTimeLocale: defaultOrSupportedLang,
});

Vue.use(new CoodeSdkPlugin(), coodeSdkPluginConfiguration);

new Vue({
    i18n,
    router,
    store,
    render: (h): VNode => h(App),
}).$mount('#app');
