import { CatalogTypeDto } from '@/service-proxies/service-proxies.g';

export class FakeCatalogTypeService {
    private readonly catalogTypes: CatalogTypeDto[] = [];

    constructor(locations: CatalogTypeDto[]) {
        this.catalogTypes = locations;
    }

    getItems (filter: { Term: string; page: number; size: number }): { result: { items: CatalogTypeDto[] }} {
        if (filter) {
            return {
                result: {
                    items: this.catalogTypes
                        .filter(x => x.catalogTypeDescriptions?.flatMap(x => x.description)?.some(y => y?.includes(filter.Term)) || x.catalogTypeAbbr?.includes(filter.Term))
                        .slice((filter.page - 1) * filter.size, filter.page * filter.size)
                }
            };

        }
        return { result: { items: this.catalogTypes }};
    }
}
