import Vue from 'vue'
import { EventBus } from '@/utils';

const SidebarButton = Vue.extend({
    name: 'SidebarButton',
    props: {
        displayValue: {
            required: true,
            type: String
        },
        componentName: {
            required: true,
            type: String
        },
        selected: {
            default: false,
            type: Boolean
        },
        eventName: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data (): {
    } {
        return {
        };
    },
    computed: {
    },
    methods: {
        buttonClicked (): void {
            if (!this.disabled) {
                EventBus.$emit(`${this.eventName}`, { componentName: this.componentName });
            }
        }
    }
});
export default SidebarButton;
