

import {Component, Vue} from 'vue-property-decorator';
import { EventBus } from '@/utils/eventbus';
import clickOutside from '@/directives/click-outside';
import { LocalStorageHelper } from '@/utils/local-storage-helper';
import { LANGUAGES } from '@/utils/languages';
import { reloadCurrentPage } from '@/utils/platform-utils';

@Component({
    directives: {
        clickOutside,
    },
})
export default class UserProfile extends Vue {
    private version: string | undefined = process.env.VUE_APP_VERSION;
    private apiUrl: string | undefined = process.env.VUE_APP_API_BASE_URL;
    private swaggerURL: string | undefined = process.env.VUE_APP_API_SWAGGER_URL;
    private detailsToggled = false;

    private selectedLanguage = LocalStorageHelper.getSelectedLanguage();
    private languageOptions = LocalStorageHelper.getLanguageOptions();
    private soundIsActive = LocalStorageHelper.getSoundIsActive();

    private logOut(): void {
        (this as any).$sdk.$authenticator?.logout();
    }

    private async copyToken(): Promise<void> {
        const textElement: any = document.getElementById('copyText');
        if (textElement) {
            const token = await (this as any).$sdk.$authenticator?.getToken(process.env.VUE_APP_SCOPE);
            textElement.value = '';
            textElement.value = `Bearer ${token}`;
            textElement.select();
            await navigator.clipboard?.writeText(textElement.value);
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'copiedToClipboard');
        } else {
            throw new Error('Copy text element is undefined');
        }
    }

    private offFocus(): void {
        if (this.detailsToggled) {
            this.detailsToggled = false;
        }
    }

    private changeSelectedLanguage(): void {
        LocalStorageHelper.setSelectedLanguage(this.selectedLanguage);
        this.$i18n.locale = LANGUAGES[this.selectedLanguage ?? 0];
        reloadCurrentPage();
    }

    private toggleSoundIsActive (): void {
        LocalStorageHelper.setSoundIsActive(!this.soundIsActive);
    }
}
