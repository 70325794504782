import Vue from 'vue';
import { mapGetters } from 'vuex';
const ConfirmationModal = Vue.extend({
    name: 'ConfirmationModal',
    components: {
    },
    props: {},
    data (): {
    } {
        return {
        }
    },
    watch: {
        isOpen: {
            handler (val: boolean): void {
                if (val) {
                    (this.$refs['globalConfirmationModalRef'] as any).open();
                }
                else {
                    (this.$refs['globalConfirmationModalRef'] as any).close();
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            isOpen: 'confirmationModal/isWaitingForConfirmation',
            header: 'confirmationModal/header',
            footerConfirmLabel: 'confirmationModal/footerConfirmLabel',
            footerCancelLabel: 'confirmationModal/footerCancelLabel',
            fitContent: 'confirmationModal/fitContent',
            fullScreen: 'confirmationModal/fullScreen',
            onConfirmCallback: 'confirmationModal/onConfirmCallback',
            onCancelCallback: 'confirmationModal/onCancelCallback',
            content: 'confirmationModal/content',
            showLightboxCloseIcon: 'confirmationModal/showLightboxCloseIcon'
        }),
    },
    methods: {
        closeModal (): void {
            if (this.isOpen) {
                this.$store.commit('confirmationModal/CLOSE_AND_RESET');
            }
        }
    }
});
export default ConfirmationModal;
