import Vue from 'vue';

export const EventBus = new Vue({
    data(): any {
        return {
            GLOBAL: {
                SHOW_SNACKBAR: 'global.show-snackbar',
                SHOW_WARNING_MODAL: 'global.show-warning-modal',
            },
            VIEWS: {
                ADMIN: {
                    SIDEBAR_BUTTON_CLICKED: 'view.admin.sidebarButtonClicked',
                    REFRESH_TABLE: 'view.admin.refreshTable',
                    USER_EDIT_BUTTON_CLICKED: 'view.admin.userEditButtonClicked',
                    USER_DETAILS_LOADING: 'view.admin.userDetailsLoading',
                    SUBMIT_USER_CHANGES: 'view.admin.submitUserChanges',
                    QUESTION_CATALOG_EDIT_BUTTON_CLICKED: 'view.admin.questionCatalogEditButtonClicked',
                    QUESTION_CATALOG_ADD_BUTTON_CLICKED: 'view.admin.questionCatalogAddButtonClicked',
                    QUESTION_CATALOG_DELETE_BUTTON_CLICKED: 'view.admin.questionCatalogDeleteButtonClicked',
                    QUESTION_CATALOG_DUPLICATE_BUTTON_CLICKED: 'view.admin.questionCatalogDuplicateButtonClicked',
                    QUESTION_CATALOG_DETAILS_LOADING: 'view.admin.questionCatalogDetailsLoading',
                    QUESTION_CATALOG_EDITED: 'view.admin.questionCatalogEdited',
                    LOCATION_EDIT_BUTTON_CLICKED: 'view.admin.locationEditButtonClicked',
                    LOCATION_DETAILS_LOADING: 'view.admin.locationDetailsLoading',
                    LOCATION_EDITED: 'view.user.locationEdited',
                },
                USER: {
                    REFRESH_TABLE: 'view.user.refreshTable',
                    SIDEBAR_BUTTON_CLICKED: 'view.user.sidebarButtonClicked',
                    REPORT_EDIT_BUTTON_CLICKED: 'view.user.reportEditButtonClicked',
                    REPORT_ADD_BUTTON_CLICKED: 'view.user.reportAddButtonClicked',
                    REPORT_DELETE_BUTTON_CLICKED: 'view.user.reportDeleteButtonClicked',
                    REPORT_DUPLICATE_BUTTON_CLICKED: 'view.user.reportDuplicateButtonClicked',
                    REPORT_DETAILS_LOADING: 'view.user.reportDetailsLoading',
                    REPORT_EDITED: 'view.user.reportEdited',
                },
                CENTRAL: {
                    SIDEBAR_BUTTON_CLICKED: 'view.central.sidebarButtonClicked',
                    REFRESH_TABLE: 'view.central.refreshTable',
                },
            }
        };
    },
});
