import { UserRoles } from '@/enums/user-roles';
import { UserActions } from '@/enums/user-actions';

export type PermissionMatrix = Partial<Record<UserRoles, UserActions[]>>;

export const staticPermissionMatrix: PermissionMatrix = {
    'Admin': [
        UserActions.ManageCatalogueTypeAccess,
        UserActions.ManageLocationAccess,
        UserActions.AddNewCatalogue,
        UserActions.TestReleaseCatalogue,
        UserActions.FinalReleaseCatalogue,
        UserActions.CreateTestReport,
        UserActions.ReportStatusOverview,
        UserActions.AccessToReports,
        UserActions.AccessToAverageReports,
        UserActions.Export,
        UserActions.CreateReport,
        UserActions.EditReport,
        UserActions.ReleaseReport,
        UserActions.ApproveReport,
        UserActions.DeleteReport,
        UserActions.SetReleasedReportBackToEditMode,
        UserActions.SetApprovedReportBackToEditMode,
    ],
    'Controller': [
        UserActions.ManageCatalogueTypeAccess,
        UserActions.ManageLocationAccess,
        UserActions.AddNewCatalogue,
        UserActions.TestReleaseCatalogue,
        UserActions.FinalReleaseCatalogue,
        UserActions.ReportStatusOverview,
        UserActions.AccessToReports,
        UserActions.AccessToAverageReports,
        UserActions.Export,
    ],
    'StandardUser': [
        UserActions.ReportStatusOverview,
        UserActions.AccessToReports,
        UserActions.CreateReport,
        UserActions.EditReport,
        UserActions.ReleaseReport,
        UserActions.ApproveReport,
        UserActions.DeleteReport,
        UserActions.SetReleasedReportBackToEditMode,
    ],
    'DataProvider': [
        UserActions.CreateReport,
        UserActions.EditReport,
    ]
}
