export const WAITING_MODAL_DEFAULT_TITLE = 'waiting.title';
export const WAITING_MODAL_DEFAULT_TEXT = 'waiting.content';

export interface WaitingModalState {
    header: string;
    content: string;
    isWaiting: boolean;
}

export const WAITING_MODAL_OPEN_STRING = 'waitingModal/WAITING';
export const WAITING_MODAL_CLOSE_STRING = 'waitingModal/CLOSE_AND_RESET';

export const waitingModal = {
    namespaced: true,
    state: (): WaitingModalState => ({
        header: '',
        content: '',
        isWaiting: false,
    }),
    getters: {
        header: (state: WaitingModalState): string => state.header,
        content: (state: WaitingModalState): string => state.content,
        isWaiting: (state: WaitingModalState): boolean => state.isWaiting,
    },
    actions: {
    },
    mutations: {
        WAITING (state: WaitingModalState, waitingModalArgs: { title: string; content: string }): void {
            if (waitingModalArgs) {
                state.header = waitingModalArgs.title;
                state.content = waitingModalArgs.content;
            } else {
                state.header = '';
                state.content = '';
            }
            state.isWaiting = !state.isWaiting;
        },
        CLOSE_AND_RESET (state: WaitingModalState): void {
            state.header = '';
            state.content= '';
            state.isWaiting = false;
        },
    }
};
