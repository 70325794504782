import _ from 'lodash';
import { isEqualLight } from '@/utils/javascript-utils';
import Vue from 'vue';
import { QuestionCatalogSortColumn, ReportSortColumn, SortType } from '@/service-proxies/service-proxies.g';
import { QuestionCatalogListEndpointRequest, ReportListEndpointRequest } from '@/models';

export const filterChangedHandler = <TFilter extends object>(filters: TFilter, defaultFilter: TFilter, targetFilter: TFilter): void => {
    const temp = _.cloneDeep(defaultFilter);
    let change = false;
    for (const k of Object.keys(temp)) {
        const key = k as keyof TFilter;
        const equalValue = isEqualLight(targetFilter[key], filters[key])
        if (!equalValue && key !== 'page') {
            change = true;
            break;
        }
    }
    for (const [k, v] of Object.entries(temp)) {
        Vue.set(targetFilter, k, v);
    }
    for (const [k, v] of Object.entries(filters)) {
        Vue.set(targetFilter, k, v);
    }

    if (change) {
        Vue.set(targetFilter, 'page', 1);
    }
}

export const QUESTIONNAIRE_DEFAULT_FILTER: QuestionCatalogListEndpointRequest = {
    sortDirection: SortType.Descending,
    sortProperty: QuestionCatalogSortColumn.Year,
    questionCatalogIds: undefined,
    catalogTypeIds: undefined,
    description: undefined,
    reportYear: undefined,
    catalogVersion: undefined,
    questionCatalogStatus: undefined,
    page: 1,
    size: 25,
    Term: undefined,
    TermToLower: undefined
};

export const REPORT_DEFAULT_FILTER: ReportListEndpointRequest = {
    ids: undefined,
    questionCatalogIds: undefined,
    catalogTypeIds: undefined,
    reportTypeIds: undefined,
    reportYears: undefined,
    locationIds: undefined,
    languageIds: undefined,
    approvalStatus: undefined,
    sortDirection: SortType.Descending,
    sortProperty: ReportSortColumn.Description,
    page: 1,
    size: undefined,
    Term: undefined,
    TermToLower: undefined
};
