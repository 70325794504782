import Vue from 'vue';
import { EventBus } from '@/utils';
import UserAdministration from '../../components/user-administration/user-administration.vue';
import QuestionnaireAdministration from '@/components/questionnaire-administration/questionnaire-administration.vue';
import UserDetails from '../../components/user-administration/user-details/user-details.vue';
import { UserRoles } from '@/enums/user-roles';
import { ErrorLoaderIcons } from '@/enums/pebble-ui-enums';
import LocationAdministration from '@/components/location-administration/location-administration.vue';
import ReportOverview from '@/components/report-overview/report-overview.vue';

const AdminView = Vue.extend({
    name: 'AdminView',
    components: {
        UserAdministration,
        UserDetails,
        QuestionnaireAdministration,
        LocationAdministration,
        ReportOverview,
    },
    data (): {
        sidebarOptionsList: Array<{ displayValue: string; componentName: string; disabled?: boolean; settingsType?: string }>;
        selectedComponentName: string;
        } {
        return {
            sidebarOptionsList: [],
            selectedComponentName: '',
        }
    },
    async mounted (): Promise<void> {
        this.fillSidebarOptionsList();
        this.registerCallbacks();
        await this.showAdminSidebar();
        if (this.unauthorized) {
            this.$store.commit('sidebar/SET_IF_SHOW_SIDEBAR', false);
        }
    },
    computed: {
        selectedComponentProperties (): { componentName?: string; componentDisplayValue?: string; preloadedReportId?: number; showOnlyTestReports?: boolean } {
            const selectedListItem = this.sidebarOptionsList.find(x => x.componentName === this.selectedComponentName);

            // special case for reportOverview
            if (this.selectedComponentName === 'reportOverview') {
                return {
                    componentName: this.selectedComponentName,
                    componentDisplayValue: selectedListItem?.displayValue ?? '',
                    ...(selectedListItem?.settingsType) && { settingsType: selectedListItem.settingsType },
                    preloadedReportId: undefined,
                    showOnlyTestReports: true,
                }
            }

            return {
                componentName: this.selectedComponentName,
                componentDisplayValue: selectedListItem?.displayValue ?? '',
                ...(selectedListItem?.settingsType) && { settingsType: selectedListItem.settingsType },
            }
        },
        userRole (): string {
            return this.$store.getters['userAccessManagement/getUserRole'];
        },
        unauthorized (): boolean {
            return this.userRole !== UserRoles.Admin;
        },
        errorOccured (): boolean {
            return this.unauthorized;
        },
        errorLoaderIcon (): string {
            if (this.unauthorized) {
                return ErrorLoaderIcons.Alert;
            }

            return ErrorLoaderIcons.EmptyData;
        },
        errorLoaderLabels (): { title: string; message: string } {
            if (this.unauthorized) {
                return {
                    title: this.$t('monitoring.unauthorizedErrorTitle').toString(),
                    message: this.$t('monitoring.unauthorizedErrorMessage').toString(),
                }
            }

            return {
                title: this.$t('monitoring.errorTitle').toString(),
                message: this.$t('monitoring.errorMessage').toString(),
            }
        },
    },
    methods: {
        registerCallbacks (): void {
            EventBus.$on(EventBus.VIEWS.ADMIN.SIDEBAR_BUTTON_CLICKED,
                (event: { componentName: string }) => this.selectedComponentName = event.componentName
            );
        },
        fillSidebarOptionsList (): void {
            this.sidebarOptionsList = [
                {
                    displayValue: this.$t('adminView.sidepanel.options.userManagement').toString(),
                    componentName: 'userAdministration',
                    disabled: false,
                },
                {
                    displayValue: this.$t('adminView.sidepanel.options.locationDefinition').toString(),
                    componentName: 'locationAdministration',
                    disabled: false,
                },
                {
                    displayValue: this.$t('adminView.sidepanel.options.questionnaires').toString(),
                    componentName: 'questionnaireAdministration',
                    disabled: false,
                },
                {
                    displayValue: this.$t('adminView.sidepanel.options.createTestReport').toString(),
                    componentName: 'reportOverview',
                    disabled: false,
                },
            ];
        },
        async showAdminSidebar (): Promise<void> {
            await this.$store.dispatch('sidebar/setCurrentSidebar',
                {
                    shouldShowSidebar: true,
                    currentSidebarComponent: 'SidebarMultiOption',
                    sidebarComponentProperties: {
                        optionsList: this.sidebarOptionsList,
                        eventName: EventBus.VIEWS.ADMIN.SIDEBAR_BUTTON_CLICKED,
                    }
                });

            // Hack to disable the padding of the sidepanel here
            await this.$nextTick();
            const sidepanel = document.querySelector('.pui-side-panel__main--padding');
            if (sidepanel) {
                (sidepanel as any).style.padding = 0;
                await this.$store.dispatch('sidebar/setCurrentSidebarWidth', '25rem');
            }
        }
    }
});

export default AdminView;
