import { CatalogTypeListEndpointResponseOkResponse, CatalogTypesServiceProxy } from '@/service-proxies/service-proxies.g';
import { CatalogTypeListEndpointRequest } from '@/models';

/**
 * @name CatalogTypeService
 * @description This class is used to query the backend server for catalogType data
 */
export class CatalogTypeService {
    private catalogTypeServiceProxy: CatalogTypesServiceProxy;

    constructor() {
        this.catalogTypeServiceProxy = new CatalogTypesServiceProxy();
    }

    /**
     * Get filtered list of catalogTypes
     * @param filter CatalogType Filter
     */
    getCatalogTypes(filter: CatalogTypeListEndpointRequest): Promise<CatalogTypeListEndpointResponseOkResponse> {
        return this.catalogTypeServiceProxy.listCatalogTypes(
            filter?.sortDirection,
            filter?.sortProperty,
            filter?.catalogTypeAbbrs,
            filter?.page,
            filter?.size,
            filter?.term,
            filter?.termToLower
        )
    }
}
