import { riskPresent } from '@/utils/composables';
import { FlatQuestion } from './../models/reports';
import { ReportValidation } from '@/models';
import {
    MainQuestionDto,
    QuestionCatalogDto,
    QuestionGroupDto,
    ReportDto,
    ReportRowDto,
    ReportRowReply,
    ReportRowType,
    RiskRating,
    SubQuestionDto,
    SubQuestionType
} from './../service-proxies/service-proxies.g';

/**
 * Validates if the report is submittable by checking if there are unanswered questions and returns those.
 * @param { ReportDto } report The report to be validated.
 * @return { ReportValidation } Contains the questions that need to be answered if !valid else the arr is empty.
 */
/* eslint-disable-next-line sonarjs/cognitive-complexity */
export const reportValidator = (report: ReportDto, questionCatalog: QuestionCatalogDto): ReportValidation => {
    const result = {
        valid: true,
        openQuestions: [] as FlatQuestion[]
    };

    const questionGroups = questionCatalog.questionGroups;

    if (!questionGroups) {
        return result;
    }

    questionGroups.forEach((group: QuestionGroupDto) => {
        const mainQuestionCandidates = group.mainQuestions?.filter((mainQuestion: MainQuestionDto) => {
            if (report.reportRows) {
                const matchingReportRow = report.reportRows.find((row: ReportRowDto) => row.reportRowType === ReportRowType.MainQuestion && row.referenceId === mainQuestion.id);
                const isRisk = matchingReportRow ? riskPresent(mainQuestion, matchingReportRow) : false;

                if (matchingReportRow?.replyYN === ReportRowReply.NotYetAnswered 
                    || (matchingReportRow?.riskRating === RiskRating.Undefined && matchingReportRow?.replyYN !== ReportRowReply.NotApplicable)
                    || (isRisk && (!matchingReportRow?.remedy || !matchingReportRow?.remedyResponsible))
                ) {
                    result.openQuestions.push({
                        id: mainQuestion.id || -1,
                        group: group.description || '',
                        type: ReportRowType[ReportRowType.MainQuestion],
                        text: group.position+'.'+mainQuestion.position + '.' + mainQuestion.text || '',
                        risk: matchingReportRow?.riskRating,
                        reply: matchingReportRow?.replyYN,
                        remedy: matchingReportRow?.remedy,
                        remedyResponsible: matchingReportRow?.remedyResponsible,
                    });
                }
                return isRisk;
            }
        });

        mainQuestionCandidates?.forEach((mainQuestion: MainQuestionDto) => {
            if (mainQuestion.subQuestions && mainQuestion.subQuestions.length > 0) {
                mainQuestion.subQuestions.forEach((subQuestion: SubQuestionDto) => {
                    if (report.reportRows) {
                        const matchingReportRow = report.reportRows.find((row: ReportRowDto) => row.reportRowType === ReportRowType.SubQuestion && row.referenceId === subQuestion.id);
                        const isListType = subQuestion.subQuestionType === SubQuestionType.List;

                        if (matchingReportRow?.replyYN === ReportRowReply.NotYetAnswered 
                            || (isListType && !matchingReportRow?.reportRowListEntries?.some(entry => entry.dueDate && entry.listing))
                        ) {
                            result.openQuestions.push({
                                id: subQuestion.id || -1,
                                group: group.description || '',
                                type: ReportRowType[ReportRowType.SubQuestion],
                                text: group.position+'.'+mainQuestion.position + '.' + subQuestion.position + '.' + subQuestion.text || '',
                            });
                        }
                    }
                });
            }
        })
    })

    if (result.openQuestions.length > 0) {
        result.valid = false;
    }

    // result.openQuestions.sort((a, b) => {
    //     const positionA = a.text.split('.').slice(0, 3).join('.');
    //     const positionB = b.text.split('.').slice(0, 3).join('.');
    //     return positionA.localeCompare(positionB, undefined, { numeric: true });
    // });
    result.openQuestions.sort((a, b) => {
        const positionA = a.text.split('.').map(Number);
        const positionB = b.text.split('.').map(Number);

        for (let i = 0; i < Math.max(positionA.length, positionB.length); i++) {
            if (positionA[i] === undefined) return -1; // a is shorter and should come first
            if (positionB[i] === undefined) return 1; // b is shorter and should come first
            if (positionA[i] !== positionB[i]) return positionA[i] - positionB[i]; // Compare numerically
        }
        return 0;
    });
    return result;
}
