import {
    CatalogTypeDto,
    UserDto,
    UserGetEndpointResponse,
    QuestionGroupDto,
    UserEditEndpointResponse,
    QuestionCatalogDto,
    QuestionCatalogAddEndpointResponse,
    QuestionCatalogGetEndpointResponse,
    QuestionCatalogEditEndpointResponse,
    SubQuestionType
} from '@/service-proxies/service-proxies.g'
import { UserRoles } from '@/enums/user-roles';
import _ from 'lodash';

declare module '@/service-proxies/service-proxies.g' {
    export interface CatalogTypeDto {
        getDescription(lang: string): string;
    }

    export interface UserDto {
        getUserRole(useCaseId: number): string;
    }

    export interface UserGetEndpointResponse  {
        getUserRole(useCaseId: number): string;
    }

    export interface UserEditEndpointResponse  {
        getUserRole(useCaseId: number): string;
    }

    export interface QuestionGroupDto  {
        getSumWeighting(): number;
    }

    export interface QuestionCatalogDto {
        replaceEnums(): void;
    }

    export interface QuestionCatalogGetEndpointResponse {
        replaceEnums(): void;
    }

    export interface QuestionCatalogAddEndpointResponse {
        replaceEnums(): void;
    }

    export interface QuestionCatalogEditEndpointResponse {
        replaceEnums(): void;
    }
}

CatalogTypeDto.prototype.getDescription = function (lang: string): string {
    const _self = this as CatalogTypeDto;
    const desc = _self.catalogTypeDescriptions?.filter(x => x.language?.languageAbbr === lang)[0];
    return desc?.description ?? '';
}

const getUserRole = (_self: UserDto | UserGetEndpointResponse | UserEditEndpointResponse, useCaseId: number): UserRoles => {
    const role = _self.userGroupTypes?.filter(x => x.useCaseId === useCaseId).map(x => x.name)[0] as string;
    return _.startCase(_.camelCase(role)).replace(/ /g, '') as UserRoles;
}

UserDto.prototype.getUserRole = function (useCaseId: number): UserRoles {
    const _self = this as UserDto;
    return getUserRole(_self, useCaseId);
}

UserGetEndpointResponse.prototype.getUserRole = function (useCaseId: number): UserRoles {
    const _self = this as UserGetEndpointResponse;
    return getUserRole(_self, useCaseId);
}

UserEditEndpointResponse.prototype.getUserRole = function (useCaseId: number): UserRoles {
    const _self = this as UserEditEndpointResponse;
    return getUserRole(_self, useCaseId);
}

QuestionGroupDto.prototype.getSumWeighting = function (): number {
    const _self = this as QuestionGroupDto;
    return _self.mainQuestions?.reduce((x, y) => x + (y.weight ?? 0), 0) ?? 0;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const replaceEnums = (_self: QuestionCatalogDto | QuestionCatalogAddEndpointResponse | QuestionCatalogAddEndpointResponse | QuestionCatalogEditEndpointResponse): void => {
    // Sub-Question type enum
    for(const group of _self.questionGroups ?? []) {
        for (const mq of group.mainQuestions ?? []) {
            for (const sq of mq.subQuestions ?? []) {
                if (typeof sq.subQuestionType === 'string') {
                    sq.subQuestionType = sq.subQuestionType ? SubQuestionType[sq.subQuestionType] as unknown as SubQuestionType : sq.subQuestionType;
                }
            }
        }
    }
}

QuestionCatalogDto.prototype.replaceEnums = function (): void {
    const _self = this as QuestionCatalogDto;
    replaceEnums(_self);
}

QuestionCatalogAddEndpointResponse.prototype.replaceEnums = function (): void {
    const _self = this as QuestionCatalogAddEndpointResponse;
    replaceEnums(_self);
}

QuestionCatalogGetEndpointResponse.prototype.replaceEnums = function (): void {
    const _self = this as QuestionCatalogAddEndpointResponse;
    replaceEnums(_self);
}

QuestionCatalogEditEndpointResponse.prototype.replaceEnums = function (): void {
    const _self = this as QuestionCatalogEditEndpointResponse;
    replaceEnums(_self);
}
