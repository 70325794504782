import Vue from 'vue';
import moment from 'moment';
import { toShortIsoString } from '@/utils/date-time-utils';

const Datetime = Vue.extend({
    name: 'Datetime',
    props: {
        daysOnly: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        isReadonly: {
            type: Boolean,
            default: false
        },
        useUtc: {
            type: Boolean,
            default: true
        },
        input: {
            type: String,
            default: undefined
        },
    },
    data (): {
        value: string | undefined;
        } {
        return {
            value: undefined,
        }
    },
    created() {
        this.value = this.initValue(this.input, this.daysOnly, this.useUtc);
    },
    watch: {
        value: {
            handler (newVal: string): void {
                let val = '';
                if (newVal) {
                    val = moment(newVal).toISOString(!this.useUtc);
                } else {
                    val = moment(this.value).toISOString(!this.useUtc);
                }
                this.$emit('update:input', val ? val : '');
            }
        },
    },
    computed: {
        inputClass (): object {
            return {
                'pui-form-type-ahead-input-single-select--disabled': this.isDisabled,
                'pui-form-input-field--readonly': this.isReadonly
            }
        },
        inputType(): string {
            return this.daysOnly ? 'date' : 'datetime-local';
        },
    },
    methods: {
        handleUpdate (dateOrDatetime: string): void {
            this.value = toShortIsoString(dateOrDatetime, this.daysOnly, dateOrDatetime[dateOrDatetime.length - 1] === 'Z');
        },
        handleNativeInput($event: any): void {
            this.handleUpdate($event.target.value);
        },
        initValue(input: string, ignoreTime: boolean, useUtc: boolean): string | undefined {
            if (input && input !== '' && input !== '0') {
                return toShortIsoString(input, ignoreTime, useUtc);
            }
            return undefined;
        }
    }
});
export default Datetime;
