import Vue from 'vue';
import QuestionnaireExport from '@/components/exporter/partials/questionnaire-export.vue';

const Exporter = Vue.extend({
    name: 'Exporter',
    components: {
        QuestionnaireExport
    },
    props: {
    },
    data (): {
        selectedTab: number;
        } {
        return {
            selectedTab: 0,
        }
    },
    methods: {
        onTabChanged(newTabIndex: number): void {
            this.selectedTab = newTabIndex;
        }
    }
});
export default Exporter;
