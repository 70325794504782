
import { ReportValidation } from '@/models';
import { FlatQuestion } from '@/models/reports';
import { QuestionCatalogDto, ReportDto, ReportRowReply, RiskRating } from '@/service-proxies/service-proxies.g';
import { reportValidator } from '@/utils';
import Vue, { PropType } from 'vue';
import _ from 'lodash';

export default Vue.extend({
    props: {
        report: {
            type: Object as PropType<ReportDto>,
            required: true
        },
        questionCatalog: {
            type: Object as PropType<QuestionCatalogDto>,
            required: true
        }
    },
    data(): {
        reportValidation: ReportValidation;
        internalReport: ReportDto | undefined;
        } {
        return {
            reportValidation: {} as ReportValidation,
            internalReport: undefined,
        }
    },
    created() {
        this.internalReport = _.cloneDeep(this.report);
        this.reportValidation = reportValidator(this.internalReport, this.questionCatalog);
    },
    computed: {
        isValid(): boolean {
            return this.reportValidation.valid;
        },
        openQuestions(): FlatQuestion[] {
            return this.reportValidation.openQuestions;
        },
        questionCount(): number {
            return this.reportValidation.openQuestions.length;
        },
        reportIsSubmittable(): boolean {
            return this.questionCount === 0;
        },
        lightboxHeaderTitle(): string {
            return this.$t('reportConfirmationTitle').toString();
        }
    },
    watch: {
        report (newReport, _): void {
            this.reportValidation = reportValidator(newReport, this.questionCatalog);
        }
    },
    methods: {
        getRejectionReason (question: FlatQuestion): string {
            if (question?.risk === RiskRating.Undefined 
                && question?.reply !== ReportRowReply.NotYetAnswered) 
            {
                return this.$t('answeredButNoRisk').toString();
            }

            if (question?.risk !== RiskRating.Undefined 
                && question.type === 'MainQuestion'
                && (!!question.remedy || !!question.remedyResponsible)) 
            {
                return this.$t('answeredButNoRemedy').toString();
            }

            return this.$t('unanswered').toString();
        },
        redirectToQuestion(q: FlatQuestion, questionString: any, question: FlatQuestion): void {
            this.$emit('onQuestionRedirect', q, questionString, question);
            this.toggleModal();
        },
        toggleModal(internalReport: ReportDto | undefined = undefined): void {
            try {
                if (internalReport) {
                    this.internalReport = internalReport;
                    this.reportValidation = reportValidator(internalReport, this.questionCatalog);
                }
                (this.$refs['reportConfirmationModal'] as any).isOpen = !((this.$refs['reportConfirmationModal'] as any).isOpen);
            } catch (err) {
                // Catching buggy pebble ui type error
                // console.log(err);
            }
        },
        buildOpenQuestionString(question: FlatQuestion): string {
            return `${this.$t('pdfCreation.question')}: ${question.text} | ${this.$t('pdfCreation.questionGroup')}: ${question.group} | ${this.$t('export.questionType')}: ${this.$t('reportRowType.' + question.type).toString()}`;
        }
    }
})
