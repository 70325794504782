import { UserListEndpointRequest } from '@/models';
import {
    IUserEditEndpointRequest,
    UserEditEndpointRequest,
    UserEditEndpointResponseOkResponse, UserGetEndpointResponseOkResponse,
    UserListEndpointResponseOkResponse, UserRoleGetEndpointResponseOkResponse,
    UsersServiceProxy
} from '@/service-proxies/service-proxies.g';

/**
 * @name UserService
 * @description This class is used to query the backend server for user data
 */
export class UserService {
    private userServiceProxy: UsersServiceProxy;

    constructor() {
        this.userServiceProxy = new UsersServiceProxy();
    }

    /**
     * Get the role/user group type of a user.
     * @param userId User Id
     */
    public getUserRoleById = async (userId: number): Promise<UserRoleGetEndpointResponseOkResponse> => {
        return this.userServiceProxy.getUserRole(userId);
    }

    /**
     * Edit the permissions of the user.
     * @param editRequest Edit Request.
     */
    public editUser = async (editRequest: IUserEditEndpointRequest): Promise<UserEditEndpointResponseOkResponse> => {
        return this.userServiceProxy.editUser(new UserEditEndpointRequest(editRequest));
    }

    /**
     * Get filtered list of users.
     * @param filter User Filter.
     */
    public getUsers = async (filter: UserListEndpointRequest | undefined = undefined): Promise<UserListEndpointResponseOkResponse> => {
        return this.userServiceProxy.listUser(
            filter?.SortColumn,
            filter?.SortDirection,
            filter?.UserIds,
            filter?.UserKIDs,
            filter?.LocationIds,
            filter?.CatalogTypes,
            filter?.page,
            filter?.size,
            filter?.Term,
            filter?.TermToLower,
        );
    }

    /**
     * Get filtered list of users (proxy function).
     * @param filter User Filter.
     */
    public getItems = async (filter: UserListEndpointRequest | undefined = undefined): Promise<UserListEndpointResponseOkResponse> => {
        return this.getUsers(filter);
    }

    /**
     * Get a single user identified by id.
     * @param id
     */
    public getUser = async (id: number): Promise<UserGetEndpointResponseOkResponse> => {
        return this.userServiceProxy.getUser(id);
    }
}
