/* eslint-disable vue/require-default-prop */
import Vue, { PropType } from 'vue';
import { ColumnFilter, FilterChangedEvent, VendorOptions } from '../table/contracts/table-data';
import CustomAgGridTable from '../table/custom-ag-grid-table.vue'
import _ from 'lodash'

let uuid = 0;
const TableFiltered = Vue.extend({
    name: 'TableFiltered',
    components: {
        CustomAgGridTable,
    },
    props: {
        constructTableCallback: {
            type: Function as PropType<() => void>,
            required: true,
        },
        setFilterValuesCallback: {
            type: Function as PropType<(size: number) => void>,
            required: false,
        },
        filter: {
            type: Object as PropType<any>,
            required: true,
        },
        vendorOptions: {
            type: Object as PropType<VendorOptions<any>>,
            required: true,
        },
        tableName: {
            type: String,
            required: true,
        },
        agGridFilter: {
            type: Object as PropType<Record<string, ColumnFilter>>,
            required: false,
            default: undefined
        }
    },
    data (): {
        isLoading: boolean;
        oldFilter: any;
        uuid: string;
        overlayLoadingTemplate: string;
        overlayNoRowsTemplate: string;
        } {
        return {
            isLoading: false,
            oldFilter: undefined,
            uuid: '',
            overlayLoadingTemplate: `<span class="ag-overlay-loading-center">${this.$t('waitingForRows')}</span>`,
            overlayNoRowsTemplate: 'overlayNoRowsTemplate',
        }
    },
    created (): void {
        this.uuid = 'EventTableFiltered_' + uuid.toString();
        uuid += 1;
    },
    computed: {
    },
    watch: {
        filter: {
            async handler (newVal: Record<string, any>): Promise<void> {
                if (!_.isEqual(newVal, this.oldFilter)) {
                    // using the oldVal from the handler does not work for some reason
                    // Therefore we are saving the "old" value manually here
                    this.oldFilter = { ...newVal }
                    await this.onFilterChange();
                }
            },
            immediate: false,
            deep: true,
        },
        isLoading: {
            handler (newVal: boolean): void {
                if (newVal) {
                    this.showLoadingOverlay();
                }
                else {
                    this.hideOverlay();
                }
            }
        }
    },
    methods: {
        async onFilterChange (): Promise<void> {
            this.showLoadingOverlay();
            await this.constructTableCallback();
            // if (this.agGridFilter) {
            //     (this.$refs[this.uuid] as any).gridApi?.setFilterModel(this.agGridFilter);
            // }
            this.hideOverlay();
        },
        setLoading (loading: boolean): void {
            this.isLoading = loading;
        },
        showLoadingOverlay (): void {
            (this.$refs[this.uuid] as any).gridApi?.showLoadingOverlay();
        },
        hideOverlay (): void {
            (this.$refs[this.uuid] as any).gridApi?.hideOverlay();
        },
        onSortChanged (sortChangedEvent: any): void {
            this.$emit('sortChanged', sortChangedEvent);
        },
        onFilterChanged (filterChangedEvent: FilterChangedEvent): void {
            // const filters = filterChangedEvent.api.getFilterModel();
            // if (!_.isEqual(filters, this.agGridFilter)) {
            this.$emit('filterChanged', filterChangedEvent);
            // }
        },
        autoSizeAllColumns (skipHeaders: boolean): void {
            (this.$refs[this.uuid] as any).autoSizeAllColumns(skipHeaders);
        },
        sizeColumnsToFit (): void {
            (this.$refs[this.uuid] as any).sizeColumnsToFit();
        }
    }
})

export default TableFiltered;
