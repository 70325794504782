
import Vue, { PropType } from 'vue';
import {
    QuestionGroupDto,
    SubQuestionDto,
    SubQuestionType,
} from '@/service-proxies/service-proxies.g';
import _ from 'lodash';
import { FormSelectOption } from '@/models/pebble-ui';
import AutoCorrectFormInput from '@/components/auto-correct-form-input/auto-correct-form-input.vue';
import { Validation } from '@/components/questionnaire-administration/questionnaire-details/contracts/validation';
import { enumKeys } from '@/utils/javascript-utils';
const SubQuestionForm = Vue.extend({
    name: 'SubQuestionForm',
    components: {AutoCorrectFormInput},
    props: {
        value: {
            type: Object as PropType<SubQuestionDto>,
            required: true,
        },
        totalLength: {
            type: Number,
            required: true,
        },
        isEditable: {
            type: Boolean,
            default: true,
        }
    },
    data (): {
        internalSubQuestion: SubQuestionDto;
        validations: Record<string, Validation>;
        minLength: number;
        } {
        return {
            internalSubQuestion: _.cloneDeep(this.value),
            validations: {
                'text': {
                    maxLength: 500,
                    minLength: 2,
                    isValid: true,
                    helperText: '',
                }
            },
            minLength: 2
        }
    },
    watch: {
        value: {
            handler (newVal: QuestionGroupDto): void {
                this.internalSubQuestion = _.cloneDeep(newVal);
            },
            deep: true,
        }
    },
    methods: {
        modifyAndEmitChange (obj: any, prop: string, value: any): void {
            Vue.set(obj, prop, value);
            this.$emit('input', this.internalSubQuestion);
        }
    },
    computed: {
        minLengthString(): string {
            if(this.validations.text.minLength){
                return (this.validations.text.minLength + 1).toString()
            } else {
                return ''
            }
        },
        subQuestionTypeOptions (): FormSelectOption[] {
            return enumKeys(SubQuestionType).map(x => {
                return {
                    label: this.$t(`subQuestionType.${SubQuestionType[SubQuestionType[x]]}`).toString(),
                    value: Number(SubQuestionType[x]),
                }
            })
        },
        isModified (): boolean {
            return !_.isEqual(this.value, this.internalSubQuestion);
        }
    }
});
export default SubQuestionForm;
