import { render, staticRenderFns } from "./warning-modal.vue?vue&type=template&id=5ffa62f2&scoped=true"
import script from "./warning-modal.ts?vue&type=script&lang=ts&external"
export * from "./warning-modal.ts?vue&type=script&lang=ts&external"
import style0 from "./warning-modal.less?vue&type=style&index=0&id=5ffa62f2&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ffa62f2",
  null
  
)

export default component.exports