import {
    CatalogTypeAddEndpointRequest,
    CatalogTypesServiceProxy, IQuestionCatalogAddEndpointRequest,
    LanguageAddEndpointRequest,
    LanguagesServiceProxy, MainQuestionAddDto,
    QuestionCatalogAddEndpointRequest,
    QuestionCatalogsServiceProxy, QuestionGroupAddDto, SubQuestionAddDto,
} from '@/service-proxies/service-proxies.g';

/**
 * @name DataSeedingService
 * @description This class is used to fill the backend/database with initial seeding data
 */
export class DataSeedingService {
    private catalogTypeServiceProxy: CatalogTypesServiceProxy;
    private languageServiceProxy: LanguagesServiceProxy;
    private questionCatalogServiceProxy: QuestionCatalogsServiceProxy;

    constructor() {
        this.catalogTypeServiceProxy = new CatalogTypesServiceProxy();
        this.languageServiceProxy = new LanguagesServiceProxy();
        this.questionCatalogServiceProxy = new QuestionCatalogsServiceProxy();
    }

    async addCatalogTypes(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const jsonData = require('./seed-data/catalogTypes.json');
        for(const el of jsonData.items) {
            await this.catalogTypeServiceProxy.addCatalogType(
                new CatalogTypeAddEndpointRequest(
                    {
                        catalogTypeAbbr: el.catalogTypeAbbr as string,
                        // catalogTypeDesc: el.catalogTypeDesc as string,
                    }));
        }
    }

    async addLanguages(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const jsonData = require('./seed-data/languages.json');
        for(const el of jsonData.items) {
            await this.languageServiceProxy.addLanguage(
                new LanguageAddEndpointRequest(
                    {
                        languageAbbr: el.languageAbbr as string,
                        languageName: el.languageName as string,
                    }));
        }
    }

    async addQuestionCatalogs(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const jsonData = require('./seed-data/questionCatalogs.json') as { items: IQuestionCatalogAddEndpointRequest[] };
        for(const el of jsonData.items) {
            const mappedDto = this.recordToQuestionCatalogAddEndpointRequest(el)
            await this.questionCatalogServiceProxy.addQuestionCatalog(mappedDto);
        }
    }

    recordToQuestionCatalogAddEndpointRequest (item: Record<string, any>): QuestionCatalogAddEndpointRequest {
        for(const [key, value] of Object.entries(item)) {
            if (key === 'questionGroups' && Array.isArray(value) && value.length > 0) {
                item[key] = value.map(qg => this.recordToQuestionGroupAddDto(qg));
            }
        }

        return new QuestionCatalogAddEndpointRequest(item);
    }

    recordToQuestionGroupAddDto (item: Record<string, any>): QuestionGroupAddDto {
        for(const [key, value] of Object.entries(item)) {
            if (key === 'mainQuestions' && Array.isArray(value) && value.length > 0) {
                item[key] = value.map(qg => this.recordToMainQuestionAddDto(qg));
            }
        }

        return new QuestionGroupAddDto(item);
    }

    recordToMainQuestionAddDto (item: Record<string, any>): MainQuestionAddDto {
        for(const [key, value] of Object.entries(item)) {
            if (key === 'subQuestions' && Array.isArray(value) && value.length > 0) {
                item[key] = value.map(qg => this.recordToSubQuestionAddDto(qg));
            }
        }

        return new MainQuestionAddDto(item);
    }

    recordToSubQuestionAddDto (item: Record<string, any>): SubQuestionAddDto {
        return new SubQuestionAddDto(item);
    }
}
