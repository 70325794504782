
import Vue, { PropType } from 'vue';
import {
    MainQuestionDto,
    QuestionCatalogDto,
    QuestionGroupDto,
    ReportApprovalStatus,
    ReportDto,
    ReportRowDto,
    ReportRowListEntryDto,
    ReportRowReply,
    ReportRowType,
    ReportType, RiskRating,
    SubQuestionDto,
    UserDto
} from '@/service-proxies/service-proxies.g';
import ReportForm from '@/components/report-overview/report-details/sub-components/report-form.vue';
import MainQuestionAnswerForm from '@/components/report-overview/report-details/sub-components/main-question-form.vue'
import SubQuestionAnswerForm from '@/components/report-overview/report-details/sub-components/sub-question-form.vue'
import _ from 'lodash';
import { catalogAsTree, onSelectedTreeElement, riskPresent } from '@/utils/composables';
import { QuestionCatalogService } from '@/services/question-catalog-service';
import { NodeTypes } from '@/components/questionnaire-administration/questionnaire-details/contracts/enums';
import { apiCallWithErrorHandling } from '@/services/utils';
import { WAITING_MODAL_DEFAULT_TEXT, WAITING_MODAL_DEFAULT_TITLE } from '@/store/modules/waiting-modal';
import { EventBus } from '@/utils';
import { ReportService } from '@/services/report-service';
import { Tree, TreeNode, VueTreeList } from 'vue-tree-list';

const generateNewMainQuestionReportRow = (mainQuestion: MainQuestionDto, seqNo: string, riskRating: number): ReportRowDto => {
    return new ReportRowDto({
        id: 0,
        referenceId: mainQuestion.id,
        reportRowType: ReportRowType.MainQuestion,
        seqNo: seqNo,
        replyYN: ReportRowReply.NotYetAnswered,
        riskRating: riskRating,
        reportRowListEntries: [
            new ReportRowListEntryDto({
                id: 0,
                status: 0,
                position: 1
            }),
        ],
    })
}

const generateNewSubQuestionReportRow = (subQuestion: SubQuestionDto, seqNo: string, riskRating: number): ReportRowDto => {
    return new ReportRowDto({
        id: 0,
        referenceId: subQuestion.id,
        reportRowType: ReportRowType.SubQuestion,
        seqNo: seqNo,
        replyYN: ReportRowReply.NotYetAnswered,
        riskRating: riskRating,
        reportRowListEntries: _.range(0, 10).map(x => new ReportRowListEntryDto({
            id: 0,
            status: 0,
            listing: '',
            dueDate: undefined,
            position: x + 1,
        })),
    })
}

const addRowForMainQuestion = (reportRows: ReportRowDto[], mq: MainQuestionDto, qg: QuestionGroupDto, nullRiskRating: number): void => {
    // No row for this main question? Then add one
    if (reportRows.filter(x => x.reportRowType === ReportRowType.MainQuestion && x.referenceId === mq.id).length === 0) {
        reportRows.push(generateNewMainQuestionReportRow(mq, `${qg.position}.${mq.position}`, nullRiskRating));
    }
    for (const sq of mq.subQuestions ?? []) {
        // No row for this sub question? Then add one
        if (reportRows.filter(x => x.reportRowType === ReportRowType.SubQuestion && x.referenceId === sq.id).length === 0) {
            reportRows.push(generateNewSubQuestionReportRow(sq, `${qg.position}.${mq.position}.${sq.position}`, nullRiskRating));
        }
    }
}

const ReportDetails = Vue.extend({
    name: 'ReportDetails',
    components: {
        ReportForm,
        MainQuestionAnswerForm,
        SubQuestionAnswerForm,
        VueTreeList,
    },
    props: {
        report: {
            type: Object as PropType<ReportDto>,
            required: true,
        },
        saveData:  {
            type: Boolean,
            required: true,
        },
        creatorApprovalUser: {
            type: Object as PropType<UserDto>,
            default: undefined,
        },
        plantManagerApprovalUser: {
            type: Object as PropType<UserDto>,
            default: undefined,
        },
        creatorApprovalDate: {
            type: String,
            default: undefined,
        },
        plantManagerApprovalDate: {
            type: String,
            default: undefined,
        }
    },
    data (): {
        selectedTab: number;
        selectedNode: QuestionGroupDto | MainQuestionDto | SubQuestionDto | undefined;
        selectedType: NodeTypes;
        internalReport: ReportDto;
        reportApi: ReportService;
        questionCatalogApi: QuestionCatalogService;
        selectedQuestionGroup: QuestionGroupDto | undefined;
        selectedMainQuestion: MainQuestionDto | undefined;
        selectedSubQuestion: SubQuestionDto | undefined;
        internalQuestionCatalog: QuestionCatalogDto;
        nullRiskRating: number;
        currentDiv: HTMLElement | undefined;
        } {
        return {
            selectedTab: 0,
            selectedNode: undefined,
            selectedType: NodeTypes.None,
            internalReport: _.cloneDeep(this.report),
            reportApi: new ReportService(),
            questionCatalogApi: new QuestionCatalogService(),
            selectedQuestionGroup: undefined,
            selectedMainQuestion: undefined,
            selectedSubQuestion: undefined,
            internalQuestionCatalog: new QuestionCatalogDto(),
            nullRiskRating: RiskRating.Undefined,
            currentDiv: undefined,
        }
    },
    async mounted (): Promise<void> {
        // FIXME: Proper loading!
        this.internalQuestionCatalog = (await this.questionCatalogApi.getQuestionCatalog(this.internalReport.questionCatalogId ?? 0)).result as QuestionCatalogDto;

        // Empty Report? Make sure there are report Rows!
        if (!this.internalReport.reportRows) {
            Vue.set(this.internalReport, 'reportRows', []);
        }

        // And then add any missing rows
        this.addReportRows();
    },
    watch: {
        selectedTab: {
            handler (newVal: number, oldVal: number): void {
                if (Number(oldVal) === 1 && Number(newVal) !== 1) {
                    this.treeViewClosed();
                }
            }
        },
        reportModified: {
            handler(newValue: boolean): void {
                this.$emit('report-modified', newValue);
            },
            immediate: true
        },
        saveData: {
            handler (): void {
                this.verifyAndSubmit();
            },
        },
        report: {
            handler (newVal: ReportDto): void {
                this.internalReport = _.cloneDeep(newVal);
            }
        }
    },
    computed: {
        testReportMode (): boolean {
            return this.report.reportType === ReportType.TestReport;
        },
        catalogAsTree (): Tree {
            if (this.internalQuestionCatalog) {
                return catalogAsTree(this.internalQuestionCatalog, true);
            }
            return new Tree([]);
        },
        currentReportStatus (): ReportApprovalStatus {
            return this.calculateReportStatus(this.internalReport);
        },
        originalReportStatus (): ReportApprovalStatus {
            return this.calculateReportStatus(this.report);
        },
        userCanEditReport (): boolean {
            return this.$store.getters['userAccessManagement/canEditReport'](
                this.internalReport.locationId,
                this.internalReport.catalogTypeId
            );
        },
        reportInEditableState (): boolean {
            return (
                this.originalReportStatus === ReportApprovalStatus.Editable
                || this.originalReportStatus === ReportApprovalStatus.New
            );
        },
        isEditable (): boolean {
            return this.userCanEditReport && this.reportInEditableState;
        },
        currentReportRow (): ReportRowDto | undefined {
            if (this.selectedMainQuestion && this.nodeIsQuestionGroup) {
                return this.internalReport.reportRows?.filter(x => x.referenceId === this.selectedQuestionGroup?.id ?? 0)
                    .filter(x => x.reportRowType === ReportRowType.QuestionGroup)[0];
            }
            if (this.selectedMainQuestion && this.nodeIsMainQuestion) {
                return this.internalReport.reportRows?.filter(x => x.referenceId === this.selectedMainQuestion?.id ?? 0)
                    .filter(x => x.reportRowType === ReportRowType.MainQuestion)[0];
            }
            if (this.selectedMainQuestion && this.nodeIsSubQuestion) {
                return this.internalReport.reportRows?.filter(x => x.referenceId === this.selectedSubQuestion?.id ?? 0)
                    .filter(x => x.reportRowType === ReportRowType.SubQuestion)[0];
            }
            return undefined;
        },
        mainQuestionReportRow (): ReportRowDto | undefined {
            if (this.selectedMainQuestion && this.nodeIsSubQuestion) {
                return this.internalReport.reportRows?.filter(x => x.referenceId === this.selectedMainQuestion?.id ?? 0)
                    .filter(x => x.reportRowType === ReportRowType.MainQuestion)[0];
            }
            return undefined;
        },
        reportModified (): boolean {
            return !_.isEqual(this.report, this.internalReport) || !this.report.id;
        },
        reportDifferences (): any[] {
            return Object.entries(this.internalReport)
                .filter(el => el[1] !== this.report[el[0] as unknown as keyof ReportDto])
                .map(el => `${el[0]}: ${el[1]} -- ${this.report[el[0] as unknown as keyof ReportDto]}`);
        },
        nodeIsQuestionGroup (): boolean {
            return this.selectedType === NodeTypes.QuestionGroup;
        },
        nodeIsMainQuestion (): boolean {
            return this.selectedType === NodeTypes.MainQuestion;
        },
        nodeIsSubQuestion (): boolean {
            return this.selectedType === NodeTypes.SubQuestion;
        },
        allQuestionsAnswered (): boolean {
            let allAnswered = true;
            for(const rr of this.internalReport.reportRows ?? []) {
                if (rr.replyYN === ReportRowReply.NotYetAnswered) {
                    allAnswered = false;
                }
            }
            return allAnswered;
        },
        subQuestions (): SubQuestionDto[] {
            return this.internalQuestionCatalog.questionGroups?.flatMap(x => x.mainQuestions)
                .flatMap(x => x?.subQuestions) as SubQuestionDto[];
        },
        mainQuestions (): MainQuestionDto[] {
            return this.internalQuestionCatalog.questionGroups?.flatMap(x => x.mainQuestions) as MainQuestionDto[];
        },
        catalogTypeDescription (): string {
            return this.internalQuestionCatalog?.catalogType?.getDescription(this.$i18n.locale) ?? '';
        }
    },
    methods: {
        addReportRows (): void {
            const reportRows = (this.internalReport.reportRows as unknown as ReportRowDto[]);
            for(const qg of this.internalQuestionCatalog?.questionGroups ?? []) {
                for (const mq of qg.mainQuestions ?? []) {
                    addRowForMainQuestion(reportRows, mq, qg, this.nullRiskRating);
                }
            }
        },
        calculateReportStatus (catalog: ReportDto): ReportApprovalStatus {
            return catalog.approvalStatus
                ? catalog.approvalStatus
                : ReportApprovalStatus.New;
        },
        onTabChanged (tabChangedEvent: number, questionString: any, question: any, event: TreeNode): void {
            this.selectedTab = tabChangedEvent;
            let questionVal: any = null;
            let subQuestionVal: any = null;
            this.selectedType = NodeTypes[question.type] as any;
            const questionType: any = NodeTypes[question.type];
            if(questionType == NodeTypes.MainQuestion) {
                questionVal = this.mainQuestions.find(ele => ele.id == question.id)
            } else if(questionType == NodeTypes.SubQuestion){
                subQuestionVal = this.subQuestions.find(ele => ele.id == question.id);
                questionVal = this.mainQuestions.find(ele => ele.id == subQuestionVal.mainQuestionId);
            }
            this.selectedNode = this.findNodeById(this.catalogAsTree, questionVal.id, questionVal, subQuestionVal, questionType); // Assuming questionVal has an 'id' property
            this.selectedMainQuestion = questionVal;
            if(this.selectedNode){
                this.onSelectedTreeElement(this.selectedNode);
            }
        },
        getPosition(questionType: any, questionGroupVal: any, subQuestionVal: any, subQuestionValue: any): any{
            if(questionType == NodeTypes.MainQuestion) {
                return `QG${questionGroupVal.position}-MQ${subQuestionVal.position}`
            } else if(questionType == NodeTypes.SubQuestion){
                return `QG${questionGroupVal.position}-MQ${subQuestionVal.position}-SQ${subQuestionValue.position}`
            } else {
                return `QG${questionGroupVal.position}`
            }
        },
        findNodeById(node: any, nodeId: string, question: any, subQuestionValue: any, questionType: any): TreeNode | any {
            if(this.internalQuestionCatalog?.questionGroups && this.internalQuestionCatalog?.questionGroups.length){
                const questionGroupVal: any = this.internalQuestionCatalog?.questionGroups.find((ele: any) => ele.id == question.questionGroupId);
                const subQuestionVal: any = questionGroupVal.mainQuestions.find((ele: any) => ele.id == question.id);
                const position: any = this.getPosition(questionType, questionGroupVal, subQuestionVal, subQuestionValue);
                if (node.id == position) {
                    return node;
                }
                if(node.children){
                    for (const child of node.children) {
                        const foundNode = this.findNodeById(child, nodeId, question, subQuestionValue, questionType);
                        if (foundNode !== null) {
                            return foundNode;
                        }
                    }   
                }
            }
            
            return null;
        },
        treeViewClosed (): void {
            this.selectedNode = undefined;
            this.selectedType = NodeTypes.None;
            this.selectedMainQuestion = undefined;
        },
        onSelectedTreeElement (event: TreeNode | any): void {
            onSelectedTreeElement(event, this);
        },
        onStatusChange (event: ReportApprovalStatus): void {
            // FIXME: This needs to be handled via the workflow
            this.internalReport.approvalStatus = event;
        },
        updateReportRow (row: ReportRowDto): void {
            // update existing row
            if (this.currentReportRow) {
                for (const [key, val] of Object.entries(row)) {
                    (this.currentReportRow as any)[key] = val;
                }
            } else {
                // or add a new one
                this.internalReport.reportRows?.push(_.cloneDeep(row));
            }
        },
        async verifyAndSubmit (additionalChanges: Partial<ReportDto> | undefined = undefined, showWaitingModal = true): Promise<void> {
            this.internalReport = { ...this.internalReport, ...additionalChanges } as ReportDto;

            // clean up sub-questions and remove answers if there are any but the main question does not give a risk
            this.cleanUpSubQuestionAnswers();

            // Submit if check successful
            await this.submitChanges(showWaitingModal);
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        cleanUpSubQuestionAnswers (): void {
            // Loop over all main questions of this report/catalog and find the corresponding report rows
            for(const el of this.mainQuestions) {
                const reportRow = this.internalReport.reportRows?.filter(x => x.referenceId === el?.id ?? 0)
                    .filter(x => x.reportRowType === ReportRowType.MainQuestion)[0];

                // Check if the report row does not give a risk
                if (reportRow && !riskPresent(el, reportRow)) {
                    // loop over all sub questions and "reset them"
                    for (const sq of el.subQuestions ?? []) {
                        const subQuestionRow = this.internalReport.reportRows?.filter(x => x.referenceId === sq?.id ?? 0)
                            .filter(x => x.reportRowType === ReportRowType.SubQuestion)[0];
                        if (subQuestionRow) {
                            // reset all relevant properties
                            subQuestionRow.replyYN = ReportRowReply.NotYetAnswered;
                            subQuestionRow.riskRating = this.nullRiskRating;
                            subQuestionRow.annotation = undefined;
                            subQuestionRow.updatedAt = undefined;
                            subQuestionRow.updatedBy = undefined;
                            for (const le of subQuestionRow.reportRowListEntries ?? []) {
                                le.status = 0;
                                le.listing = '';
                                le.dueDate = undefined;
                            }
                        }
                    }
                }
            }
        },
        async submitChanges (showWaitingModal = true): Promise<void> {
            const response = await apiCallWithErrorHandling(
                this.internalReport.id
                    ? this.reportApi.editReport
                    : this.reportApi.addReport,
                new ReportDto({
                    ...this.internalReport,
                    lastModifiedUser: this.$store.getters['userAccessManagement/getUserId'] ?? 0
                }),
                (this as any).$pui.toast,
                {
                    successTitle: this.$t('reportDetails.reportChangeToast.successTitle').toString(),
                    successText: this.$t('reportDetails.reportChangeToast.successText').toString(),
                    errorTitle: this.$t('reportDetails.reportChangeToast.errorTitle').toString(),
                    errorText: this.$t('reportDetails.reportChangeToast.errorText').toString(),
                    forbiddenErrorText: this.$t('reportDetails.reportChangeToast.forbiddenErrorText').toString(),
                    conflictErrorText: this.$t('reportDetails.reportChangeToast.conflictErrorText').toString(),
                    waitingTitle: this.$t(WAITING_MODAL_DEFAULT_TITLE).toString(),
                    waitingText: this.$t(WAITING_MODAL_DEFAULT_TEXT).toString(),
                },
                this.$store,
                0,
                showWaitingModal
            );


            // Update Question Catalog
            if (response && response?.result) {
                this.internalReport = response.result;
                EventBus.$emit(EventBus.VIEWS.USER.REFRESH_TABLE);
                EventBus.$emit(EventBus.VIEWS.USER.REPORT_EDITED, _.cloneDeep(this.internalReport))
            }
        }
    }
});

export default ReportDetails;
