import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import AdminView from '@/views/admin/admin-view.vue';
import UserView from '@/views/user/user-view.vue';
import CentralAnalysisView from '@/views/central-analysis/central-analysis-view.vue';
import { RouteNames } from '@/router/route-names';
import ReportOverview from '@/components/report-overview/report-overview.vue';
import ExportView from '@/views/export/export-view.vue';
import store from '@/store';
import { UserRoles } from '@/enums/user-roles';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/admin',
        name: RouteNames.AdminView,
        meta: { translationKey: 'navigation.admin' },
        component: AdminView,
        beforeEnter: (to, from, next): any => {
            const role = store.getters['userAccessManagement/getUserRole'] as UserRoles;
            if (role !== UserRoles.Admin) {
                return false;
            }
            next();
            return true;
        },
    },
    {
        path: '/user',
        name: RouteNames.UserView,
        meta: { translationKey: 'navigation.userArea' },
        component: UserView,
        children: [
            {
                name: RouteNames.UserViewReports,
                path: 'reports/:reportId?',
                component: ReportOverview,
                props: (route: Route): Record<string, number> => ({ preloadedReportId: Number(route.params.reportId) }),
            }
        ]
    },
    {
        path: '/central-analysis',
        name: RouteNames.CentralAnalysisView,
        meta: { translationKey: 'navigation.centralAnalysis' },
        component: CentralAnalysisView,
        beforeEnter: (to, from, next): any => {
            const role = store.getters['userAccessManagement/getUserRole'] as UserRoles;
            if (role !== UserRoles.Admin && role !== UserRoles.Controller) {
                return false;
            }
            next();
            return true;
        },
    },
    {
        path: '/export',
        name: RouteNames.ExportView,
        meta: { translationKey: 'navigation.export' },
        component: ExportView,
        beforeEnter: (to, from, next): any => {
            if (!store.getters['userAccessManagement/canExportReport']) {
                return false;
            }
            next();
            return true;
        },
    },
];

const router = new VueRouter({
    routes,
});

export default router;
