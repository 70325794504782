import { render, staticRenderFns } from "./sidebar-single-button.vue?vue&type=template&id=29598104&scoped=true"
import script from "./sidebar-single-button.ts?vue&type=script&lang=ts&external"
export * from "./sidebar-single-button.ts?vue&type=script&lang=ts&external"
import style0 from "./sidebar-single-button.less?vue&type=style&index=0&id=29598104&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29598104",
  null
  
)

export default component.exports