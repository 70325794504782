import { ReportApprovalStatus } from '@/service-proxies/service-proxies.g';

export interface ApprovalStatus {
    id: number;
    description: string;
}

export class FakeApprovalStatusService {

    public translationMap: { [key: number]: string } = {
        [ReportApprovalStatus.New]: 'reportStatus.New',
        [ReportApprovalStatus.Editable]: 'reportStatus.Editable',
        [ReportApprovalStatus.ApprovedByEditor]: 'reportStatus.ApprovedByEditor',
        [ReportApprovalStatus.ApprovedByApprover]: 'reportStatus.ApprovedByApprover',
        [ReportApprovalStatus.Finalized]: 'reportStatus.Finalized',
    };

    getItems (filter: { Term: string; page: number; size: number } | undefined = undefined): { result: { items: ApprovalStatus[] }} {
        const items = Object.values(ReportApprovalStatus)
            .filter((v) => !isNaN(Number(v)))
            .map(x => ({
                id: x as number,
                description: ReportApprovalStatus[x as number] as string,
            }));

        if (filter) {
            return {
                result: {
                    items: items
                        .filter(x => x.description?.toLowerCase().includes(filter.Term.toLowerCase()))
                        .slice((filter.page - 1) * filter.size, filter.page * filter.size)
                }
            };

        }
        return { result: { items: items }};
    }

    getTranslatedItems(filter: { Term: string; page: number; size: number } | undefined = undefined): ApprovalStatus[] {
        return this.getItems(filter).result.items.map(i => ({ id: i.id, description: this.translationMap[i.id] }));
    }
}
