import moment from 'moment-timezone';
import { LANGUAGES } from '@/utils/languages';

export const selectedLanguageToMomentLocale = (selectedLanguage: typeof LANGUAGES): moment.LocaleSpecifier => {
    let localeKey = 'en-gb';
    if (selectedLanguage === LANGUAGES.DE) {
        localeKey = 'de';
    } else if (selectedLanguage === LANGUAGES.NL) {
        localeKey = 'nl';
    } else if (selectedLanguage === LANGUAGES.HU) {
        localeKey = 'hu';
    } else if (selectedLanguage === LANGUAGES.EN) {
        localeKey = 'en-gb';
    } else {
        throw 'Unknown language!'
    }
    return localeKey;
}

export const toShortIsoString = (input: string, ignoreTime: boolean, inputIsInUtc: boolean): string => {
    if (input === '') return '';

    const cutAt = ignoreTime ? 10 : 16;

    return inputIsInUtc
        ? moment.utc(input).local().toISOString(true).slice(0, cutAt)
        : moment(input).local().toISOString(true).slice(0, cutAt);
}
