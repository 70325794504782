import _ from 'lodash';

export const base64PDFtoBlob = async (base64String: string): Promise<Blob> => {
    const base64Response = await fetch(`data:application/pdf;base64,${base64String}`)
    return await base64Response.blob();
}

export function until (conditionFunction: any, timeout = 0): Promise<any> {

    const poll = (resolve: any): any => {
        if (conditionFunction()) {
            resolve();
        } else if (timeout > 0 && poll.timePassed > timeout) {
            resolve();
        }
        else {
            poll.timePassed += 400
            setTimeout(() => poll(resolve), 400);
        }
    }
    poll.timePassed = 0;

    return new Promise(poll);
}

export async function sleep (millisecs: number): Promise<void> {
    return new Promise(r => setTimeout(r, millisecs));
}

export function nameof<T> (key: keyof T): keyof T {
    return key;
}

/**
 * Helper function to compare two objects. Behaves similar to lodash but differs in the comparison of
 * arrays. There is returns "isEqual" if one object is undefined and the other is an empty list.
 * @param value1
 * @param value2
 */
export function isEqualLight(value1: any, value2: any): boolean {
    return _.isEqualWith(value1, value2, (v1: any, v2: any) => {
        if (Array.isArray(v1) || Array.isArray(v2)) {
            if (Array.isArray(v1) && v1.length === 0 && v2 === undefined || Array.isArray(v2) && v2.length === 0 && v1 === undefined) {
                return true;
            }
            return undefined;
        }
        return undefined;
    });
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export const clearArray = <T>(array: T[]): void => {
    while (array.length > 0) {
        array.pop();
    }
}
