import Vue, { PropType } from 'vue';
import BgTooltip from '@/components/table/custom-tooltips/bgTooltip.vue';
import TableFiltered from '@/components/table-filtered/table-filtered.vue';
import { LocationListEndpointRequest } from '@/models';
import { ColumnDef, VendorOptions } from '@/components/table/contracts/table-data';
import { EventBus } from '@/utils';
import moment from 'moment-timezone';
import { CatalogTypeDto, LocationDto, LocationSortColumn, SortType } from '@/service-proxies/service-proxies.g';
import { FilterChangedEvent, GridApi } from 'ag-grid-community';
import { removeEmptyFieldsFrom } from '@/utils/filter-utils';
import { TableData } from '@/components/location-config-table/contracts/table-data';
import { LocationService } from '@/services/location-service';
import CustomMultiSelectFilter from '@/components/table-filtered/custom-filters/multi-select-filter.vue';
import { FakeCatalogTypeService } from '@/components/user-config-table/fake-services/fake-catalog-type-service';
import { FakeLocationService } from '@/components/user-config-table/fake-services/fake-location-service';
import _ from 'lodash';
import EditLocationButtonCell from '@/components/location-config-table/custom-cells/edit-location-button-cell.vue';
import { CustomFilterProps } from '@/components/table-filtered/custom-filters/contracts/CustomFilterProps';
import { ColumnSort } from '@/components/table/contracts/table-enums';

const LocationConfigTable = Vue.extend({
    name: 'LocationConfigTable',
    props: {
        tableTitle: {
            type: String,
            required: true,
        },
        filterValues: {
            type: Object as PropType<LocationListEndpointRequest>,
            required: true
        },
    },
    components: {
        BgTooltip,
        TableFiltered,
        CustomMultiSelectFilter,
        EditLocationButtonCell,
    },
    data (): {
        eventTableName: string;
        lastUpdateRequest: moment.Moment;
        tz: string;
        pagination: {
            totalPages: number;
            rangeOfPages: number;
        };
        vendorOptions: VendorOptions<TableData>;
        locationService: LocationService;
        sortColumnToRequestFieldMap: Record<string, LocationSortColumn>;
        filterColumnToRequestFieldMap: Record<string, keyof LocationListEndpointRequest>;
        sortTypeToRequestFieldMap: { [key: string]: SortType | undefined };
        gridApi: undefined | GridApi;
        availableColumnsFilterValues: Array<keyof LocationListEndpointRequest>;
        fakeCatalogTypeService: any;
        fakeLocationService: any;
        } {
        return {
            eventTableName: 'AdminViewLocationConfigTable',
            lastUpdateRequest: moment.tz(moment.tz.guess()),
            tz: moment.tz.guess(),
            pagination: {
                totalPages: 1,
                rangeOfPages: 3,
            },
            vendorOptions: {
                data:
                    _.range(500).map(() => ({
                        'meta': {
                            locationId: undefined,
                            locationDto: new LocationDto(),
                        },
                        'col-location-id': undefined,
                        'col-location-abbreviation': undefined,
                        'col-location-name': undefined,
                        'col-catalog-types': undefined,
                        'col-catalog-types-id': undefined,
                        'col-details': undefined,
                    })),
                rowSelection: 'single',
                columnDefs: [],
                withPagination: true,
                paginationAutoPageSize: true,
            },
            locationService: new LocationService(),
            sortColumnToRequestFieldMap: {
                'col-location-id': LocationSortColumn.LocationId,
                'col-location-abbreviation': LocationSortColumn.LocationAbbr,
                'col-location-name': LocationSortColumn.LocationName,
            },
            filterColumnToRequestFieldMap: {
                'col-catalog-types': 'catalogTypeIds',
                'col-location-name': 'locationIds',
            },
            sortTypeToRequestFieldMap: {
                'asc': SortType.Ascending,
                'desc': SortType.Descending,
            },
            gridApi: undefined,
            availableColumnsFilterValues: [
                'catalogTypeIds',
                'locationIds',
            ],
            fakeCatalogTypeService: new FakeCatalogTypeService(this.$store.getters['catalogTypes/catalogTypes']),
            fakeLocationService: new FakeLocationService(this.$store.getters['locations/locations']),
        }
    },
    created (): void {
        this.registerEventCallbacks();
        this.vendorOptions.columnDefs = this.defaultColumnDefs();
    },
    async mounted (): Promise<void> {
        await this.constructTable();
    },
    computed: {
        filterColumnToRequestFieldMapInverse (): Record<string, string> {
            const inverseMap: Record<string, string> = {};
            for (const [k, v] of Object.entries(this.filterColumnToRequestFieldMap)) {
                inverseMap[v] = k;
            }
            return inverseMap;
        },
        agGridFilterModel (): Record<string, { value: any }> {
            // Map to correct filter props
            const filterModel: Record<string, { value: any }> = {};
            for (const [k, v] of Object.entries(this.filterValues)) {
                const column = this.filterColumnToRequestFieldMapInverse[k];
                if (this.availableColumnsFilterValues.includes(k as keyof LocationListEndpointRequest)) {
                    filterModel[column] = {
                        value: v,
                    };
                }
            }

            return filterModel;
        },
        catalogTypes (): CatalogTypeDto[] {
            return this.$store.getters['catalogTypes/catalogTypes'];
        }
    },
    methods: {
        /* eslint-disable sonarjs/no-duplicate-string */
        defaultColumnDefs (): ColumnDef[] {
            const locationNameCustomFilterParams: CustomFilterProps<LocationDto> = {
                filterLabel: this.$t('filterLabels.location').toString(),
                dataName: 'col-location-id',
                apiService: this.fakeLocationService,
                valueName: 'locationName',
                labelName: 'locationName',
                secondaryLabelName: 'locationAbbr'
            }
            const catalogTypesCustomFilterParams: CustomFilterProps<CatalogTypeDto> = {
                filterLabel: this.$t('filterLabels.catalogType').toString(),
                dataName: 'col-catalog-types-id',
                apiService: this.fakeCatalogTypeService,
                valueName: 'catalogTypeDescriptions',
                labelName: 'catalogTypeDescriptions',
                secondaryLabelName: 'catalogTypeAbbr'
            }

            return [
                {
                    headerName: this.$t('locationConfigTable.locationId').toString(),
                    field: 'col-location-id', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-location-id', tooltipComponentParams: { key: 'col-location-id' },
                    comparator: (): number => 0,
                    filter: false,
                },
                {
                    headerName: this.$t('locationConfigTable.locationAbbr').toString(),
                    field: 'col-location-abbreviation', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-location-abbreviation', tooltipComponentParams: { key: 'col-location-abbreviation' },
                    comparator: (): number => 0,
                    filter: false,
                    sort: ColumnSort.asc
                },
                {
                    headerName: this.$t('locationConfigTable.locationName').toString(),
                    field: 'col-location-name', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-location-name', tooltipComponentParams: { key: 'col-location-name' },
                    comparator: (): number => 0,
                    filter: 'CustomMultiSelectFilter',
                    filterParams: locationNameCustomFilterParams,
                },
                {
                    headerName: this.$t('locationConfigTable.catalogTypes').toString(),
                    field: 'col-catalog-types',
                    tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-catalog-types',
                    tooltipComponentParams: { key: 'col-catalog-types' },
                    comparator: (): number => 0,
                    filter: 'CustomMultiSelectFilter',
                    filterParams: catalogTypesCustomFilterParams,
                    sortable: false,
                },
                {
                    headerName: this.$t(`locationConfigTable.details`).toString(),
                    field: `col-details`,
                    minWidth: 50,
                    cellRenderer: 'EditLocationButtonCell',
                    filter: false,
                    sortable: false,
                }
            ];
        },
        onPageSizeFound(pageSize: number): void {
            this.filterValues.size = pageSize;
        },
        registerEventCallbacks (): void {
            EventBus.$on(EventBus.VIEWS.ADMIN.REFRESH_TABLE, () => {
                this.constructTable();
            });
        },
        async constructTable (): Promise<void> {
            const { filteredEvents, time } = await this.getFilteredValues(this.filterValues);
            // only update the table if this is really the latest request
            if (time.valueOf() === this.lastUpdateRequest.valueOf()) {
                this._constructTable(filteredEvents);
            }
        },
        async getFilteredValues (filters: LocationListEndpointRequest): Promise<{ filteredEvents: LocationDto[]; time: moment.Moment }> {
            // make sure the datetimes are in utc!
            // TODO: Not needed at the moment
            const utcFilters: LocationListEndpointRequest = {
                ...filters,
            }

            const time = moment.tz(this.tz);
            this.lastUpdateRequest = time; // save the current time to handle overlapping requests
            const res = (await this.locationService.getLocations(utcFilters)).result;

            // success
            if (res) {
                this.pagination.totalPages = res.totalPages === 0 ? 1 : res.totalPages ?? 0;
                return {
                    filteredEvents: res.items ?? [],
                    time: time
                };
            }

            // failure
            return {
                filteredEvents: [],
                time: time,
            }
        },
        _constructTable (filteredEvents: LocationDto[]): void {
            this.vendorOptions.data = [];
            filteredEvents.forEach((location: LocationDto) => {
                this.vendorOptions.data.push(
                    {
                        'meta': {
                            locationId: location.id,
                            locationDto: location,
                        },
                        'col-location-id': location.id,
                        'col-location-abbreviation': location.locationAbbr,
                        'col-location-name': location.locationName,
                        'col-catalog-types': location
                            .locationCatalogTypeDtos?.map(x => this.catalogTypes.find(y => y.id === x.catalogTypeId))
                            .map(x => x?.getDescription(this.$i18n.locale) ?? 'Unknown'),
                        'col-catalog-types-id': location
                            .locationCatalogTypeDtos?.map(x => x.catalogTypeId),
                        'col-details': this.$t('locationConfigTable.details').toString(),
                    }
                );
            });
        },
        onSortChanged (sortChangedEvent: any): void {
            // caution: this doesnt work as expected once, multi-column sort is activated
            // only the first found sorted column will be used
            const currentSortedColumn = sortChangedEvent.columnApi
                .getColumnState()
                .find((col: any) => col.sort === 'asc' || col.sort === 'desc');

            if (!currentSortedColumn) {
                this.filterValues.sortProperty = undefined;
                this.filterValues.sortDirection = undefined;
                return;
            }

            if (this.sortColumnToRequestFieldMap[currentSortedColumn.colId] == null
                || this.sortColumnToRequestFieldMap[currentSortedColumn.colId] == undefined) {
                return;
            }

            this.filterValues.sortDirection = this.sortTypeToRequestFieldMap[currentSortedColumn.sort];
            this.filterValues.sortProperty = this.sortColumnToRequestFieldMap[currentSortedColumn.colId];
        },
        onAGGridFilterChanged (filterChangedEvent: FilterChangedEvent): void {
            const filters = filterChangedEvent.api.getFilterModel();
            this.gridApi = filterChangedEvent.api;

            // Map to correct filter props
            // As a first step set everything to undefined
            const mappedFilters: Record<string, string | undefined> = {};
            for (const el of this.availableColumnsFilterValues) {
                mappedFilters[el] = undefined;
            }

            // then fill with actual filter values
            for (const [k, v] of Object.entries(filters)) {
                const mappedValue = this.filterColumnToRequestFieldMap[k];
                if (mappedValue) {
                    // standard ag-grid uses 'filter' but our custom filter uses 'value'
                    if (v.filter) {
                        mappedFilters[mappedValue] = v.filter;
                    } else {
                        mappedFilters[mappedValue] = v.value;
                    }
                } else {
                    console.error('Cannot find a filter mapping for ' + k);
                }
            }

            const newFilterValues = _.cloneDeep(this.filterValues);
            // Add filters to "real/global" filter object which is passed down again to the ag grid table
            for (const [k, v] of Object.entries(mappedFilters)) {
                Vue.set(newFilterValues, k as keyof LocationListEndpointRequest, v as never);
            }

            // Update global filter
            this.$emit('locationFilterChanged', removeEmptyFieldsFrom<LocationListEndpointRequest>(newFilterValues));
        },
    }
});

export default LocationConfigTable;
