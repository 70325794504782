/* eslint-disable sonarjs/no-duplicate-string */
export const AG_GRID_LOCALE_DE = {
    // Filter setzen
    selectAll: '(Alles auswählen)',
    selectAllSearchResults: '(Alle Suchergebnisse auswählen)',
    searchOoo: 'Suche...',
    blanks: '(Leerzeichen)',
    noMatches: 'Keine Treffer',

    // Zahlenfilter & Textfilter
    filterOoo: 'Filter...',
    equals: 'Gleich',
    notEqual: 'Nicht gleich',
    blank: 'Leer',
    notBlank: 'Nicht leer',
    empty: 'Wähle eine',

    // Zahlenfilter
    lessThan: 'Kleiner als',
    greaterThan: 'Größer als',
    lessThanOrEqual: 'Kleiner als oder gleich',
    greaterThanOrEqual: 'Größer als oder gleich',
    inRange: 'Im Bereich',
    inRangeStart: 'von',
    inRangeEnd: 'bis',

    // Textfilter
    contains: 'Enthält',
    notContains: 'Enthält nicht',
    startsWith: 'Beginnt mit',
    endsWith: 'Endet mit',

    // Datumsfilter
    dateFormatOoo: 'jjjj-mm-tt',

    // Filterbedingungen
    andCondition: 'AND',
    orCondition: 'OR',

    // Filter-Schaltflächen
    applyFilter: 'Anwenden',
    resetFilter: 'Zurücksetzen',
    clearFilter: 'Löschen',
    cancelFilter: 'Abbrechen',

    // Filterüberschriften
    textFilter: 'Text-Filter',
    numberFilter: 'Zahlenfilter',
    dateFilter: 'Datumsfilter',
    setFilter: 'Filter setzen',

    // Seitenleiste
    columns: 'Columns',
    filters: 'Filter',

    // Spalten Werkzeugleiste
    pivotMode: 'Pivot-Modus',
    groups: 'Zeilengruppen',
    rowGroupColumnsEmptyMessage: 'Ziehen Sie hier, um Zeilengruppen festzulegen',
    values: 'Werte',
    valueColumnsEmptyMessage: 'Ziehen Sie hier, um zu aggregieren',
    pivots: 'Spaltenbezeichnungen',
    pivotColumnsEmptyMessage: 'Ziehen Sie hier, um Spaltenbeschriftungen festzulegen',

    // Überschrift der Standardgruppenspalte
    group: 'Gruppe',

    // Sonstiges
    loadingOoo: 'Lade...',
    noRowsToShow: 'Keine anzuzeigenden Zeilen',
    enabled: 'Aktiviert',

    // Menü
    pinColumn: 'Pin-Spalte',
    pinLeft: 'Anstecknadel Links',
    pinRight: 'Pin Rechts',
    noPin: 'Keine Anstecknadel',
    valueAggregation: 'Wert-Aggregation',
    autosizeThiscolumn: 'Diese Spalte automatisch vergrößern',
    autosizeAllColumns: 'Alle Spalten automatisch vergrößern',
    groupBy: 'Gruppieren nach',
    ungroupBy: 'Gruppierung aufheben nach',
    addToValues: '${variable} zu den Werten hinzufügen',
    removeFromValues: '${Variable} aus den Werten entfernen',
    addToLabels: '${variable} zu Etiketten hinzufügen',
    removeFromLabels: '${variable} aus Beschriftungen entfernen',
    resetColumns: 'Spalten zurücksetzen',
    expandAll: 'Alles aufklappen',
    collapseAll: 'Alle schließen',
    copy: 'Kopieren',
    ctrlC: 'Strg+C',
    copyWithHeaders: 'Mit Kopfzeilen kopieren',
    copyWithHeaderGroups: 'Kopieren mit Kopfzeilengruppen',
    paste: 'Einfügen',
    ctrlV: 'Strg+V',
    export: 'Exportieren',
    csvExport: 'CSV-Export',
    excelExport: 'Excel-Export',

    // Unternehmensmenü Aggregation und Statusleiste
    sum: 'Summe',
    min: 'Min',
    max: 'Max',
    keine: 'Keine',
    count: 'Count',
    avg: 'Durchschnitt',
    filteredRows: 'Gefiltert',
    selectedRows: 'Ausgewählt',
    totalRows: 'Gesamtzeilen',
    totalAndFilteredRows: 'Zeilen',
    more: 'Mehr',
    to: 'bis',
    of: 'von',
    page: 'Seite',
    nextPage: 'Nächste Seite',
    lastPage: 'Letzte Seite',
    firstPage: 'Erste Seite',
    previousPage: 'Vorherige Seite',

    // Pivotierung
    pivotColumnGroupTotals: 'Gesamt',

    // Unternehmensmenü (Diagramme)
    pivotChartAndPivotMode: 'Pivot-Diagramm und Pivot-Modus',
    pivotChart: 'Pivot-Diagramm',
    chartRange: 'Diagrammbereich',

    columnChart: 'Säule',
    groupedColumn: 'Gruppiert',
    stackedColumn: 'Gestapelt',
    normalizedColumn: '100% gestapelt',

    barChart: 'Balken',
    groupedBar: 'Gruppiert',
    stackedBar: 'Gestapelt',
    normalizedBar: '100% gestapelt',

    pieChart: 'Tortendiagramm',
    pie: 'Torte',
    doughnut: 'Donut',

    line: 'Linie',

    xyChart: 'X Y (Streuung)',
    scatter: 'Streuung',
    bubble: 'Blase',

    areaChart: 'Fläche',
    area: 'Fläche',
    stackedArea: 'Gestapelt',
    normalizedArea: '100% gestapelt',

    histogramChart: 'Histogramm',

    // Diagramme
    pivotChartTitle: 'Pivot-Diagramm',
    rangeChartTitle: 'Bereichsdiagramm',
    settings: 'Einstellungen',
    data: 'Daten',
    format: 'Format',
    categories: 'Kategorien',
    defaultCategory: '(None)',
    series: 'Reihe',
    xyValues: 'X Y Werte',
    paired: 'Gepaarter Modus',
    axis: 'Achse',
    navigator: 'Navigator',
    color: 'Farbe',
    thickness: 'Dicke',
    xType: 'X-Typ',
    automatic: 'Automatisch',
    category: 'Kategorie',
    number: 'Zahl',
    time: 'Zeit',
    xRotation: 'X-Drehung',
    yRotation: 'Y-Drehung',
    ticks: 'Ticks',
    width: 'Breite',
    height: 'Höhe',
    length: 'Länge',
    padding: 'Füllung',
    spacing: 'Abstand',
    chart: 'Diagramm',
    title: 'Titel',
    titlePlaceholder: 'Diagrammtitel - Doppelklick zum Bearbeiten',
    background: 'Hintergrund',
    font: 'Schriftart',
    top: 'Oben',
    rechts: 'Rechts',
    bottom: 'Unten',
    links: 'Links',
    labels: 'Beschriftungen',
    size: 'Größe',
    minSize: 'Mindestgröße',
    maxSize: 'Maximale Größe',
    legend: 'Legende',
    position: 'Position',
    markerSize: 'Größe der Markierung',
    markerStroke: 'Marker-Strich',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Element-Abstand',
    itemPaddingX: 'Element-Padding X',
    itemPaddingY: 'Element-Padding Y',
    layoutHorizontalSpacing: 'Horizontaler Abstand',
    layoutVerticalSpacing: 'Vertikaler Abstand',
    strokeWidth: 'Strichstärke',
    offset: 'Versatz',
    offsets: 'Versatze',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markierungen',
    shadow: 'Schatten',
    blur: 'Unschärfe',
    xOffset: 'X-Offset',
    yOffset: 'Y-Offset',
    lineWidth: 'Linienbreite',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Kursiv',
    boldItalic: 'Fett kursiv',
    predefined: 'Vordefiniert',
    fillOpacity: 'Füll-Deckkraft',
    strokeOpacity: 'Linientransparenz',
    histogramBinCount: 'Bin Count',
    columnGroup: 'Säule',
    barGroup: 'Balken',
    pieGroup: 'Torte',
    lineGroup: 'Linie',
    scatterGroup: 'X Y (Streuung)',
    areaGroup: 'Fläche',
    histogramGroup: 'Histogramm',
    groupedColumnTooltip: 'Gruppiert',
    stackedColumnTooltip: 'Gestapelt',
    normalizedColumnTooltip: '100% gestapelt',
    groupedBarTooltip: 'Gruppiert',
    stackedBarTooltip: 'Gestapelt',
    normalizedBarTooltip: '100% gestapelt',
    pieTooltip: 'Torte',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Linie',
    groupedAreaTooltip: 'Fläche',
    stackedAreaTooltip: 'Gestapelt',
    normalizedAreaTooltip: '100% gestapelt',
    scatterTooltip: 'Streuung',
    bubbleTooltip: 'Blase',
    histogramTooltip: 'Histogramm',
    noDataToChart: 'Keine Daten für das Diagramm verfügbar.',
    pivotChartRequiresPivotMode: 'Pivot-Diagramm erfordert aktivierten Pivot-Modus.',
    chartSettingsToolbarTooltip: 'Menü',
    chartLinkToolbarTooltip: 'Mit Raster verbunden',
    chartUnlinkToolbarTooltip: 'Nicht mit dem Raster verknüpft',
    chartDownloadToolbarTooltip: 'Diagramm herunterladen',

    // ARIA
    ariaHidden: 'versteckt',
    ariaVisible: 'sichtbar',
    ariaChecked: 'geprüft',
    ariaUnchecked: 'nicht angekreuzt',
    ariaIndeterminate:'unbestimmt',
    ariaDefaultListName: 'Liste',
    ariaColumnSelectAll: 'Alle Spalten umschalten',
    ariaInputEditor: 'Eingabe-Editor',
    ariaDateFilterInput: 'Datumsfilter-Eingabe',
    ariaFilterList: 'Filterliste',
    ariaFilterInput: 'Filter-Eingabe',
    ariaFilterColumnsInput: 'Filterspalten Eingabe',
    ariaFilterValue: 'Filterwert',
    ariaFilterFromValue: 'Von Wert filtern',
    ariaFilterToValue: 'Nach Wert filtern',
    ariaFilteringOperator: 'Filterungs-Operator',
    ariaColumn: 'Spalte',
    ariaColumnList: 'Spaltenliste',
    ariaColumnGroup: 'Spaltengruppe',
    ariaRowSelect: 'Drücken Sie die Leertaste, um diese Zeile auszuwählen',
    ariaRowDeselect: 'Drücken Sie die Leertaste, um die Auswahl dieser Zeile aufzuheben',
    ariaRowToggleSelection: 'Drücken Sie die Leertaste, um die Zeilenauswahl umzuschalten',
    ariaRowSelectAll: 'Drücken Sie die Leertaste, um die Auswahl aller Zeilen umzuschalten',
    ariaToggleVisibility: 'Drücken Sie die Leertaste, um die Sichtbarkeit umzuschalten',
    ariaSearch: 'Suchen',
    ariaSearchFilterValues: 'Suchfilterwerte',

    ariaRowGroupDropZonePanelLabel: 'Zeilengruppen',
    ariaValuesDropZonePanelLabel: 'Werte',
    ariaPivotDropZonePanelLabel: 'Spaltenbezeichnungen',
    ariaDropZoneColumnComponentDescription: 'Zum Entfernen DELETE drücken',
    ariaDropZoneColumnValueItemDescription: 'Drücken Sie ENTER, um den Aggregationstyp zu ändern',

    // ARIA-Beschriftungen für Dialoge
    ariaLabelColumnMenu: 'Spaltenmenü',
    ariaLabelCellEditor: 'Zellen-Editor',
    ariaLabelDialog: 'Dialog',
    ariaLabelSelectField: 'Auswahlfeld',
    ariaLabelTooltip: 'Tooltip',
    ariaLabelContextMenu: 'Kontextmenü',
    ariaLabelSubMenu: 'UnterMenü',
    ariaLabelAggregationFunction: 'Aggregationsfunktion',

    // Zahlenformat (Statusleiste, Paginierungsfeld)
    thousandSeparator: '.',
    decimalSeparator: ','
}