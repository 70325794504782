import Vue from 'vue';
import { LocationListEndpointRequest } from '@/models';
import {
    LocationDto,
    LocationSortColumn,
    SortType
} from '@/service-proxies/service-proxies.g';
import { LocationService } from '@/services/location-service';
import LocationDetails from '@/components/location-administration/location-details/location-details.vue';
import LocationConfigTable from '@/components/location-config-table/location-config-table.vue';
import { EventBus } from '@/utils';
import _ from 'lodash';
import { WAITING_MODAL_DEFAULT_TEXT, WAITING_MODAL_DEFAULT_TITLE } from '@/store/modules/waiting-modal';
import { filterChangedHandler } from '@/utils/table-utils';
import WarningModal from '@/components/warning-modal/warning-modal.vue'

const defaultFilter = {
    locationIds: undefined,
    catalogTypeIds: undefined,
    sortProperty: LocationSortColumn.LocationAbbr,
    sortDirection: SortType.Ascending,
    page: 1,
    size: 12,
    Term: undefined,
    TermToLower: undefined
};

const LocationAdministration = Vue.extend({
    name: 'LocationAdministration',
    components: {
        LocationConfigTable,
        LocationDetails,
        WarningModal
    },
    props: {
        componentName: {
            type: String,
            required: true
        },
        componentDisplayValue: {
            type: String,
            required: true
        },
    },
    data (): {
        dataLoaded: boolean;
        filterValues: LocationListEndpointRequest;
        tableTitle: string;
        selectedLocationDetailsCandidate: LocationDto | undefined;
        locationApi: LocationService;
        dataChanged: boolean;
        } {
        return {
            dataLoaded: false,
            filterValues: _.cloneDeep(defaultFilter),
            tableTitle: this.$t('locationConfigTable.tableTitle').toString(),
            selectedLocationDetailsCandidate: undefined,
            locationApi: new LocationService(),
            dataChanged: false
        }
    },
    created (): void {
        // noop
    },
    async mounted (): Promise<void> {
        this.registerCallbacks();
        await this.loadInitialData();
    },
    computed: {
    },
    methods: {
        updateDataChanged(passedValue: boolean): void {
            this.dataChanged = passedValue;
        },
        closeDialog(): void {
            if(this.dataChanged){
                EventBus.$emit(EventBus.GLOBAL.SHOW_WARNING_MODAL);
            } else {
                this.toggleDetailsModal()
            }
            this.dataChanged = false;
        },
        registerCallbacks (): void {
            EventBus.$on(EventBus.VIEWS.ADMIN.LOCATION_EDIT_BUTTON_CLICKED,
                async (locationDto: LocationDto) => {
                    try {
                        EventBus.$emit(EventBus.VIEWS.ADMIN.LOCATION_DETAILS_LOADING, locationDto.id, true);

                        this.selectedLocationDetailsCandidate = locationDto;

                        if (this.selectedLocationDetailsCandidate) {
                            this.toggleDetailsModal();
                        }
                    } catch(error) {
                        console.error(error);
                        // TODO: Error Toast
                        // this.showDetailsErrorToast();
                    } finally {
                        EventBus.$emit(EventBus.VIEWS.ADMIN.LOCATION_DETAILS_LOADING, locationDto.id, false);
                    }
                }
            );

            EventBus.$on(EventBus.VIEWS.ADMIN.LOCATION_EDITED, (locationDto: LocationDto) => {
                this.selectedLocationDetailsCandidate = locationDto;
            });
        },
        async loadInitialData (): Promise<void> {
            this.$store.commit('waitingModal/WAITING', {
                title: this.$t(WAITING_MODAL_DEFAULT_TITLE),
                content: this.$t(WAITING_MODAL_DEFAULT_TEXT)
            });

            // TODO: Do we need this?
            this.dataLoaded = true;

            this.$store.commit('waitingModal/CLOSE_AND_RESET');
        },
        toggleDetailsModal(): void {
            try {
                (this.$refs.detailsLocationModalRef as any).isOpen = !((this.$refs.detailsLocationModalRef as any).isOpen);
                if (!((this.$refs.detailsLocationModalRef as any).isOpen)) {
                    this.selectedLocationDetailsCandidate = undefined;
                }
            } catch (err) {
                // Catching buggy pebble ui type error
                // console.log(err);
            }
        },
        filterChangedHandler (filters: LocationListEndpointRequest): void {
            filterChangedHandler(filters, defaultFilter, this.filterValues);
        },
        async saveChanges (): Promise<void> {
            await (this.$refs.locationDetails as any).submitChanges();
        }
    }
});

export default LocationAdministration;
