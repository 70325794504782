
import Vue, { PropType } from 'vue';
import {
    MainQuestionDto,
    QuestionGroupDto,
    ReportRowDto,
    ReportRowReply,
    RiskRating
} from '@/service-proxies/service-proxies.g';
import _ from 'lodash';
import { FormSelectOption } from '@/models/pebble-ui';
import { Validation } from '@/components/questionnaire-administration/questionnaire-details/contracts/validation';
import AutoCorrectFormInput from '@/components/auto-correct-form-input/auto-correct-form-input.vue';
import { ReportRowStatus } from '@/enums/report-row-status';
import { riskPresent } from '@/utils/composables';
import { enumKeys } from '@/utils/javascript-utils';

const MainQuestionAnswerForm = Vue.extend({
    name: 'MainQuestionAnswerForm',
    components: {
        AutoCorrectFormInput,
    },
    props: {
        questionGroup: {
            type: Object as PropType<QuestionGroupDto>,
            required: true,
        },
        mainQuestion: {
            type: Object as PropType<MainQuestionDto>,
            required: true,
        },
        value: {
            type: Object as PropType<ReportRowDto>,
            required: true,
        },
        isEditable: {
            type: Boolean,
            default: true,
        }
    },
    data (): {
        internalReportRow: ReportRowDto;
        answerOptions: FormSelectOption[];
        reportStatusOptions: FormSelectOption[];
        validations: Record<string, Validation>;
        } {
        return {
            internalReportRow: _.cloneDeep(this.value),
            answerOptions: Object.entries(ReportRowReply)
                .filter((k, v) => isNaN(k[1] as any))
                .map((k, v) => ({
                    label: this.$t(`reportRowReply.${k[1]}`).toString(),
                    value: v,
                })),
            reportStatusOptions: Object.entries(ReportRowStatus)
                .filter((k, v) => isNaN(k[1] as any))
                .map((k, v) => ({
                    label: this.$t(`reportRowStatus.${k[1]}`).toString(),
                    value: v,
                })),
            validations: {
                'remedy': {
                    maxLength: 500,
                    isValid: true,
                    helperText: '',
                },
                'remedyResponsible': {
                    maxLength: 500,
                    isValid: true,
                    helperText: '',
                },
                'annotation': {
                    maxLength: 500,
                    isValid: true,
                    helperText: '',
                }
            }
        }
    },
    watch: {
        value: {
            handler (newVal: ReportRowDto): void {
                this.internalReportRow = _.cloneDeep(newVal);
            },
            deep: true,
        }
    },
    computed: {
        seqNo (): string {
            return `${this.questionGroup.position}.${this.mainQuestion.position}`;
        },
        riskOptions (): FormSelectOption[] {
            return enumKeys(RiskRating).map(x => {
                return {
                    label: this.$t(`riskRating.${x.toString()}`).toString(),
                    value: Number(RiskRating[x]),
                }
            }).filter(x => (x.value === RiskRating.HighRisk && this.mainQuestion.highRiskAllowed) ||
                (x.value === RiskRating.MedRisk && this.mainQuestion.midRiskAllowed) ||
                (x.value === RiskRating.LowRisk && this.mainQuestion.lowRiskAllowed) ||
                (x.value === RiskRating.NoRisk && this.noRiskAllGreen) ||
                (x.value === RiskRating.Undefined)
            );
        },
        annotationWrapper (): string {
            return this.internalReportRow.annotation ?? '';
        },
        remedyWrapper (): string {
            return this.internalReportRow.remedy ?? '';
        },
        remedyResponsibleWrapper (): string {
            return this.internalReportRow.remedyResponsible ?? '';
        },
        noRiskAllGreen (): boolean {
            // Not Answered/not applicable? Then there can be no risk
            if (this.internalReportRow.replyYN === Number(ReportRowReply.NotYetAnswered) ||
                this.internalReportRow.replyYN === Number(ReportRowReply.NotApplicable)
            ) {
                return false;
            }

            // Otherwise, we need to check if there is a risk, this depends on the configuration of the question and
            // the answer.
            const temp = this.internalReportRow.replyYN === Number(ReportRowReply.Yes);
            return temp === this.mainQuestion.noRiskWhenAnsweredWithYes;
        },
        riskPresent (): boolean {
            return riskPresent(this.mainQuestion, this.internalReportRow);
        }
    },
    methods: {
        modifyAndEmitChange (obj: ReportRowDto, prop: string, value: any, targetType: 'number' | 'boolean' | 'string'): void {
            let convertedValue: number | boolean | string;

            switch (targetType) {
            case 'boolean':
                convertedValue = value === 'true';
                break;
            case 'number':
                convertedValue = Number(value);
                break;
            case 'string':
                convertedValue = String(value);
            }

            // Handle reportStatus in a special way
            if (prop === 'reportRowStatus' && obj.reportRowListEntries && targetType === 'number') {
                obj.reportRowListEntries[0].status = convertedValue as number;
            }

            if (value !== undefined &&  value !== null) {
                Vue.set(obj, prop, convertedValue);
            }

            // Handle risk yes/no in a special way
            // In some cases we automatically set the risk rating as well (when the answer implies "no risk")
            if (prop === 'replyYN') {
                if (this.noRiskAllGreen) {
                    Vue.set(obj, 'riskRating', RiskRating.NoRisk);
                } else if (this.riskPresent) {
                    if (obj.riskRating === RiskRating.NoRisk) {
                        Vue.set(obj, 'riskRating', RiskRating.Undefined);
                    }
                } else {
                    Vue.set(obj, 'riskRating', RiskRating.Undefined);
                }
            }

            this.$emit('input', this.internalReportRow);
        }
    },
});

export default MainQuestionAnswerForm;
