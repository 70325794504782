
import Vue, { PropType } from 'vue';
import {
    MainQuestionDto,
    QuestionCatalogDto,
    QuestionGroupDto,
    ReportListEndpointResponseOkResponse,
    ReportSortColumn,
    ReportType,
    SortType,
    SubQuestionDto,
    SubQuestionType,
    UserDto
} from '@/service-proxies/service-proxies.g';
import { NodeTypes } from '@/components/questionnaire-administration/questionnaire-details/contracts/enums';
import _ from 'lodash';
import QuestionGroupForm
    from '@/components/questionnaire-administration/questionnaire-details/sub-components/question-group-form.vue';
import MainQuestionForm
    from '@/components/questionnaire-administration/questionnaire-details/sub-components/main-question-form.vue';
import SubQuestionForm
    from '@/components/questionnaire-administration/questionnaire-details/sub-components/sub-question-form.vue';
import QuestionCatalogForm
    from '@/components/questionnaire-administration/questionnaire-details/sub-components/question-catalog-form.vue';
import { QuestionCatalogService } from '@/services/question-catalog-service';
import { EventBus } from '@/utils';
import { CatalogStatus } from '@/enums/catalog-status';
import moment from 'moment-timezone';
import { UserService } from '@/services/user-service';
import { apiCallWithErrorHandling } from '@/services/utils';
import { ReportService } from '@/services/report-service';
import { WAITING_MODAL_DEFAULT_TEXT, WAITING_MODAL_DEFAULT_TITLE } from '@/store/modules/waiting-modal';
import { catalogAsTree, onSelectedTreeElement } from '@/utils/composables';
import { TreeNode, VueTreeList, Tree } from 'vue-tree-list';
import { ConfirmationModalProps } from '@/store/modules/confirmation-modal';

const QuestionnaireDetails = Vue.extend({
    name: 'QuestionnaireDetails',
    components: {
        SubQuestionForm,
        MainQuestionForm,
        QuestionGroupForm,
        QuestionCatalogForm,
        VueTreeList,
    },
    props: {
        questionCatalog: {
            type: Object as PropType<QuestionCatalogDto>,
            required: true,
        },
        saveData:  {
            type: Boolean,
            required: true,
        },
    },
    data (): {
        selectedTab: number;
        selectedNode: QuestionGroupDto | MainQuestionDto | SubQuestionDto | undefined;
        selectedType: NodeTypes;
        internalQuestionCatalog: QuestionCatalogDto;
        api: QuestionCatalogService;
        reportApi: ReportService;
        selectedQuestionGroup: QuestionGroupDto | undefined;
        selectedMainQuestion: MainQuestionDto | undefined;
        selectedSubQuestion: SubQuestionDto | undefined;
        userApi: UserService;
        approver: UserDto | undefined;
        approvalDate: moment.Moment | undefined;
        dataLoaded: boolean;
        currentDiv: HTMLElement | undefined;
        catalogTreeComponentRef: string;
        } {
        return {
            selectedTab: 0,
            selectedNode: undefined,
            selectedType: NodeTypes.None,
            internalQuestionCatalog: _.cloneDeep(this.questionCatalog),
            api: new QuestionCatalogService(),
            reportApi: new ReportService(),
            selectedQuestionGroup: undefined,
            selectedMainQuestion: undefined,
            selectedSubQuestion: undefined,
            userApi: new UserService(),
            approver: undefined,
            approvalDate: undefined,
            dataLoaded: false,
            currentDiv: undefined,
            catalogTreeComponentRef: 'CTCR-1',
        }
    },
    async mounted(): Promise<void> {
        this.dataLoaded = await this.loadApprover();
    },
    watch: {
        selectedTab: {
            handler (newVal: number, oldVal: number): void {
                if (Number(oldVal) === 1 && Number(newVal) !== 1) {
                    this.treeViewClosed();
                }
            }
        },
        catalogModified: {
            handler (newValue): void {
                this.$emit('catalog-modified', newValue)
            },
            immediate: true
        },
        saveData: {
            handler (): void {
                this.verifyAndSubmit();
            },
        }
    },
    methods: {
        async onPositionChange (newPosition: number, oldPosition: number): Promise<void> {
            let list: QuestionGroupDto[] | MainQuestionDto[] | SubQuestionDto[] = [];
            let newTreeId = '';
            if (this.selectedType === NodeTypes.QuestionGroup) {
                newTreeId = `QG${newPosition}`
                if (this.internalQuestionCatalog.questionGroups) {
                    list = this.internalQuestionCatalog.questionGroups;
                }
            } else if (this.selectedType === NodeTypes.MainQuestion) {
                newTreeId = `QG${this.selectedQuestionGroup?.position as number}-MQ${newPosition}`
                const questionGroup = this.internalQuestionCatalog
                    .questionGroups?.filter(x => _.some(x.mainQuestions, y => y.id === (this.selectedNode as MainQuestionDto).id))[0];
                if (questionGroup && questionGroup.mainQuestions) {
                    list = questionGroup.mainQuestions;
                }
            } else if (this.selectedType === NodeTypes.SubQuestion) {
                newTreeId = `QG${this.selectedQuestionGroup?.position as number}-MQ${this.selectedMainQuestion?.position as number}-SQ${newPosition}`
                const mainQuestion =  this.internalQuestionCatalog
                    .questionGroups?.flatMap(x => x.mainQuestions)
                    .filter(x => _.some(x?.subQuestions, y => y.id === (this.selectedNode as SubQuestionDto).id))[0];
                if (mainQuestion && mainQuestion.subQuestions) {
                    list = mainQuestion.subQuestions;
                }
            }

            this._changePositions(list, newPosition, oldPosition);

            // now "click" on the new element
            this.catalogTreeComponentRef = `CTCR-${Number(this.catalogTreeComponentRef.split('-')) + 1}`;
            await Vue.nextTick();
            this.onSelectedTreeElement(new TreeNode({
                id: newTreeId,
                name: '',
                isLeaf: false,
                dragDisabled: false,
                disabled: false,
            }));
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        _changePositions (list: QuestionGroupDto[] | MainQuestionDto[] | SubQuestionDto[], newPosition: number, oldPosition: number): void {
            const goesDown = newPosition - oldPosition > 0;
            list = list.slice().sort((x: any, y: any) => (x.position ?? 0) - (y.position ?? 0));
            if (goesDown) {
                for(let i = oldPosition - 1; i < newPosition; i++) {
                    const el = list[i];
                    if (i === oldPosition - 1) {
                        el.position = newPosition;
                    } else {
                        el.position = (el.position as number) - 1;
                    }
                }
            } else {
                for(let i = oldPosition - 1; i >= newPosition - 1; i--) {
                    const el = list[i];
                    if (i === oldPosition - 1) {
                        el.position = newPosition;
                    } else {
                        el.position = (el.position as number) + 1;
                    }
                }
            }
        },
        /**
         * Selects a question group, main question or sub question.
         * @param event
         */
        onSelectedTreeElement (event: TreeNode): void {
            onSelectedTreeElement(event, this);
        },
        onTabChanged (tabChangedEvent: number): void {
            this.selectedTab = tabChangedEvent;
        },
        treeViewClosed (): void {
            this.selectedNode = undefined;
            this.selectedType = NodeTypes.None;
        },
        updateNode (node: QuestionGroupDto | MainQuestionDto | SubQuestionDto): void {
            if (this.selectedNode) {
                for (const [key, val] of Object.entries(node)) {
                    (this.selectedNode as any)[key] = val;
                }
            }
        },
        async checkIfReportExists (reportTypes: ReportType[]): Promise<boolean> {
            if (!this.internalQuestionCatalog.id) {
                return false;
            }

            const response = await apiCallWithErrorHandling(
                this.reportApi.getReports,
                {
                    ids: undefined,
                    questionCatalogIds: [this.internalQuestionCatalog.id],
                    catalogTypeIds: undefined,
                    reportTypeIds: reportTypes,
                    reportYears: undefined,
                    locationIds: undefined,
                    languageIds: undefined,
                    approvalStatus: undefined,
                    sortDirection: SortType.Ascending,
                    sortProperty: ReportSortColumn.Description,
                    page: 1,
                    size: 5,
                    Term: undefined,
                    TermToLower: undefined,
                },
                (this as any).$pui.toast,
                {
                    errorTitle: this.$t('questionCatalogDetails.reportCheckToast.errorTitle').toString(),
                    errorText: this.$t('questionCatalogDetails.reportCheckToast.errorText').toString(),
                    waitingTitle: this.$t(WAITING_MODAL_DEFAULT_TITLE).toString(),
                    waitingText: this.$t(WAITING_MODAL_DEFAULT_TEXT).toString(),
                },
                this.$store
            );

            if (response && response?.result) {
                return ((response as ReportListEndpointResponseOkResponse)?.result?.items?.length ?? 0) > 0;
            }

            // we should never get here
            throw 'Report loading crashed for unknown reason!';
        },
        async verifyAndSubmit (): Promise<void> {
            // step 1: make sure there is no report for this catalog
            const reportExists = await this.checkIfReportExists([ReportType.InterimReport, ReportType.MainReport]);
            const testReportExists = await this.checkIfReportExists([ReportType.TestReport]);

            // step 2: submit to backend or show error
            if (reportExists) {
                // show error toast
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('questionCatalogDetails.reportExistsError.keepApprovalStatus.title').toString(),
                    copy: this.$t('questionCatalogDetails.reportExistsError.keepApprovalStatus.text').toString(),
                });
                // undo changes to catalog status
                this.internalQuestionCatalog.testApproval = this.questionCatalog.testApproval;
                this.internalQuestionCatalog.approval = this.questionCatalog.approval;
                this.internalQuestionCatalog.approvalDate = this.questionCatalog.approvalDate;
                this.internalQuestionCatalog.approvalUserId = this.questionCatalog.approvalUserId;
                this.internalQuestionCatalog.testApprovalDate = this.questionCatalog.testApprovalDate;
                this.internalQuestionCatalog.testApprovalUserId = this.questionCatalog.testApprovalUserId;
            } else if (testReportExists) {
                // possible cases
                // test report exists, but test approval is still true
                if (this.internalQuestionCatalog.testApproval || this.internalQuestionCatalog.approval){
                    await this.submitChanges();
                } else if (!this.internalQuestionCatalog.testApproval && !this.internalQuestionCatalog.approval){
                    await this.showTestReportDeletionConfirmation();
                } else {
                    this.showErrorToast();
                }
            } else {
                await this.submitChanges();
            }
        },
        async showTestReportDeletionConfirmation(): Promise<void> {
            this.$store.commit('confirmationModal/WAIT_FOR_CONFIRMATION', {
                header: this.$t('questionCatalogDetails.deleteTestReportConfirmation.header').toString(),
                footerConfirmLabel: this.$t('yes').toString(),
                footerCancelLabel: this.$t('no').toString(),
                content: this.$t('questionCatalogDetails.deleteTestReportConfirmation.content').toString(),
                onConfirmCallback: async () => {
                    await this.deleteTestReports();
                    await this.submitChanges();
                    this.$store.commit('confirmationModal/CLOSE_AND_RESET');
                },
                onCancelCallback: () => {
                    this.$store.commit('confirmationModal/CLOSE_AND_RESET');
                },
                showLightboxCloseIcon: false
            } as ConfirmationModalProps)
        },
        async deleteTestReports(): Promise<void> {
            const response = await apiCallWithErrorHandling(
                this.api.deleteTestReportsByQuestionCatalogId,
                this.internalQuestionCatalog.id ?? -1,
                (this as any).$pui.toast,
                {
                    successTitle: this.$t('questionCatalogDetails.deleteTestReportConfirmation.successTitle').toString(),
                    successText: this.$t('questionCatalogDetails.deleteTestReportConfirmation.successText').toString(),
                    errorTitle: this.$t('questionCatalogDetails.deleteTestReportConfirmation.errorTitle').toString(),
                    errorText: this.$t('questionCatalogDetails.deleteTestReportConfirmation.errorText').toString(),
                    waitingTitle: this.$t(WAITING_MODAL_DEFAULT_TITLE).toString(),
                    waitingText: this.$t(WAITING_MODAL_DEFAULT_TEXT).toString(),
                },
                this.$store
            );
        },
        async submitChanges (): Promise<void> {
            const response = await apiCallWithErrorHandling(
                this.internalQuestionCatalog.id
                    ? this.api.editQuestionCatalog
                    : this.api.addQuestionCatalog,
                this.internalQuestionCatalog,
                (this as any).$pui.toast,
                {
                    successTitle: this.$t('questionCatalogDetails.questionCatalogChangeToast.successTitle').toString(),
                    successText: this.$t('questionCatalogDetails.questionCatalogChangeToast.successText').toString(),
                    errorTitle: this.$t('questionCatalogDetails.questionCatalogChangeToast.errorTitle').toString(),
                    errorText: this.$t('questionCatalogDetails.questionCatalogChangeToast.errorText').toString(),
                    forbiddenErrorText: this.$t('questionCatalogDetails.questionCatalogChangeToast.forbiddenErrorText').toString(),
                    conflictErrorText: this.$t('questionCatalogDetails.questionCatalogChangeToast.conflictErrorText').toString(),
                    waitingTitle: this.$t(WAITING_MODAL_DEFAULT_TITLE).toString(),
                    waitingText: this.$t(WAITING_MODAL_DEFAULT_TEXT).toString(),
                },
                this.$store
            );


            // Update Question Catalog
            if (response && response?.result) {
                this.internalQuestionCatalog = response.result;
                EventBus.$emit(EventBus.VIEWS.ADMIN.REFRESH_TABLE);
                EventBus.$emit(EventBus.VIEWS.ADMIN.QUESTION_CATALOG_EDITED, _.cloneDeep(this.internalQuestionCatalog))
                await this.loadApprover();
            }
        },
        showSuccessToast (): void {
            (this as any).$pui.toast({
                type: 'success',
                title: this.$t('questionCatalogDetails.questionCatalogChangeToast.successTitle').toString(),
                copy: this.$t('questionCatalogDetails.questionCatalogChangeToast.successText').toString(),
            });
        },
        showErrorToast (customMessage?: string): void {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('questionCatalogDetails.questionCatalogChangeToast.errorTitle').toString(),
                copy: customMessage ?? this.$t('questionCatalogDetails.questionCatalogChangeToast.errorText').toString(),
            });
        },
        async addButtonHandler (slotProps: TreeNode): Promise<void> {
            const nodeId = slotProps.id as string;
            const level = nodeId.split('-').length;
            const position = Number(nodeId.split('-')[level - 1].substring(2));

            // fake "click" on the element
            this.onSelectedTreeElement(slotProps);

            const targetPosition = position + 1;
            let tempPosition = 0;
            let newNodeId = '';
            if (level === 1) {
                tempPosition = (this.internalQuestionCatalog.questionGroups?.length ?? 0) + 1;
                newNodeId = `QG${targetPosition}`;

                // Add element at the end and then perform a position change
                this.internalQuestionCatalog.questionGroups?.push(new QuestionGroupDto({
                    position: tempPosition,
                    description: this.$t('newQuestionGroupText').toString(),
                    id: 0,
                    sumWeighting: 0,
                    questionCatalogId: this.internalQuestionCatalog.id,
                    mainQuestions: [],
                }))
            } else if (level === 2) {
                tempPosition = (this.selectedQuestionGroup?.mainQuestions?.length ?? 0) + 1;
                newNodeId = `QG${(this.selectedQuestionGroup?.position as number)}-MQ${targetPosition}`;

                // Add element at the end and then perform a position change
                this.selectedQuestionGroup?.mainQuestions?.push(new MainQuestionDto({
                    weight: 1,
                    cycle: 2,
                    text: this.$t('newMainQuestionText').toString(),
                    position: tempPosition,
                    noRiskWhenAnsweredWithYes: true,
                    lowRiskAllowed: true,
                    midRiskAllowed: true,
                    highRiskAllowed: true,
                    id: 0,
                    questionGroupId: this.selectedQuestionGroup?.id ?? 0,
                    subQuestions: [],
                }))
            } else if (level === 3) {
                tempPosition = (this.selectedMainQuestion?.subQuestions?.length ?? 0) + 1;
                newNodeId = `QG${(this.selectedQuestionGroup?.position as number)}-MQ${(this.selectedMainQuestion?.position as number)}-SQ${targetPosition}`;

                // Add element at the end and then perform a position change
                this.selectedMainQuestion?.subQuestions?.push(new SubQuestionDto({
                    text: this.$t('newSubQuestionText').toString(),
                    position: tempPosition,
                    id: 0,
                    mainQuestionId: this.selectedMainQuestion.id,
                    subQuestionType: SubQuestionType.SubQuestion,
                }))
            }

            await this.onPositionChange(targetPosition, tempPosition);
            // now "click" on the new element
            this.catalogTreeComponentRef = `CTCR-${Number(this.catalogTreeComponentRef.split('-')) + 1}`;
            await Vue.nextTick();
            this.onSelectedTreeElement(new TreeNode({
                id: newNodeId,
                name: '',
                isLeaf: false,
                dragDisabled: false,
                disabled: false,
            }));
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        async deleteButtonHandler (node: TreeNode): Promise<void> {
            const nodeId = node.id as string;
            const level = nodeId.split('-').length;
            const position = Number(nodeId.split('-')[level - 1].substring(2));

            // fake "click" on the element
            this.onSelectedTreeElement(node);

            if (level === 1) {
                const targetPosition = this.internalQuestionCatalog.questionGroups?.length ?? 0;
                await this.onPositionChange(targetPosition, position);
                if (this.internalQuestionCatalog.questionGroups) {
                    this._removeWithSplice(this.internalQuestionCatalog.questionGroups, x => x.position === targetPosition);
                }
            } else if (level === 2) {
                const targetPosition = this.selectedQuestionGroup?.mainQuestions?.length ?? 0;
                await this.onPositionChange(targetPosition, position);
                if (this.selectedQuestionGroup?.mainQuestions) {
                    this._removeWithSplice(this.selectedQuestionGroup?.mainQuestions, x => x.position === targetPosition);
                }
            } else if (level === 3) {
                const targetPosition = this.selectedMainQuestion?.subQuestions?.length ?? 0;
                await this.onPositionChange(targetPosition, position);
                if (this.selectedMainQuestion?.subQuestions) {
                    this._removeWithSplice(this.selectedMainQuestion?.subQuestions, x => x.position === targetPosition);
                }
            }

            // deselect all
            this.selectedNode = undefined;
            this.selectedQuestionGroup = undefined;
            this.selectedMainQuestion = undefined;
            this.selectedSubQuestion = undefined;
        },
        async addSubButtonHandler (slotProps: TreeNode): Promise<void> {
            const nodeId = slotProps.id as string;
            const level = nodeId.split('-').length;

            // fake "click" on the element
            this.onSelectedTreeElement(slotProps);

            let targetPosition = 0;
            let newNodeId = '';

            if (level === 1) {
                targetPosition = (this.selectedQuestionGroup?.mainQuestions?.length ?? 0) + 1;
                newNodeId = `QG${(this.selectedQuestionGroup?.position as number)}-MQ${targetPosition }`;

                // Add element at the end
                this.selectedQuestionGroup?.mainQuestions?.push(new MainQuestionDto({
                    weight: 1,
                    cycle: 2,
                    text: this.$t('newMainQuestionText').toString(),
                    position: targetPosition,
                    noRiskWhenAnsweredWithYes: true,
                    lowRiskAllowed: true,
                    midRiskAllowed: true,
                    highRiskAllowed: true,
                    id: 0,
                    questionGroupId: this.selectedQuestionGroup?.id ?? 0,
                    subQuestions: [],
                }))
            } else if (level === 2) {
                targetPosition = (this.selectedMainQuestion?.subQuestions?.length ?? 0) + 1;
                newNodeId = `QG${(this.selectedQuestionGroup?.position as number)}-MQ${(this.selectedMainQuestion?.position as number)}-SQ${targetPosition}`;

                // Add element at the end
                this.selectedMainQuestion?.subQuestions?.push(new SubQuestionDto({
                    text: this.$t('newSubQuestionText').toString(),
                    position: targetPosition,
                    id: 0,
                    mainQuestionId: this.selectedMainQuestion.id,
                    subQuestionType: SubQuestionType.SubQuestion,
                }))
            }

            // now "click" on the new element
            this.catalogTreeComponentRef = `CTCR-${Number(this.catalogTreeComponentRef.split('-')) + 1}`;
            await Vue.nextTick();
            this.onSelectedTreeElement(new TreeNode({
                id: newNodeId,
                name: '',
                isLeaf: false,
                dragDisabled: false,
                disabled: false,
            }));
        },
        _removeWithSplice<T> (array: ArrayLike<T>, predicate: (x: T) => boolean): void {
            for (let i = 0; i < array.length; i++) {
                if (predicate(array[i])) {
                    (array as any[]).splice(i, 1);
                    i--;
                }
            }
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        nodeIdToNode (id: string): QuestionGroupDto | MainQuestionDto | SubQuestionDto | undefined {
            const level = id.split('-').length;
            const position = Number(id.split('-')[level - 1].substring(2));
            const questionGroupPosition = level > 0
                ? Number(id.split('-')[0].substring(2))
                : 0;
            const mainQuestionPosition = level > 1
                ? Number(id.split('-')[1].substring(2))
                : 0;
            if (level === 1 && this.internalQuestionCatalog?.questionGroups) {
                return this.internalQuestionCatalog?.questionGroups.filter(x => x.position === questionGroupPosition)[0];
            } else if (level === 2 && this.internalQuestionCatalog?.questionGroups) {
                const questionGroup = this.internalQuestionCatalog?.questionGroups.filter(x => x.position === questionGroupPosition)[0];
                if (questionGroup && questionGroup.mainQuestions) {
                    return questionGroup.mainQuestions.filter(x => x.position === mainQuestionPosition)[0];
                }
            } else if (level === 3 && this.internalQuestionCatalog?.questionGroups) {
                const questionGroup = this.internalQuestionCatalog?.questionGroups.filter(x => x.position === questionGroupPosition)[0];
                if (questionGroup && questionGroup.mainQuestions) {
                    const mainQuestion = questionGroup?.mainQuestions.filter(x => x.position === mainQuestionPosition)[0];
                    if (mainQuestion && mainQuestion.subQuestions) {
                        return mainQuestion.subQuestions.filter(x => x.position === position)[0];
                    }
                }
            }
            return undefined;
        },
        lengthOfSubNodes (node: QuestionGroupDto | MainQuestionDto | SubQuestionDto): number {
            if (node instanceof QuestionGroupDto) {
                return node.mainQuestions?.length ?? 0;
            }
            if (node instanceof MainQuestionDto) {
                return node.subQuestions?.length ?? 0;
            }
            return 1;
        },
        onStatusChange (event: CatalogStatus): void {
            if (event === CatalogStatus.Draft) {
                this.internalQuestionCatalog.testApproval = false;
                this.internalQuestionCatalog.approval = false;
                this.internalQuestionCatalog.testApprovalDate = undefined;
                this.internalQuestionCatalog.testApprovalUserId = undefined;
                this.internalQuestionCatalog.approvalDate = undefined;
                this.internalQuestionCatalog.approvalUserId = undefined;
            } else if (event === CatalogStatus.TestApproval) {
                this.internalQuestionCatalog.testApproval = true;
                this.internalQuestionCatalog.approval = false;
                this.internalQuestionCatalog.testApprovalDate = moment.tz(moment.tz.guess()).seconds(0).milliseconds(0).toISOString(false);
                this.internalQuestionCatalog.testApprovalUserId = this.currentUserId;
                this.internalQuestionCatalog.approvalDate = undefined;
                this.internalQuestionCatalog.approvalUserId = undefined;
            } else {
                this.internalQuestionCatalog.testApproval = false;
                this.internalQuestionCatalog.approval = true;
                this.internalQuestionCatalog.approvalDate = moment.tz(moment.tz.guess()).seconds(0).milliseconds(0).toISOString(false);
                this.internalQuestionCatalog.approvalUserId = this.currentUserId;
                if (!this.internalQuestionCatalog.testApprovalDate) {
                    this.internalQuestionCatalog.testApprovalDate = moment.tz(moment.tz.guess()).seconds(0).milliseconds(0).toISOString(false);
                }
                if (!this.internalQuestionCatalog.testApprovalUserId) {
                    this.internalQuestionCatalog.testApprovalUserId = this.currentUserId;
                }
            }
        },
        async loadApprover (): Promise<boolean> {
            let id = 0;
            this.approver = undefined;
            this.approvalDate = undefined;
            if (this.currentCatalogStatus === CatalogStatus.TestApproval && this.internalQuestionCatalog.testApprovalUserId) {
                id = this.internalQuestionCatalog.testApprovalUserId;
                this.approvalDate = moment.tz(this.internalQuestionCatalog.testApprovalDate, 'Etc/UTC');
            } else if (this.currentCatalogStatus === CatalogStatus.Approval && this.internalQuestionCatalog.approvalUserId) {
                id = this.internalQuestionCatalog.approvalUserId;
                this.approvalDate = moment.tz(this.internalQuestionCatalog.approvalDate, 'Etc/UTC');
            } else {
                return true;
            }

            // Load user data
            const response = await apiCallWithErrorHandling(
                this.userApi.getUser,
                id,
                (this as any).$pui.toast,
                {
                    errorTitle: this.$t('questionCatalogDetails.loadApproverToast.errorTitle').toString(),
                    errorText: this.$t('questionCatalogDetails.loadApproverToast.errorText').toString(),
                    waitingTitle: this.$t(WAITING_MODAL_DEFAULT_TITLE).toString(),
                    waitingText: this.$t(WAITING_MODAL_DEFAULT_TEXT).toString(),
                },
                this.$store
            );

            if (response && response?.result) {
                this.approver = response.result;
                return true;
            }

            return false;
        },
        calculateCatalogStatus (catalog: QuestionCatalogDto): CatalogStatus {
            if (catalog.testApproval && !catalog.approval) {
                return CatalogStatus.TestApproval
            } else if (!catalog.testApproval && catalog.approval) {
                return CatalogStatus.Approval
            } else {
                return CatalogStatus.Draft
            }
        },
        canBeDeleted (slotProps: TreeNode): boolean {
            const node = this.nodeIdToNode((slotProps.id as string));

            if (node instanceof QuestionGroupDto) {
                return (this.internalQuestionCatalog.questionGroups?.length ?? 0) > 1;
            }

            return true;
        }
    },
    computed: {
        catalogAsTree (): Tree {
            return catalogAsTree(this.internalQuestionCatalog);
        },
        nodeIsQuestionGroup (): boolean {
            return this.selectedType === NodeTypes.QuestionGroup;
        },
        nodeIsMainQuestion (): boolean {
            return this.selectedType === NodeTypes.MainQuestion;
        },
        nodeIsSubQuestion (): boolean {
            return this.selectedType === NodeTypes.SubQuestion;
        },
        catalogModified (): boolean {
            return !_.isEqual(this.questionCatalog, this.internalQuestionCatalog) || !this.questionCatalog.id;
        },
        currentCatalogStatus (): CatalogStatus {
            return this.calculateCatalogStatus(this.internalQuestionCatalog);
        },
        originalCatalogStatus (): CatalogStatus {
            return this.calculateCatalogStatus(this.questionCatalog);
        },
        isEditable (): boolean {
            return this.originalCatalogStatus === CatalogStatus.Draft;
        },
        currentUserId (): number {
            return this.$store.getters['userAccessManagement/getUserId'];
        }
    }
})

export default QuestionnaireDetails;

