import { QUESTIONNAIRE_DEFAULT_FILTER } from './../../utils/table-utils';
import Vue, { PropType } from 'vue';
import { EventBus } from '@/utils';
import QuestionCatalogConfigTable from '@/components/questionnaire-config-table/questionnaire-config-table.vue';
import { QuestionCatalogListEndpointRequest } from '@/models';
import {
    CatalogTypeDto,
    LocationDto,
    SortType,
    QuestionCatalogDto,
    QuestionCatalogSortColumn,
    LanguageDto,
    QuestionGroupDto,
    ReportSortColumn,
    ReportListEndpointResponseOkResponse
} from '@/service-proxies/service-proxies.g';
import QuestionnaireDetails from '@/components/questionnaire-administration/questionnaire-details/questionnaire-details.vue';
import { QuestionCatalogService } from '@/services/question-catalog-service';
import { LocationService } from '@/services/location-service';
import { CatalogTypeService } from '@/services/catalog-type-service';
import { ConfirmationModalProps } from '@/store/modules/confirmation-modal';
import axios from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';
import { apiCallWithErrorHandling } from '@/services/utils';
import { ReportService } from '@/services/report-service';
import { WAITING_MODAL_DEFAULT_TEXT, WAITING_MODAL_DEFAULT_TITLE } from '@/store/modules/waiting-modal';
import { filterChangedHandler } from '@/utils/table-utils';
import WarningModal from '@/components/warning-modal/warning-modal.vue'

const QuestionCatalogAdministration = Vue.extend({
    name: 'QuestionCatalogAdministration',
    components: {
        QuestionCatalogConfigTable,
        QuestionnaireDetails,
        WarningModal
    },
    props: {
        componentName: {
            type: String,
            required: true
        },
        componentDisplayValue: {
            type: String,
            required: true
        },
    },
    data (): {
        dataLoaded: boolean;
        filterValues: QuestionCatalogListEndpointRequest;
        tableTitle: string;
        selectedQuestionCatalogDetailsCandidate: QuestionCatalogDto | undefined;
        questionCatalogs: QuestionCatalogDto[];
        locations: LocationDto[];
        catalogTypes: CatalogTypeDto[];
        questionCatalogApi: QuestionCatalogService;
        locationApi: LocationService;
        catalogTypeApi: CatalogTypeService;
        reportApi: ReportService;
        tz: string;
        dataChanged: boolean;
        saveData: boolean;
        } {
        return {
            dataLoaded: false,
            filterValues: _.cloneDeep(QUESTIONNAIRE_DEFAULT_FILTER),
            tableTitle: this.$t('questionCatalogConfigTable.tableTitle').toString(),
            selectedQuestionCatalogDetailsCandidate: undefined,
            questionCatalogs: [] as QuestionCatalogDto[],
            locations: [] as LocationDto[],
            catalogTypes: [] as CatalogTypeDto[],
            questionCatalogApi: new QuestionCatalogService(),
            locationApi: new LocationService(),
            catalogTypeApi: new CatalogTypeService(),
            reportApi: new ReportService(),
            tz: moment.tz.guess(),
            dataChanged: false,
            saveData: false,
        }
    },
    created (): void {
        // noop
    },
    async mounted (): Promise<void> {
        this.registerCallbacks();
        await this.loadInitialData();
    },
    computed: {
        questionCatalogTitle (): string {
            return this.selectedQuestionCatalogDetailsCandidate?.description ?? '';
        },
    },
    methods: {
        updateChangeStatus(passedValue: boolean): void {
            this.dataChanged = passedValue;
        },
        closeDialog(): void {
            if(this.dataChanged){
                EventBus.$emit(EventBus.GLOBAL.SHOW_WARNING_MODAL);
            } else {
                this.toggleDetailsModal();
            }
        },
        saveChanges(): void {
            this.saveData = !this.saveData;
        },
        registerCallbacks (): void {
            EventBus.$on(EventBus.VIEWS.ADMIN.QUESTION_CATALOG_EDIT_BUTTON_CLICKED,
                async (questionCatalogDto: QuestionCatalogDto) => {
                    try {
                        EventBus.$emit(EventBus.VIEWS.ADMIN.QUESTION_CATALOG_DETAILS_LOADING, questionCatalogDto.id, true);

                        this.selectedQuestionCatalogDetailsCandidate = questionCatalogDto;

                        if (this.selectedQuestionCatalogDetailsCandidate) {
                            this.toggleDetailsModal();
                        }
                    } catch(error) {
                        console.error(error);
                    } finally {
                        EventBus.$emit(EventBus.VIEWS.ADMIN.QUESTION_CATALOG_DETAILS_LOADING, questionCatalogDto.id, false);
                    }
                }
            );

            EventBus.$on(EventBus.VIEWS.ADMIN.QUESTION_CATALOG_ADD_BUTTON_CLICKED,
                async () => {
                    this.selectedQuestionCatalogDetailsCandidate = new QuestionCatalogDto({
                        description: this.$t('newQuestionCatalogDescription').toString(),
                        version: 1,
                        reportYear: moment.tz(this.tz).year(),
                        deadlineHReport: moment.tz(this.tz)
                            .month(3)
                            .date(30)
                            .hour(10)
                            .minutes(0)
                            .seconds(0)
                            .milliseconds(0)
                            .toISOString(false),
                        deadlineZReport: moment.tz(this.tz)
                            .month(9)
                            .date(31)
                            .hour(10)
                            .minutes(0)
                            .seconds(0)
                            .milliseconds(0)
                            .toISOString(false),
                        catalogTypeId: (this.$store.getters['catalogTypes/catalogTypes'] as CatalogTypeDto[])[0].id,
                        languageId: (this.$store.getters['languages/languages'] as LanguageDto[])[0].id,
                        questionGroups: [
                            new QuestionGroupDto({
                                position: 1,
                                description: this.$t('newQuestionGroupText').toString(),
                                sumWeighting: 0,
                                mainQuestions: [],
                            })
                        ],
                    });

                    if (this.selectedQuestionCatalogDetailsCandidate) {
                        this.toggleDetailsModal();
                    }
                }
            );

            EventBus.$on(EventBus.VIEWS.ADMIN.QUESTION_CATALOG_DELETE_BUTTON_CLICKED,
                async (questionCatalogDto: QuestionCatalogDto) => {
                    const deleteFunction = async (id: number): Promise<void> => {
                        try {
                            this.$store.commit('waitingModal/WAITING', {
                                title: this.$t(WAITING_MODAL_DEFAULT_TITLE),
                                content: this.$t(WAITING_MODAL_DEFAULT_TEXT)
                            });
                            this.$store.commit('confirmationModal/CLOSE_AND_RESET');
                            // if a report Exists
                            if (!questionCatalogDto.id) {
                                throw 'Question Catalog has no ID';
                            }
                            const reportExists = await this.checkIfReportExists(questionCatalogDto.id);
                            if (reportExists) {
                                (this as any).$pui.toast({
                                    type: 'error',
                                    title: this.$t('questionCatalogDetails.reportExistsError.cannotDeleteReport.title').toString(),
                                    copy: this.$t('questionCatalogDetails.reportExistsError.cannotDeleteReport.text').toString(),
                                });
                                return;
                            }
                            await this.questionCatalogApi.deleteQuestionCatalogById(id);
                            this.showSuccessToast();
                        } catch(e) {
                            if (axios.isAxiosError(e)) {
                                console.error(e);
                                this.showErrorToast();
                            } else {
                                console.error(`An unknown error occurred while delete a question catalog.`);
                                console.error(e);
                                this.showErrorToast();
                            }
                        } finally {
                            EventBus.$emit(EventBus.VIEWS.ADMIN.REFRESH_TABLE);
                            this.$store.commit('waitingModal/CLOSE_AND_RESET');
                        }
                    }

                    this.$store.commit('confirmationModal/WAIT_FOR_CONFIRMATION', {
                        header: this.$t('questionCatalogDeleteModal.header', { description: questionCatalogDto.description }).toString(),
                        footerConfirmLabel: this.$t('yes').toString(),
                        footerCancelLabel: this.$t('no').toString(),
                        content: this.$t('questionCatalogDeleteModal.content', { description: questionCatalogDto.description }).toString(),
                        onConfirmCallback: () => deleteFunction(questionCatalogDto.id as number),
                        onCancelCallback: () => this.$store.commit('confirmationModal/CLOSE_AND_RESET'),
                        showLightboxCloseIcon: false
                    } as ConfirmationModalProps)
                }
            );

            EventBus.$on(EventBus.VIEWS.ADMIN.QUESTION_CATALOG_DUPLICATE_BUTTON_CLICKED,
                async (questionCatalogDto: QuestionCatalogDto) => {
                    this.selectedQuestionCatalogDetailsCandidate = _.cloneDeep(questionCatalogDto)
                    this.selectedQuestionCatalogDetailsCandidate.id = 0;
                    this.selectedQuestionCatalogDetailsCandidate.questionGroups?.forEach(qg => {
                        qg.id = 0;
                        qg.mainQuestions?.forEach(mq => {
                            mq.id = 0;
                            mq.subQuestions?.forEach(sq => {
                                sq.id = 0;
                            })
                        });
                    });
                    this.selectedQuestionCatalogDetailsCandidate.testApproval = false;
                    this.selectedQuestionCatalogDetailsCandidate.approval = false;
                    this.selectedQuestionCatalogDetailsCandidate.testApprovalDate = undefined;
                    this.selectedQuestionCatalogDetailsCandidate.approvalDate = undefined;
                    this.selectedQuestionCatalogDetailsCandidate.testApprovalUserId = undefined;
                    this.selectedQuestionCatalogDetailsCandidate.approvalUserId = undefined;

                    if (this.selectedQuestionCatalogDetailsCandidate) {
                        this.toggleDetailsModal();
                    }
                }
            );

            EventBus.$on(EventBus.VIEWS.ADMIN.QUESTION_CATALOG_EDITED, (questionCatalogDto: QuestionCatalogDto) => {
                this.selectedQuestionCatalogDetailsCandidate = questionCatalogDto;
            });
        },
        async loadInitialData (): Promise<void> {
            this.$store.commit('waitingModal/WAITING', {
                title: this.$t(WAITING_MODAL_DEFAULT_TITLE),
                content: this.$t(WAITING_MODAL_DEFAULT_TEXT)
            });

            // TODO: Do we even need this here?

            this.dataLoaded = true;
            this.$store.commit('waitingModal/CLOSE_AND_RESET');
        },
        async checkIfReportExists (questionCatalogId: number): Promise<boolean> {
            const response = await apiCallWithErrorHandling(
                this.reportApi.getReports,
                {
                    ids: undefined,
                    questionCatalogIds: [questionCatalogId],
                    catalogTypeIds: undefined,
                    reportTypeIds: undefined,
                    reportYears: undefined,
                    locationIds: undefined,
                    languageIds: undefined,
                    approvalStatus: undefined,
                    sortDirection: SortType.Ascending,
                    sortProperty: ReportSortColumn.Description,
                    page: 1,
                    size: 5,
                    Term: undefined,
                    TermToLower: undefined,
                },
                (this as any).$pui.toast,
                {
                    errorTitle: this.$t('questionCatalogDetails.reportCheckToast.errorTitle').toString(),
                    errorText: this.$t('questionCatalogDetails.reportCheckToast.errorText').toString(),
                    waitingTitle: this.$t(WAITING_MODAL_DEFAULT_TITLE).toString(),
                    waitingText: this.$t(WAITING_MODAL_DEFAULT_TEXT).toString(),
                },
                this.$store
            );

            if (response && response?.result) {
                return ((response as ReportListEndpointResponseOkResponse)?.result?.items?.length ?? 0) > 0;
            }

            // we should never get here
            throw 'Report loading crashed for unknown reason!';

        },
        toggleDetailsModal(): void {
            try {
                (this.$refs.detailsQuestionCatalogModalRef as any).isOpen = !((this.$refs.detailsQuestionCatalogModalRef as any).isOpen);
                if (!((this.$refs.detailsQuestionCatalogModalRef as any).isOpen)) {
                    this.selectedQuestionCatalogDetailsCandidate = undefined;
                }
            } catch (err) {
                // Catching buggy pebble ui type error
                // console.log(err);
            }
        },
        showErrorToast (): void {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('questionCatalogDeleteToast.error.title').toString(),
                copy: this.$t('questionCatalogDeleteToast.error.text').toString(),
            });
        },
        showSuccessToast (): void {
            (this as any).$pui.toast({
                type: 'success',
                title: this.$t('questionCatalogDeleteToast.success.title').toString(),
                copy: this.$t('questionCatalogDeleteToast.success.text').toString(),
            });
        },
        filterChangedHandler (filters: QuestionCatalogListEndpointRequest): void {
            filterChangedHandler(filters, QUESTIONNAIRE_DEFAULT_FILTER, this.filterValues);
        }
    }
});

export default QuestionCatalogAdministration;
