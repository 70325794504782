
import Vue, { PropType } from 'vue';
import { QuestionGroupDto } from '@/service-proxies/service-proxies.g';
import _ from 'lodash';
import { Validation } from '@/components/questionnaire-administration/questionnaire-details/contracts/validation';
import AutoCorrectFormInput from '@/components/auto-correct-form-input/auto-correct-form-input.vue';

export enum FormValidations {
    Description = 'description',
}

const QuestionGroupForm = Vue.extend({
    name: 'QuestionGroupForm',
    components: {
        AutoCorrectFormInput,
    },
    props: {
        value: {
            type: Object as PropType<QuestionGroupDto>,
            required: true,
        },
        totalLength: {
            type: Number,
            required: true,
        },
        isEditable: {
            type: Boolean,
            default: true,
        }
    },
    data (): {
        internalQuestionGroup: QuestionGroupDto;
        validations: Record<FormValidations, Validation>;
        minLength: number;
        } {
        return {
            internalQuestionGroup: _.cloneDeep(this.value),
            validations: {
                'description': {
                    maxLength: 100,
                    minLength: 2,
                    isValid: true,
                    helperText: '',
                }
            },
            minLength: 2
        }
    },
    watch: {
        value: {
            handler (newVal: QuestionGroupDto): void {
                this.internalQuestionGroup = _.cloneDeep(newVal);
            },
            deep: true,
        }
    },
    methods: {
        modifyAndEmitChange (obj: any, prop: string, value: any): void {
            Vue.set(obj, prop, value);
            this.$emit('input', this.internalQuestionGroup);
        }
    },
    computed: {
        minLengthString(): string {
            if(this.validations.description.minLength){
                return (this.validations.description.minLength + 1).toString()
            } else {
                return ''
            }
        },
        isModified (): boolean {
            return !_.isEqual(this.value, this.internalQuestionGroup);
        },
        sumWeighting (): number {
            return this.internalQuestionGroup.getSumWeighting();
        }
    }
});
export default QuestionGroupForm;
