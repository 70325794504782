import {
    MainQuestionDto,
    QuestionCatalogDto,
    QuestionGroupDto,
    ReportRowDto,
    ReportRowReply,
    SubQuestionDto
} from '@/service-proxies/service-proxies.g';
import { NodeTypes } from '@/components/questionnaire-administration/questionnaire-details/contracts/enums';
import { CombinedVueInstance, Vue } from 'vue/types/vue';
import { ITreeNode, Tree, TreeNode } from 'vue-tree-list';

// eslint-disable-next-line sonarjs/cognitive-complexity,sonarjs/no-identical-functions
export const catalogAsTree =  (catalog: QuestionCatalogDto, disableButtons = false): Tree =>
{
    const treeArray: ITreeNode[] = [];
    if (catalog.questionGroups) {
        const sortedQuestionGroups = [...catalog.questionGroups]
            .sort((x, y) => (x.position ?? 0) - (y.position ?? 0));
        for (const questionGroup of sortedQuestionGroups) {
            treeArray.push({
                id: `QG${questionGroup.position}`,
                name: questionGroup.description ?? '',
                pid: 0,
                children: [],
                dragDisabled: true,
                editNodeDisabled: true,
                addLeafNodeDisabled: disableButtons,
                addTreeNodeDisabled: disableButtons,
                delNodeDisabled: disableButtons,
            });
            if (questionGroup.mainQuestions) {
                const sortedMainQuestions = [...questionGroup.mainQuestions]
                    .sort((x, y) => (x.position ?? 0) - (y.position ?? 0));
                for (const mainQuestion of sortedMainQuestions) {
                    const questionGroupNode = treeArray[treeArray.length - 1];
                    questionGroupNode.children?.push({
                        id: `QG${questionGroup.position}-MQ${mainQuestion.position}`,
                        name: mainQuestion.text ?? '',
                        pid: questionGroupNode.id,
                        children: [],
                        dragDisabled: true,
                        editNodeDisabled: true,
                        addLeafNodeDisabled: disableButtons,
                        addTreeNodeDisabled: disableButtons,
                        delNodeDisabled: disableButtons,
                    })
                    if (mainQuestion.subQuestions) {
                        const sortedSubQuestions = [...mainQuestion.subQuestions]
                            .sort((x, y) => (x.position ?? 0) - (y.position ?? 0));
                        for (const subQuestion of sortedSubQuestions) {
                            if (questionGroupNode.children) {
                                const mainQuestionNode = questionGroupNode.children[questionGroupNode.children.length - 1];
                                mainQuestionNode.children?.push({
                                    id: `QG${questionGroup.position}-MQ${mainQuestion.position}-SQ${subQuestion.position}`,
                                    name: subQuestion.text ?? '',
                                    pid: mainQuestionNode.id,
                                    dragDisabled: true,
                                    isLeaf: false,
                                    editNodeDisabled: true,
                                    addLeafNodeDisabled: disableButtons,
                                    addTreeNodeDisabled: disableButtons,
                                    delNodeDisabled: disableButtons,
                                })
                            }
                        }
                    }
                }
            }
        }
    }
    return new Tree(treeArray);
}

export const onSelectedTreeElement = (
    event: TreeNode,
    vueInstance: CombinedVueInstance<Vue, {currentDiv: HTMLElement | undefined; selectedTab: number; selectedNode: QuestionGroupDto | MainQuestionDto | SubQuestionDto | undefined; selectedType: NodeTypes; selectedQuestionGroup: QuestionGroupDto | undefined; selectedMainQuestion: MainQuestionDto | undefined; selectedSubQuestion: SubQuestionDto | undefined; internalQuestionCatalog: QuestionCatalogDto}, {}, {}, {}, {}>,
): void => {
    const nodeId = event.id as string;
    const level = nodeId.split('-').length;
    const position = Number(nodeId.split('-')[level - 1].substring(2));
    const questionGroupPosition = level > 0
        ? Number(nodeId.split('-')[0].substring(2))
        : 0;
    const mainQuestionPosition = level > 1
        ? Number(nodeId.split('-')[1].substring(2))
        : 0;
    if (level === 1) {
        vueInstance.selectedType = NodeTypes.QuestionGroup;
        if (vueInstance.internalQuestionCatalog.questionGroups) {
            vueInstance.selectedQuestionGroup = vueInstance.internalQuestionCatalog
                .questionGroups.filter(x => x.position === (Number(position)))[0] as QuestionGroupDto;
            vueInstance.selectedNode = vueInstance.selectedQuestionGroup
        }
    } else if (level === 2) {
        vueInstance.selectedType = NodeTypes.MainQuestion;
        if (vueInstance.internalQuestionCatalog.questionGroups) {
            vueInstance.selectedQuestionGroup = vueInstance.internalQuestionCatalog
                .questionGroups.filter(x => x.position === (Number(questionGroupPosition)))[0];
            vueInstance.selectedMainQuestion = vueInstance.selectedQuestionGroup
                .mainQuestions?.filter(x => x.position === (Number(position)))[0] as MainQuestionDto;
            vueInstance.selectedNode = vueInstance.selectedMainQuestion;
        }
    } else if (level === 3) {
        vueInstance.selectedType = NodeTypes.SubQuestion;
        if (vueInstance.internalQuestionCatalog.questionGroups) {
            vueInstance.selectedQuestionGroup = vueInstance.internalQuestionCatalog
                .questionGroups.filter(x => x.position === (Number(questionGroupPosition)))[0];
            vueInstance.selectedMainQuestion = vueInstance.selectedQuestionGroup
                .mainQuestions?.filter(x => x.position === (Number(mainQuestionPosition)))[0]
            vueInstance.selectedSubQuestion = vueInstance.selectedMainQuestion?.subQuestions?.filter(x => x.position === (Number(position)))[0] as SubQuestionDto;
            vueInstance.selectedNode = vueInstance.selectedSubQuestion;
        }
    }

    const el = document.getElementById(nodeId)?.children[1] as HTMLElement;
    if (el) {
        if (vueInstance.currentDiv) {
            vueInstance.currentDiv.style.backgroundColor = '';
        }
        el.style.backgroundColor = '#F5F5F5';
        vueInstance.currentDiv = el;
    }
}

export const riskPresent = (mainQuestionDto: MainQuestionDto, reportRow: ReportRowDto): boolean => {
    // Not Answered/not applicable? Then there can be no risk
    if (reportRow.replyYN === Number(ReportRowReply.NotYetAnswered) ||
        reportRow.replyYN === Number(ReportRowReply.NotApplicable)
    ) {
        return false;
    }

    // Otherwise, we need to check if there is a risk, this depends on the configuration of the question and
    // the answer.
    const temp = reportRow.replyYN === Number(ReportRowReply.No);
    return temp === mainQuestionDto.noRiskWhenAnsweredWithYes;
}
