import {
    AverageReportGetEndpointResponse,
    AverageReportGetEndpointResponseOkResponse,
    AverageReportsServiceProxy,
    CalculateReportEvaluationServiceProxy,
    CalculateReprotEvaluationEndpointResponseOkResponse, IReportStatusEndpointResponseOkResponse,
    ReportStatusEndpointRequest, RiskRating, WorkflowsServiceProxy
} from './../service-proxies/service-proxies.g';
import { AddPdfRequest, GetPdfRequest, ReportListEndpointRequest } from '@/models';
import {
    IReportDto,
    ReportAddEndpointResponseOkResponse,
    ReportApprovalStatus,
    ReportDto,
    ReportEditEndpointResponseOkResponse,
    ReportGetEndpointResponseOkResponse,
    ReportListEndpointResponseOkResponse, ReportPDFAddEndpointResponseOkResponse,
    ReportPDFGetEndpointResponseOkResponse,
    ReportRowReply,
    ReportRowType,
    ReportsServiceProxy,
    ReportType,
} from '@/service-proxies/service-proxies.g';

/**
 * @name ReportService
 * @description This class is used to query the backend server for report data
 */
export class ReportService {
    private reportServiceProxy: ReportsServiceProxy;
    private averageReportServiceProxy: AverageReportsServiceProxy;
    private reportCalculationServiceProxy: CalculateReportEvaluationServiceProxy;
    private workflowServiceProxy: WorkflowsServiceProxy;

    constructor() {
        this.reportServiceProxy = new ReportsServiceProxy();
        this.averageReportServiceProxy = new AverageReportsServiceProxy();
        this.reportCalculationServiceProxy = new CalculateReportEvaluationServiceProxy();
        this.workflowServiceProxy = new WorkflowsServiceProxy();
    }

    /**
     * Edit the permissions of the report.
     * @param editDto Edit Request.
     */
    public editReport = async (editDto: IReportDto): Promise<ReportEditEndpointResponseOkResponse> => {
        if (!editDto.id) {
            throw 'Dto has no valid id!';
        }

        const result = await this.reportServiceProxy.editReport(editDto.id, new ReportDto(editDto));
        if (result.result) {
            this.replaceEnums(result.result);
        }
        return result;
    }

    /**
     * Get filtered list of reports.
     * @param filter Report Filter.
     */
    public getReports = async (filter: ReportListEndpointRequest | undefined = undefined): Promise<ReportListEndpointResponseOkResponse> => {
        const result = await this.reportServiceProxy.listReport(
            filter?.ids,
            filter?.questionCatalogIds,
            filter?.catalogTypeIds,
            filter?.reportTypeIds,
            filter?.reportYears,
            filter?.locationIds,
            filter?.languageIds,
            filter?.approvalStatus,
            filter?.sortDirection,
            filter?.sortProperty,
            filter?.page,
            filter?.size,
            filter?.Term,
            filter?.TermToLower,
        );
        if (result.result?.items) {
            for (const el of result.result.items) {
                this.replaceEnums(el);
            }
        }
        return result;
    }

    /**
     * Get filtered list of reports (proxy function).
     * @param filter Report Filter.
     */
    public getItems = async (filter: ReportListEndpointRequest | undefined = undefined): Promise<ReportListEndpointResponseOkResponse> => {
        return this.getReports(filter);
    }

    /**
     * Get report by id.
     * @param id
     */
    public getReport = async (id: number): Promise<ReportGetEndpointResponseOkResponse> => {
        const result = await this.reportServiceProxy.getReport(id);
        if (result.result) {
            this.replaceEnums(result.result);
        }
        return result;
    }

    /**
     * Delete a report by id.
     * @param id
     */
    public deleteReportById = async (id: number): Promise<void> => {
        return this.reportServiceProxy.deleteReport(id);
    }

    /**
     * Add a report.
     * @param reportDto
     */
    public addReport = async (reportDto: ReportDto): Promise<ReportAddEndpointResponseOkResponse> => {
        const result = await this.reportServiceProxy.addReport(reportDto);
        if (result.result) {
            this.replaceEnums(result.result);
        }
        return result;
    }

    /**
     * Get pdf by id.
     * @param request
     */
    public getPDF = async (request: GetPdfRequest): Promise<ReportPDFGetEndpointResponseOkResponse> => {
        return await this.reportServiceProxy.getPDFReport(request.id, request.reportType);
    }

    /**
     * Add a pdf.
     * @param request
     */
    public addPDF = async (request: AddPdfRequest): Promise<ReportPDFAddEndpointResponseOkResponse> => {
        return await this.reportServiceProxy.addPDFReport(
            request.id,
            request.reportType,
            request.pdf,
            request.useLocalTestMode,
            request.suffix);
    }

    /**
     * Helper function which mutates the given Report and replaces all enums with their integer values.
     */
    // eslint-disable-next-line sonarjs/cognitive-complexity
    private replaceEnums = (reportDto: Partial<ReportDto & AverageReportGetEndpointResponse> | undefined): void => {
        if (!reportDto) {
            return;
        }

        // Yes/No reply of rows
        if (reportDto.reportRows) {
            for(const row of reportDto.reportRows) {
                if (typeof row.replyYN === 'string') {
                    row.replyYN = row.replyYN ? ReportRowReply[row.replyYN] as unknown as ReportRowReply : row.replyYN;
                }

                if (typeof row.reportRowType === 'string') {
                    row.reportRowType = row.reportRowType ? ReportRowType[row.reportRowType] as unknown as ReportRowType : row.reportRowType;
                }

                if (typeof row.riskRating === 'string') {
                    row.riskRating = row.riskRating ? RiskRating[row.riskRating] as unknown as RiskRating : row.riskRating;
                }
            }
        }

        // Report Status
        if (reportDto.approvalStatus && typeof reportDto.approvalStatus === 'string') {
            reportDto.approvalStatus = ReportApprovalStatus[reportDto.approvalStatus] as unknown as ReportApprovalStatus;
        }

        // Report Type
        if (reportDto.reportType && typeof reportDto.reportType === 'string') {
            reportDto.reportType = ReportType[reportDto.reportType] as unknown as ReportType;
        }
    }

    /**
     * Get average report for provided report ids.
     * @param ids
     */
    public getAverageReport = async (ids: number[]): Promise<AverageReportGetEndpointResponseOkResponse> => {
        const result = await this.averageReportServiceProxy.getAverageReport(ids);

        // replace enums in report
        if (result.result?.locationReportParticipationMap) {
            for (const el of result.result?.locationReportParticipationMap) {
                this.replaceEnums(el.report);
            }
            this.replaceEnums(result.result);
        }

        // replace enums in question catalog
        result.result?.questionCatalog?.replaceEnums();

        return result;
    }

    /**
     * Trigger the evaluation of a report.
     * @param reportId
     */
    public triggerReportEvaluation = async (reportId: number): Promise<CalculateReprotEvaluationEndpointResponseOkResponse> => {
        return await this.reportCalculationServiceProxy.calculateReportEvaluation(reportId);
    }

    /**
     * Update the status of a report (usually called by workflow/meta data).
     * @param reportStatus
     */
    public updateReportStatus = async (reportStatus: ReportStatusEndpointRequest): Promise<IReportStatusEndpointResponseOkResponse> => {
        const result = await this.workflowServiceProxy.reportStatus(reportStatus);
        if (result.result) {
            this.replaceEnums(result.result);
        }
        return result;
    }
}
