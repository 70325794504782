import { LocationDto } from '@/service-proxies/service-proxies.g';

export class FakeLocationService {
    private readonly locations: LocationDto[] = [];

    constructor(locations: LocationDto[]) {
        this.locations = locations;
    }

    getItems (filter: { Term: string; page: number; size: number }): { result: { items: LocationDto[] }} {
        if (filter) {
            return {
                result: {
                    items: this.locations
                        .filter(x => x.locationName?.includes(filter.Term) || x.locationAbbr?.includes(filter.Term))
                        .slice((filter.page - 1) * filter.size, filter.page * filter.size)
                }
            };

        }
        return { result: { items: this.locations }};
    }
}
