
import Vue from 'vue';

const CubicBezier = Vue.extend({
    name: 'CubicBezier',
    props: {
        index: Number,
        halfSize: Number,
        topHeight: Number,
        bottomHeight: Number,
        radius: Number,
        distance: Number
    },
    computed: {
        calcXPosition(): number {
            return this.distance * this.index + (this.distance * 0.5);
        },
        d(): string {
            return `M${this.halfSize},${(this.topHeight + this.radius)} C${this.halfSize},${this.halfSize} ${this.calcXPosition},${this.halfSize} ${this.calcXPosition},${this.bottomHeight}`
        }
    },
    methods: {
    }
});
export default CubicBezier;
