import {
    CatalogTypePermissionDto, LocationDto,
    PDFReportType,
    QuestionCatalogDto,
    ReportApprovalStatus,
    UserDto
} from '@/service-proxies/service-proxies.g';
import { ReportService } from '@/services/report-service';
import { ReportDto, ReportType } from '@/service-proxies/service-proxies.g';
import { QuestionCatalogListEndpointRequest, ReportListEndpointRequest } from '@/models';
import { filterChangedHandler, QUESTIONNAIRE_DEFAULT_FILTER, REPORT_DEFAULT_FILTER } from '@/utils/table-utils';
import { SelectionChangedEvent } from 'ag-grid-community';
import _ from 'lodash';
import Vue, { Ref } from 'vue';
import ReadonlyQuestionnaireTable from './partials/readonly-questionnaire-table.vue';
import ReadonlyReportTable from './partials/readonly-report-table.vue';
import ReportAverageMerge from './partials/report-average-merge.vue';
import PdfCreator from '../pdf-creator/pdf-creator.vue';
import { WAITING_MODAL_DEFAULT_TEXT, WAITING_MODAL_DEFAULT_TITLE } from '@/store/modules/waiting-modal';
import { UserService } from '@/services/user-service';
import { VendorOptions } from '../table/contracts/table-data';
import { ReportTableData } from '../report-table/contracts/table-data';
import { QuestionCatalogTableData } from '../questionnaire-config-table/contracts/table-data';

const AverageReport = Vue.extend({
    name: 'AverageReport',
    components: {
        ReadonlyQuestionnaireTable,
        ReadonlyReportTable,
        ReportAverageMerge,
        PdfCreator
    },
    data (): {
        questionnaireFilterValues: QuestionCatalogListEndpointRequest;
        reportFilterValues: ReportListEndpointRequest;
        reportIsDownloadable: boolean;
        averageReportCandidates: ReportDto[];
        reportTranslationMap: { [key: number]: string };
        reportService: ReportService;
        tempPdfCreationData: any;
        renderOnlyReport: ReportDto | undefined;
        selectedQuestionCatalog: QuestionCatalogDto | undefined;
        pdfReportType: number;
        catalogApprovalUser: UserDto | undefined;
        userApi: UserService;
        reportVendorOptions: VendorOptions<ReportTableData>;
        questionaireVendorOptions: VendorOptions<QuestionCatalogTableData>;
        } {
        return {
            questionnaireFilterValues: {
                ..._.cloneDeep(QUESTIONNAIRE_DEFAULT_FILTER),
                catalogTypeIds: (this.$store.getters['userAccessManagement/getUserCatalogTypes'] as CatalogTypePermissionDto[])
                    .map(x => x.catalogTypeId).filter(x => x) as number[]
            },
            reportFilterValues: {
                ...(_.cloneDeep(REPORT_DEFAULT_FILTER)),
                ...{
                    approvalStatus: [
                        ReportApprovalStatus.ApprovedByEditor,
                        ReportApprovalStatus.ApprovedByApprover,
                        ReportApprovalStatus.Finalized
                    ],
                    questionCatalogIds: [-1],
                    reportTypeIds: [
                        ReportType.InterimReport,
                        ReportType.MainReport
                    ],
                    locationIds: (this.$store.getters['userAccessManagement/getReaderUserLocations'] as LocationDto[])
                        .map(x => x.id).filter(x => x) as number[]
                }
            },
            reportIsDownloadable: false,
            averageReportCandidates: [],
            reportTranslationMap: {
                [ReportType.MainReport]: this.$t('newReportModal.formLabels.mainReport').toString(),
                [ReportType.InterimReport]: this.$t('newReportModal.formLabels.interimReport').toString(),
            },
            reportService: new ReportService(),
            tempPdfCreationData: undefined,
            renderOnlyReport: undefined,
            selectedQuestionCatalog: undefined,
            pdfReportType: PDFReportType.AverageReport,
            catalogApprovalUser: undefined,
            userApi: new UserService(),
            reportVendorOptions: {
                data: _.range(500).map(() => ({
                    'meta': {
                        reportDto: new ReportDto(),
                    },
                    'col-description': '',
                    'col-catalogTitle': '',
                    'col-year': undefined,
                    'col-type': '',
                    'col-version': undefined,
                    'col-details': '',
                    'col-reportType': '',
                    'col-location': '',
                    'col-approval-state': undefined,
                })),
                columnDefs: [],
            },
            questionaireVendorOptions: {
                data:
                    _.range(500).map(() => ({
                        'meta': {
                            questionCatalogDto: new QuestionCatalogDto(),
                        },
                        'col-description': '',
                        'col-year': undefined,
                        'col-language': '' ,
                        'col-type': '',
                        'col-version': undefined,
                        'col-testApproval': '',
                        'col-approval':  '',
                        'col-approvalDate': '',
                        'col-deadlineHReport': '',
                        'col-deadlineZReport': '',
                        'col-details': '',
                    })),
                columnDefs: [],
            }
        }
    },
    mounted (): void {
        this.$watch(
            () => (this.$refs['readonlyReportTableRef'] as any).selectedReportRows,
            val => {
                this.averageReportCandidates = val;
                this.reportIsDownloadable = !!val.length;
            }
        );
    },
    methods: {
        questionnaireFilterChangedHandler (filters: QuestionCatalogListEndpointRequest): void {
            filterChangedHandler(filters, QUESTIONNAIRE_DEFAULT_FILTER, this.questionnaireFilterValues);
        },
        reportFilterChangedHandler (filters: ReportListEndpointRequest): void {
            filterChangedHandler(filters, REPORT_DEFAULT_FILTER, this.reportFilterValues);
        },
        async onQuestionnaireSelectionChange (event: SelectionChangedEvent): Promise<void> {
            const nodes = event.api.getSelectedNodes();
            const ids = nodes.map(n => n.data.meta.questionCatalogDto?.id);
            const catalog = nodes.map(n => n.data.meta.questionCatalogDto)[0];
            this.catalogApprovalUser = catalog.approvalUserId
                ? (await this.userApi.getUser(catalog.approvalUserId)).result
                : undefined;
            this.reportFilterValues.questionCatalogIds = ids;
            (this.$refs['readonlyReportTableRef'] as any).selectedReportRows = [];
            if((this.$refs['readonlyReportTableRef'] as any).filterChangedEvent){
                (this.$refs['readonlyReportTableRef'] as any).filterChangedEvent.api.setFilterModel(null);
            }
            (this.$refs['readonlyReportTableRef'] as any).filterValues.page = 1;
        },
        toggleAverageReportSummaryModal(): void {
            try {
                (this.$refs['averageReportSummaryModalRef'] as any).isOpen = !((this.$refs['averageReportSummaryModalRef'] as any).isOpen);
            } catch (err) {
                // Catching buggy pebble ui type error
                // console.log(err);
            }
        },
        async generateAverageReport(): Promise<void> {
            try {
                this.$store.commit('waitingModal/WAITING', {
                    title: this.$t(WAITING_MODAL_DEFAULT_TITLE),
                    content: this.$t(WAITING_MODAL_DEFAULT_TEXT)
                });
                const { result } = await this.reportService.getAverageReport(this.averageReportCandidates.map(r => r.id) as number[]);
                this.tempPdfCreationData = result;
                this.renderOnlyReport = new ReportDto({...result});
                this.selectedQuestionCatalog = result?.questionCatalog;
                await this.$nextTick();
                if (!this.renderOnlyReport || !this.selectedQuestionCatalog) {
                    throw Error('Data from api not set. Aborting...');
                }
                await(this.$refs['pdfCreatorRef'] as any).createReport({
                    openInTab: false,
                    showWaitingModal: true,
                });
                this.toggleAverageReportSummaryModal();
            } catch (error) {
                this.showErrorToast();
                console.error(error);
            } finally {
                this.$store.commit('waitingModal/CLOSE_AND_RESET');
            }
        },
        handleFileCreation(blob: Blob): void {
            const dateOfCreationInMs = new Date().getTime();
            const kid = this.$store.getters['userAccessManagement/getUserKid'];
            const a = document.createElement('a');
            a.setAttribute('download', this.$t('averageReport.filename', { kid, uid: dateOfCreationInMs }).toString());
            a.setAttribute('href', window.URL.createObjectURL(blob));
            a.click();
            a.remove();
        },
        showErrorToast (): void {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('averageReport.error.title').toString(),
                copy: this.$t('averageReport.error.text').toString(),
            });
        },
    }
});

export default AverageReport;
