import { StartWorkflowRequest } from '@/models';
import {
    AdminRevokeEndpointResponseOkResponse,
    ApproverApproveEndpointResponseOkResponse,
    ApproverRejectEndpointResponseOkResponse,
    EditorApproveEndpointResponseOkResponse,
    EditorRevokeEndpointResponseOkResponse, IReportStatusEndpointResponseOkResponse, ReportStatusEndpointRequest,
    WorkflowsServiceProxy,
    WorkflowStartEndpointResponseOkResponse
} from '@/service-proxies/service-proxies.g';

/**
 * @name WorkflowService
 * @description This class is used to interact with the workflow
 */
export class WorkflowService {
    private workflowServiceProxy: WorkflowsServiceProxy;

    constructor() {
        this.workflowServiceProxy = new WorkflowsServiceProxy();
    }

    /**
     * Start a workflow (release a report).
     * @param request
     */
    public startWorkflow = async (request: StartWorkflowRequest): Promise<WorkflowStartEndpointResponseOkResponse> => {
        return await this.workflowServiceProxy.startWorkflow(
            request.reportId,
            request.editorUserId,
            request.locationName,
            request.reportTypeGerman,
            request.reportTypeEnglish,
            request.date,
            request.cancelToken
        );
    }

    /**
     * Release a report.
     * @param reportId
     */
    public releaseReport = async ({ reportId, date }: { reportId: number; date: string }): Promise<EditorApproveEndpointResponseOkResponse> => {
        return await this.workflowServiceProxy.editorApprove(reportId, date);
    }

    /**
     * Approve a report.
     * @param reportId
     */
    public approveReport = async ({ reportId, date }: { reportId: number; date: string }): Promise<ApproverApproveEndpointResponseOkResponse> => {
        return await this.workflowServiceProxy.approverApprove(reportId, date);
    }

    /**
     * Approve a report.
     * @param reportId
     */
    public rejectReport = async (reportId: number): Promise<ApproverRejectEndpointResponseOkResponse> => {
        return await this.workflowServiceProxy.approverReject(reportId);
    }

    /**
     * Revoke an approval.
     * @param reportId
     */
    public revokeApproval = async (reportId: number): Promise<AdminRevokeEndpointResponseOkResponse> => {
        return await this.workflowServiceProxy.adminRevoke(reportId);
    }

    /**
     * Revoke a release.
     * @param reportId
     */
    public revokeRelease = async (reportId: number): Promise<EditorRevokeEndpointResponseOkResponse> => {
        return await this.workflowServiceProxy.editorRevoke(reportId);
    }
}
