import {
    CatalogTypeDto, LocationCatalogTypeDto,
    LocationDto,
    LocationListEndpointResponse,
    LocationSortColumn,
    SortType
} from '@/service-proxies/service-proxies.g';
import { LocationService } from '@/services/location-service';
import axios, { AxiosError } from 'axios';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import { applicationState } from '@/store';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';

export type LocationsState = {
    locationsApi: LocationService;
    locations: LocationDto[];
}

export type LocationsGetters = {
    locations: (state: LocationsState, getters: LocationsGetters, rootState: typeof applicationState, rootGetters: any) => LocationDto[];
} & GetterTree<LocationsState, typeof applicationState>

export type LocationsActions = {
    loadLocations: ({ commit, state }: ActionContext<LocationsState, typeof applicationState>) => Promise<number>;
} & ActionTree<LocationsState, typeof applicationState>

export type LocationsMutations = {
    SET_LOCATIONS: (state: LocationsState, locations: LocationListEndpointResponse | undefined) => void;
    SET_LOCATION: (state: LocationsState, location: LocationDto | undefined) => void;
} & MutationTree<LocationsState>

export type LocationModule = {
    state: LocationsState;
    getters: LocationsGetters;
    mutations: LocationsMutations;
    actions: LocationsActions;
} & Module<LocationsState, typeof applicationState>

const state: LocationsState = {
    locationsApi: new LocationService(),
    locations: [],
}

const getters: LocationsGetters = {
    locations: (state: LocationsState, getters: LocationsGetters, rootState: typeof applicationState, rootGetters: any): LocationDto[] => {
        const catalogTypes = rootGetters['catalogTypes/catalogTypes'] as CatalogTypeDto[];
        const testLocation = new LocationDto({
            id: -1,
            locationAbbr: 'Test',
            locationName: 'Test Location',
            locationCatalogTypeDtos: catalogTypes.map((x, idx) => new LocationCatalogTypeDto({
                id: idx + 1,
                locationId: -1,
                catalogTypeId: x.id,
            }
            ))
        });
        return [...state.locations, testLocation];
    }
}

const actions: LocationsActions = {
    async loadLocations ({ commit, state }: ActionContext<LocationsState, typeof applicationState>): Promise<number> {
        try {
            const locations = await state.locationsApi.getLocations(
                {
                    sortDirection: SortType.Ascending,
                    sortProperty: LocationSortColumn.LocationAbbr,
                    locationIds: undefined,
                    catalogTypeIds: undefined,
                    page: 1,
                    size: 10000,
                    Term: undefined,
                    TermToLower: undefined
                }
            );
            commit('SET_LOCATIONS', locations.result);
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
            } else {
                console.error(`An unknown error occurred while fetching the catalog type data.`);
                console.error(e);
                return HttpStatusCodes.InternalServerError;
            }
        }
        return HttpStatusCodes.Ok;
    }
}

const mutations: LocationsMutations = {
    SET_LOCATIONS (state: LocationsState, locations: LocationListEndpointResponse | undefined): void {
        if (locations) {
            state.locations = locations.items ?? [];
        }
    },
    SET_LOCATION (state: LocationsState, location: LocationDto | undefined): void {
        if (location) {
            state.locations = [
                ...state.locations.filter(x => x.id !== location?.id),
                location
            ];
        }
    },
}

const namespaced = true;

export const locations: LocationModule = {
    namespaced: namespaced,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};
