
import Vue from 'vue';
import { FormSelectOption } from '@/models/pebble-ui';
import { Validation } from '@/components/questionnaire-administration/questionnaire-details/contracts/validation';
import AutoCorrectFormInput from '@/components/auto-correct-form-input/auto-correct-form-input.vue';
import QuestionnaireConfigTable from '@/components/questionnaire-config-table/questionnaire-config-table.vue';
import ReportTable from '@/components/report-table/report-table.vue';
import { QuestionCatalogListEndpointRequest, ReportListEndpointRequest } from '@/models';
import {
    CatalogTypeDto,
    LanguageDto,
    LocationDto,
    QuestionCatalogDto,
    QuestionCatalogSortColumn,
    QuestionCatalogStatus,
    ReportApprovalStatus,
    ReportDto,
    ReportRowDto,
    ReportRowType,
    ReportSortColumn,
    ReportType,
    SortType
} from '@/service-proxies/service-proxies.g';
import _ from 'lodash';
import { filterChangedHandler } from '@/utils/table-utils';
import { QuestionCatalogTableData } from '@/components/questionnaire-config-table/contracts/table-data';
import { ReportTableData } from '@/components/report-table/contracts/table-data';
import moment from 'moment-timezone';
import { RowSelectedEvent } from 'ag-grid-community';
import { QuestionCatalogService } from '@/services/question-catalog-service';
import { apiCallWithErrorHandling } from '@/services/utils';
import { ReportService } from '@/services/report-service';

const questionCatalogTableDefaultFilterValues = {
    sortDirection: SortType.Descending,
    sortProperty: QuestionCatalogSortColumn.Year,
    questionCatalogIds: undefined,
    catalogTypeIds: [-1],
    description: undefined,
    reportYear: undefined,
    catalogVersion: undefined,
    questionCatalogStatus: [QuestionCatalogStatus.FinalRelease],
    page: 1,
    size: 12,
    Term: undefined,
    TermToLower: undefined
}

const reportTableDefaultFilterValues: ReportListEndpointRequest = {
    ids: undefined,
    questionCatalogIds: undefined,
    catalogTypeIds: undefined,
    reportTypeIds: [-1],
    reportYears: [-1],
    locationIds: undefined,
    languageIds: undefined,
    approvalStatus: [ReportApprovalStatus.ApprovedByApprover, ReportApprovalStatus.Finalized],
    sortDirection: SortType.Descending,
    sortProperty: ReportSortColumn.Description,
    page: 1,
    size: 12,
    Term: undefined,
    TermToLower: undefined
}

const NewReportModal = Vue.extend({
    name: 'NewReportModal',
    components: {
        ReportTable,
        QuestionnaireConfigTable,
        AutoCorrectFormInput
    },
    props: {
        testReportMode: {
            type: Boolean,
            default: false,
        }
    },
    data (): {
        selectedReportType: ReportType;
        selectedLocation: LocationDto | undefined;
        selectedQuestionCatalogId: number;
        selectedReportId: number;
        selectedReport: ReportDto | undefined;
        selectedQuestionCatalog: QuestionCatalogDto | undefined;
        title: string;
        validations: Record<string, Validation>;
        reportTableTitle: string;
        questionCatalogTableTitle: string;
        questionCatalogTableFilterValues: QuestionCatalogListEndpointRequest;
        reportTableFilterValues: ReportListEndpointRequest;
        questionCatalogApi: QuestionCatalogService;
        reportApi: ReportService;
        } {
        return {
            selectedReportType: ReportType.MainReport,
            selectedLocation: undefined,
            selectedQuestionCatalogId: 0,
            selectedReportId: 0,
            selectedReport: undefined,
            selectedQuestionCatalog: undefined,
            title: '',
            validations: {
                title: {
                    maxLength: 500,
                    isValid: true,
                    helperText: '',
                }
            },
            reportTableTitle: this.$t('reportTable.tableTitle').toString(),
            questionCatalogTableTitle: this.$t('questionCatalogConfigTable.tableTitle').toString(),
            questionCatalogTableFilterValues: _.cloneDeep(questionCatalogTableDefaultFilterValues),
            reportTableFilterValues: _.cloneDeep(reportTableDefaultFilterValues),
            questionCatalogApi: new QuestionCatalogService(),
            reportApi: new ReportService(),
        }
    },
    mounted (): void {
        // for interim reports we need a filter for main reports
        reportTableDefaultFilterValues.reportTypeIds = [ReportType.MainReport];
        this.reportTableFilterValues.reportTypeIds = [ReportType.MainReport];

        // In addition, set the correct year
        const currentYear = moment.tz(moment.tz.guess()).year();
        const years = [currentYear, currentYear - 1];
        reportTableDefaultFilterValues.reportYears = years;
        this.reportTableFilterValues.reportYears = years;

        // Test report mode? Then we set the location automatically
        if (this.questionCatalogTableFilterValues?.questionCatalogStatus && this.testReportMode) {
            // Test Location has Id -1
            this.selectedLocation = (this.$store.getters['locations/locations'] as LocationDto[])
                .filter(x => x.id === -1)[0];
            this.questionCatalogTableFilterValues.questionCatalogStatus.push(QuestionCatalogStatus.TestRelease);
        }
    },
    watch: {
        catalogTypeIds: {
            handler (newVal: number): void {
                Vue.set(this.questionCatalogTableFilterValues, 'catalogTypeIds', newVal);
                Vue.set(this.reportTableFilterValues, 'catalogTypeIds', newVal);
            }
        }
    },
    computed: {
        selectedLocationId (): number | undefined {
            return this.selectedLocation?.id;
        },
        reportOptions (): FormSelectOption[] {
            return [
                {
                    label: this.$t('newReportModal.formLabels.mainReport').toString(),
                    value: ReportType.MainReport,
                },
                {
                    label: this.$t('newReportModal.formLabels.interimReport').toString(),
                    value: ReportType.InterimReport,
                }
            ];
        },
        userEditorLocations (): LocationDto[] {
            return this.$store.getters['userAccessManagement/getEditorUserLocations'];
        },
        locationOptions (): FormSelectOption[] {
            return this.userEditorLocations?.filter(x => (x?.id ?? -1) > 0)
                .map(x => ({
                    label: x.locationName ?? '',
                    value: x.id ?? 0,
                })) ?? [];
        },
        isMainReport (): boolean {
            return this.selectedReportType === ReportType.MainReport;
        },
        catalogTypeIds (): number[] {
            const allowedCatalogTypes = this.$store.getters['userAccessManagement/getEditorUserCatalogTypes'](this.selectedLocation?.id) as CatalogTypeDto[];
            const count = allowedCatalogTypes.length;
            // generate correct filter to show only these question catalogs to which to user has access
            // if the list is empty the user has access to no catalogs, but if we pass an empty list or null/undefined
            // the backend will interpret this as "no filter". However, we want to filter out everything, so we generate
            // a list with id -1 (no entity can have this id).
            return count > 0 ? allowedCatalogTypes.map(x => x.id ?? 0) : [-1]
        },
        canBeCreated (): boolean {
            if (this.selectedQuestionCatalogId && this.selectedReportType === ReportType.MainReport) {
                return true;
            }
            if (this.selectedReportId && this.selectedReportType === ReportType.InterimReport) {
                return true;
            }
            return false;
        }
    },
    methods: {
        questionCatalogFilterChangedHandler (filters: QuestionCatalogListEndpointRequest): void {
            filterChangedHandler(filters, questionCatalogTableDefaultFilterValues, this.questionCatalogTableFilterValues);
        },
        reportFilterChangedHandler (filters: ReportListEndpointRequest): void {
            filterChangedHandler(filters, reportTableDefaultFilterValues, this.reportTableFilterValues);
        },
        questionCatalogSelectedHandler (event: RowSelectedEvent): void {
            if (event.node.isSelected()) {
                const data  = event.node.data as QuestionCatalogTableData;
                this.selectedQuestionCatalogId = data.meta.questionCatalogDto?.id ?? 0;
                this.selectedQuestionCatalog = data.meta.questionCatalogDto;
            }
        },
        reportSelectedHandler (event: RowSelectedEvent): void {
            if (event.node.isSelected()) {
                const data  = event.node.data as ReportTableData;
                this.selectedReportId = data.meta.reportDto?.id ?? 0;
                this.selectedReport = data.meta.reportDto;
            }
        },
        async anotherMainReportExists (selectedQuestionCatalogId: number, catalogTypeId: number | undefined, locationId: number | undefined): Promise<boolean> {
            if (!catalogTypeId || !locationId) {
                // return true so check fails and no new report is created
                return true;
            }
            const response = await apiCallWithErrorHandling(
                this.reportApi.getReports,
                {
                    ids: undefined,
                    questionCatalogIds: [selectedQuestionCatalogId],
                    catalogTypeIds: [catalogTypeId],
                    reportTypeIds: [ReportType.MainReport],
                    reportYears: undefined,
                    locationIds: [locationId],
                    languageIds: undefined,
                    approvalStatus: undefined,
                    sortDirection: SortType.Ascending,
                    sortProperty: ReportSortColumn.Description,
                    page: 1,
                    size: 5,
                    Term: undefined,
                    TermToLower: undefined,
                },
                (this as any).$pui.toast,
                {
                    errorTitle: this.$t('questionCatalogDetails.reportCheckToast.errorTitle').toString(),
                    errorText: this.$t('questionCatalogDetails.reportCheckToast.errorText').toString(),
                },
                this.$store
            );
            // abort if there was an error
            if (!response || !response?.result) {
                return true;
            }
            return (response.result.totalCount != null && response.result.totalCount >= 1);
        },
        createReportDto(reportType: ReportType, creationDate: moment.Moment, currentUserId: number): ReportDto {
            return new ReportDto({
                id: 0,
                approvalStatus: ReportApprovalStatus.New,
                description: this.title,
                reportType: reportType,
                questionCatalogId: this.selectedQuestionCatalogId,
                catalogTypeId: this.selectedQuestionCatalog?.catalogTypeId ?? 0,
                locationId: this.selectedLocationId,
                version: 1,
                createdAt: creationDate.toISOString(false),
                creatorUser: currentUserId,
                languageId: (this.$store.getters['languages/languages'] as LanguageDto[])[0].id,
                updatedAt: creationDate.toISOString(false),
                lastModifiedUser: currentUserId,
                reportYear: this.selectedQuestionCatalog?.reportYear ?? creationDate.year(),
            });
        },
        async createReportHandler (): Promise<void> {
            const creationDate = moment.tz(moment.tz.guess());
            const currentUserId = this.$store.getters['userAccessManagement/getUserId'] as number;

            let reportDto = new ReportDto();
            // Difficult case: We have an interim report. This means we have to copy over some answers
            if (this.selectedReportId) {
                // Load catalog for this report first
                const response = await apiCallWithErrorHandling(
                    this.questionCatalogApi.getQuestionCatalog,
                    this.selectedReport?.questionCatalogId ?? 0,
                    (this as any).$pui.toast,
                    {
                        errorTitle: this.$t('newReportModal.catalogLoadingToast.errorTitle').toString(),
                        errorText: this.$t('newReportModal.catalogLoadingToast.errorText').toString(),
                    },
                    this.$store
                );

                // abort if there was an error
                if (!response || !response?.result) {
                    return;
                }

                // then get questions from it
                const catalog = response?.result as QuestionCatalogDto;
                const questionsToCopy = catalog.questionGroups?.flatMap(x => x.mainQuestions)
                    .filter(x => x?.cycle === 1) ?? [];
                const mainQuestionIds = questionsToCopy.map(x => x?.id).filter(x => x !== undefined);
                const subQuestionIds = questionsToCopy
                    .flatMap(x => x?.subQuestions)
                    .filter(x => x !== undefined)
                    .map(x => x?.id);
                const mainQuestionRows = _.cloneDeep(this.selectedReport?.reportRows?.filter(x => x.reportRowType === ReportRowType.MainQuestion && mainQuestionIds.includes(x.referenceId)));
                const subQuestionRows = _.cloneDeep(this.selectedReport?.reportRows?.filter(x => x.reportRowType === ReportRowType.SubQuestion && subQuestionIds.includes(x.referenceId)));
                const copiedRows = [mainQuestionRows, subQuestionRows].flatMap(x => x) as ReportRowDto[];
                reportDto = new ReportDto({
                    ...this.selectedReport,
                    id: 0,
                    description: this.title,
                    reportType: ReportType.InterimReport,
                    version: 1,
                    createdAt: creationDate.toISOString(false),
                    creatorUser: currentUserId,
                    updatedAt: creationDate.toISOString(false),
                    lastModifiedUser: currentUserId,
                    reportYear:  catalog?.reportYear ?? creationDate.year(),
                    preReportId: this.selectedReport?.id ?? 0,
                    reportRows: copiedRows,
                    approvalStatus: ReportApprovalStatus.New,
                    creatorApprovalDate: undefined,
                    creatorApprovalUser: undefined,
                    plantManagerApprovalDate: undefined,
                    plantManagerApprovalUser: undefined,
                });
            }
            // Test Report Mode
            else if (this.testReportMode) {
                reportDto = this.createReportDto(ReportType.TestReport, creationDate, currentUserId);
            }
            // Main report: We have to make sure no other report exists with the same catalog, year and location
            else {
                if (await this.anotherMainReportExists(this.selectedQuestionCatalogId, this.selectedQuestionCatalog?.catalogTypeId, this.selectedLocationId))
                {
                    (this as any).$pui.toast(
                        {
                            type: 'error',
                            title: this.$t('questionCatalogDetails.reportExistsCheckToast.errorTitle').toString(),
                            copy: this.$t('questionCatalogDetails.reportExistsCheckToast.errorText').toString(),
                        });

                    return;
                }
                reportDto = this.createReportDto(ReportType.MainReport, creationDate, currentUserId);
            }

            this.$emit('createNewReport', { report: reportDto });
            this.$emit('requestClose');
        },
        locationChangeHandler (locationId: number): void {
            this.selectedLocation = this.userEditorLocations.filter(x => x.id === locationId)[0];
            this.selectedQuestionCatalogId = 0;
            this.selectedReportId = 0;
            Vue.set(this.reportTableFilterValues, 'locationIds', [this.selectedLocation?.id ?? 0]);
            this.selectedQuestionCatalog = undefined;
            this.selectedReport = undefined;
        },
    }
});

export default NewReportModal;
