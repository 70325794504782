import { ApiException } from '@/service-proxies/service-proxies.g';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import { ToastArgument } from '@/models/pebble-ui';
import { Store } from 'vuex';
import { BasicResponse } from '@/models/api-response';
import { sleep } from '@/utils/javascript-utils';

const removeQueryOperatorsFromEndOfString = (queryString: string): string => {
    if (queryString.endsWith('&') || queryString.endsWith('?')) {
        return queryString.slice(0, -1);
    }
    return queryString;
}

/* eslint-disable sonarjs/cognitive-complexity */
export function filterToQueryString (filter: Record<string, any> | undefined): string {
    if (!filter) {
        return '';
    }

    let queryString = '?';

    for (const [key, value] of Object.entries(filter)) {
        if (value) {
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    for (const idx in value) {
                        queryString += `${key}[${idx}]=${value[idx]}&`;
                    }
                }
            } else {
                queryString = queryString.concat(`${key}=${value}&`);
            }
        }
    }

    queryString = removeQueryOperatorsFromEndOfString(queryString);

    return queryString;
}

type GetFirstArgumentOfAnyFunction<T> = T extends (
        first: infer FirstArgument,
        ...args: any[]
    ) => any
    ? FirstArgument
    : never;

export const apiCallWithErrorHandling = async <T extends (arg: any) => Promise<any>>(
    apiFunction: T,
    argument: GetFirstArgumentOfAnyFunction<T>,
    toastFunction: (arg: ToastArgument) => void,
    strings: {
        successTitle?: string;
        successText?: string;
        errorTitle: string;
        errorText: string;
        waitingTitle?: string;
        waitingText?: string;
        forbiddenErrorText?: string;
        conflictErrorText?: string;
    },
    store: Store<any>,
    additionalWaitInSeconds = 0,
    showWaitingModal = true): Promise<ReturnType<T>> => {
    if (showWaitingModal && strings.waitingText && strings.waitingTitle) {
        store.commit('waitingModal/WAITING', {
            title: strings.waitingTitle,
            content: strings.waitingText,
        });
    }

    let response: BasicResponse | undefined = undefined;
    let errorShown = false;
    try {
        response = await apiFunction(argument);
    } catch (error) {
        if (error.isApiException) {
            const apiException = error as ApiException;
            let errorText: string | undefined = undefined;
            if (apiException.status === HttpStatusCodes.Conflict) {
                errorText = strings.conflictErrorText ?? strings.errorText;
            } else if (apiException.status === HttpStatusCodes.Forbidden) {
                errorText = strings.forbiddenErrorText ?? strings.errorText;
            }
            if (errorText) {
                toastFunction({
                    type: 'error',
                    title: strings.errorTitle,
                    copy: errorText,
                });
                errorShown = true;
            }

            // Fill response with error code and message
            response = {
                statusCode: apiException.status,
                message: apiException.message,
            };
        }

        // Raised by UserFriendlyException? Then "error" looks different
        response = {
            statusCode: error.statusCode,
            message: error.message,
        };
    } finally {
        if (response?.statusCode === 200) {
            if (strings.successTitle && strings.successText) {
                toastFunction({
                    type: 'success',
                    title: strings.successTitle,
                    copy: strings.successText,
                });
            }
        } else {
            if (!errorShown) {
                toastFunction({
                    type: 'error',
                    title: strings.errorTitle,
                    copy: strings.errorText,
                });
            }
        }

        if (strings.waitingText && strings.waitingTitle) {
            if (additionalWaitInSeconds > 0) {
                await sleep(additionalWaitInSeconds * 1000);
            }
            if (showWaitingModal) {
                store.commit('waitingModal/CLOSE_AND_RESET');
            }
        }
    }

    return response;
}
