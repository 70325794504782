import Vue from 'vue';
import { Api } from '@coode/fe-sdk/dist/networking/api';
import { ApiResponse, ApiResponseList } from '@coode/fe-sdk/dist/types';
import { Application } from '@/models';
import { IUseCaseService } from '@/services/interfaces/IUseCaseService';

export class UseCaseService implements IUseCaseService {
    private readonly api: Api = Vue.prototype.$sdk?.$http?.$core.metaData.request.api;

    public async getUseCases(): Promise<ApiResponse<ApiResponseList<Application>>> {
        return (await this.api.get<ApiResponse<ApiResponseList<Application>>>('use-cases')).data;
    }
}
