import { render, staticRenderFns } from "./multi-select-filter.vue?vue&type=template&id=28ef0449&scoped=true"
import script from "./multi-select-filter.vue?vue&type=script&lang=ts"
export * from "./multi-select-filter.vue?vue&type=script&lang=ts"
import style0 from "./multi-select-filter.vue?vue&type=style&index=0&id=28ef0449&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ef0449",
  null
  
)

export default component.exports