
import { EventBus } from '@/utils';
import Vue from 'vue';
import { ICellRendererParams } from 'ag-grid-community';
import { LocationDto } from '@/service-proxies/service-proxies.g';
import { TableData } from '@/components/location-config-table/contracts/table-data';

const EditLocationButtonCell = Vue.extend({
    name: 'EditLocationButtonCell',
    data (): {
        buttonText: string | null;
        loaderText: string;
        isLoading: boolean;
        agGridParams: ICellRendererParams | undefined;
        } {
        return {
            buttonText: null,
            isLoading: false,
            loaderText: this.$t('loading').toString(),
            agGridParams: undefined,
        };
    },
    beforeMount() {
        // this.params contains the cell & row information and
        // is made available to this component at creation time
        // see ICellRendererParams for more details
        this.agGridParams = (this as any).params;
        if (this.agGridParams) {
            this.buttonText = this.getValueToDisplay(this.agGridParams);
        }

        EventBus.$on(EventBus.VIEWS.ADMIN.LOCATION_DETAILS_LOADING, (locationId: number, loadingState: boolean) => {
            if (this.locationId === locationId) {
                this.isLoading = loadingState;
            }
        });
    },
    computed: {
        tableData(): TableData {
            return (this as any).params.data as TableData;
        },
        locationId(): number {
            return Number(this.tableData.meta.locationId);
        },
        locationDto(): LocationDto {
            return this.tableData.meta.locationDto as LocationDto;
        }
    },
    methods: {
        buttonClicked (): void {
            EventBus.$emit(EventBus.VIEWS.ADMIN.LOCATION_EDIT_BUTTON_CLICKED, this.locationDto);
        },
        getValueToDisplay(params: ICellRendererParams): any {
            return params.valueFormatted ? params.valueFormatted : params.value;
        }
    }
});
export default EditLocationButtonCell;
