import {
    LanguageDto,
    LanguageListEndpointResponse,
    LanguageSortColumn,
    SortType
} from '@/service-proxies/service-proxies.g';
import { LanguageService } from '@/services/language-service';
import axios, { AxiosError } from 'axios';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { applicationState } from '@/store';

export type LanguagesState = {
    languagesApi: LanguageService;
    languages: LanguageDto[];
}

export type LanguagesGetters = {
    languages: (state: LanguagesState) => LanguageDto[];
} & GetterTree<LanguagesState, typeof applicationState>

export type LanguagesActions = {
    loadLanguages: ({ commit, state }: { commit: any; state: LanguagesState }) => Promise<number>;
} & ActionTree<LanguagesState, typeof applicationState>

export type LanguagesMutations = {
    SET_LANGUAGES: (state: LanguagesState, languages: LanguageListEndpointResponse | undefined) => void;
} & MutationTree<LanguagesState>

export type LanguageModule = {
    state: LanguagesState;
    getters: LanguagesGetters;
    mutations: LanguagesMutations;
    actions: LanguagesActions;
} & Module<LanguagesState, typeof applicationState>

const state: LanguagesState = {
    languagesApi: new LanguageService(),
    languages: [],
}

const getters: LanguagesGetters = {
    languages: (state: LanguagesState): LanguageDto[] => state.languages,
}

const actions: LanguagesActions = {
    async loadLanguages ({ commit, state }: { commit: any; state: LanguagesState }): Promise<number> {
        try {
            const languages = await state.languagesApi.getLanguages(
                {
                    sortDirection: SortType.Ascending,
                    sortProperty: LanguageSortColumn.LanguageAbbr,
                    page: 1,
                    size: 10000,
                    term: undefined,
                    termToLower: undefined
                }
            );
            commit('SET_LANGUAGES', languages.result);
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
            } else {
                console.error(`An unknown error occurred while fetching the language data.`);
                console.error(e);
                return HttpStatusCodes.InternalServerError;
            }
        }
        return HttpStatusCodes.Ok;
    }
}

const mutations: LanguagesMutations = {
    SET_LANGUAGES (state: LanguagesState, languages: LanguageListEndpointResponse | undefined): void {
        if (languages) {
            state.languages = languages.items ?? [];
        }
    },
}

const namespaced = true;

export const languages: LanguageModule = {
    namespaced: namespaced,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};
