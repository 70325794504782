import Vue from 'vue';
import { EventBus } from '@/utils';
import UserConfigTable from '@/components/user-config-table/user-config-table.vue';
import { UserListEndpointRequest } from '@/models';
import {
    CatalogTypeDto,
    CatalogTypeSortColumn,
    LocationDto,
    LocationSortColumn,
    SortType,
    UserDto,
    UserSortColumn
} from '@/service-proxies/service-proxies.g';
import UserDetails from '@/components/user-administration/user-details/user-details.vue';
import { UserService } from '@/services/user-service';
import { LocationService } from '@/services/location-service';
import { CatalogTypeService } from '@/services/catalog-type-service';
import axios from 'axios';
import { WAITING_MODAL_DEFAULT_TEXT, WAITING_MODAL_DEFAULT_TITLE } from '@/store/modules/waiting-modal';
import { filterChangedHandler } from '@/utils/table-utils';
import _ from 'lodash';
import WarningModal from '@/components/warning-modal/warning-modal.vue'
import { ConfirmationModalProps } from '@/store/modules/confirmation-modal';
import { UserRoles } from '@/enums/user-roles';

const defaultFilter = {
    SortDirection: SortType.Ascending,
    SortColumn: UserSortColumn.Kid,
    UserIds: undefined,
    UserKIDs: undefined,
    LocationIds: undefined,
    CatalogTypes: undefined,
    page: 1,
    size: 12,
    Term: undefined,
    TermToLower: undefined
}

const UserAdministration = Vue.extend({
    name: 'UserAdministration',
    components: {
        UserConfigTable,
        UserDetails,
        WarningModal
    },
    props: {
        componentName: {
            type: String,
            required: true
        },
        componentDisplayValue: {
            type: String,
            required: true
        },
    },
    data (): {
        dataLoaded: boolean;
        filterValues: UserListEndpointRequest;
        tableTitle: string;
        selectedUserDetailsCandidate: UserDto | undefined;
        users: UserDto[];
        locations: LocationDto[];
        catalogTypes: CatalogTypeDto[];
        userApi: UserService;
        locationApi: LocationService;
        catalogTypeApi: CatalogTypeService;
        dataChanged: boolean;
        saveData: boolean;
        } {
        return {
            dataLoaded: false,
            filterValues: _.cloneDeep(defaultFilter),
            tableTitle: this.$t('userConfigTable.tableTitle').toString(),
            selectedUserDetailsCandidate: undefined,
            users: [] as UserDto[],
            locations: [] as LocationDto[],
            catalogTypes: [] as CatalogTypeDto[],
            userApi: new UserService(),
            locationApi: new LocationService(),
            catalogTypeApi: new CatalogTypeService(),
            dataChanged: false,
            saveData: false,
        }
    },
    created (): void {
        // noop
    },
    async mounted (): Promise<void> {
        this.registerCallbacks();
        await this.loadInitialData();
    },
    computed: {
        candidateIsAdmin (): boolean {
            return this.selectedUserDetailsCandidate?.getUserRole(this.$store.getters['appData/useCaseId']) === UserRoles.Admin;
        }
    },
    methods: {
        confirmResetAll (): void {
            this.$store.commit('confirmationModal/WAIT_FOR_CONFIRMATION', {
                header: this.$t('userDetails.resetAll.header').toString(),
                footerConfirmLabel: this.$t('yes').toString(),
                footerCancelLabel: this.$t('no').toString(),
                content: this.$t('userDetails.resetAll.content').toString(),
                onConfirmCallback: () => {
                    (this.$refs['userDetailsModal'] as any).resetAllPermissionsForUser();
                    this.$store.commit('confirmationModal/CLOSE_AND_RESET')
                },
                onCancelCallback: () => this.$store.commit('confirmationModal/CLOSE_AND_RESET'),
            } as ConfirmationModalProps)
        },
        saveChanges(): void {
            this.saveData = !this.saveData;
        },
        updateDataChanged(passedValue: boolean): void {
            this.dataChanged = passedValue;
        },
        closeDialog(): void {
            if(this.dataChanged){
                EventBus.$emit(EventBus.GLOBAL.SHOW_WARNING_MODAL);
            } else {
                this.toggleDetailsModal()
            }
        },
        registerCallbacks (): void {
            EventBus.$on(EventBus.VIEWS.ADMIN.USER_EDIT_BUTTON_CLICKED,
                async (userDto: UserDto) => {
                    try {
                        EventBus.$emit(EventBus.VIEWS.ADMIN.USER_DETAILS_LOADING, userDto.id, true);

                        this.selectedUserDetailsCandidate = userDto;

                        if (this.selectedUserDetailsCandidate) {
                            this.toggleDetailsModal();
                        }
                    } catch(error) {
                        console.error(error);
                        // TODO: Error Toast
                        // this.showDetailsErrorToast();
                    } finally {
                        EventBus.$emit(EventBus.VIEWS.ADMIN.USER_DETAILS_LOADING, userDto.id, false);
                    }
                }
            );

            EventBus.$on(EventBus.VIEWS.ADMIN.SUBMIT_USER_CHANGES,
                () => {
                    //noop
                }
            );
        },
        async loadInitialData (): Promise<void> {
            this.$store.commit('waitingModal/WAITING', {
                title: this.$t(WAITING_MODAL_DEFAULT_TITLE),
                content: this.$t(WAITING_MODAL_DEFAULT_TEXT)
            });
            const locationSuccess = await this.loadLocations();
            const catalogTypeSuccess = await this.loadCatalogTypes();
            if (locationSuccess && catalogTypeSuccess) {
                this.dataLoaded = true;
            }
            this.$store.commit('waitingModal/CLOSE_AND_RESET');
        },
        async loadUserKids (): Promise<boolean> {
            try {
                const result = await this.userApi.getUsers({
                    UserIds: undefined,
                    UserKIDs: undefined,
                    LocationIds: undefined,
                    CatalogTypes: undefined,
                    SortColumn: UserSortColumn.Kid,
                    SortDirection: SortType.Ascending,
                    page: 1,
                    size: 10000,
                    Term: undefined,
                    TermToLower: undefined,
                });
                if (result.result?.items) {
                    this.locations = result.result?.items;
                }
                return true;
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    // TODO: Show axios error toast
                    console.error(e);
                } else {
                    // TODO: Show error toast
                    console.error(`An unknown error occured while fetching the locations.`);
                    console.error(e);
                }
                return false;
            }
        },
        async loadLocations (): Promise<boolean> {
            try {
                const result = await this.locationApi.getLocations({
                    locationIds: undefined,
                    catalogTypeIds: undefined,
                    sortDirection: SortType.Ascending,
                    sortProperty: LocationSortColumn.LocationAbbr,
                    page: 1,
                    size: 10000,
                    Term: undefined,
                    TermToLower: undefined
                });
                if (result.result?.items) {
                    this.locations = result.result?.items;
                }
                return true;
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    // TODO: Show axios error toast
                    console.error(e);
                } else {
                    // TODO: Show error toast
                    console.error(`An unknown error occured while fetching the locations.`);
                    console.error(e);
                }
                return false;
            }
        },
        async loadCatalogTypes(): Promise<boolean> {
            try {
                const result = await this.catalogTypeApi.getCatalogTypes({
                    sortDirection: SortType.Ascending,
                    sortProperty: CatalogTypeSortColumn.CatalogTypeAbbr,
                    catalogTypeAbbrs: undefined,
                    page: 1,
                    size: 10000,
                    term: undefined,
                    termToLower: undefined
                });
                if (result.result?.items) {
                    this.catalogTypes = result.result?.items;
                }
                return true;
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    // TODO: Show axios error toast
                    console.error(e);
                } else {
                    // TODO: Show error toast
                    console.error(`An unknown error occured while fetching the catalog types.`);
                    console.error(e);
                }
                return false;
            }
        },
        toggleDetailsModal(): void {
            try {
                (this.$refs.detailsUserModalRef as any).isOpen = !((this.$refs.detailsUserModalRef as any).isOpen);
                if (!((this.$refs.detailsUserModalRef as any).isOpen)) {
                    this.selectedUserDetailsCandidate = undefined;
                }
            } catch (err) {
                // Catching buggy pebble ui type error
                // console.log(err);
            }
        },
        showDetailsErrorToast (): void {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('monitoring.detailsToast.errorTitle').toString(),
                copy: this.$t('monitoring.detailsToast.errorText').toString(),
            });
        },
        filterChangedHandler (filters: UserListEndpointRequest): void {
            filterChangedHandler(filters, defaultFilter, this.filterValues);
        }
    }
});

export default UserAdministration;
