import Exporter from '@/components/exporter/exporter.vue';
import Vue from 'vue';

const ExportView = Vue.extend({
    name: 'ExportView',
    components: {
        Exporter
    },
    props: {
    },
    data (): {
        show: boolean;
        } {
        return {
            show: false,
        }
    },
    mounted (): void {
        this.sidebarSetup();
    },
    computed: {
    },
    methods: {
        sidebarSetup (): void {
            this.$store.dispatch('sidebar/setCurrentSidebar',
                {
                    shouldShowSidebar: false,
                    currentSidebarComponent: '',
                    sidebarComponentProperties: {}
                });
        }
    }
});
export default ExportView;
