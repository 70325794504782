import {
    CatalogTypeDto,
    CatalogTypeListEndpointResponse,
    CatalogTypeSortColumn,
    SortType
} from '@/service-proxies/service-proxies.g';
import { CatalogTypeService } from '@/services/catalog-type-service';
import axios, { AxiosError } from 'axios';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { applicationState } from '@/store';

export type CatalogTypesState = {
    catalogTypesApi: CatalogTypeService;
    catalogTypes: CatalogTypeDto[];
}

export type CatalogTypesGetters = {
    catalogTypes: (state: CatalogTypesState) => CatalogTypeDto[];
} & GetterTree<CatalogTypesState, typeof applicationState>

export type CatalogTypesActions = {
    loadCatalogTypes: ({ commit, state }: { commit: any; state: CatalogTypesState }) => Promise<number>;
} & ActionTree<CatalogTypesState, typeof applicationState>

export type CatalogTypesMutations = {
    SET_CATALOG_TYPES: (state: CatalogTypesState, catalogTypes: CatalogTypeListEndpointResponse | undefined) => void;
} & MutationTree<CatalogTypesState>

export type CatalogTypeModule = {
    state: CatalogTypesState;
    getters: CatalogTypesGetters;
    mutations: CatalogTypesMutations;
    actions: CatalogTypesActions;
} & Module<CatalogTypesState, typeof applicationState>

const state: CatalogTypesState = {
    catalogTypesApi: new CatalogTypeService(),
    catalogTypes: [],
}

const getters: CatalogTypesGetters = {
    catalogTypes: (state: CatalogTypesState): CatalogTypeDto[] => state.catalogTypes,
}

const actions: CatalogTypesActions = {
    async loadCatalogTypes ({ commit, state }: { commit: any; state: CatalogTypesState }): Promise<number> {
        try {
            const catalogTypes = await state.catalogTypesApi.getCatalogTypes(
                {
                    sortDirection: SortType.Ascending,
                    sortProperty: CatalogTypeSortColumn.CatalogTypeAbbr,
                    catalogTypeAbbrs: undefined,
                    page: 1,
                    size: 10000,
                    term: undefined,
                    termToLower: undefined
                }
            );
            commit('SET_CATALOG_TYPES', catalogTypes.result);
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
            } else {
                console.error(`An unknown error occurred while fetching the catalog type data.`);
                console.error(e);
                return HttpStatusCodes.InternalServerError;
            }
        }
        return HttpStatusCodes.Ok;
    }
}

const mutations: CatalogTypesMutations = {
    SET_CATALOG_TYPES (state: CatalogTypesState, catalogTypes: CatalogTypeListEndpointResponse | undefined): void {
        if (catalogTypes) {
            state.catalogTypes = catalogTypes.items ?? [];
        }
    },
}

const namespaced = true;

export const catalogTypes: CatalogTypeModule = {
    namespaced: namespaced,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};
