import { EventBus } from '@/utils';
import Vue from 'vue';

/**
 * When using this component, listen to the emit at line 34 (@confirm-close-component) inside the other component.vue
 * and link that to the method responsible for closing the pop-up
 */

const WarningModal = Vue.extend({
    name: 'WarningModal',
    components: {},
    mounted(): void {
        EventBus.$on(EventBus.GLOBAL.SHOW_WARNING_MODAL, () => {
            this.toggleDialog(true);
        })
    },
    methods: {
        toggleDialog(state: boolean): void {
            try{
                if(!state){
                    (this.$refs['globalCloseDialogModalRef'] as any).close();
                }
                else{
                    (this.$refs['globalCloseDialogModalRef'] as any).open();
                }
            } catch(err) {
                console.log(err)
            }

        },
        emitCloseOrder(): void {
            this.$emit('confirm-close-component');
            this.toggleDialog(false);
        }

    }
});

export default WarningModal;
